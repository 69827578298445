import { Form, FormikProps } from "formik";
import React from "react";

import { ButtonRowDefault } from "@src/Components/Buttons/ButtonRow";
import { FieldLabel, Fieldset, InputGroup, ReadOnlyInput } from "@src/Components/Input/InputGroup";

import { CountryCodeSelect } from "./CountryCodeSelect";
import { FederationGuestFormInput } from "./formSchemas";
import { SiteSelectorInput } from "./SiteSelectorInput";

interface GuestFormInnerProps extends FormikProps<FederationGuestFormInput> {
  navigateBack: () => void;
  isEdit?: boolean;
}

export function GuestFormInner({
  navigateBack,
  isEdit = false,
  ...formikProps
}: GuestFormInnerProps) {
  const { errors, values, setFieldValue } = formikProps;

  return (
    <Form>
      <Fieldset>
        {!isEdit ? (
          <InputGroup label="federation name" name="federationName" errors={errors} />
        ) : (
          <>
            <FieldLabel htmlFor={"federation name"}>federation name</FieldLabel>
            <ReadOnlyInput name="federationName" />
          </>
        )}
        {!isEdit ? (
          <InputGroup label="operator name" name="operatorName" errors={errors} />
        ) : (
          <>
            <FieldLabel htmlFor={"operator name"}>operator name</FieldLabel>
            <ReadOnlyInput name="operatorName" />
          </>
        )}
        <CountryCodeSelect selectedCode={values.countryCode} errors={errors} />
        <InputGroup label="MCC" name="mcc" errors={errors} />
        <InputGroup label="MNCs" name="mncs" errors={errors} />
        <SiteSelectorInput
          setFieldValue={setFieldValue}
          fieldLabel="parentSite"
          parentSiteId={values.parentSite}
          errors={errors}
          disabled={isEdit}
        />

        <ButtonRowDefault
          onClickBack={navigateBack}
          isSubmitting={formikProps.isSubmitting}
          isValid={formikProps.isValid}
          backText="Cancel"
          submitText="Save"
        />
      </Fieldset>
    </Form>
  );
}
