import React from "react";
import styled, { css } from "styled-components";

import LogsIcon from "@img/logs.svg";
import ScrollIcon from "@img/scroll-solid.svg";
import { Clickable } from "@src/Components/Buttons/Clickable";

import { ActionsCell } from "./ActionsCell";
import { iconSize } from "./iconSize";

const iconStyles = css`
  ${iconSize}
  fill: #444b88;
`;

const Scroll = styled(ScrollIcon)`
  ${iconStyles}
`;

const Logs = styled(LogsIcon)`
  ${iconStyles}
`;

interface OktoResourceActionCellProps {
  showManifest: () => void;
  showLogs: () => void;
}

export function OktoResourceActionCell({ showManifest, showLogs }: OktoResourceActionCellProps) {
  return (
    <ActionsCell>
      <Clickable onClick={showManifest} title="manifest">
        <Scroll />
      </Clickable>
      <Clickable onClick={showLogs} title="logs">
        <Logs />
      </Clickable>
    </ActionsCell>
  );
}
