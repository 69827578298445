import React, { Dispatch } from "react";

import { CategoryItem, Title } from "@src/Components/BlockSelector/BlockSelectorList";
import { MarketplaceAction } from "@src/ServiceDesigner/marketplaceReducer";

import { VendorItem } from "../../ServiceDesigner/VendorItem";
import { Loading } from "../Loading/Loading";

type CategoriesFilterProps = {
  loading: boolean;
  allCategories: string[];
  selectedCategory: string;
  allVendors: string[];
  selectedVendors: string[];
  dispatch: Dispatch<MarketplaceAction>;
};

export function CategoriesFilter({
  loading,
  allCategories,
  allVendors,
  selectedCategory,
  selectedVendors,
  dispatch
}: CategoriesFilterProps) {
  return (
    <div>
      <Title mTop="23px">categories</Title>
      {loading ? (
        <Loading />
      ) : (
        allCategories.map(c => (
          <CategoryItem
            onClick={() => dispatch({ type: "selectCategory", value: c })}
            key={c}
            selected={selectedCategory === c}
          >
            {c}
            <span
              onClick={e => {
                e.stopPropagation();
                dispatch({ type: "removeCategory" });
              }}
            />
          </CategoryItem>
        ))
      )}
      <Title>vendors</Title>
      {loading ? (
        <Loading />
      ) : (
        allVendors.map(v => (
          <VendorItem
            key={v}
            vendorName={v}
            selectedVendors={selectedVendors}
            dispatch={dispatch}
          />
        ))
      )}
    </div>
  );
}
