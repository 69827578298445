import React from "react";
import { Navigate } from "react-router-dom";

import { useIsMno } from "./Hooks/isMno";
import { useIsSuperAdmin } from "./Hooks/isSuperAdmin";

export function InitialRedirect() {
  const { isMno } = useIsMno();
  const { isSuperAdmin } = useIsSuperAdmin();

  return isMno == null || isSuperAdmin == null ? null : (
    <Navigate
      to={
        isSuperAdmin
          ? "app/settings/user-management"
          : isMno
          ? "app/settings/federation"
          : "app/services"
      }
    />
  );
}
