import React, { ChangeEvent, useCallback } from "react";

import { LazyQueryResult, QueryLazyOptions, useQuery } from "@apollo/client";
import { ErrorLine } from "@src/Components/Input/InputGroup";
import { InlineLoading } from "@src/Components/Loading/Loading";
import { H3 } from "@src/Components/Text";
import {
  ChartKey,
  Exact,
  FetchCrossplaneConfigQuery,
  ListCrossplaneChartsQuery
} from "@src/generated/graphql";

import ListCrossplaneCharts from "./ListCrossplaneCharts.graphql";

interface CrossplaneChartSelectProps {
  setChart: (val: ChartKey) => void;
  fetchChartConfig: (
    options?: QueryLazyOptions<Exact<{ name: string; version: string }>>
  ) => Promise<
    LazyQueryResult<
      FetchCrossplaneConfigQuery,
      {
        name: string;
        version: string;
      }
    >
  >;
}

export function CrossplaneChartSelect({ setChart, fetchChartConfig }: CrossplaneChartSelectProps) {
  const { data, loading } = useQuery<ListCrossplaneChartsQuery>(ListCrossplaneCharts);

  const onChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      if (!e.target.value) {
        setChart(null);
      } else {
        const profile: ChartKey = JSON.parse(e.target.value);
        setChart(profile);
        fetchChartConfig({ variables: profile });
      }
    },
    [setChart, fetchChartConfig]
  );

  return (
    <>
      <H3>Resource Provider</H3>
      {loading ? (
        <InlineLoading />
      ) : data ? (
        <select onChange={onChange}>
          <option value="">select type...</option>
          {data.crossplaneCharts.map(cc => {
            const key = `${cc.key.name}-${cc.key.version}`;
            return (
              <option key={key} value={JSON.stringify(cc.key)}>
                {cc.displayName}
              </option>
            );
          })}
        </select>
      ) : (
        <ErrorLine>failed to load types</ErrorLine>
      )}
    </>
  );
}
