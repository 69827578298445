import React, { ChangeEvent, useCallback, useState } from "react";
import { css } from "styled-components";

import { ButtonGroupModal } from "@src/Components/Buttons/ButtonGroup";
import { DangerButton } from "@src/Components/Buttons/Danger";
import { PrimaryButton } from "@src/Components/Buttons/Primary";
import { PrimaryTextButton } from "@src/Components/Buttons/Text";
import { Modal, Title } from "@src/Components/Modal/Modal";
import { styled } from "@src/Components/theme";

import { FormBlock } from "./serialise";

const Wrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.border};
`;

const Instance = styled.div<{ all?: boolean }>`
  padding: 5px 10px;
  display: flex;
  gap: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.border};
  ${({ all, theme }) =>
    all &&
    css`
      background-color: ${theme.backgroundLight};
    `}

  :last-child {
    border-bottom: none;
  }
`;

interface InstancesModalProps {
  selectedBlocks: FormBlock[];
  modalShown: boolean;
  actionName: string;
  hideModal: () => void;
  action: (blocks: FormBlock[]) => void;
}

export function InstancesModal({
  selectedBlocks,
  modalShown,
  actionName,
  hideModal,
  action
}: InstancesModalProps) {
  const [removeInstances, setRemoveInstances] = useState<FormBlock[]>([]);

  const updateSelectedInstances = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const instanceId = e.target.id;
      const checked = e.target.checked;
      setRemoveInstances(prevState => {
        const updatedInstances = checked
          ? [...prevState, selectedBlocks.find(sB => sB.displayName === instanceId)]
          : prevState.filter(b => b.displayName !== instanceId);
        return updatedInstances;
      });
    },
    [setRemoveInstances, selectedBlocks]
  );

  const updateAllInstances = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const checked = e.target.checked;
      setRemoveInstances(() => {
        const updatedInstances = checked ? selectedBlocks : [];
        return updatedInstances;
      });
    },
    [setRemoveInstances, selectedBlocks]
  );
  const [firstStep, setFirstStep] = useState(true);
  const toggleFirstStep = useCallback(() => setFirstStep(true), [setFirstStep]);
  const toggleSecondStep = useCallback(() => setFirstStep(false), [setFirstStep]);

  const hide = useCallback(() => {
    toggleFirstStep();
    setRemoveInstances([]);
    hideModal();
  }, [toggleFirstStep, hideModal, setRemoveInstances]);

  const doAction = useCallback(() => {
    action(removeInstances);
    hide();
  }, [removeInstances, action, hide]);

  return (
    <Modal show={modalShown} onOutsideClick={hide}>
      {firstStep ? (
        <>
          <Title>Select the block instances you want to {actionName}:</Title>
          <Wrapper>
            <Instance all>
              <input
                type="checkbox"
                id="all"
                onChange={updateAllInstances}
                checked={removeInstances.length === selectedBlocks.length}
              />
              <label htmlFor="all">All</label>
            </Instance>
            {selectedBlocks.map(b => (
              <Instance key={b.displayName}>
                <input
                  type="checkbox"
                  id={b.displayName}
                  onChange={updateSelectedInstances}
                  checked={removeInstances.some(rI => rI.displayName === b.displayName)}
                />
                <label htmlFor={b.displayName}>{b.displayName}</label>
              </Instance>
            ))}
          </Wrapper>
          <ButtonGroupModal>
            <PrimaryTextButton onClick={hideModal}>cancel</PrimaryTextButton>
            <PrimaryButton onClick={toggleSecondStep} disabled={removeInstances.length === 0}>
              Continue
            </PrimaryButton>
          </ButtonGroupModal>
        </>
      ) : (
        <>
          <Title>Confirm block instances {actionName}</Title>

          <p>
            The configuration will not be able to be recovered. Are you sure you want to{" "}
            {actionName} the following instances?
          </p>
          <ul>
            {removeInstances.map(rI => (
              <li key={rI.displayName}>{rI.displayName}</li>
            ))}
          </ul>
          <ButtonGroupModal>
            <PrimaryTextButton onClick={toggleFirstStep}>back</PrimaryTextButton>
            <DangerButton onClick={doAction}>{actionName}</DangerButton>
          </ButtonGroupModal>
        </>
      )}
    </Modal>
  );
}
