import React, { useCallback } from "react";

import { ApolloQueryResult } from "@apollo/client";
import { Clickable } from "@src/Components/Buttons/Clickable";
import { styled } from "@src/Components/theme";
import {
  Exact,
  FetchAuditLogsQuery,
  FetchAuditLogsQueryVariables,
  PageInfo
} from "@src/generated/graphql";

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
`;

interface AuditLogsPaginationProps {
  fetchLogs: (
    variables?: Partial<Exact<FetchAuditLogsQueryVariables>>
  ) => Promise<ApolloQueryResult<FetchAuditLogsQuery>>;
  pageInfo: PageInfo;
  totalCount: number;
  logsShown: number;
}

export function AuditLogsPagination({
  fetchLogs,
  pageInfo,
  totalCount,
  logsShown
}: AuditLogsPaginationProps) {
  const { startCursor, endCursor, hasNextPage, hasPreviousPage } = pageInfo;

  const fetchNextLogs = useCallback(() => {
    fetchLogs({ afterCursor: endCursor, beforeCursor: null });
  }, [endCursor, fetchLogs]);

  const fetchPreviousLogs = useCallback(() => {
    fetchLogs({ beforeCursor: startCursor, afterCursor: null });
  }, [startCursor, fetchLogs]);

  return (
    <Pagination>
      <Clickable onClick={fetchPreviousLogs} disabled={!hasPreviousPage}>
        {"<"}
      </Clickable>
      <p>
        Showing {logsShown} of {totalCount} logs
      </p>
      <Clickable onClick={fetchNextLogs} disabled={!hasNextPage}>
        {">"}
      </Clickable>
    </Pagination>
  );
}
