import React, { useMemo } from "react";
import {
  Cell,
  Column,
  HeaderGroup,
  Row,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable
} from "react-table";
import { StyledComponent } from "styled-components";

import { theme } from "@src/Components/theme";

import { Clickable } from "../Buttons/Clickable";
import { styled } from "../theme";
import { SelectionColumn } from "./RowSelectionColumn";
import { Table, Td, Th, THead, Tr } from "./Table";

const Wrapper = styled.div<{ tableWidth?: string; tableMargin?: string }>`
  width: ${({ tableWidth }) => (tableWidth ? tableWidth : "100%")};
  margin: ${({ tableMargin }) => (tableMargin ? tableMargin : "30px auto")};
`;

const TableTh = styled(Th)`
  :first-child,
  :last-child {
    padding-left: 25px;
  }
`;

const EmptyTable = styled(Td)`
  text-align: center;
  font-style: italic;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export type ActionsColumn = "_actions";

interface TableComponentProps<D extends object> {
  items: D[];
  columnHeaders: Array<Column<D>>;
  rowSelection?: boolean;
  leftActions?: Column<{ _actions: unknown }>;
  rightActions?: Column<{ _actions: unknown }>;
  pagination?: boolean;
  pageSize?: number;
  tableWidth?: string;
  tableMargin?: string;
  hiddenColumns?: string[];
  sortArray?: { id: string; desc: boolean }[];
  StyledTr?: StyledComponent<"tr", typeof theme>;
  StyledTd?: StyledComponent<"td", typeof theme>;
  StyledTh?: StyledComponent<"th", typeof theme>;
}

export function TableComponent<D extends object>({
  items,
  columnHeaders,
  rowSelection = false,
  leftActions = null,
  rightActions = null,
  pagination = false,
  pageSize = 10,
  tableWidth,
  tableMargin,
  hiddenColumns = [],
  sortArray = [
    { id: "displayName", desc: false },
    { id: "name", desc: false }
  ],
  StyledTr = Tr,
  StyledTd = Td,
  StyledTh = TableTh
}: TableComponentProps<D>) {
  type C = D & { _actions: unknown };

  const data = useMemo(() => items, [items]);
  const columns: Array<Column<C>> = useMemo(() => {
    const selectionColumn: Array<Column<C>> = rowSelection
      ? [SelectionColumn(pagination) as Column<C>]
      : [];
    const rightActionsColumn: Array<Column<C>> = rightActions ? [rightActions as Column<C>] : [];
    const leftActionsColumn: Array<Column<C>> = leftActions ? [leftActions as Column<C>] : [];
    return [
      ...selectionColumn,
      ...leftActionsColumn,
      ...(columnHeaders as Column<C>[]),
      ...rightActionsColumn
    ];
  }, [columnHeaders, rightActions, leftActions, pagination, rowSelection]);

  const tableProps = useTable<C>(
    {
      columns,
      data: data as C[],
      initialState: {
        pageSize,
        sortBy: sortArray,
        hiddenColumns
      },
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      autoResetGlobalFilter: false
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const {
    headerGroups,
    page,
    rows,
    state,
    pageOptions,
    pageCount,
    getTableProps,
    getTableBodyProps,
    prepareRow,
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage
  } = tableProps;

  const totalColumnsLength =
    columnHeaders.length + (leftActions ? 1 : 0) + (rightActions ? 1 : 0) + (rowSelection ? 1 : 0);

  return (
    <Wrapper tableWidth={tableWidth} tableMargin={tableMargin}>
      <Table {...getTableProps()}>
        <colgroup>
          {columns.map((c, i) => (
            <col key={i} style={{ width: c.width }} />
          ))}
        </colgroup>
        {headerGroups.map((hg: HeaderGroup<C>) => (
          <THead key={hg.id} {...hg.getHeaderGroupProps()}>
            <tr>
              {hg.headers.map((column: HeaderGroup<C>) => (
                <StyledTh key={column.id} {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span> {column.isSorted ? (column.isSortedDesc ? "↓" : "↑") : ""}</span>
                </StyledTh>
              ))}
            </tr>
          </THead>
        ))}
        <tbody {...getTableBodyProps()}>
          {(pagination ? page.length : rows.length) === 0 ? (
            <Tr>
              <EmptyTable colSpan={totalColumnsLength}>There are no elements to show</EmptyTable>
            </Tr>
          ) : (
            (pagination ? page : rows).map((row: Row<C>) => {
              prepareRow(row);
              return (
                <StyledTr key={row.id} {...row.getRowProps()}>
                  {row.cells.map((cell: Cell<C>) => {
                    const { column } = cell;
                    return (
                      <StyledTd key={column.id} {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </StyledTd>
                    );
                  })}
                </StyledTr>
              );
            })
          )}
        </tbody>
      </Table>
      {pagination && pageOptions.length > 1 && (
        <Pagination>
          <Clickable onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {"<<"}
          </Clickable>
          <Clickable onClick={previousPage} disabled={!canPreviousPage}>
            {"<"}
          </Clickable>
          <p>
            Page {state.pageIndex + 1} of {pageOptions.length}
          </p>
          <Clickable onClick={nextPage} disabled={!canNextPage}>
            {">"}
          </Clickable>
          <Clickable onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {">>"}
          </Clickable>
        </Pagination>
      )}
    </Wrapper>
  );
}
