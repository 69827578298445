import React from "react";

import { useQuery } from "@apollo/client";
import { InlineLoading } from "@src/Components/Loading/Loading";
import { UserOrgQuery, UserOrgQueryVariables } from "@src/generated/graphql";

import query from "./UserOrg.graphql";
import styled from "styled-components";

const OrgName = styled.span`
  font-weight: 600;
`;

export function UserOrg() {
  const { data } = useQuery<UserOrgQuery, UserOrgQueryVariables>(query);

  return <OrgName>{data?.user?.loggedInOrg?.displayName}</OrgName> || <InlineLoading />;
}
