import React from "react";
import { CellProps, Column, HeaderProps } from "react-table";

export const SelectionColumn = (pagination: boolean): Column => ({
  Header: ({
    getToggleAllRowsSelectedProps,
    getToggleAllPageRowsSelectedProps
  }: HeaderProps<object>) => {
    const props = pagination
      ? getToggleAllPageRowsSelectedProps()
      : getToggleAllRowsSelectedProps();
    props.indeterminate = props.indeterminate ? true : undefined;
    return <input type="checkbox" {...props} />;
  },
  accessor: "_selector",
  Cell: ({ row }: CellProps<object>) => (
    <input type="checkbox" {...row.getToggleRowSelectedProps()} />
  ),
  disableSortBy: true,
  width: 20
});
