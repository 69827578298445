import styled, { css } from "styled-components";

import { baseButtonStyles } from "./Base";

export const disabledStyles = css`
  background-color: #dadae7;
  color: #484886;
`;

export const primaryButtonStyles = css`
  ${baseButtonStyles}

  background-color: #484886;
  color: white;

  :hover {
    color: #b5b7d0;
  }

  :disabled {
    ${disabledStyles}
  }
`;

export const PrimaryButton = styled.button`
  ${primaryButtonStyles}
`;
