import { MockedResponse } from "@apollo/client/testing";
import { SetBlockRepoMutation, SetBlockRepoMutationVariables } from "@src/generated/graphql";
import { mockedResponses } from "@src/Mock/mockedResponse";

import SetBlockRepo from "./SetBlockRepo.graphql";

export const customizationsMocks = mockedResponses(<MockedResponse<SetBlockRepoMutation>>{
  request: {
    query: SetBlockRepo,
    variables: <SetBlockRepoMutationVariables>{
      repo: {
        url: "https://registry.nearbycomputing.com/chartrepo/blocks",
        username: "tom",
        password: "tom"
      }
    }
  },
  result: {
    data: {
      setBlockRepo: "https://registry.nearbycomputing.com/chartrepo/blocks"
    }
  }
});
