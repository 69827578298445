import React, { useState } from "react";
import { CellProps, Column } from "react-table";

import { Arrow } from "@src/Components//arrowStyles";
import { Th, Tr } from "@src/Components/Table/Table";
import { ActionsColumn, TableComponent } from "@src/Components/Table/TableComponent";
import { styled } from "@src/Components/theme";
import { AuditLogEntry, Org, User } from "@src/generated/graphql";
import { Td } from "@src/Instances/Table";

const StyledTr = styled(Tr)`
  height: 38px;
  :nth-child(even) {
    background-color: #f0f0f0;
  }
  :nth-child(odd) {
    background-color: white;
  }
`;
const StyledTd = styled(Td)`
  font-size: 12px;
  letter-spacing: initial;
  font-weight: 500;
  padding-left: 10px;
  background: inherit;
  :last-child {
    padding-right: 16px;
  }
`;
const StyledTh = styled(Th)`
  padding-left: 10px;
`;

const ExpandArrow = styled(Arrow)`
  cursor: pointer;
`;

const Message = styled.span`
  display: block;
  width: 100%;
  max-width: 35vw;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: "Courier New", Courier, monospace;
  font-weight: bold;
  /* font-family: monospace; */
`;
const ExpandedMessage = styled.span`
  display: block;
  padding: 6px 0;
  line-height: 1.8em;
  font-family: "Courier New", Courier, monospace;
  font-weight: bold;
`;

interface PanelLogsTableProps {
  logs: AuditLogEntry[];
}

interface LogColumn extends AuditLogEntry {
  entityName: string;
}

export function PanelLogsTable({ logs }: PanelLogsTableProps) {
  const [expandedLog, setExpandedLog] = useState<string>();

  const columns: Array<Column<LogColumn>> = [
    {
      Header: "Time",
      accessor: "time"
    },
    {
      Header: "Entity Type",
      accessor: "entityType"
    },
    {
      id: "Entity Name",
      Header: "Entity Name",
      accessor: "entity",
      Cell: ({ value }: CellProps<object>) => {
        return value?.name || "No Name";
      }
    },
    {
      Header: "Organization",
      accessor: "org",
      Cell: ({ value }: CellProps<object>) => {
        const { displayName } = value as Org;
        return displayName;
      }
    },
    {
      Header: "User",
      accessor: "user",
      Cell: ({ value }: CellProps<object>) => {
        const { email } = value as User;
        return email;
      }
    },
    {
      Header: "Type",
      accessor: "type"
    },
    {
      Header: "Reason",
      accessor: "reason"
    },
    {
      Header: "Message",
      accessor: "message",
      width: "40%",
      Cell: ({ value, row }: CellProps<object>) => {
        const rowLog = row.original as AuditLogEntry;
        const expanded = expandedLog === rowLog.time;
        return expanded ? <ExpandedMessage>{value}</ExpandedMessage> : <Message>{value}</Message>;
      }
    }
  ];

  const actionColumn = {
    Header: "",
    accessor: "_actions" as ActionsColumn,
    disableSortBy: true,
    Cell: ({ row }: CellProps<object>) => {
      const rowLog = row.original as AuditLogEntry;
      const expanded = expandedLog === rowLog.time;
      return (
        <ExpandArrow
          expanded={expanded}
          title={`expand log for ${rowLog.time}`}
          onClick={() => {
            setExpandedLog(!expanded ? rowLog.time : null);
          }}
        >
          <span />
        </ExpandArrow>
      );
    }
  };

  const tableItems: LogColumn[] = logs.map(log => ({ ...log, entityName: "" }));

  return (
    <TableComponent<LogColumn>
      columnHeaders={columns}
      leftActions={actionColumn}
      items={tableItems}
      tableMargin="0 auto 20px auto"
      StyledTr={StyledTr}
      StyledTd={StyledTd}
      StyledTh={StyledTh}
    />
  );
}
