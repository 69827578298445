import { ApolloError, useQuery } from "@apollo/client";
import {
  Device,
  FetchOrgDevicesQuery,
  FetchOrgDevicesQueryVariables,
  FetchSiteDevicesQuery,
  FetchSiteDevicesQueryVariables,
  Site
} from "@src/generated/graphql";

import FetchOrgDevices from "./FetchOrgDevices.graphql";
import FetchSiteDevices from "./FetchSiteDevices.graphql";

export interface UseInventoryFetch {
  devices: Device[];
  loading?: boolean;
  error?: ApolloError;
  ancestors: Site[];
}

export function useFetchSiteInventory(siteId: string) {
  const { data, loading, error } = useQuery<FetchSiteDevicesQuery, FetchSiteDevicesQueryVariables>(
    FetchSiteDevices,
    {
      variables: {
        id: siteId
      },
      pollInterval: 3000
    }
  );

  return {
    devices: data?.site?.devices?.devices,
    loading,
    error,
    ancestors: data?.site?.ancestors
  };
}
export function useFetchOrgInventory(orgId: string) {
  const { data, loading, error } = useQuery<FetchOrgDevicesQuery, FetchOrgDevicesQueryVariables>(
    FetchOrgDevices,
    {
      variables: {
        id: orgId
      },
      pollInterval: 3000
    }
  );

  const ancestors: Site[] = [];
  return {
    devices: data?.org?.devices?.devices,
    loading,
    error,
    ancestors
  };
}
