import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const FirstLine = styled.header`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: 1em;
  align-items: baseline;
`;
