import React from "react";
import { useSearchParams } from "react-router-dom";
import { CellProps, Column } from "react-table";

import { ApolloQueryResult } from "@apollo/client";
import EyeIcon from "@img/eye.svg";
import { Clickable } from "@src/Components/Buttons/Clickable";
import { Td, Tr } from "@src/Components/Table/Table";
import { ActionsColumn, TableComponent } from "@src/Components/Table/TableComponent";
import { styled } from "@src/Components/theme";
import {
  AuditLogEntry,
  Exact,
  FetchAuditLogsQuery,
  FetchAuditLogsQueryVariables,
  Org,
  PageInfo,
  User
} from "@src/generated/graphql";
import { iconSize } from "@src/Instances/ActionsCell/iconSize";

import { AuditLogsPagination } from "./AuditLogsPagination";
import { AuditTypeTag, EntityTag } from "./AuditTags";
import { hidableColumns } from "./FilterColumns/LogsColumnFilter";

const Eye = styled(EyeIcon)`
  ${iconSize}
  color: #444b88;
`;

const Message = styled.span`
  display: block;
  width: 100%;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledTr = styled(Tr)`
  height: 45px;
`;
const StyledTd = styled(Td)`
  font-size: 12px;
  letter-spacing: initial;
  font-weight: 500;
`;

interface AuditLogsTableProps {
  auditLogs: AuditLogEntry[];
  openDetailsModal: (log: AuditLogEntry) => void;
  selectedFilters: string[];
  fetchLogs: (
    variables?: Partial<Exact<FetchAuditLogsQueryVariables>>
  ) => Promise<ApolloQueryResult<FetchAuditLogsQuery>>;
  pageInfo: PageInfo;
  totalCount: number;
}

export function AuditLogsTable({
  auditLogs,
  openDetailsModal,
  selectedFilters,
  fetchLogs,
  pageInfo,
  totalCount
}: AuditLogsTableProps) {
  const [searchParams] = useSearchParams();
  const pageSize = Number(searchParams.get("pageSize"));

  const columns: Array<Column<AuditLogEntry>> = [
    {
      Header: "Time",
      accessor: "time"
    },
    {
      Header: "Entity Type",
      accessor: "entityType",
      Cell: ({ value }: CellProps<object>) => {
        return <EntityTag entity={value} />;
      }
    },
    {
      id: "Entity Name",
      Header: "Entity Name",
      accessor: "entity",
      Cell: ({ value }: CellProps<object>) => {
        return value?.name || "No Name";
      }
    },
    {
      id: "Organization",
      Header: "Organization",
      accessor: "org",
      Cell: ({ value }: CellProps<object>) => {
        const { displayName } = value as Org;
        return displayName;
      }
    },
    {
      id: "User",
      Header: "User",
      accessor: "user",
      Cell: ({ value }: CellProps<object>) => {
        const { email } = value as User;
        return email;
      }
    },
    {
      Header: "Type",
      accessor: "type",
      Cell: ({ value }: CellProps<object>) => {
        return <AuditTypeTag type={value} />;
      }
    },
    {
      id: "Reason",
      Header: "Reason",
      accessor: "reason"
    },
    {
      Header: "Message",
      accessor: "message",
      Cell: ({ value }: CellProps<object>) => {
        return <Message>{value}</Message>;
      }
    }
  ];

  const actionColumn = {
    Header: "details",
    accessor: "_actions" as ActionsColumn,
    disableSortBy: true,
    Cell: ({ row }: CellProps<object>) => {
      const rowLog = row.original as AuditLogEntry;
      return (
        <Clickable
          title={`details for log on ${rowLog.time}`}
          onClick={() => {
            openDetailsModal(rowLog);
          }}
        >
          <Eye />
        </Clickable>
      );
    }
  };

  const hiddenColumns = hidableColumns.filter(filter => !selectedFilters.includes(filter));

  return (
    <>
      <TableComponent<AuditLogEntry>
        columnHeaders={columns}
        items={auditLogs}
        rightActions={actionColumn}
        hiddenColumns={hiddenColumns}
        StyledTr={StyledTr}
        StyledTd={StyledTd}
        pageSize={pageSize}
        sortArray={[]}
      />
      <AuditLogsPagination
        fetchLogs={fetchLogs}
        pageInfo={pageInfo}
        totalCount={totalCount}
        logsShown={auditLogs.length}
      />
    </>
  );
}
