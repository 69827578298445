import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row } from "react-table";

export type Entity = {
  id: string;
  displayName: string;
  type:
    | "device"
    | "organization"
    | "service chain"
    | "site"
    | "user"
    | "provision"
    | "partner"
    | "block chart";
};

interface RemoveRowProps {
  modalShown: boolean;
  entity: Entity;
  show: (entity: Entity) => void;
  hide: () => void;
  openRowModal: (entity: Entity, row: Row) => void;
  onCompleted: () => void;
}

export function useRemoveRow(showInitial = false, entity: Entity = null): RemoveRowProps {
  const navigate = useNavigate();
  const [state, setState] = useState({ show: showInitial, entity });
  const show = useCallback((entity: Entity) => setState({ show: true, entity }), []);
  const hide = useCallback(() => setState({ show: false, entity: null }), []);

  const [selectedRow, setSelectedRow] = useState<Row>(null);

  const openRowModal = useCallback(
    (entity: Entity, row: Row) => {
      setSelectedRow(row);
      show(entity);
    },
    [setSelectedRow, show]
  );

  const onCompleted = useCallback(() => {
    if (selectedRow) {
      selectedRow?.toggleRowSelected(false);
      setSelectedRow(null);
    } else {
      navigate(-1);
    }
    hide();
  }, [selectedRow, navigate, hide, setSelectedRow]);

  return {
    modalShown: state.show,
    entity: state.entity,
    show,
    hide,
    openRowModal,
    onCompleted
  };
}
