import { useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

interface AuditLogsQueryParams {
  from?: string | number;
  to?: string | number;
  alias?: string;
  pageSize?: number;
}

export function useAuditLogNavigation() {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const fromCurrent = searchParams.get("from");
  const toCurrent = searchParams.get("to");
  const aliasCurrent = searchParams.get("alias");
  const pageSizeCurrent = searchParams.get("pageSize");

  const updateQueryParams = useCallback(
    (params: AuditLogsQueryParams, removeAlias = false) => {
      const from = params.from ? params.from : fromCurrent;
      const to = params.to ? params.to : toCurrent;
      const alias = params.alias ? params.alias : aliasCurrent;
      const pageSize = params.pageSize ? params.pageSize : pageSizeCurrent;

      const aliasString = alias && !removeAlias ? `alias=${alias}` : "";
      navigate(`../audits?${aliasString}&from=${from}&to=${to}&pageSize=${pageSize}`);
    },
    [aliasCurrent, fromCurrent, navigate, pageSizeCurrent, toCurrent]
  );

  return updateQueryParams;
}
