import React, { Dispatch } from "react";
import { CircleLayer, Layer, LayerProps, MapLayerMouseEvent, Source } from "react-map-gl";

import { theme } from "@src/Components/theme";
import { Device, DeviceStatus } from "@src/generated/graphql";
import { InteractiveLayer } from "@src/Map/InteractiveLayer";
import { MapAction } from "@src/Map/mapReducer";

import { clusterProperties } from "./clusterProperties";

const SOURCE_STATUS_DEVICES = "status-devices";
export const LAYER_DEVICE_CLUSTERS = "status-device-markers-clusters";
const LAYER_DEVICE_CLUSTER_COUNT = "status-device-markers-cluster-count";
export const LAYER_DEVICE_CIRCLES = "status-device-markers-circles";
const LAYER_DEVICE_LABELS = "status-device-markers-labels";

export const palette = {
  unknown: "#ccc",
  neutral: "#51bbd6",
  ready: theme.success,
  down: theme.error,
  warning: theme.warning
};

const clusters: CircleLayer = {
  id: LAYER_DEVICE_CLUSTERS,
  type: "circle",
  filter: ["has", "point_count"],
  paint: {
    "circle-color": [
      "case",
      ["get", "statusUnknown"],
      palette.unknown,
      ["get", "statusProvisioning"],
      palette.neutral,
      ["get", "statusReady"],
      palette.ready,
      ["get", "statusDown"],
      palette.down,
      palette.warning
    ],
    "circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40]
  },
  layout: {}
};

const clusterCount: LayerProps = {
  id: LAYER_DEVICE_CLUSTER_COUNT,
  type: "symbol",
  filter: ["has", "point_count"],
  paint: {},
  layout: {
    "text-field": "{point_count_abbreviated}",
    "text-size": 12
  }
};

const statusCircle: CircleLayer = {
  id: LAYER_DEVICE_CIRCLES,
  type: "circle",
  filter: ["!", ["has", "point_count"]],
  paint: {
    "circle-color": [
      "case",
      ["get", "ready"],
      palette.ready,
      ["get", "down"],
      palette.down,
      ["get", "provisioning"],
      palette.neutral,
      palette.unknown
    ],
    "circle-radius": 5,
    "circle-stroke-width": 1,
    "circle-stroke-color": ["case", ["get", "down"], "#fff", "#4a4a4a"]
  },
  layout: {}
};

const label: LayerProps = {
  id: LAYER_DEVICE_LABELS,
  type: "symbol",
  filter: ["!", ["has", "point_count"]],
  paint: {
    "icon-translate": [-15, 0],
    "text-color": { type: "identity", property: "color" },
    "text-translate": [-18, 0]
  },
  layout: {
    "icon-image": "tooltip",
    "icon-text-fit": "both",
    "icon-text-fit-padding": [4, 12, 4, 12],
    "text-field": ["get", "title"],
    "text-anchor": "right"
  }
};

interface DeviceMarkersProps {
  dispatch: Dispatch<MapAction>;
  devices: Device[];
}

export function StatusDeviceMarkers({ dispatch, devices }: DeviceMarkersProps) {
  const geojson: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
    type: "FeatureCollection",
    features: (devices || [])
      .filter(d => d.position)
      .map(({ id, position: { lat, lng }, displayName, status, tags }) => {
        const isNztp = !!tags?.includes("nztp");
        const isCloudlet = !!tags?.includes("cloudlet");
        return {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [lng, lat]
          },
          properties: {
            id,
            title: displayName,
            status,
            ready: status === DeviceStatus.Ready,
            provisioning: status === DeviceStatus.Deploying,
            down: (isNztp || isCloudlet) && status === DeviceStatus.Unknown,
            unknown: !isNztp && !isCloudlet
          }
        };
      })
  };

  return (
    <Source
      id={SOURCE_STATUS_DEVICES}
      type="geojson"
      data={geojson}
      cluster={true}
      clusterProperties={clusterProperties}
    >
      <InteractiveLayer dispatch={dispatch} {...clusters} />
      <Layer {...clusterCount} />
      <InteractiveLayer dispatch={dispatch} {...statusCircle} />
      <Layer {...label} />
    </Source>
  );
}

export function clusterFromEvent(e: MapLayerMouseEvent) {
  return e.features.find(f => f.layer?.id === LAYER_DEVICE_CLUSTERS);
}

export function onDeviceMarkersClick(e: MapLayerMouseEvent) {
  return e.features.find(f => f.source === SOURCE_STATUS_DEVICES)?.properties;
}
