import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { RecoveryFlow, UpdateRecoveryFlowBody } from "@ory/client";
import { Title } from "@src/Components/Modal/Modal";
import { getNodeId, isUiNodeInputAttributes, useSelfService } from "@src/Hooks/selfService";

import { handleGetFlowError } from "./errors";
import { Flow } from "./Flow";
import { kratos } from "./kratos";
import { FieldInput } from "./NodeFieldInput";
import { NodeInput } from "./NodeInput";
import { Placeholders } from "./Placeholders";

const placeholders: Placeholders<UpdateRecoveryFlowBody> = {
  email: "Email"
};

export function Recover() {
  const [flow, setFlow] = useState<RecoveryFlow>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const flowId = searchParams.get("flow");
    if (flow) {
      return;
    }

    if (flowId) {
      kratos
        .getRecoveryFlow({ id: String(flowId) })
        .then(({ data }) => {
          setFlow(data);
        })
        .catch(handleGetFlowError(navigate, "recovery", setFlow));
      return;
    }

    kratos
      .createBrowserRecoveryFlow()
      .then(({ data }) => {
        setFlow(data);
      })
      .catch(handleGetFlowError(navigate, "recovery", setFlow))
      .catch((err: { response: { status: number; data: RecoveryFlow } }) => {
        // If the previous handler did not catch the error it's most likely a form validation error
        if (err.response?.status === 400) {
          setFlow(err.response?.data);
          return;
        }

        return Promise.reject(err);
      });
  }, [flow, searchParams, navigate]);

  const onSubmit = useCallback(
    (values: UpdateRecoveryFlowBody) => {
      return kratos
        .updateRecoveryFlow({
          flow: String(flow?.id),
          updateRecoveryFlowBody: values
        })
        .then(({ data }) => setFlow(data))
        .catch(handleGetFlowError(navigate, "recovery", setFlow))
        .catch(err => {
          if (err.response?.status === 400) {
            setFlow(err.response?.data);
            return;
          }

          return Promise.reject(err);
        });
    },
    [navigate, flow]
  );

  const { filteredNodes, state, onChange, handleSubmit } = useSelfService({
    flow,
    only: "link",
    onSubmit
  });

  return (
    <>
      <Title>Recover account</Title>
      <p>A recovery link will be sent to the following email address.</p>
      <Flow flow={flow} onSubmit={handleSubmit}>
        {filteredNodes.map(node => {
          const id = getNodeId(node) as keyof UpdateRecoveryFlowBody;
          return (
            <NodeInput
              input={FieldInput}
              placeholder={placeholders[id]}
              key={id}
              node={node}
              attributes={isUiNodeInputAttributes(node.attributes) ? node.attributes : undefined}
              disabled={state.isLoading}
              value={state.values[id] ?? ""}
              setValue={value => onChange(id, value)}
            />
          );
        })}
      </Flow>
    </>
  );
}
