/* eslint-disable @typescript-eslint/triple-slash-reference */
/// <reference path="./defines.d.ts" />
/// <reference path="./imports.d.ts" />
/// <reference path="./generated/graphql.d.ts" />
import "core-js";
import "regenerator-runtime/runtime";
import "whatwg-fetch";

import React, { ReactChild } from "react";
import { render } from "react-dom";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { ApolloProvider } from "@apollo/client";

import { getApolloClient } from "./apolloClient";
import { App } from "./App";
import { GlobalStyles } from "./Components/GlobalStyles";
import { theme } from "./Components/theme";
import { SvgDefTrackerProvider } from "./Hooks/svgDef";
import { queryClient } from "./reactQueryClient";
import { SessionProvider } from "./Session";

const rootEl = document.getElementById("root");

function Providers({ children }: { children: ReactChild }) {
  const apolloClient = getApolloClient();

  return (
    <ApolloProvider client={apolloClient}>
      <QueryClientProvider client={queryClient}>
        <GlobalStyles />
        <SvgDefTrackerProvider>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <SessionProvider>{children}</SessionProvider>
            </BrowserRouter>
          </ThemeProvider>
        </SvgDefTrackerProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ApolloProvider>
  );
}

document.addEventListener("DOMContentLoaded", () =>
  render(
    <Providers>
      <App />
    </Providers>,
    rootEl
  )
);

if (module.hot) {
  module.hot.accept("./App", () => {
    /* eslint-disable @typescript-eslint/no-var-requires */
    const { App: NewApp } = require("./App");

    render(
      <Providers>
        <NewApp />
      </Providers>,
      rootEl
    );
  });
}
