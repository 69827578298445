import { Formik } from "formik";
import React from "react";
import toast from "react-hot-toast";
import { useOutletContext, useParams } from "react-router-dom";

import { useMutation, useQuery } from "@apollo/client";
import { Loading } from "@src/Components/Loading/Loading";
import { H2 } from "@src/Components/Text";
import { ToastNotification } from "@src/Components/ToastNotification";
import {
  EditFederationHostMutation,
  EditFederationHostMutationVariables,
  FederationHostQuery,
  FederationHostQueryVariables
} from "@src/generated/graphql";

import EditFederationHost from "./EditFederationHost.graphql";
import { FederationsContext } from "./federationsContext";
import FederationHost from "./FetchFederationHost.graphql";
import { FederationHostFormInput, hostEditSchema } from "./formSchemas";
import { HostFormInner } from "./HostFormInner";

export function EditHostForm() {
  const { id } = useParams();
  const { navigateBack } = useOutletContext<FederationsContext>();

  const { data, loading } = useQuery<FederationHostQuery, FederationHostQueryVariables>(
    FederationHost,
    {
      variables: { id }
    }
  );

  const [editHostPartner] = useMutation<
    EditFederationHostMutation,
    EditFederationHostMutationVariables
  >(EditFederationHost);

  const initialValues: FederationHostFormInput = {
    id: data?.federationHost?.id,
    federationName: data?.federationHost?.federationName,
    operatorName: data?.federationHost?.operatorName,
    countryCode: data?.federationHost?.countryCode,
    mcc: data?.federationHost?.mcc,
    mncs: data?.federationHost?.mncs.join(","),
    hostUrl: data?.federationHost?.hostUrl,
    siteId: data?.federationHost?.siteId,
    clientId: "",
    clientSecret: ""
  };

  if (loading) return null;
  return (
    <Formik<FederationHostFormInput>
      initialValues={initialValues}
      validationSchema={hostEditSchema}
      onSubmit={async values => {
        try {
          const { clientId, clientSecret, ...hostValues } = values;
          const setCredentials = !!clientId && !!clientSecret;
          const hostInput = setCredentials
            ? {
                ...hostValues,
                clientId,
                clientSecret
              }
            : {
                ...hostValues
              };
          editHostPartner({
            variables: { host: { ...hostInput, id: values.id, mncs: values.mncs.split(",") } },
            onCompleted: navigateBack
          });
        } catch (e) {
          console.error(e);
          toast.error(<ToastNotification title="Failed to edit Host Partner" />);
        }
      }}
    >
      {formikProps =>
        formikProps.isSubmitting ? (
          <Loading />
        ) : (
          <>
            <H2>Edit Host Partner</H2>
            <HostFormInner {...formikProps} navigateBack={navigateBack} isEdit />
          </>
        )
      }
    </Formik>
  );
}
