import React from "react";
import styled from "styled-components";

import { H1 } from "@src/Components/Text";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 0;
`;

export function NoMnoPermissions() {
  return (
    <Wrapper>
      <H1>Permissions Error</H1>
      <p>You do not have permission to access this tab.</p>
      <p>Only Mobile Network Operators have access to this tab.</p>
    </Wrapper>
  );
}
