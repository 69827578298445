import React from "react";
import { Outlet, useParams } from "react-router-dom";

import { useQuery } from "@apollo/client";
import { Loading } from "@src/Components/Loading/Loading";
import { styled } from "@src/Components/theme";
import { ServiceChainsQuery, ServiceChainsQueryVariables } from "@src/generated/graphql";
import { InstanceGridElement } from "@src/Instances/InstanceGridElement";
import { sortServices } from "@src/Instances/sortServices";

import ServiceChains from "./ServiceChains.graphql";

const Wrapper = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-auto-rows: 1fr;
  padding-top: 20px;
`;
export function SiteServices() {
  const { orgId, siteId } = useParams();

  const { data, loading } = useQuery<ServiceChainsQuery, ServiceChainsQueryVariables>(
    ServiceChains,
    {
      variables: { site: siteId ? siteId : orgId },
      pollInterval: 5000,
      errorPolicy: "all"
    }
  );
  const serviceChains = sortServices(
    data && data.serviceChains ? data.serviceChains.filter(Boolean) : []
  );

  return loading ? (
    <Loading />
  ) : (
    <>
      <Wrapper>
        {serviceChains.map(sc => (
          <InstanceGridElement key={sc.id} {...sc} />
        ))}
      </Wrapper>
      <Outlet />
    </>
  );
}
