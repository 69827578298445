import { useQuery } from "@apollo/client";
import { FetchParentSiteQuery, FetchParentSiteQueryVariables } from "@src/generated/graphql";
import { useOrgInfo } from "@src/Hooks/orgInfo";

import FetchParentSite from "./FetchParentSite.graphql";

export function useParentSiteInfo(parentSiteId: string) {
  const { orgId, orgName } = useOrgInfo(true);
  const isOrg = orgId === parentSiteId;
  const { data: siteData } = useQuery<FetchParentSiteQuery, FetchParentSiteQueryVariables>(
    FetchParentSite,
    {
      variables: { id: parentSiteId },
      skip: !parentSiteId || isOrg
    }
  );

  const parentSiteName = isOrg ? orgName : siteData?.site?.displayName;

  const linkUrl = isOrg
    ? `/app/infrastructure/org/${parentSiteId}/overview`
    : `/app/infrastructure/org/${orgId}/site/${parentSiteId}/overview`;

  return {
    parentSiteName,
    linkUrl
  };
}
