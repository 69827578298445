import React, { useCallback } from "react";

import { PrimaryTextButton } from "@src/Components/Buttons/Text";
import { styled } from "@src/Components/theme";
import { MarketplaceProps } from "@src/Hooks/marketplace";

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const Filter = styled.div`
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 5px 10px;
  position: relative;
  display: flex;
  gap: 5px;

  & > span {
    display: block;
    position: relative;
    cursor: pointer;
    height: 12px;
    width: 12px;
    align-self: center;

    ::before,
    ::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      height: 2px;
      width: 100%;
      background-color: #ccc;
    }

    ::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    ::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
`;

const ThinTextButton = styled(PrimaryTextButton)`
  font-weight: normal;
`;

export function Filters(marketplaceProps: MarketplaceProps) {
  const { dispatch, selectedCategory, selectedVendors } = marketplaceProps;
  const removeVendor = useCallback(
    (vendor: string) => {
      dispatch({ type: "removeVendor", value: vendor });
    },
    [dispatch]
  );

  return (
    <Wrapper>
      {selectedCategory && (
        <Filter>
          {selectedCategory}
          <span onClick={() => dispatch({ type: "removeCategory" })} />
        </Filter>
      )}
      {selectedVendors.map(v => (
        <Filter key={v}>
          {v || <em>no vendor</em>}
          <span onClick={() => removeVendor(v)} />
        </Filter>
      ))}
      {(selectedVendors.length > 0 || selectedCategory) && (
        <ThinTextButton onClick={() => dispatch({ type: "cleanFilters" })}>
          Clear all filters
        </ThinTextButton>
      )}
    </Wrapper>
  );
}
