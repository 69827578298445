export function ipv4(value: string) {
  if (!value?.split) return false;
  const parts = value.split(".");
  if (parts.length !== 4) {
    return false;
  }
  if (parts.some(p => /[^0-9]/.test(p))) {
    return false;
  }

  return parts.map(p => parseInt(p, 10)).every(p => p >= 0 && p < 256);
}
