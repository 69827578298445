import { FormikProps } from "formik";
import React, { useCallback, useReducer, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";

import { useQuery } from "@apollo/client";
import { styled } from "@src/Components/theme";
import {
  ServiceDesignerPreviousValuesQuery,
  ServiceDesignerPreviousValuesQueryVariables
} from "@src/generated/graphql";

import { BlockList } from "./BlockList";
import { ConfigurationPanel } from "./ConfigurationPanel";
import { editorDesignerReducer, initBlocksObject } from "./editorDesignerReducer";
import { ServiceForm } from "./serialise";
import ServiceDesignerPreviousValues from "./ServiceDesignerPreviousValues.graphql";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(1250px, 5fr);
  flex-grow: 1;
`;

export enum EditingSection {
  Values = "Block values",
  Descriptor = "Block descriptor"
}

function useDesignerProps() {
  return useOutletContext<FormikProps<ServiceForm>>();
}

export function Designer() {
  const editMatch = useParams<{ id: string }>();
  const isEditing = !!editMatch?.id;
  const serviceChainId = editMatch?.id;
  const formikProps = useDesignerProps();
  const {
    values: { blocks }
  } = formikProps;

  const { data } = useQuery<
    ServiceDesignerPreviousValuesQuery,
    ServiceDesignerPreviousValuesQueryVariables
  >(ServiceDesignerPreviousValues, {
    variables: {
      id: serviceChainId
    },
    skip: !isEditing
  });
  const previouslyDeployedBlocks = data?.serviceChain?.blocks || [];

  const [editorStatus, editorDispatch] = useReducer(editorDesignerReducer, {
    selectedBlock: Object.values(blocks)[0]?.displayName,
    isEditing,
    blocks: initBlocksObject(blocks, previouslyDeployedBlocks)
  });

  const [editingSection, setEditingSection] = useState<EditingSection>(EditingSection.Values);

  const selectBlock = useCallback(
    (blockName: string) => {
      editorDispatch({
        type: "selectBlock",
        payload: { block: blockName }
      });
    },
    [editorDispatch]
  );

  return (
    <Wrapper>
      <BlockList
        selectBlock={selectBlock}
        editorStatus={editorStatus}
        editorDispatch={editorDispatch}
        {...formikProps}
      />
      <ConfigurationPanel
        editorStatus={editorStatus}
        editorDispatch={editorDispatch}
        editingSection={editingSection}
        setEditingSection={setEditingSection}
        isEditing={isEditing}
        {...formikProps}
      />
    </Wrapper>
  );
}
