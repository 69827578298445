import React, { ReactNode } from "react";
import styled from "styled-components";

interface NavIconProps {
  children: ReactNode;
}

const Svg = styled.svg`
  display: inline-block;
  height: 50px;
  width: 50px;
`;

export function NavIcon({ children }: NavIconProps) {
  return (
    <Svg height={50} width={50} viewBox="-5 -5 50 50">
      <circle fill="none" r={25} cx={20} cy={20} />
      <g fill="white">{children}</g>
    </Svg>
  );
}
