import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/link-error";

import { Block, OktoResource, OrgRole, Settings, User } from "./generated/graphql";

interface NetworkError {
  statusCode: number;
}

function isNetworkError(error: unknown): error is NetworkError {
  return Number.isInteger((error as NetworkError).statusCode);
}

const resolvers = {};

export const cache = new InMemoryCache({
  typePolicies: {
    Settings: {
      merge(existing = {}, incoming: Partial<Settings>, { mergeObjects }) {
        return mergeObjects(existing, incoming);
      }
    },
    Block: {
      keyFields: ["id", "chainRevision"],
      fields: {
        resources: {
          merge(_ = [], incoming: Partial<OktoResource>[]) {
            return incoming;
          }
        }
      }
    },
    OktoResource: {
      keyFields: false
    },
    OrgRole: {
      keyFields: false
    },
    Org: {
      fields: {
        users: {
          merge(_ = [], incoming: Partial<User>[]) {
            return incoming;
          }
        }
      }
    },
    ServiceChain: {
      fields: {
        blocks: {
          merge(_ = [], incoming: Partial<Block>[]) {
            return incoming;
          }
        }
      }
    },
    User: {
      fields: {
        orgRoles: {
          merge(_ = [], incoming: Partial<OrgRole>[]) {
            return incoming;
          }
        }
      }
    }
  }
});

export function getApolloClient(onLoggedOut?: () => void, ...extraLinks: ApolloLink[]) {
  const link = ApolloLink.from([
    ...extraLinks,
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        );
      }

      if (networkError && isNetworkError(networkError)) {
        if (networkError.statusCode === 401 && onLoggedOut) {
          onLoggedOut();
        }

        console.log(`[Network error]: ${networkError}`);
      }
    }),
    createHttpLink({
      credentials: "same-origin",
      uri: "/query"
    })
  ] as ApolloLink[]);

  return new ApolloClient({
    cache,
    link,
    resolvers
  });
}
