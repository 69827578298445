import React from "react";
import styled from "styled-components";

import { PrimaryLinkButton } from "@src/Components/Buttons/PrimaryLink";
import { H2 } from "@src/Components/Text";

import { GuestTable } from "./GuestTable";
import { HostTable } from "./HostTable";
import { IdentityDetails } from "./Identity";

const TitleButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export function FederationsTab() {
  return (
    <>
      <TitleButton>
        <H2>Operator Identity</H2>
        <PrimaryLinkButton to="edit-identity">Edit</PrimaryLinkButton>
      </TitleButton>
      <IdentityDetails />

      <TitleButton>
        <H2>Host Partners</H2>
        <PrimaryLinkButton to="partner/host/add">Add Host Partner</PrimaryLinkButton>
      </TitleButton>
      <HostTable />

      <TitleButton>
        <H2>Guest Partners</H2>
        <PrimaryLinkButton to="partner/guest/add">Add Guest Partner</PrimaryLinkButton>
      </TitleButton>
      <GuestTable />
    </>
  );
}
