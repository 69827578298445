import { Field, Form, Formik } from "formik";
import React, { useCallback } from "react";
import styled from "styled-components";

import { useMutation } from "@apollo/client";
import Edit from "@img/edit.svg";
import { ButtonGroup } from "@src/Components/Buttons/ButtonGroup";
import { GhostButton } from "@src/Components/Buttons/Ghost";
import { PrimaryButton } from "@src/Components/Buttons/Primary";
import { Label } from "@src/Components/Label";
import { TextArea } from "@src/Components/TextArea";
import {
  EditOrgMutation,
  EditOrgMutationVariables,
  EditSiteMutation,
  EditSiteMutationVariables,
  Role
} from "@src/generated/graphql";
import { useToggle } from "@src/Hooks/toggle";
import { useUserRole } from "@src/Hooks/userRole";

import EditOrg from "./EditOrg.graphql";
import EditSite from "./EditSite.graphql";

const DescriptionLabel = styled(Label)`
  cursor: pointer;
  width: fit-content;
  display: flex;
  justify-content: center;
  :hover svg {
    opacity: 1;
  }
`;

const EditIcon = styled(Edit)`
  height: 20px;
  width: 20px;
  opacity: 0.2;
`;

const BtnGroup = styled(ButtonGroup)`
  width: fit-content;
  margin: 10px 0 0 auto;
`;

interface EntityDescriptionProps {
  id: string;
  description: string;
}

export function OrgDescription(descriptionProps: EntityDescriptionProps) {
  const { id } = descriptionProps;
  const [editOrg] = useMutation<EditOrgMutation, EditOrgMutationVariables>(EditOrg);
  const onSubmit = useCallback(
    async (values: { description: string }) => {
      try {
        await editOrg({
          variables: {
            id,
            org: {
              description: values.description
            }
          }
        });
      } catch (e) {}
    },
    [editOrg, id]
  );

  return <Description {...descriptionProps} onSubmit={onSubmit} />;
}

export function SiteDescription(descriptionProps: EntityDescriptionProps) {
  const { id } = descriptionProps;
  const [editSite] = useMutation<EditSiteMutation, EditSiteMutationVariables>(EditSite);
  const onSubmit = useCallback(
    async (values: { description: string }) => {
      try {
        await editSite({
          variables: {
            id,
            site: {
              description: values.description
            }
          }
        });
      } catch (e) {}
    },
    [editSite, id]
  );

  return <Description {...descriptionProps} onSubmit={onSubmit} />;
}

interface DescriptionProps {
  description: string;
  onSubmit: (values: { description: string }) => void;
}

export function Description({ description, onSubmit }: DescriptionProps) {
  const { userRole } = useUserRole();
  const canEdit = (userRole ?? Role.Viewer) !== Role.Viewer;
  const { state: editDescription, toggle: toggleDescription } = useToggle();

  return (
    <>
      <DescriptionLabel
        onClick={canEdit ? toggleDescription : undefined}
        title="edit site description"
      >
        description {canEdit && <EditIcon />}
      </DescriptionLabel>
      {editDescription ? (
        <Formik<{ description: string }>
          initialValues={{ description }}
          onSubmit={async values => {
            onSubmit(values);
            toggleDescription();
          }}
        >
          {formikProps => (
            <Form>
              <Field
                as={TextArea}
                autoFocus
                placeholder="site description..."
                name="description"
                {...formikProps}
              />
              <BtnGroup>
                <GhostButton onClick={toggleDescription}>cancel</GhostButton>
                <PrimaryButton type="submit">update</PrimaryButton>
              </BtnGroup>
            </Form>
          )}
        </Formik>
      ) : description ? (
        <p>{description}</p>
      ) : (
        <em>No description.</em>
      )}
    </>
  );
}
