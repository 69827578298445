import React from "react";

import { Tag } from "@src/Components/StatusTag";
import { styled } from "@src/Components/theme";
import { Role } from "@src/generated/graphql";

const Admin = styled(Tag)`
  background-color: #9000ff;
  color: white;
`;

const Operator = styled(Tag)`
  border: solid 1px ${({ theme }) => theme.lightblue};
  color: ${({ theme }) => theme.lightblue};
`;

const MNO = styled(Tag)`
  background-color: ${({ theme }) => theme.blue};
  color: white;
`;

const Viewer = styled(Tag)`
  border: 1px solid #ccc;
  color: #ccc;
`;

interface RoleTagProps {
  role: Role;
}

export function RoleTag({ role }: RoleTagProps) {
  switch (role) {
    case Role.Admin:
      return <Admin>Admin</Admin>;
    case Role.Operator:
      return <Operator>Operator</Operator>;
    case Role.Mno:
      return <MNO>MNO</MNO>;
    case Role.Viewer:
    default:
      return <Viewer>Viewer</Viewer>;
  }
}
