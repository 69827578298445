import { FormikProps } from "formik";
import React, { Fragment, useCallback } from "react";
import styled from "styled-components";

import RemoveIcon from "@img/remove.svg";
import { Clickable } from "@src/Components/Buttons/Clickable";
import { Connection } from "@src/generated/graphql";
import { FieldEditButton } from "@src/ServiceDef/FieldEditButton";

import { DeviceValues } from "./AddDeviceForm";
import { ConnectionForm } from "./ConnectionForm";
import { EditDeviceValues } from "./EditDeviceForm";

const FieldFirstLine = styled.div`
  grid-area: title;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #dddddd;
`;

const Remove = styled(RemoveIcon)`
  width: 20px;
  height: 20px;
  padding: 11px 7px;
`;

const ToggleVisibility = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  font-size: 12px;
  letter-spacing: 0.5px;
  color: #777777;
`;

const FieldTitle = styled.span`
  font-weight: 600;
  line-height: 44px;
`;

const FieldDetails = styled.div`
  grid-area: details;
  font-size: 12px;
  line-height: 24px;
  margin: 14px 0;
  letter-spacing: 0.5px;
  color: #9b9b9b;
`;

const Value = styled.span`
  font-weight: 500;
  color: #444444;
  margin-right: 0.5em;
`;

const ConnectionItemWrapper = styled.div`
  background-color: white;
  border: solid 1px #f8f4f4;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  margin: 20px 0;
`;

const ConnectionWrapper = styled(ConnectionItemWrapper)`
  display: grid;
  grid-template-areas: "icon title drag" "icon details drag";
  grid-template-columns: 100px 1fr 100px;
  grid-template-rows: 44px 1fr;
  column-gap: 10px;
  position: relative;
  min-height: 100px;
`;

const ConnectionsGrid = styled.div`
  grid-column: 1 / span 3;
  margin: 20px 0;
`;

const FieldDetailsConnections = styled(FieldDetails)`
  display: grid;
  grid-template-columns: 100px 1fr;
`;

const EmptyConnectionsWrapper = styled(ConnectionItemWrapper)`
  text-align: center;
  font-size: 15px;
  font-style: italic;
  font-weight: 600;
  padding: 60px 0;
`;

export const initialEditMode: EditMode = { fieldId: null, isNew: false };

export interface EditMode {
  fieldId: string;
  isNew: boolean;
}

interface CreatedConnectionsProps extends FormikProps<DeviceValues | EditDeviceValues> {
  connections: Connection[];
  remove: (index: number) => void;
  editMode: EditMode;
  setEditMode: (m: EditMode) => void;
}

export function CreatedConnections({
  connections,
  remove,
  editMode,
  setEditMode,
  ...formikProps
}: CreatedConnectionsProps) {
  const { fieldId, isNew } = editMode;
  const cancelConnection = useCallback(() => {
    setEditMode(initialEditMode);
    if (isNew) {
      remove(connections.length - 1);
    }
  }, [setEditMode, isNew, remove, connections]);

  const saveConnection = useCallback(() => {
    setEditMode(initialEditMode);
  }, [setEditMode]);

  return (
    <>
      {connections.length ? (
        <ConnectionsGrid>
          {connections.map((c, i) => {
            const canEdit = fieldId === null;
            return (
              <Fragment key={c.id}>
                <ConnectionWrapper>
                  <FieldEditButton
                    disabled={!canEdit}
                    onClick={() => {
                      setEditMode({ fieldId: c.id, isNew: false });
                    }}
                  />
                  <FieldFirstLine>
                    <FieldTitle>
                      CONNECTION
                      <span> : </span>
                      {c.name}
                    </FieldTitle>
                    <ToggleVisibility>
                      Remove
                      <Clickable
                        title="remove"
                        onClick={() => {
                          setEditMode({ fieldId: null, isNew: false });
                          remove(i);
                        }}
                      >
                        <Remove />
                      </Clickable>
                    </ToggleVisibility>
                  </FieldFirstLine>
                  <FieldDetailsConnections>
                    Category <Value>{c.category}</Value>
                    Kind <Value>{c.kind}</Value>
                    Source <Value>{c.source}</Value>
                  </FieldDetailsConnections>
                </ConnectionWrapper>
                {fieldId === c.id ? (
                  <ConnectionForm
                    isNew={isNew}
                    saveConnection={saveConnection}
                    cancelConnection={cancelConnection}
                    name={`connections[${i}]`}
                    {...formikProps}
                  />
                ) : null}
              </Fragment>
            );
          })}
        </ConnectionsGrid>
      ) : (
        <EmptyConnectionsWrapper> No connections yet</EmptyConnectionsWrapper>
      )}
    </>
  );
}
