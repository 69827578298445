import React, { useCallback } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { CellProps, Column, Row } from "react-table";
import styled from "styled-components";

import { useQuery } from "@apollo/client";
import EditIcon from "@img/edit.svg";
import RemoveIcon from "@img/remove.svg";
import { PrimaryButton } from "@src/Components/Buttons/Primary";
import { Loading } from "@src/Components/Loading/Loading";
import { ActionsColumn, TableComponent } from "@src/Components/Table/TableComponent";
import { H2 } from "@src/Components/Text";
import {
  FetchOrganizationsQuery,
  FetchOrganizationsQueryVariables,
  Site
} from "@src/generated/graphql";
import { useIsSuperAdmin } from "@src/Hooks/isSuperAdmin";
import { Entity } from "@src/Hooks/removeRow";
import { ActionLink, RemoveButton } from "@src/Infrastructure/sites/Inventory";
import { iconSize } from "@src/Instances/ActionsCell/iconSize";

import FetchOrganizations from "./FetchOrganizations.graphql";
import { FirstLine } from "./UserManagement";

const AddOrganizationButton = styled(PrimaryButton)`
  margin-left: auto;
`;

const DescriptionWrapper = styled.div`
  padding: 5px 0;
`;

const WrapperActions = styled.div`
  display: flex;
  gap: 10px;
`;

const Edit = styled(EditIcon)`
  ${iconSize}
  fill: #444b88;
`;

const Remove = styled(RemoveIcon)`
  ${iconSize}
  fill: #444b88;
`;

interface OrganizationsTableProps {
  openRowModal: (entity: Entity, row: Row) => void;
}

function useOrganizationsTableProps() {
  return useOutletContext<OrganizationsTableProps>();
}

export function OrganizationsTable() {
  const navigate = useNavigate();
  const { openRowModal } = useOrganizationsTableProps();
  const openAddOrganization = useCallback(() => navigate("add"), [navigate]);

  const { isSuperAdmin, loading: superAdminLoading } = useIsSuperAdmin();

  const { data, loading: orgsLoading } = useQuery<
    FetchOrganizationsQuery,
    FetchOrganizationsQueryVariables
  >(FetchOrganizations);
  const organizations = data?.orgs;

  const columns: Array<Column<object>> = [
    {
      Header: "Name",
      accessor: "displayName",
      width: "10%"
    },
    {
      Header: "ID",
      accessor: "id",
      width: "25%"
    },
    {
      Header: "Description",
      accessor: "description",
      width: "55%",
      Cell: ({ value }: CellProps<object>) => (
        <DescriptionWrapper>{value || "-"}</DescriptionWrapper>
      )
    }
  ];

  const actionColumn = {
    Header: "actions",
    accessor: "_actions" as ActionsColumn,
    width: "10%",
    disableSortBy: true,
    Cell: ({ row }: CellProps<object>) => {
      const { id, displayName } = row.original as Site;
      return (
        <WrapperActions>
          <ActionLink title={`edit ${displayName}`} to={`edit/${id}`}>
            <Edit />
          </ActionLink>
          {isSuperAdmin && (
            <RemoveButton
              title={`remove ${displayName}`}
              onClick={() => openRowModal({ id, displayName, type: "organization" }, row)}
            >
              <Remove />
            </RemoveButton>
          )}
        </WrapperActions>
      );
    }
  };

  const loading = superAdminLoading || orgsLoading;

  return (
    <>
      <H2>Organizations</H2>
      {loading ? (
        <Loading />
      ) : (
        <>
          {isSuperAdmin && (
            <FirstLine>
              <AddOrganizationButton onClick={openAddOrganization}>
                Add new organization
              </AddOrganizationButton>
            </FirstLine>
          )}

          <TableComponent
            columnHeaders={columns}
            items={organizations || []}
            rightActions={actionColumn}
            pagination={true}
            pageSize={10}
          />
        </>
      )}
    </>
  );
}
