import React from "react";
import { Navigate } from "react-router-dom";

import { useIsAdmin } from "@src/Hooks/isAdmin";
import { useIsMno } from "@src/Hooks/isMno";
import { useIsSuperAdmin } from "@src/Hooks/isSuperAdmin";

export function SettingsRedirect() {
  const { isAdmin } = useIsAdmin();
  const { isSuperAdmin } = useIsSuperAdmin();
  const { isMno } = useIsMno();

  if (isAdmin == null || isSuperAdmin == null || isMno == null) return null;

  const redirectRoute =
    isSuperAdmin || isAdmin ? "user-management" : isMno ? "federation" : "map-settings";

  return <Navigate to={redirectRoute} />;
}
