import React, { MouseEvent } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import styled from "styled-components";

const DisabledNavLink = styled(NavLink)`
  cursor: not-allowed;
`;

function preventDefault(e: MouseEvent) {
  e.preventDefault();
}

export interface DisableableNavLinkProps extends NavLinkProps {
  disabled?: boolean;
}

export function DisableableNavLink({ disabled, ...props }: DisableableNavLinkProps) {
  return disabled ? (
    <DisabledNavLink onClick={preventDefault} {...props} />
  ) : (
    <NavLink {...props} />
  );
}
