import React from "react";

import { LicenseRow } from "./Licenses";
import { H2 } from "@src/Components/Text";
import { Table, THead, Th } from "@src/Components/Table/Table";

import licensesJson from "../../licenses.json";

interface License {
  name: string;
  version: string;
  licenses: string;
  licenseText: string;
}

export function LicensesWeb() {
  const licenses: { [mod: string]: License } = licensesJson;

  return (
    <>
      <H2>web</H2>
      <p>
        Icons from <a href="https://fontawesome.com/license">Font Awesome</a>, licensed under the
        Creative Commons Attribution 4.0 International license
      </p>
      <Table>
        <THead>
          <tr>
            <Th>NPM module</Th>
            <Th>Version</Th>
            <Th>License</Th>
          </tr>
        </THead>
        {Object.entries(licenses).map(([mod, license]) => (
          <LicenseRow
            key={mod}
            license={{
              Name: license.name,
              Version: license.version,
              License: license.licenses,
              LicenseText: license.licenseText
            }}
          />
        ))}
      </Table>
    </>
  );
}
