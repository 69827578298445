import { styled } from "../theme";
import { baseButtonStyles } from "./Base";

export const DangerButton = styled.button`
  ${baseButtonStyles}

  background-color: white;
  color: ${({ theme }) => theme.error};
  border: 1px solid ${({ theme }) => theme.error};
  box-sizing: border-box;

  :hover {
    background-color: ${({ theme }) => theme.error};
    color: white;
  }

  :disabled {
    background-color: ${({ theme }) => theme.error};
    color: white;
    opacity: 0.5;
  }
`;
