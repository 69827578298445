import { AxiosResponse } from "axios";
import {
  QueryClient,
  QueryClientConfig,
  QueryFunction,
  useQuery,
  UseQueryOptions
} from "react-query";

import { CheckPermissionResult } from "@ory/client";

const config: QueryClientConfig = {
  defaultOptions: {
    queries: {
      staleTime: 5 * 1000
    }
  }
};

export const queryClient = new QueryClient(config);

export function useReactQuery(
  keys: string[],
  fn: QueryFunction<AxiosResponse<CheckPermissionResult>>,
  options: UseQueryOptions<AxiosResponse<CheckPermissionResult>>
) {
  return useQuery<AxiosResponse<CheckPermissionResult>>(keys, fn, options);
}
