import { FormikProps } from "formik";
import React, { Dispatch, KeyboardEvent, useCallback, useRef, useState } from "react";
import toast from "react-hot-toast";

import EditIcon from "@img/edit.svg";
import { BlockName } from "@src/Components/BlockSelector/BlockStyles";
import { Input } from "@src/Components/Input/Input";
import { styled } from "@src/Components/theme";
import { useToggle } from "@src/Hooks/toggle";

import { DesignerEditorAction } from "./editorDesignerReducer";
import { FormBlock, ServiceForm } from "./serialise";

const NameInput = styled(Input)<{ invalid: boolean }>`
  height: 24px;
  width: 220px;
  padding: 5px 10px;
  margin: 2px 0;
  margin-top: 1em;
  font-size: small;
  outline-offset: -2px;
  :focus {
    outline: ${({ theme, invalid }) =>
      invalid ? `3px solid ${theme.error}` : `3px solid ${theme.yellow}`};
  }
`;

const Wrapper = styled.div`
  padding-right: 26px;
`;

const HoverEdit = styled(EditIcon)`
  opacity: 0.4;
  margin-left: 5px;
  height: 18px;
  min-width: 18px;
  align-self: baseline;
  :hover {
    opacity: 1;
  }
`;

const Name = styled(BlockName)`
  display: flex;
  width: 220px;
  justify-content: space-between;
`;

export const NameWrapper = styled.span`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`;

interface BlockItemNameProps extends FormikProps<ServiceForm> {
  displayName: string;
  editorDispatch: Dispatch<DesignerEditorAction>;
}

export function BlockItemName({ displayName, editorDispatch, ...formikProps }: BlockItemNameProps) {
  const {
    values: { blocks },
    setFieldValue
  } = formikProps;

  const inputRef = useRef<HTMLInputElement>();

  const { state: editName, toggle: toggleEditName } = useToggle();
  const [isValid, setIsValid] = useState(true);

  const validate = useCallback(
    (value: string) => {
      const newName = value.trim();
      const isRepeatedName = Object.keys(blocks).some(name => name === newName);

      let error: string;
      if (newName === displayName) {
        error = `Display name is already set to ${newName}`;
      } else if (newName.length === 0) {
        error = `The block new display name cannot be empty`;
      } else if (isRepeatedName) {
        error = `There is already a block with the display name ${newName}`;
      }
      if (error) {
        toast.error(error);
        return false;
      } else {
        return true;
      }
    },
    [blocks, displayName]
  );

  const handleSubmit = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key !== "Enter") return;
      const newName = inputRef.current.value;
      if (!validate(newName)) {
        setIsValid(false);
        return;
      }
      const blockList: { [key: string]: FormBlock } = {};
      for (const key in blocks) {
        if (key === displayName) {
          blockList[newName] = { ...blocks[key], displayName: newName };
        } else {
          blockList[key] = blocks[key];
        }
      }
      editorDispatch({ type: "changeBlockName", payload: { block: displayName, value: newName } });
      setFieldValue("blocks", blockList);
    },
    [displayName, setFieldValue, blocks, editorDispatch, validate]
  );

  const resetValidity = useCallback(() => {
    setIsValid(true);
  }, []);

  return (
    <Wrapper>
      {editName ? (
        <NameInput
          ref={inputRef}
          autoFocus
          type="text"
          onKeyDown={handleSubmit}
          defaultValue={displayName}
          onBlur={toggleEditName}
          onFocus={resetValidity}
          onChange={resetValidity}
          invalid={!isValid}
        />
      ) : (
        <NameWrapper onClick={toggleEditName}>
          <Name>
            {displayName}
            <HoverEdit />
          </Name>
        </NameWrapper>
      )}
    </Wrapper>
  );
}
