import styled from "styled-components";

export const H1 = styled.h1`
  font: 600 36px "rawline", sans-serif;
`;
export const H2 = styled.h2`
  font: 28px "rawline", sans-serif;
`;
export const H3 = styled.h3`
  font: 18px "rawline", sans-serif;
`;

export const MapOverlayText = styled.span`
  text-shadow: 0 0 5px white, 0 0 5px white, 0 0 5px white, 0 0 5px white, 0 0 5px white,
    0 0 5px white, 0 0 5px white, 0 0 5px white, 0 0 5px white, 0 0 5px white;
  position: absolute;
  pointer-events: none;
`;
