import { FormikErrors } from "formik";
import React, { useCallback } from "react";

import { Clickable } from "@src/Components/Buttons/Clickable";
import { baseInputStyles } from "@src/Components/Input/Input";
import { FieldLabel, InputErrors } from "@src/Components/Input/InputGroup";
import { Modal } from "@src/Components/Modal/Modal";
import { styled } from "@src/Components/theme";
import { useToggle } from "@src/Hooks/toggle";
import { SiteIdSelector } from "@src/ServiceDesigner/SiteIdSelector";

import { FederationGuestFormInput, FederationHostFormInput } from "./formSchemas";
import { useParentSiteInfo } from "./parentSiteInfo";

const SiteSelectorButton = styled(Clickable)`
  ${baseInputStyles}
  :disabled {
    background: ${({ theme }) => theme.backgroundLight};
    opacity: 1;
    :focus {
      outline: none;
    }
  }
`;
const InputErrorsWrapper = styled.span`
  grid-column: 2;
`;

type SiteSelectorInputProps = {
  setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void;
  fieldLabel: string;
  parentSiteId: string;
  errors: FormikErrors<FederationGuestFormInput | FederationHostFormInput>;
  disabled?: boolean;
};

export function SiteSelectorInput({
  setFieldValue,
  fieldLabel,
  parentSiteId,
  errors,
  disabled = false
}: SiteSelectorInputProps) {
  const { state, toggle } = useToggle();
  const selectSite = useCallback(
    (siteId: string) => {
      setFieldValue(fieldLabel, siteId);
      toggle();
    },
    [setFieldValue, toggle, fieldLabel]
  );

  const { parentSiteName } = useParentSiteInfo(parentSiteId);

  return (
    <>
      <FieldLabel htmlFor="parentSite">Parent Site</FieldLabel>
      <SiteSelectorButton onClick={toggle} disabled={disabled}>
        {parentSiteName || "Select Site"}
      </SiteSelectorButton>
      <InputErrorsWrapper>
        <InputErrors name={fieldLabel} errors={errors} />
      </InputErrorsWrapper>
      <Modal show={state} onOutsideClick={toggle}>
        <SiteIdSelector
          onSelectSite={selectSite}
          modalTitle="Select a Site to share as Availability Zone"
          selectedSite={parentSiteId}
        />
      </Modal>
    </>
  );
}
