import React from "react";
import { Link, Navigate } from "react-router-dom";
import styled from "styled-components";

import { Loading } from "@src/Components/Loading/Loading";
import { H2 } from "@src/Components/Text";
import { useOrgInfo } from "@src/Hooks/orgInfo";

const Wrapper = styled.div`
  grid-column: 1 /-1;
`;

export function RedirectFirstSite() {
  const { loading, orgId } = useOrgInfo();

  return loading ? (
    <Loading />
  ) : orgId ? (
    <Navigate to={`org/${orgId}`} />
  ) : (
    <Wrapper>
      <H2>There are no Organizations created yet!</H2>
      <p>
        Go to <Link to="/app/settings/organizations">Organization Settings</Link> to add one
      </p>
    </Wrapper>
  );
}
