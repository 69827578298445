import React from "react";

import { Tab } from "@src/Components/Navigation/Tab";
import { Role } from "@src/generated/graphql";
import { useUserRole } from "@src/Hooks/userRole";

export function TabBar() {
  const { userRole } = useUserRole();

  return (
    <nav>
      <Tab to="templates">Templates</Tab>
      <Tab to="editor">Editor</Tab>
      <Tab disabled={!(userRole === Role.Admin || userRole === Role.Operator)} to="publish">
        Publish
      </Tab>
    </nav>
  );
}
