import React, { lazy } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { useQuery } from "@apollo/client";
import { InlineLoading, Loading } from "@src/Components/Loading/Loading";
import { H1, H2 } from "@src/Components/Text";
import {
  ServiceDesignerFormParamsQuery,
  ServiceDesignerFormParamsQueryVariables
} from "@src/generated/graphql";

import { deserialiseFromEdit } from "./serialise";
import { FirstLine, Wrapper } from "./ServiceDesignerComponents";
import ServiceDesignerFormParams from "./ServiceDesignerFormParams.graphql";
import { TabBar } from "./TabBar";

const ServiceDesigner = lazy(() => import("./ServiceDesigner"));

const LoadingWrapper = styled.div`
  grid-row: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export function EditServicePage() {
  const editMatch = useParams<{ id: string }>();
  const serviceChainId = editMatch?.id;

  const { data, loading } = useQuery<
    ServiceDesignerFormParamsQuery,
    ServiceDesignerFormParamsQueryVariables
  >(ServiceDesignerFormParams, {
    variables: { id: serviceChainId },
    fetchPolicy: "network-only"
  });

  const formValues = data ? deserialiseFromEdit(data.serviceChain) : null;

  const extraTitle = (
    <H2>
      {loading ? (
        <InlineLoading />
      ) : (
        <>
          editing service <strong>{formValues?.name}</strong>
        </>
      )}
    </H2>
  );

  return (
    <Wrapper>
      <FirstLine>
        <H1>Service Designer</H1>
        {extraTitle}
      </FirstLine>
      <TabBar />
      {loading ? (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      ) : (
        <ServiceDesigner formValues={formValues} serviceChainId={serviceChainId} />
      )}
    </Wrapper>
  );
}
