import React from "react";

import { DescriptionList } from "@src/Components/DescriptionList";
import { Loading } from "@src/Components/Loading/Loading";
import { H3 } from "@src/Components/Text";
import { styled } from "@src/Components/theme";
import { Tag } from "@src/Infrastructure/sites/Inventory";

import { useFetchBlockInformation } from "./fetchBlockInformation";

const Wrapper = styled.div`
  padding: 0 10%;
`;
const IconPreview = styled.img`
  grid-column: 2;
  margin-bottom: 13px;
  max-width: 100%;
  max-height: 120px;
  justify-self: center;
`;

const DescriptorValues = styled(DescriptionList)`
  display: grid;
  grid-template-columns: auto minmax(auto, 1fr);
  dd {
    font-size: 13px;
    font-weight: 400;
  }
`;

type BlockDescriptorViewerProps = {
  blockName: string;
  blockVersion: string;
};

export function BlockDescriptorViewer({ blockName, blockVersion }: BlockDescriptorViewerProps) {
  const { chart, loading } = useFetchBlockInformation(blockName, blockVersion);
  const { name, version, displayName, vendor, description, logoUrl, categories } = chart;
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Wrapper>
          <H3>Descriptor Values</H3>
          <DescriptorValues>
            <DescriptorFields label="Name" value={name} />
            <DescriptorFields label="version" value={version} />
            <DescriptorFields label="displayName" value={displayName} />
            <DescriptorFields label="vendor" value={vendor} />
            <DescriptorFields label="description" value={description} />
            <DescriptorFields label="logoUrl" value={logoUrl} />
            <IconPreview src={logoUrl} alt="alt logo block" />
            <dt>Categories</dt>
            <dd>
              {categories.map(t => (
                <Tag key={t}>{t}</Tag>
              ))}
            </dd>
          </DescriptorValues>
        </Wrapper>
      )}
    </>
  );
}

function DescriptorFields({ label, value }: { label: string; value: string }) {
  return (
    <>
      <dt>{label}</dt>
      <dd>{value}</dd>
    </>
  );
}
