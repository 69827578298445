import React from "react";
import { useParams } from "react-router-dom";

import RemoveIcon from "@img/remove.svg";
import { ButtonGroup } from "@src/Components/Buttons/ButtonGroup";
import { InlineClickable } from "@src/Components/Buttons/Clickable";
import { Edit } from "@src/Components/Buttons/Edit";
import { PrimaryButton } from "@src/Components/Buttons/Primary";
import { CheckboxSlider, CheckBoxWrapper } from "@src/Components/Input/CheckboxSlider";
import { Modal } from "@src/Components/Modal/Modal";
import { DisableableNavLink } from "@src/Components/Navigation/DisableableNavLink";
import { H2, H3 } from "@src/Components/Text";
import { styled } from "@src/Components/theme";
import { Block, FetchInstanceInfoQuery } from "@src/generated/graphql";
import { useToggle } from "@src/Hooks/toggle";

import { Connections } from "./ActionsCell/Connections";
import { iconSize } from "./ActionsCell/iconSize";
import {
  useCanEditServiceChain,
  useCanRemoveServiceChain
} from "./ActionsCell/serviceChainPermisions";
import { BlockControlsContainer } from "./BlockControls";
import { ConnectionsLogModal } from "./LogsModal/ConnectionsLogModal";

const TopBar = styled.div`
  display: grid;
  grid-template-areas: "name name" "created connections";
  grid-template-columns: 5fr 6fr;
  align-items: baseline;
`;

const Name = styled(H2)`
  grid-area: name;
`;
const RemoveButton = styled(InlineClickable)`
  margin-left: 30px;
`;

const Remove = styled(RemoveIcon)`
  ${iconSize}
  fill: #444b88;
`;

const LogsConnectionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModalButton = styled(PrimaryButton)`
  height: fit-content;
  width: fit-content;
  padding: 10px;
  border-radius: 6px;
`;

const ConnectionsSection = styled.div`
  display: flex;
  align-items: center;
`;

const DateToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px 0 0;
`;

const ResourceValueToggleWrapper = styled(CheckBoxWrapper)`
  padding: 0;
`;

interface TopBarInstanceInfoProps {
  chain: FetchInstanceInfoQuery["serviceChain"];
  showResources: boolean;
  showConfirmationDelete: () => void;
  toggleInfo: () => void;
  selectedBlock: Block;
}

export function TopBarInstanceInfo({
  chain,
  showResources,
  toggleInfo,
  selectedBlock,
  showConfirmationDelete
}: TopBarInstanceInfoProps) {
  const { serviceChainId: id } = useParams();

  const edit = `/app/service-designer/edit/${id}`;
  const canRemove = useCanRemoveServiceChain(chain?.id);
  const canEdit = useCanEditServiceChain(chain?.id);
  const { state: shouldShowLogs, on: showLogs, off: hideLogs } = useToggle();
  const { state: shouldShowControls, on: showControls, off: hideControls } = useToggle();

  return (
    <>
      <TopBar>
        <Name>
          {chain?.name}
          <RemoveButton
            title={`remove ${chain?.name}`}
            disabled={!canRemove}
            onClick={showConfirmationDelete}
          >
            <Remove />
          </RemoveButton>
          <DisableableNavLink disabled={!canEdit} to={edit}>
            <Edit />
          </DisableableNavLink>
        </Name>
        <DateToggleWrapper>
          Created: {chain?.createdAt && new Date(chain.createdAt).toLocaleString()}
          <ResourceValueToggleWrapper>
            <CheckboxSlider
              name="resources or values"
              checked={showResources}
              onChange={toggleInfo}
              labelLeft="Values"
              labelRight="Resources"
            />
          </ResourceValueToggleWrapper>
        </DateToggleWrapper>
        <LogsConnectionsWrapper>
          <ConnectionsSection>
            <H3>Connections: </H3>
            <Connections connections={selectedBlock?.connections} />
          </ConnectionsSection>
          <ButtonGroup>
            {selectedBlock && selectedBlock.controls.length > 0 && (
              <ModalButton onClick={showControls}>Controls</ModalButton>
            )}
            {selectedBlock && <ModalButton onClick={showLogs}>Logs</ModalButton>}
          </ButtonGroup>
        </LogsConnectionsWrapper>
      </TopBar>

      {shouldShowLogs && (
        <Modal show={shouldShowLogs} onOutsideClick={hideLogs}>
          <ConnectionsLogModal
            serviceChainId={id}
            selectedBlock={selectedBlock?.id}
            hideLogs={hideLogs}
          />
        </Modal>
      )}
      {shouldShowControls && (
        <Modal show={shouldShowControls} onOutsideClick={hideControls}>
          <BlockControlsContainer
            blockName={selectedBlock.displayName}
            controls={selectedBlock.controls}
            hideModal={hideControls}
          />
        </Modal>
      )}
    </>
  );
}
