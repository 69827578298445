import { FieldLabel, SelectField } from "@src/Components/Input/InputGroup";
import { FormikErrors } from "formik";
import React from "react";

interface Org {
  id: string;
  displayName?: string;
}

interface OrgFormFieldProps {
  orgs: Org[];
  errors: FormikErrors<{ org: string }>;
}

export function OrgFormField({ orgs, errors }: OrgFormFieldProps) {
  return (
    <>
      <FieldLabel htmlFor="org">organization</FieldLabel>
      <SelectField name="org" errors={errors}>
        <option disabled value="">
          -- select an organization --
        </option>
        {orgs?.map(o => (
          <option key={o.id} value={o.id}>
            {o.displayName}
          </option>
        ))}
      </SelectField>
    </>
  );
}
