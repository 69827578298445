import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
#root {
  color: #1D232A;
  font: 12px "rawline", sans-serif;
}

body {
  margin:0;
  padding: 0;
}

@font-face {
  font-family: "rawline";
  src: url(${require("../../fonts/rawline-100.eot")});
  src: url(${require("../../fonts/rawline-100.eot?#iefix")}) format("embedded-opentype"),
    url(${require("../../fonts/rawline-100.woff2")}) format("woff2"),
    url(${require("../../fonts/rawline-100.woff")}) format("woff"),
    url(${require("../../fonts/rawline-100.ttf")}) format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "rawline";
  src: url(${require("../../fonts/rawline-100i.eot")});
  src: url(${require("../../fonts/rawline-100i.eot?#iefix")}) format("embedded-opentype"),
    url(${require("../../fonts/rawline-100i.woff2")}) format("woff2"),
    url(${require("../../fonts/rawline-100i.woff")}) format("woff"),
    url(${require("../../fonts/rawline-100i.ttf")}) format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "rawline";
  src: url(${require("../../fonts/rawline-200.eot")});
  src: url(${require("../../fonts/rawline-200.eot?#iefix")}) format("embedded-opentype"),
    url(${require("../../fonts/rawline-200.woff2")}) format("woff2"),
    url(${require("../../fonts/rawline-200.woff")}) format("woff"),
    url(${require("../../fonts/rawline-200.ttf")}) format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "rawline";
  src: url(${require("../../fonts/rawline-200i.eot")});
  src: url(${require("../../fonts/rawline-200i.eot?#iefix")}) format("embedded-opentype"),
    url(${require("../../fonts/rawline-200i.woff2")}) format("woff2"),
    url(${require("../../fonts/rawline-200i.woff")}) format("woff"),
    url(${require("../../fonts/rawline-200i.ttf")}) format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "rawline";
  src: url(${require("../../fonts/rawline-300.eot")});
  src: url(${require("../../fonts/rawline-300.eot?#iefix")}) format("embedded-opentype"),
    url(${require("../../fonts/rawline-300.woff2")}) format("woff2"),
    url(${require("../../fonts/rawline-300.woff")}) format("woff"),
    url(${require("../../fonts/rawline-300.ttf")}) format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "rawline";
  src: url(${require("../../fonts/rawline-300i.eot")});
  src: url(${require("../../fonts/rawline-300i.eot?#iefix")}) format("embedded-opentype"),
    url(${require("../../fonts/rawline-300i.woff2")}) format("woff2"),
    url(${require("../../fonts/rawline-300i.woff")}) format("woff"),
    url(${require("../../fonts/rawline-300i.ttf")}) format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "rawline";
  src: url(${require("../../fonts/rawline-400.eot")});
  src: url(${require("../../fonts/rawline-400.eot?#iefix")}) format("embedded-opentype"),
    url(${require("../../fonts/rawline-400.woff2")}) format("woff2"),
    url(${require("../../fonts/rawline-400.woff")}) format("woff"),
    url(${require("../../fonts/rawline-400.ttf")}) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "rawline";
  src: url(${require("../../fonts/rawline-400i.eot")});
  src: url(${require("../../fonts/rawline-400i.eot?#iefix")}) format("embedded-opentype"),
    url(${require("../../fonts/rawline-400i.woff2")}) format("woff2"),
    url(${require("../../fonts/rawline-400i.woff")}) format("woff"),
    url(${require("../../fonts/rawline-400i.ttf")}) format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "rawline";
  src: url(${require("../../fonts/rawline-500.eot")});
  src: url(${require("../../fonts/rawline-500.eot?#iefix")}) format("embedded-opentype"),
    url(${require("../../fonts/rawline-500.woff2")}) format("woff2"),
    url(${require("../../fonts/rawline-500.woff")}) format("woff"),
    url(${require("../../fonts/rawline-500.ttf")}) format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "rawline";
  src: url(${require("../../fonts/rawline-500i.eot")});
  src: url(${require("../../fonts/rawline-500i.eot?#iefix")}) format("embedded-opentype"),
    url(${require("../../fonts/rawline-500i.woff2")}) format("woff2"),
    url(${require("../../fonts/rawline-500i.woff")}) format("woff"),
    url(${require("../../fonts/rawline-500i.ttf")}) format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "rawline";
  src: url(${require("../../fonts/rawline-600.eot")});
  src: url(${require("../../fonts/rawline-600.eot?#iefix")}) format("embedded-opentype"),
    url(${require("../../fonts/rawline-600.woff2")}) format("woff2"),
    url(${require("../../fonts/rawline-600.woff")}) format("woff"),
    url(${require("../../fonts/rawline-600.ttf")}) format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "rawline";
  src: url(${require("../../fonts/rawline-600i.eot")});
  src: url(${require("../../fonts/rawline-600i.eot?#iefix")}) format("embedded-opentype"),
    url(${require("../../fonts/rawline-600i.woff2")}) format("woff2"),
    url(${require("../../fonts/rawline-600i.woff")}) format("woff"),
    url(${require("../../fonts/rawline-600i.ttf")}) format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "rawline";
  src: url(${require("../../fonts/rawline-700.eot")});
  src: url(${require("../../fonts/rawline-700.eot?#iefix")}) format("embedded-opentype"),
    url(${require("../../fonts/rawline-700.woff2")}) format("woff2"),
    url(${require("../../fonts/rawline-700.woff")}) format("woff"),
    url(${require("../../fonts/rawline-700.ttf")}) format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "rawline";
  src: url(${require("../../fonts/rawline-700i.eot")});
  src: url(${require("../../fonts/rawline-700i.eot?#iefix")}) format("embedded-opentype"),
    url(${require("../../fonts/rawline-700i.woff2")}) format("woff2"),
    url(${require("../../fonts/rawline-700i.woff")}) format("woff"),
    url(${require("../../fonts/rawline-700i.ttf")}) format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "rawline";
  src: url(${require("../../fonts/rawline-800.eot")});
  src: url(${require("../../fonts/rawline-800.eot?#iefix")}) format("embedded-opentype"),
    url(${require("../../fonts/rawline-800.woff2")}) format("woff2"),
    url(${require("../../fonts/rawline-800.woff")}) format("woff"),
    url(${require("../../fonts/rawline-800.ttf")}) format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "rawline";
  src: url(${require("../../fonts/rawline-800i.eot")});
  src: url(${require("../../fonts/rawline-800i.eot?#iefix")}) format("embedded-opentype"),
    url(${require("../../fonts/rawline-800i.woff2")}) format("woff2"),
    url(${require("../../fonts/rawline-800i.woff")}) format("woff"),
    url(${require("../../fonts/rawline-800i.ttf")}) format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "rawline";
  src: url(${require("../../fonts/rawline-900.eot")});
  src: url(${require("../../fonts/rawline-900.eot?#iefix")}) format("embedded-opentype"),
    url(${require("../../fonts/rawline-900.woff2")}) format("woff2"),
    url(${require("../../fonts/rawline-900.woff")}) format("woff"),
    url(${require("../../fonts/rawline-900.ttf")}) format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "rawline";
  src: url(${require("../../fonts/rawline-900i.eot")});
  src: url(${require("../../fonts/rawline-900i.eot?#iefix")}) format("embedded-opentype"),
    url(${require("../../fonts/rawline-900i.woff2")}) format("woff2"),
    url(${require("../../fonts/rawline-900i.woff")}) format("woff"),
    url(${require("../../fonts/rawline-900i.ttf")}) format("truetype");
  font-weight: 900;
  font-style: italic;
}
`;
