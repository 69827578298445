import styled from "styled-components";

import { Input } from "./Input";

export const SearchInput = styled(Input)`
  width: 100%;
  color: #777777;

  :focus {
    box-shadow: 0 2px 4px 0 rgba(60, 155, 216, 0.55);
    border: solid 1px rgba(60, 155, 216, 0.53);
    color: #444444;
    font-weight: 600;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const PaddedSearchInput = styled(SearchInput)`
  padding-left: 51px;
`;
