import { useSession } from "@src/Session";

import { usePermissionCheck } from "./permissionsCheck";

export function useIsMno() {
  const { session } = useSession();
  const sessionMetadata = session?.identity?.metadata_public as { org?: string };
  const orgId = sessionMetadata?.org;

  const { allowed, loading } = usePermissionCheck({
    relation: "mno",
    objectType: "orgs",
    objectId: orgId
  });

  return { isMno: allowed, loading };
}
