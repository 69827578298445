import { useQuery } from "@apollo/client";
import {
  FetchEndpointSourcesQuery,
  FetchEndpointSourcesQueryVariables,
  FetchInstanceEndpointsQuery,
  FetchInstanceEndpointsQueryVariables,
  FetchSourceLogsQuery,
  FetchSourceLogsQueryVariables,
  LogEndpoint,
  LogSource
} from "@src/generated/graphql";

import FetchEndpointSources from "./FetchEndpointSources.graphql";
import FetchInstanceEndpoints from "./FetchInstanceEndpoints.graphql";
import FetchSourceLogs from "./FetchSourceLogs.graphql";

export function useFetchBlockLogs(
  instanceId: string,
  blockId: string,
  selectedEndpoint: LogEndpoint,
  selectedSource: LogSource
) {
  const { data: endpointData, loading: loadingEndpoints } = useQuery<
    FetchInstanceEndpointsQuery,
    FetchInstanceEndpointsQueryVariables
  >(FetchInstanceEndpoints, {
    variables: { id: instanceId },
    skip: !instanceId,
    errorPolicy: "all",
    fetchPolicy: "network-only"
  });
  const { data: sourcesData, loading: loadingSources } = useQuery<
    FetchEndpointSourcesQuery,
    FetchEndpointSourcesQueryVariables
  >(FetchEndpointSources, {
    variables: {
      id: instanceId,
      endpointId: selectedEndpoint?.id,
      hash: selectedEndpoint?.hash
    },
    skip: !selectedEndpoint,
    errorPolicy: "all",
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true
  });
  const {
    data: logsData,
    loading: loadingLogs,
    refetch
  } = useQuery<FetchSourceLogsQuery, FetchSourceLogsQueryVariables>(FetchSourceLogs, {
    variables: {
      id: instanceId,
      endpointId: selectedEndpoint?.id,
      hash: selectedEndpoint?.hash,
      sourceId: selectedSource?.id
    },
    skip: !selectedSource,
    errorPolicy: "all",
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true
  });

  const endpointBlock = (endpointData?.serviceChain?.blocks || []).find(
    block => block.id === blockId
  );
  const sourceBlock = (sourcesData?.serviceChain?.blocks || []).find(block => block.id === blockId);
  const logsBlock = (logsData?.serviceChain?.blocks || []).find(block => block.id === blockId);

  const sourceLogs = (logsBlock?.logEndpoint?.source?.logs || []).map(({ log }) => log).join(`\n`);

  return {
    endpoints: endpointBlock?.logEndpoints || [],
    oneEndpoint: endpointBlock?.logEndpoints ? endpointBlock?.logEndpoints[0] : null,
    sources: sourceBlock?.logEndpoint?.sources || [],
    logs: sourceLogs,
    loadingEndpoints,
    loadingSources,
    loadingLogs,
    refetchLogs: refetch
  };
}
