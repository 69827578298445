import { FormikProps } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import { Title } from "@src/Components/BlockSelector/BlockSelectorList";
import {
  ConfigWrapper,
  GridContainer,
  ParameterButton,
  Sidebar,
  ValueStatusForm
} from "@src/Components/BlockSelector/ConfigStyles";
import { Loading } from "@src/Components/Loading/Loading";
import { H2 } from "@src/Components/Text";
import { BlockChart, ChartMetaInput } from "@src/generated/graphql";
import EditorSectionButton from "@src/ServiceDesigner/EditorSectionButton";
import { useEditorTheme } from "@src/ServiceDesigner/editorTheme";
import { EmbeddedEditor } from "@src/ServiceDesigner/EmbeddedEditor";

import { ChartYamlForm } from "./ChartYamlForm";
import { EditingSection } from "./Editor";
import { EditedChart, PublisherEditorAction, PublisherEditorState } from "./editorPublisherReducer";
import { BlockPublisherForm } from "./serialise";

interface BlockConfigPanelProps extends FormikProps<BlockPublisherForm> {
  editorState: PublisherEditorState;
  editorDispatch: React.Dispatch<PublisherEditorAction>;
  originalTemplate: Omit<BlockChart, "name" | "version">;
}

export function BlockConfigPanel({
  editorState,
  editorDispatch,
  originalTemplate,
  ...formikProps
}: BlockConfigPanelProps) {
  const { values: newBlock } = formikProps;
  const { templateName: name } = newBlock;

  useEffect(() => {
    editorDispatch({
      type: "init",
      payload: {
        overrides: originalTemplate.overridesYaml || "",
        values: originalTemplate.valuesYaml || ""
      }
    });
  }, [editorDispatch, originalTemplate.overridesYaml, originalTemplate.valuesYaml]);

  return (
    <ConfigWrapper>
      {name.length ? (
        <ConfigureBlock
          editorState={editorState}
          editorDispatch={editorDispatch}
          {...formikProps}
        />
      ) : (
        <>
          <H2>No template has been selected</H2>
          <p>
            Try selecting a template from the{" "}
            <NavLink to={"/app/publisher/templates"}>publisher</NavLink>
          </p>
        </>
      )}
    </ConfigWrapper>
  );
}

interface ConfigureBlockProps extends FormikProps<BlockPublisherForm> {
  editorState: PublisherEditorState;
  editorDispatch: React.Dispatch<PublisherEditorAction>;
}

function ConfigureBlock({ editorState, editorDispatch, ...formikProps }: ConfigureBlockProps) {
  const { setFieldValue, values: newBlock, errors } = formikProps;

  const { displayName } = newBlock;

  const [editingSection, setEditingSection] = useState<EditingSection>(EditingSection.Overrides);

  const { theme, changeTheme } = useEditorTheme();

  const editBlockValue = useCallback(
    (input: string | ChartMetaInput, field: EditedChart | "descriptor") => {
      setFieldValue(field, input);
    },
    [setFieldValue]
  );

  const isDescriptorValid = !errors.descriptor;

  const changeTab = useCallback(
    (section: EditingSection) => {
      if (section !== EditingSection.Values)
        editorDispatch({
          type: "save",
          payload: { chart: EditedChart.Values, block: displayName }
        });
      if (section !== EditingSection.Overrides)
        editorDispatch({
          type: "save",
          payload: { chart: EditedChart.Overrides, block: displayName }
        });
      setEditingSection(section);
    },
    [editorDispatch, setEditingSection, displayName]
  );
  return (
    <>
      <H2>
        Configure <strong>{displayName}</strong>
      </H2>
      <GridContainer>
        <Sidebar>
          <Title>block fields</Title>
          <EditorSectionButton
            section={editingSection}
            sectionName={EditingSection.Values}
            setSection={changeTab}
            editorState={editorState.values.status}
            titleLabel="values"
          />
          <EditorSectionButton
            section={editingSection}
            sectionName={EditingSection.Overrides}
            setSection={changeTab}
            editorState={editorState.overrides.status}
            titleLabel="overrides"
          />
          <ParameterButton
            onClick={() => changeTab(EditingSection.Descriptor)}
            selected={editingSection === EditingSection.Descriptor}
          >
            {EditingSection.Descriptor}
            <ValueStatusForm
              invalid={!isDescriptorValid}
              title={(isDescriptorValid ? "valid" : "invalid") + " descriptor"}
            >
              {isDescriptorValid ? "✓" : "!"}
            </ValueStatusForm>
          </ParameterButton>
        </Sidebar>

        <>
          {newBlock ? (
            <>
              {editingSection === EditingSection.Descriptor && <ChartYamlForm {...formikProps} />}
              {editingSection === EditingSection.Values && (
                <EmbeddedEditor
                  key={`${newBlock.displayName}-values`}
                  blockDisplayName={newBlock.displayName}
                  defaultYaml={editorState.values.original}
                  theme={theme}
                  configurationParameter={editingSection}
                  isEditing={false}
                  changeTheme={changeTheme}
                  editBlockValue={editBlockValue}
                  editedChart={EditedChart.Values}
                  editorValues={newBlock.values}
                  editorDispatch={editorDispatch}
                  savedValue={editorState.values.saved}
                />
              )}
              {editingSection === EditingSection.Overrides && (
                <EmbeddedEditor
                  key={`${newBlock.displayName}-overrides`}
                  blockDisplayName={newBlock.displayName}
                  defaultYaml={editorState.overrides.original}
                  theme={theme}
                  configurationParameter={editingSection}
                  isEditing={false}
                  changeTheme={changeTheme}
                  editBlockValue={editBlockValue}
                  editedChart={EditedChart.Overrides}
                  editorValues={newBlock.overrides}
                  editorDispatch={editorDispatch}
                  savedValue={editorState.overrides.saved}
                />
              )}
            </>
          ) : (
            <Loading />
          )}
        </>
      </GridContainer>
    </>
  );
}
