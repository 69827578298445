import { useCallback } from "react";

import { useMutation } from "@apollo/client";
import {
  EditSiteDisplayNameMutation,
  EditSiteDisplayNameMutationVariables
} from "@src/generated/graphql";

import { FormValues } from "./DisplayNameMiniForm";
import EditSiteDisplayName from "./EditSiteDisplayName.graphql";

export function useEditSiteDisplayName() {
  const [mutate] = useMutation<EditSiteDisplayNameMutation, EditSiteDisplayNameMutationVariables>(
    EditSiteDisplayName
  );
  const mutateSite = useCallback(
    (id: string, displayName: FormValues) => mutate({ variables: { id, site: displayName } }),
    [mutate]
  );

  return { mutateSite };
}
