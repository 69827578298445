import { useCombobox, UseComboboxStateChange } from "downshift";
import React, {
  Dispatch,
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState
} from "react";
import styled from "styled-components";

import { useApolloClient } from "@apollo/client";
import WebMercatorViewport from "@math.gl/web-mercator";
import { DropdownMenu, DropdownMenuItemWrapper } from "@src/Components/DropdownMenu";
import { Input } from "@src/Components/Input/Input";
import { DeviceSearchQuery, DeviceSearchQueryVariables, Tag } from "@src/generated/graphql";
import { useGoogleMapsApiToken } from "@src/Hooks/apiToken";
import { metersPerPixel } from "@src/Map/convert";
import { MapAction, MapState } from "@src/Map/mapReducer";

import DeviceSearch from "./DeviceSearch.graphql";
import {
  CoordsDropdownItem,
  DeviceDropdownItem,
  LocationDropdownItem,
  locationInitialState,
  locationReducer,
  PlaceDropdownItem,
  toCombined,
  toSections
} from "./locationReducer";

interface LocationSearchProps {
  dispatch: Dispatch<MapAction>;
  mapState: MapState;
  tags?: Tag[];
}

const Loading = styled(DropdownMenuItemWrapper)`
  color: #777777;
`;

export const dropdownMenuWidth = "400px";

export const SearchContainer = styled.div`
  width: ${dropdownMenuWidth};
  margin-bottom: 10px;
`;

function hasCoords(item: LocationDropdownItem): item is CoordsDropdownItem | DeviceDropdownItem {
  return item.type === "coords" || item.type === "device";
}

export function LocationSearch(props: LocationSearchProps) {
  const { mapState, dispatch, tags } = props;

  const { sw, ne } = useMemo(() => {
    if (mapState.longitude == null && mapState.longitude == null) return { sw: null, ne: null };
    const viewport = new WebMercatorViewport(mapState);
    const [sw, ne] = viewport.getBounds();
    return {
      sw: {
        lat: sw[1],
        lng: sw[0]
      },
      ne: {
        lat: ne[1],
        lng: ne[0]
      }
    };
  }, [mapState]);

  const move = useCallback(
    (geometry: google.maps.places.PlaceGeometry) => {
      if (!geometry) return;

      const ne = geometry.viewport.getNorthEast();
      const sw = geometry.viewport.getSouthWest();
      dispatch({
        type: "flyToBounds",
        bounds: [
          { lat: sw.lat(), lng: sw.lng() },
          { lat: ne.lat(), lng: ne.lng() }
        ]
      });
    },
    [dispatch]
  );

  const apiToken = useGoogleMapsApiToken();
  const placesService = useRef<google.maps.places.PlacesService>();
  const autocompleteService = useRef<google.maps.places.AutocompleteService>();
  const attribution = useRef<HTMLDivElement>();

  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    if (!scriptLoaded) return;

    if (
      window.google &&
      attribution.current &&
      !(placesService.current && autocompleteService.current)
    ) {
      placesService.current = new google.maps.places.PlacesService(attribution.current);
      autocompleteService.current = new google.maps.places.AutocompleteService();
    }

    return () => {
      placesService.current = null;
      autocompleteService.current = null;
    };
  }, [scriptLoaded]);

  useEffect(() => {
    const scriptId = "maps-googleapis";
    if (document.getElementById(scriptId)) {
      setScriptLoaded(true);
      return;
    }

    if (!apiToken) return;

    const script = document.createElement("script");
    script.id = scriptId;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiToken}&libraries=places`;
    script.onload = () => setScriptLoaded(true);
    document.body.append(script);
  }, [apiToken]);

  const onPlaceSelect = useCallback(
    ({ selectedItem }: UseComboboxStateChange<LocationDropdownItem>) => {
      if (!selectedItem) return;

      if (hasCoords(selectedItem)) {
        dispatch({ type: "setCenter", center: selectedItem.coords });
      } else {
        placesService.current.getDetails({ placeId: selectedItem.id }, (place, status) => {
          if (status == google.maps.places.PlacesServiceStatus.OK) {
            move(place.geometry);
          }
        });
      }
    },
    [dispatch, move]
  );

  const itemToString = useCallback((item: LocationDropdownItem) => {
    return item?.displayName || "";
  }, []);

  const [isLoading, setLoading] = useState(false);
  const client = useApolloClient();

  const { latitude, longitude, width, zoom } = mapState;

  const [locationState, locationDispatch] = useReducer(locationReducer, locationInitialState);

  const comboBoxProps = useCombobox<LocationDropdownItem>({
    items: toCombined(locationState),
    onSelectedItemChange: onPlaceSelect,
    onInputValueChange: ({ inputValue }) => {
      locationDispatch({ type: "inputValueChanged", value: inputValue });

      if (/\S/.test(inputValue)) {
        setLoading(true);

        client
          .query<DeviceSearchQuery, DeviceSearchQueryVariables>({
            query: DeviceSearch,
            variables: {
              searchTerm: inputValue,
              tags
            }
          })
          .then(({ data }) => {
            locationDispatch({
              type: "setDevices",
              devices:
                data.devices?.devices?.map(({ id, displayName, position: coords }) => ({
                  id,
                  displayName,
                  type: "device",
                  coords
                })) || []
            });
          });

        autocompleteService.current.getPlacePredictions(
          {
            input: inputValue,
            location: new google.maps.LatLng({ lat: latitude, lng: longitude }),
            radius: (width / 2) * metersPerPixel(latitude, zoom)
          },
          (predictions, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              locationDispatch({
                type: "setPlaces",
                places: predictions
                  .filter(p => p.place_id)
                  .map(
                    ({ place_id: id, description }): PlaceDropdownItem => ({
                      id,
                      displayName: description,
                      type: "place"
                    })
                  )
              });
            }
            setLoading(false);
          }
        );
      }
    },
    itemToString
  });
  const { getComboboxProps, getInputProps, inputValue, highlightedIndex } = comboBoxProps;

  const inputProps = getInputProps({
    placeholder: "search for a place/device or enter coordinates...",
    onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        // if the user hit Enter without selected any result from the list
        if (highlightedIndex === -1) {
          // always prevent Enter from triggering a form submission
          event.preventDefault();

          // if the text they entered looks like a pair of coordinates, we go there
          if (locationState.coords) {
            dispatch({ type: "setCenter", center: locationState.coords });
          } else if (inputValue) {
            // otherwise, we guess they wanted to go to the place whose name they wrote
            placesService.current.findPlaceFromQuery(
              {
                query: inputValue,
                locationBias: new google.maps.LatLngBounds(sw, ne),
                fields: ["geometry"]
              },
              (res, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                  move(res[0].geometry);
                }
              }
            );
          }
        }
      }
    }
  });

  return (
    <>
      <SearchContainer {...getComboboxProps()}>
        <Input {...inputProps} />
        <DropdownMenu {...comboBoxProps} data={toSections(locationState)}>
          {isLoading ? <Loading>loading...</Loading> : null}
        </DropdownMenu>
      </SearchContainer>
      <div ref={attribution} />
    </>
  );
}
