import { OktoResourceLogsQuery, OktoResourceLogsQueryVariables } from "@src/generated/graphql";
import { mockedResponses } from "@src/Mock/mockedResponse";

import OktoResourceLogs from "./OktoResourceLogs.graphql";

const data: OktoResourceLogsQuery = {
  __typename: "Query",
  oktoResourceLogs: `{"blah": "blah"}
{"blah": "blah", "blah": "blah","blah": "blah", "blah": "blah",}
{"blah": "blah"}
{"blah": "blah"}`
};

export const mockLogs = mockedResponses({
  request: {
    query: OktoResourceLogs,
    variables: <OktoResourceLogsQueryVariables>{
      id: "block1-resource1"
    }
  },
  result: {
    data
  }
});
