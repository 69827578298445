import { FieldArray, Form, Formik, FormikProps } from "formik";
import React, {
  Dispatch,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState
} from "react";
import { useOutletContext, useParams } from "react-router-dom";
import * as Yup from "yup";

import { useMutation, useQuery } from "@apollo/client";
import { ButtonRowCustom, ButtonRowDefault } from "@src/Components/Buttons/ButtonRow";
import { DescriptionList } from "@src/Components/DescriptionList";
import { CheckboxGroup, CheckboxInput } from "@src/Components/Input/CheckboxGroup";
import { FieldLabel, Fieldset, InputField, ReadOnlyInput } from "@src/Components/Input/InputGroup";
import { Loading } from "@src/Components/Loading/Loading";
import { H2, H3 } from "@src/Components/Text";
import { styled } from "@src/Components/theme";
import { makeTag } from "@src/Devices/Tag/makeTag";
import {
  Connection,
  ConnectionInput,
  Device,
  EditableDeviceQuery,
  EditableDeviceQueryVariables,
  EditDeviceMutation,
  EditDeviceMutationVariables,
  LatLng
} from "@src/generated/graphql";
import { useToggle } from "@src/Hooks/toggle";
import { InventoryContext } from "@src/Infrastructure/sites/Inventory";
import { initialMapState, MapAction, mapReducer, MapState } from "@src/Map/mapReducer";
import { SelectedTags, TagMarker } from "@src/ServiceDef/SelectedTags";
import { TagInput } from "@src/ServiceDef/TagInput";
import { Shape } from "@src/yupTypes";

import { ErrorContainer } from "../Components/ErrorContainer";
import { Tags } from "./AddDeviceForm";
import { connectionSchema, deviceConnections } from "./connection/deviceConnections";
import { ConnectionMarker } from "./ConnectionMarker";
import { DeviceConnectionsForm } from "./DeviceConnectionsForm";
import EditableDevice from "./EditableDevice.graphql";
import EditDevice from "./EditDevice.graphql";
import { Info, WorkflowSelector } from "./NztpFields";
import { PositionSelector } from "./PositionSelector";
import { splitTags } from "./Tag/splitTags";

export interface EditDeviceValues {
  displayName: string | null;
  site: string | null;
  tags: string[] | null;
  position: {
    lat: number;
    lng: number;
  };
  workflow: string;
  provision: boolean;
  connections: Connection[];
}

const shouldProvision = (values: EditDeviceValues, initialValues: EditDeviceValues) =>
  values.workflow !== initialValues.workflow || values.provision;

export function EditDeviceForm() {
  const { returnToInventory: onComplete } = useOutletContext<InventoryContext>();

  const { deviceId: id } = useParams<{ deviceId: string }>();
  const [submitFailed, setSubmitFailed] = useState(false);
  const [editDevice] = useMutation<EditDeviceMutation, EditDeviceMutationVariables>(EditDevice);

  const { data, loading } = useQuery<EditableDeviceQuery, EditableDeviceQueryVariables>(
    EditableDevice,
    { variables: { id }, fetchPolicy: "network-only" }
  );

  const device = data?.devices?.devices?.[0];
  const { fixedTags, tags } = splitTags(device?.tags);

  const [mapState, dispatch] = useReducer(mapReducer, initialMapState);
  const { longitude, latitude, zoom } = mapState;
  const mapReady = longitude && latitude && zoom;
  const connections = deviceConnections(device);

  const initialConnections = useRef<Connection[]>([]);
  const isFirstMount = useRef(true);

  useEffect(() => {
    if (connections) {
      if (!isFirstMount.current) return;
      isFirstMount.current = false;
      initialConnections.current = connections;
    }
  }, [connections]);

  const deletedConnections = useCallback((connections: Connection[]) => {
    const initialIds = new Set<string>(initialConnections.current.map(c => c.id)),
      currentIds = new Set<string>(connections.map(c => c.id));
    return [...initialIds].filter(id => !currentIds.has(id));
  }, []);

  useEffect(() => {
    if (!mapReady && device) {
      dispatch({ type: "init", center: device.position, zoom: 15 });
    }
  }, [mapReady, device]);

  const { state: showReviewScreen, toggle: toggleReviewScreen } = useToggle();

  const schema = useMemo(
    () =>
      Yup.lazy((values: EditDeviceValues) =>
        Yup.object<Shape<EditDeviceValues>>().shape({
          displayName: Yup.string().required("display name is required"),
          site: Yup.string().required(),
          position: Yup.object<Shape<LatLng>>().shape({
            lat: Yup.number().label("latitude").required("latitude is required"),
            lng: Yup.number().label("longitude").required("longitude is required")
          }),
          tags: Yup.array().of(Yup.string()),
          workflow: !fixedTags.includes("nztp") ? Yup.string().nullable() : Yup.string().required(),
          provision: Yup.bool().nullable(true),
          connections: Yup.array().of(connectionSchema)
        })
      ),
    [fixedTags]
  );

  if (loading) return <Loading />;
  if (!device) return <ErrorContainer>failed to load device info</ErrorContainer>;

  const initialValues: EditDeviceValues = {
    displayName: device.displayName,
    site: device.site || "",
    tags,
    position: device.position,
    workflow: device.nztpWorkflow,
    provision: null,
    connections: connections
  };

  return loading ? (
    <Loading />
  ) : (
    <Formik<EditDeviceValues>
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={async values => {
        const allTags = [...fixedTags, ...values.tags];
        setSubmitFailed(false);
        const deletedConns = deletedConnections(values.connections);
        try {
          await editDevice({
            variables: {
              id,
              device: {
                displayName: values.displayName.trim(),
                tags: allTags,
                position: values.position,
                site: values.site,
                nztpWorkflow: values.workflow,
                nztpProvision: shouldProvision(values, initialValues),
                ...(deletedConns.length > 0
                  ? {
                      deletedConnections: deletedConns
                    }
                  : null),
                ...(values.connections.length > 0
                  ? {
                      connections: values.connections
                    }
                  : null)
              }
            }
          });
          onComplete();
        } catch (err) {
          console.error(err);
          setSubmitFailed(true);
        }
      }}
    >
      {formikProps =>
        formikProps.isSubmitting ? (
          <Loading />
        ) : showReviewScreen ? (
          <ReviewForm
            {...formikProps}
            deletedConnections={deletedConnections}
            initialValueConnections={initialConnections.current}
            toggleReviewScreen={toggleReviewScreen}
            submitFailed={submitFailed}
            shouldProvision={shouldProvision(formikProps.values, initialValues)}
          />
        ) : (
          <FormInner
            {...formikProps}
            mapState={mapState}
            dispatch={dispatch}
            toggleReviewScreen={toggleReviewScreen}
            device={device}
            fixedTags={fixedTags}
            onComplete={onComplete}
          />
        )
      }
    </Formik>
  );
}

interface FormInnerProps extends FormikProps<EditDeviceValues> {
  mapState: MapState;
  dispatch: Dispatch<MapAction>;
  toggleReviewScreen: () => void;
  device: Device;
  fixedTags: string[];
  onComplete: () => void;
}

const IpmiFieldset = styled.fieldset`
  margin-bottom: 35px;
  legend {
    padding: 0 1em;
    input {
      vertical-align: sub;
    }
  }
`;

function FormInner({
  mapState,
  dispatch,
  toggleReviewScreen,
  fixedTags,
  onComplete,
  ...formikProps
}: FormInnerProps) {
  const { errors, values, initialValues, setFieldValue, isValid, isSubmitting } = formikProps;

  const setPosition = useCallback(
    (center: LatLng) => {
      setFieldValue("position", center, false);
    },
    [setFieldValue]
  );

  const tags = values.tags.map(key => makeTag(key));
  return (
    <>
      <H2>Edit device</H2>

      <Form>
        <Fieldset>
          <FieldLabel htmlFor="displayName">display name</FieldLabel>
          <InputField name="displayName" errors={errors} />
          <FieldLabel htmlFor="site">site</FieldLabel>
          <ReadOnlyInput name="site" errors={errors} />
          <FieldArray name="tags">
            {({ push, remove }) => (
              <>
                <FieldLabel>tags</FieldLabel>
                <Tags>
                  <SelectedTags
                    fixedTags={fixedTags.map(key => makeTag(key))}
                    tags={tags}
                    remove={remove}
                  />
                  <TagInput tags={tags} add={t => push(t.key)} />
                </Tags>
              </>
            )}
          </FieldArray>
        </Fieldset>
        {fixedTags.includes("nztp") ? (
          <>
            <Fieldset>
              <WorkflowSelector values={values} setFieldValue={setFieldValue} />
              <CheckboxGroup
                checked={!!shouldProvision(values, initialValues)}
                onChange={() => setFieldValue("provision", !values.provision)}
                disabled={values.workflow !== initialValues.workflow}
              >
                <Info>force reprovision on next boot</Info>
              </CheckboxGroup>
            </Fieldset>
          </>
        ) : null}
        <Fieldset>
          <FieldArray name="connections">
            {({ push, remove, pop }) => (
              <DeviceConnectionsForm push={push} remove={remove} pop={pop} {...formikProps} />
            )}
          </FieldArray>
          <PositionSelector
            fieldValue={values.position}
            setFieldValue={setPosition}
            mapState={mapState}
            dispatch={dispatch}
          />
          <ButtonRowCustom
            onClickBack={onComplete}
            onClickSubmit={toggleReviewScreen}
            isValid={isValid}
            isSubmitting={isSubmitting}
          />
        </Fieldset>
      </Form>
    </>
  );
}

const WillProvision = styled.span`
  margin-left: 0.5em;
`;

const Warning = styled.strong`
  color: ${({ theme }) => theme.warning};
`;

interface ReviewFormProps extends FormikProps<EditDeviceValues> {
  toggleReviewScreen: () => void;
  submitFailed: boolean;
  shouldProvision: boolean;
  deletedConnections: (connections: Connection[]) => string[];
  initialValueConnections: Connection[];
}

function ReviewForm({
  values,
  isSubmitting,
  toggleReviewScreen,
  submitFailed,
  shouldProvision,
  deletedConnections,
  initialValueConnections
}: ReviewFormProps) {
  const deletedConns = deletedConnections(values.connections);
  return (
    <>
      <H2>Review device details</H2>
      {submitFailed ? (
        <ErrorContainer>
          <H3>Failed to add device</H3>
          <p>Please review the details and try again</p>
        </ErrorContainer>
      ) : null}
      <Form>
        <DescriptionList>
          <dt>Display Name</dt>
          <dd>{values.displayName}</dd>
          {values.site ? (
            <>
              <dt>Site</dt>
              <dd>{values.site}</dd>
            </>
          ) : null}
          <dt>Tags</dt>
          <dd>
            {values.tags.length ? (
              values.tags.map(t => (
                <TagMarker key={t} inc>
                  {t}
                </TagMarker>
              ))
            ) : (
              <em>none</em>
            )}
          </dd>
          {values.tags.includes("nztp") ? (
            <>
              <dt>Workflow</dt>
              <dd>
                {values.workflow}
                {shouldProvision ? (
                  <WillProvision>
                    <Warning>Warning!</Warning> Node will be (re)provisioned on next boot
                  </WillProvision>
                ) : null}
              </dd>
            </>
          ) : null}
          <dt>Position</dt>
          <dd>
            {values.position.lat}, {values.position.lng}
          </dd>
          {values.connections.length > 0 || deletedConns.length > 0 ? (
            <>
              <dt>Connections</dt>
              <dd>
                <>
                  {values.connections.map(c => {
                    const oldFields = initialValueConnections.find(conn => conn.id === c.id);
                    if (!oldFields) {
                      return <ConnectionMarker key={c.id} connection={c} status="new" />;
                    }

                    const fieldDiff: Partial<Connection> = {};
                    Object.keys(c).forEach((k: keyof ConnectionInput) => {
                      const oldField = oldFields[k];
                      if (c[k] !== oldField) {
                        fieldDiff[k] = oldField;
                      }
                    });

                    return (
                      <ConnectionMarker
                        key={c.id}
                        connection={c}
                        {...(Object.keys(fieldDiff).length
                          ? {
                              status: "updated",
                              fieldDiff
                            }
                          : { status: "unmodified" })}
                      />
                    );
                  })}
                  {deletedConns.map(cId => {
                    const beforeEditConn = initialValueConnections.find(conn => conn.id === cId);
                    return (
                      <ConnectionMarker key={cId} connection={beforeEditConn} status="deleted" />
                    );
                  })}
                </>
              </dd>
            </>
          ) : null}
        </DescriptionList>
        <ButtonRowDefault
          onClickBack={toggleReviewScreen}
          isSubmitting={isSubmitting}
          backText="Back"
          submitText="Update"
        />
      </Form>
    </>
  );
}
