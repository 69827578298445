import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import { useWindowSize } from "usehooks-ts";

import { StatusTag } from "@src/Components/StatusTag";
import { H3 } from "@src/Components/Text";
import { styled } from "@src/Components/theme";
import { Block } from "@src/generated/graphql";

const Wrapper = styled.div`
  height: 100%;
  overflow: hidden;
`;

const BlockList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 85%;
  overflow: auto;
  overflow-x: hidden;
  padding-right: 10px;
`;

const BlockWrapper = styled.div<{ isSelected: boolean }>`
  cursor: pointer;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.border};
  border-width: ${({ isSelected }) => (isSelected ? "2px" : null)};
  border-color: ${({ isSelected, theme }) => (isSelected ? theme.primaryLight : null)};
  :last-child {
    margin-bottom: 2rem;
  }
  :hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px;
  }
`;

const TopLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Logo = styled.img`
  height: 30px;
`;

const DisplayName = styled.p`
  font-weight: 600;
`;

const Vendor = styled.p`
  opacity: 0.7;

  &::before {
    content: "by ";
  }

  &:empty::before {
    content: "unknown vendor";
  }
`;

const Description = styled.p``;

const Version = styled(DisplayName)`
  &::before {
    content: "version: ";
  }

  &:empty::after {
    content: "unknown";
  }
`;

interface InstanceBlocksProps {
  blocks: Block[];
  selectedBlock: Block;
  selectBlock: (blockId: string) => void;
}

export function InstanceBlocks({ blocks, selectedBlock, selectBlock }: InstanceBlocksProps) {
  return (
    <Wrapper>
      <H3>Blocks</H3>

      {blocks?.length ? (
        <BlockList>
          {blocks.map(b => (
            <InstanceBlock
              key={b?.id}
              block={b}
              isSelected={selectedBlock?.id === b.id}
              selectBlock={selectBlock}
            />
          ))}
        </BlockList>
      ) : (
        <p>no blocks</p>
      )}
    </Wrapper>
  );
}

interface InstanceBlockProps {
  block: Block;
  isSelected: boolean;
  selectBlock: (blockId: string) => void;
}

function InstanceBlock({ block: b, isSelected, selectBlock }: InstanceBlockProps) {
  const { revision } = useParams();
  const showStatus = !revision;
  const onClick = useCallback(() => selectBlock(b.id), [b, selectBlock]);
  const { width } = useWindowSize();
  const showDescription = width > 1300;

  return (
    <BlockWrapper onClick={onClick} isSelected={isSelected}>
      <TopLine>
        <Logo src={b.chart?.logoUrl} />
        {showStatus && <StatusTag status={b.status} />}
      </TopLine>
      <DisplayName>{b.displayName}</DisplayName>
      <Vendor>{b.chart?.vendor}</Vendor>
      {showDescription && <Description>{b.chart?.description}</Description>}
      <Version>{b.chart?.version}</Version>
    </BlockWrapper>
  );
}
