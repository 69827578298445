import { useQuery } from "@apollo/client";
import { GoogleMapsApiTokenQuery, MapboxApiTokenQuery } from "@src/generated/graphql";

import GoogleMapsApiToken from "./GoogleMapsApiToken.graphql";
import MapboxApiToken from "./MapboxApiToken.graphql";

export function useGoogleMapsApiToken() {
  const { data } = useQuery<GoogleMapsApiTokenQuery>(GoogleMapsApiToken);
  return data?.apiTokens?.googleMaps;
}

export function useMapboxApiToken() {
  const { data } = useQuery<MapboxApiTokenQuery>(MapboxApiToken);
  return data?.apiTokens?.mapbox;
}
