import { useMutation } from "@apollo/client";
import { RemoveModalProps } from "@src/Components/Modal/RemoveModal";
import {
  AllUsersTableQuery,
  AllUsersTableQueryVariables,
  DeleteUserMutation,
  DeleteUserMutationVariables,
  DismissUserMutation,
  DismissUserMutationVariables,
  OrgUsersTableQuery,
  OrgUsersTableQueryVariables
} from "@src/generated/graphql";
import { useRemoveRow } from "@src/Hooks/removeRow";
import { AllUsersTable } from "@src/User/AllUsersTable.graphql";
import { DeleteUser } from "@src/User/DeleteUser.graphql";
import { DismissUser } from "@src/User/DismissUser.graphql";
import { OrgUsersTable } from "@src/User/OrgUsersTable.graphql";

export function useDeleteUserModal(orgId: string) {
  const {
    modalShown: modalDismissUserShown,
    entity: selectedDismissUser,
    hide: hideDismissModal,
    openRowModal: openRowDismissModal,
    onCompleted: onDismissCompleted
  } = useRemoveRow();

  const {
    modalShown: modalRemoveUserShown,
    entity: selectedRemoveUser,
    hide: hideRemoveModal,
    openRowModal: openRowRemoveModal,
    onCompleted: onRemoveCompleted
  } = useRemoveRow();

  const [dismissUser, { loading: loadingDismiss }] = useMutation<
    DismissUserMutation,
    DismissUserMutationVariables
  >(DismissUser, {
    variables: { id: selectedDismissUser?.id, orgId },
    onCompleted: onDismissCompleted,
    update(cache, { data: { dismissUser } }) {
      cache.updateQuery<OrgUsersTableQuery, OrgUsersTableQueryVariables>(
        { query: OrgUsersTable, variables: { org: orgId } },
        data =>
          !data?.org?.users
            ? data
            : {
                ...data,
                org: {
                  ...data.org,
                  users: data.org.users.filter(user => user.id !== dismissUser)
                }
              }
      );
      cache.updateQuery<AllUsersTableQuery, AllUsersTableQueryVariables>(
        { query: AllUsersTable },
        data => ({
          ...data,
          users: data?.users?.map(user =>
            user.id !== dismissUser
              ? user
              : {
                  ...user,
                  orgRoles: user.orgRoles.filter(org => org.id !== orgId)
                }
          )
        })
      );
    }
  });

  const [removeUser, { loading: loadingRemove }] = useMutation<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >(DeleteUser, {
    variables: { id: selectedRemoveUser?.id },
    onCompleted: onRemoveCompleted,
    update(cache, { data: { removeUser } }) {
      cache.evict({
        id: cache.identify({ __typename: "User", id: removeUser })
      });
      cache.gc();
    }
  });

  const dismissModalProps: RemoveModalProps = {
    entity: selectedDismissUser,
    hideModal: hideDismissModal,
    remove: dismissUser
  };
  const removeModalProps: RemoveModalProps = {
    entity: selectedRemoveUser,
    hideModal: hideRemoveModal,
    remove: removeUser
  };

  const isDeleting = loadingDismiss || loadingRemove;

  return {
    dismissModalProps,
    removeModalProps,
    modalDismissUserShown,
    modalRemoveUserShown,
    openRowDismissModal,
    openRowRemoveModal,
    isDeleting
  };
}
