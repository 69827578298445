import React, { useCallback, useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";

import { ButtonGroup } from "@src/Components/Buttons/ButtonGroup";
import { PrimaryButton } from "@src/Components/Buttons/Primary";
import { Loading } from "@src/Components/Loading/Loading";
import { Modal } from "@src/Components/Modal/Modal";
import { styled } from "@src/Components/theme";
import { AuditLogEntry } from "@src/generated/graphql";
import { useFilterByFields } from "@src/Hooks/filterByFields";
import { DismissModalButton } from "@src/Instances/DismissModalButton";

import { AuditLogDetails } from "./AuditLogDetails";
import { AuditLogsTable } from "./AuditLogsTable";
import { downloadLogsCSV } from "./exportLogs";
import { useFetchAuditLogs } from "./fetchAuditLogs";
import { hidableColumns, LogsColumnFilter } from "./FilterColumns/LogsColumnFilter";
import { PageSizeInput } from "./PageSizeInput";
import { DateRangePicker } from "./TimeFilter/DateRangePicker";
import { validateParams } from "./TimeFilter/dateUtils";

const LogActionButton = styled(PrimaryButton)`
  height: auto;
  width: auto;
  padding: 10px 20px;
`;

const TableActionButtons = styled(ButtonGroup)`
  justify-content: flex-end;
`;

const FirstLine = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: auto auto auto 1fr;
  align-items: flex-end;
`;

export function Audits() {
  const [searchParams] = useSearchParams();
  const from = searchParams.get("from");
  const to = searchParams.get("to");
  const pageSize = searchParams.get("pageSize");
  const { logs, loading, refetch, pageInfo, totalCount } = useFetchAuditLogs(from, to, pageSize);

  const [selectedLog, setSelectedLog] = useState<AuditLogEntry>();

  const openDetailsModal = useCallback(
    (log: AuditLogEntry) => {
      setSelectedLog(log);
    },
    [setSelectedLog]
  );

  const closeModal = useCallback(() => {
    setSelectedLog(null);
  }, [setSelectedLog]);

  const downloadCSV = useCallback(() => {
    downloadLogsCSV(logs);
  }, [logs]);

  const { toggleFilterTag, selectedFilters } = useFilterByFields(hidableColumns);

  const validParams = validateParams(from, to, pageSize);

  if (!validParams) return <Navigate to={`?alias=Last-24-hours&from=now-24h&to=now&pageSize=10`} />;
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <FirstLine>
            <DateRangePicker />
            <PageSizeInput />
            <LogsColumnFilter toggleFilterTag={toggleFilterTag} selectedFilters={selectedFilters} />
            <TableActionButtons>
              <LogActionButton onClick={() => refetch()}>Refresh</LogActionButton>
              <LogActionButton disabled={logs.length === 0} onClick={downloadCSV}>
                Export
              </LogActionButton>
            </TableActionButtons>
          </FirstLine>

          <AuditLogsTable
            auditLogs={logs}
            openDetailsModal={openDetailsModal}
            selectedFilters={selectedFilters}
            fetchLogs={refetch}
            pageInfo={pageInfo}
            totalCount={totalCount}
          />
          <Modal show={!!selectedLog} onOutsideClick={closeModal}>
            <>
              <DismissModalButton onClick={closeModal} />
              <AuditLogDetails auditLog={selectedLog} />
            </>
          </Modal>
        </>
      )}
    </>
  );
}
