import { useCombobox } from "downshift";
import React, { useCallback, useState } from "react";
import styled from "styled-components";

import { tagList } from "@src/Devices/Tag/splitTags";

import {
  DropdownItem,
  DropdownMenu,
  itemToString,
  RenderItemProps
} from "../Components/DropdownMenu";
import { SearchInput } from "../Components/Input/SearchInput";
import { Tag } from "../generated/graphql";

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

interface TagInputProps {
  tags: Tag[];
  add: (tag: Tag) => void;
  renderButtons?: boolean;
}

interface TagItem {
  id: string;
  displayName: string;
}

export function TagInput({ tags, add, renderButtons }: TagInputProps) {
  const [tagFilter, setTagFilter] = useState("");

  const filteredTags = tagList
    .filter(t => (!tagFilter || t.includes(tagFilter)) && !tags.some(tag => tag.key === t))
    .map(t => {
      const tag: TagItem = {
        id: t,
        displayName: t
      };
      return tag;
    });

  const addTag = useCallback(
    (key: string, inc: boolean) => {
      add({
        __typename: "Tag",
        key,
        inc
      });
    },
    [add]
  );

  const comboboxProps = useCombobox<DropdownItem>({
    items: filteredTags,
    onInputValueChange: ({ inputValue }) => {
      setTagFilter(inputValue);
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem && !selectedItem.disabled) {
        addTag(selectedItem.displayName, true);
      }
    },
    stateReducer: (omitState, { type, changes }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            inputValue: ""
          };
      }
      return changes;
    },
    itemToString
  });

  const { getComboboxProps, getInputProps } = comboboxProps;

  const renderItem = useCallback(
    ({ item, comboboxProps: { setInputValue } }: RenderItemProps<DropdownItem>) => (
      <>
        {item.displayName}{" "}
        <button
          type="button"
          style={{ border: 0 }}
          onClick={e => {
            e.stopPropagation();
            setInputValue("");
            addTag(item.displayName, true);
          }}
        >
          +
        </button>{" "}
        <button
          type="button"
          style={{ border: 0 }}
          onClick={e => {
            e.stopPropagation();
            setInputValue("");
            addTag(item.displayName, false);
          }}
        >
          -
        </button>
      </>
    ),
    [addTag]
  );

  return (
    <Wrapper {...getComboboxProps()}>
      <SearchInput {...getInputProps()} />
      <DropdownMenu
        {...comboboxProps}
        data={filteredTags}
        renderItem={renderButtons ? renderItem : null}
      />
    </Wrapper>
  );
}
