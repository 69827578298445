import React, { useCallback, useReducer } from "react";
import styled from "styled-components";

import { useMutation } from "@apollo/client";
import { H2, MapOverlayText } from "@src/Components/Text";
import { initialMapState, mapReducer } from "@src/Map/mapReducer";
import { useMapSettings } from "@src/Map/mapState";

import { SetMapMutation, SetMapMutationVariables } from "../generated/graphql";
import { MapboxMap, MapButton } from "../Map/MapboxMap";
import { DeviceMarkers } from "./DeviceMarkers";
import { LocationSearch } from "./LocationSearch";
import SetMap from "./Map.graphql";
import MapSettings from "./Settings.graphql";

export const Instructions = styled(MapOverlayText)`
  font-size: 2em;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 401;
`;

const SetPosition = styled(MapButton)`
  order: 1;
`;

const TopLine = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
`;

function MapSettingsComponent() {
  const [mapState, dispatch] = useReducer(mapReducer, initialMapState);
  const { longitude, latitude, zoom } = mapState;
  const mapIsReady = useMapSettings(mapState, dispatch);

  const [setMapMutation] = useMutation<SetMapMutation, SetMapMutationVariables>(SetMap);
  const setMap = useCallback(() => {
    setMapMutation({
      variables: {
        center: { lat: latitude, lng: longitude },
        zoom: Math.round(zoom)
      },
      refetchQueries: [{ query: MapSettings }]
    });
  }, [latitude, longitude, zoom, setMapMutation]);

  return (
    <>
      <H2>Map</H2>
      <TopLine>
        <SetPosition disabled={!mapIsReady} onClick={setMap}>
          set position
        </SetPosition>
        <LocationSearch mapState={mapState} dispatch={dispatch} />
      </TopLine>
      <MapboxMap mapState={mapState} dispatch={dispatch}>
        <Instructions>Drag/zoom the map to set the default map position</Instructions>
        <DeviceMarkers mapState={mapState} />
      </MapboxMap>
    </>
  );
}

export default MapSettingsComponent;
