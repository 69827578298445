import { styled } from "./theme";

export const Label = styled.label`
  margin: 20px 0;
  font-weight: bold;
  text-transform: capitalize;
  color: #aaa;
  font-size: 14px;
  display: block;

  & + p {
    font-size: 12px;
    text-align: justify;
  }
`;
