import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { SettingsFlow } from "@ory/client";
import { handleGetFlowError } from "@src/Auth/errors";
import { kratos } from "@src/Auth/kratos";
import { KratosMessages } from "@src/Auth/KratosMessages";
import { H1, H2 } from "@src/Components/Text";

import { UserSettingsForm } from "./UserSettingsForm";

export function UserSettings() {
  const [flow, setFlow] = useState<SettingsFlow>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const flowId = searchParams.get("flow");

    if (flow) {
      return;
    }

    // If ?flow=.. was in the URL, we fetch it
    if (flowId) {
      kratos
        .getSettingsFlow({ id: String(flowId) })
        .then(({ data }) => {
          setFlow(data);
        })
        .catch(handleGetFlowError(navigate, "settings", setFlow));
      return;
    }

    // Otherwise we initialize it
    kratos
      .createBrowserSettingsFlow()
      .then(({ data }) => {
        setFlow(data);
      })
      .catch(handleGetFlowError(navigate, "settings", setFlow));
  }, [flow, searchParams, navigate]);

  return (
    <>
      <H1>User settings</H1>
      <KratosMessages messages={flow?.ui.messages} />
      <H2>Profile</H2>
      <UserSettingsForm only="profile" flow={flow} setFlow={setFlow} navigate={navigate} />
      <H2>Password</H2>
      <UserSettingsForm only="password" flow={flow} setFlow={setFlow} navigate={navigate} />
      <H2>OpenID Connect</H2>
      <UserSettingsForm only="oidc" flow={flow} setFlow={setFlow} navigate={navigate} />
    </>
  );
}
