import { Field, FieldConfig, FieldProps, FormikErrors, getIn } from "formik";
import React, { HTMLProps } from "react";
import { css } from "styled-components";

import { Select } from "../Select";
import { TextArea } from "../TextArea";
import { styled } from "../theme";
import { Input } from "./Input";

export function InitialisedInput({ field }: FieldProps) {
  return <Input {...field} value={field.value || ""} />;
}

export const Fieldset = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(400px, 1fr) 1fr;
`;

export const fieldLabel = css`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #858585;
  text-transform: capitalize;
`;

type textTransformValues = "none" | "capitalize";
export const FieldLabel = styled.label<{ textTransform?: textTransformValues }>`
  ${fieldLabel}
  grid-column: 1;
  display: inline-block;
  text-align: right;
  padding-right: 21px;
  line-height: 45px;
  text-transform: ${props => props.textTransform};
`;

export const InputWidth = styled.div`
  grid-column: 2;
  width: 100%;
  display: inline-block;
`;

export const PaddedError = styled.div`
  margin-top: 10px;
`;

export const ErrorLine = styled.p`
  height: 13px;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: #dc675c;
  text-align: left;
`;

export const WarningLine = styled.p`
  height: 13px;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: ${({ theme }) => theme.warning};
  text-align: left;
  margin: 16px 0;
`;

export const SuccessLine = styled(ErrorLine)`
  color: #98cd96;
`;

export const ReadOnlyInput = styled(InputField).attrs({ readOnly: true })`
  background: ${({ theme }) => theme.backgroundLight};
  :focus {
    outline: none;
  }
`;

interface InputGroupProps extends InputFieldProps {
  label: string;
}

export function InputGroup({ label, name, ...inputFieldProps }: InputGroupProps) {
  return (
    <>
      <FieldLabel htmlFor={name}>{label}</FieldLabel>
      <InputField name={name} {...inputFieldProps} />
    </>
  );
}

interface InputFieldProps extends FieldConfig {
  errors?: FormikErrors<unknown>;
  disabled?: boolean;
  placeholder?: string;
  autoComplete?: HTMLProps<HTMLInputElement>["autoComplete"];
  min?: HTMLProps<HTMLInputElement>["min"];
  max?: HTMLProps<HTMLInputElement>["max"];
  checked?: boolean;
  autocomplete?: "off";
}

export const InputFieldWrapper = styled.div`
  grid-column: 2;
`;

export function InputField(props: InputFieldProps) {
  return <CustomField as={Input} {...props} />;
}

export function TextAreaField(props: InputFieldProps) {
  return <CustomField as={TextArea} {...props} />;
}

export function SelectField(props: InputFieldProps) {
  return <CustomField as={Select} {...props} />;
}

const Checkbox = styled.input.attrs({ type: "checkbox" })``;
const CheckboxContainer = styled.div`
  height: 45px;
  display: flex;
  align-items: center;
`;
const CheckboxError = styled(PaddedError)`
  margin: 0;
`;

export function CheckboxField({ errors, ...fieldProps }: InputFieldProps) {
  return (
    <InputFieldWrapper>
      <CheckboxContainer>
        <Field as={Checkbox} {...fieldProps} />
      </CheckboxContainer>
      <CheckboxError>
        <InputErrors errors={errors} name={fieldProps.name} />
      </CheckboxError>
    </InputFieldWrapper>
  );
}

interface CustomFieldProps extends InputFieldProps {
  as: FieldConfig["as"];
}

function CustomField({ as, errors, ...fieldProps }: CustomFieldProps) {
  return (
    <InputFieldWrapper>
      <Field as={as} {...fieldProps} />
      <PaddedError>
        <InputErrors errors={errors} name={fieldProps.name} />
      </PaddedError>
    </InputFieldWrapper>
  );
}

interface InputErrorsProps {
  name: string;
  errors?: FormikErrors<unknown>;
}

export function InputErrors({ errors, name }: InputErrorsProps) {
  return <ErrorLine>{getIn(errors, name) || null}</ErrorLine>;
}
