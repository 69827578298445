import { useQuery } from "@apollo/client";
import {
  FetchSiteInformationQuery,
  FetchSiteInformationQueryVariables,
  FetchSiteQuery,
  FetchSiteQueryVariables
} from "@src/generated/graphql";

import FetchSite from "./FetchSite.graphql";
import FetchSiteInformation from "./FetchSiteInformation.graphql";

export function useFetchInfrastructureSiteInfo(
  siteId: string,
  initTree: (id: string, ancestors: string[]) => void
) {
  const { data, loading, error } = useQuery<
    FetchSiteInformationQuery,
    FetchSiteInformationQueryVariables
  >(FetchSiteInformation, {
    variables: {
      id: siteId
    },
    onCompleted: data => {
      const ancestors = [
        data?.site?.org?.id,
        ...(data?.site?.ancestors || []).map(({ id }) => id),
        siteId
      ];
      initTree(siteId, ancestors);
    }
  });

  return {
    data: data?.site,
    loading,
    error
  };
}

export function useFetchSiteQuery(siteId: string) {
  const { data, loading, error } = useQuery<FetchSiteQuery, FetchSiteQueryVariables>(FetchSite, {
    variables: {
      id: siteId
    }
  });

  return {
    data: data?.site,
    loading,
    error
  };
}
