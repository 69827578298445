import { Form, FormikProps } from "formik";
import React from "react";

import { ButtonRowDefault } from "@src/Components/Buttons/ButtonRow";
import { Fieldset, InputGroup } from "@src/Components/Input/InputGroup";

import { CountryCodeSelect } from "./CountryCodeSelect";
import { FederationHostFormInput } from "./formSchemas";
import { SiteSelectorInput } from "./SiteSelectorInput";

interface HostFormInnerProps extends FormikProps<FederationHostFormInput> {
  navigateBack: () => void;
  isEdit?: boolean;
}

export function HostFormInner({
  navigateBack,
  isEdit = false,
  ...formikProps
}: HostFormInnerProps) {
  const { errors, values, setFieldValue } = formikProps;

  return (
    <Form>
      <Fieldset>
        <InputGroup label="federation name" name="federationName" errors={errors} />
        <InputGroup label="operator name" name="operatorName" errors={errors} />
        <CountryCodeSelect selectedCode={values.countryCode} errors={errors} />
        <InputGroup label="MCC" name="mcc" errors={errors} />
        <InputGroup label="MNCs" name="mncs" errors={errors} />
        <InputGroup label="Host URL" name="hostUrl" errors={errors} />
        <SiteSelectorInput
          setFieldValue={setFieldValue}
          fieldLabel="siteId"
          parentSiteId={values.siteId}
          errors={errors}
        />
        <InputGroup
          label="client ID"
          name="clientId"
          errors={errors}
          placeholder={isEdit ? "Set a new Client ID" : ""}
        />
        <InputGroup
          label="client secret"
          name="clientSecret"
          errors={errors}
          placeholder={isEdit ? "Set a new Client Secret" : ""}
        />
        <ButtonRowDefault
          onClickBack={navigateBack}
          isValid={formikProps.isValid}
          isSubmitting={formikProps.isSubmitting}
          backText="Cancel"
          submitText="Save"
        />
      </Fieldset>
    </Form>
  );
}
