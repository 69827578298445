import * as Yup from "yup";

import {
  ChartKey,
  CrossplaneConfigField,
  CrossplaneConfigFieldInput,
  LatLng
} from "@src/generated/graphql";
import { Shape } from "@src/yupTypes";

export type ConfigField = {
  label: string;
  value: ValueType;
  type: string;
};

export type ValueType = string | boolean | number;

export interface ProvisionValues {
  displayName: string;
  site: string;
  position: {
    lat: number;
    lng: number;
  };
  controllerSite: string;
  chart: ChartKey;
  config: ConfigField[];
}

export interface EditCrossplaneDeviceValues {
  displayName: string;
  config: ConfigField[];
  position: {
    lat: number;
    lng: number;
  };
}

export function customValidation(value: ValueType, ctx: Yup.TestContext) {
  if (typeof value === "number") {
    const invalidCharacters = ["e", "-", "+"];
    if (invalidCharacters.some(char => String(value).includes(char)))
      return ctx.createError({ message: "field must be a valid non negative number" });
  }
  if (value == null || value === "") return ctx.createError({ message: "this field is required" });
  return true;
}

const sharedCrossplaneValues = {
  displayName: Yup.string().required("display name is required"),
  config: Yup.array().of(
    Yup.object<Shape<CrossplaneConfigFieldInput>>().shape({
      label: Yup.string().required(),
      value: Yup.mixed().test("field-valid", customValidation)
    })
  ),
  position: Yup.object<Shape<LatLng>>().shape({
    lat: Yup.number().label("latitude").required("latitude is required"),
    lng: Yup.number().label("longitude").required("longitude is required")
  })
};

export const addCrossplaneSchema = Yup.object<Shape<ProvisionValues>>().shape({
  ...sharedCrossplaneValues,
  site: Yup.string().required(),
  controllerSite: Yup.string().required(),
  chart: Yup.object<Shape<ChartKey>>().shape({
    name: Yup.string().required(),
    version: Yup.string().required()
  })
});

export const updateCrossplaneSchema =
  Yup.object<Shape<EditCrossplaneDeviceValues>>().shape(sharedCrossplaneValues);

export function initFieldValues(fields: CrossplaneConfigField[], keepValues = false) {
  return (fields || []).map(({ type, label, value }) => {
    let fieldValue;
    switch (type) {
      case "integer":
      case "number":
        fieldValue = keepValues ? Number(value) : 0;
        break;
      case "boolean":
        fieldValue = keepValues ? Boolean(value) : false;
        break;
      default:
        fieldValue = keepValues ? value : "";
        break;
    }
    return { label, value: fieldValue, type };
  });
}
