import { useMutation } from "@apollo/client";
import {
  RemoveServiceChainMutation,
  RemoveServiceChainMutationVariables,
  Status
} from "@src/generated/graphql";

import RemoveServiceChain from "./RemoveServiceChain.graphql";
import ServiceChainStatus from "./ServiceChainStatus.graphql";

export function useRemoveServiceChain(id: string) {
  return useMutation<RemoveServiceChainMutation, RemoveServiceChainMutationVariables>(
    RemoveServiceChain,
    {
      variables: { id },
      update(cache, { data: { removeServiceChain: id } }) {
        cache.writeFragment({
          id: `ServiceChain:${id}`,
          fragment: ServiceChainStatus,
          data: {
            id,
            status: Status.Undeploying
          }
        });
      }
    }
  );
}
