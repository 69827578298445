import React from "react";
import styled from "styled-components";

import { useQuery } from "@apollo/client";
import Editor from "@monaco-editor/react";
import { Loading } from "@src/Components/Loading/Loading";
import { H3 } from "@src/Components/Text";
import {
  ProfileDetailsQuery,
  ProfileDetailsQueryVariables,
  ProvisionChart
} from "@src/generated/graphql";
import { useEditorTheme } from "@src/ServiceDesigner/editorTheme";

import query from "./ProfileDetails.graphql";

const Wrapper = styled.section`
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr 1fr;
`;

interface ProfileDetailsProps {
  name: string;
  version: string;
}

const sections: { title: string; property: keyof ProvisionChart }[] = [
  { title: "Hardware", property: "hardwareValues" },
  { title: "Bare Metal", property: "baremetalValues" },
  { title: "Software", property: "softwareValues" },
  { title: "Post", property: "postValues" }
];

export function ProfileDetails({ name, version }: ProfileDetailsProps) {
  const { data, loading } = useQuery<ProfileDetailsQuery, ProfileDetailsQueryVariables>(query, {
    variables: {
      name,
      version
    }
  });

  const { theme } = useEditorTheme();

  return loading ? (
    <Loading />
  ) : (
    <Wrapper>
      {sections.map(({ title, property }) => (
        <div key={title}>
          <H3>{title}</H3>
          <Editor
            language="yaml"
            height="400px"
            theme={theme}
            options={{ readOnly: true }}
            value={data?.provisionChart?.[property] || "failed to load values"}
          />
        </div>
      ))}
    </Wrapper>
  );
}
