import { Form, FormikProps } from "formik";
import { GraphQLError } from "graphql";
import React, { Dispatch, useCallback, useEffect } from "react";
import styled from "styled-components";

import { useLazyQuery } from "@apollo/client";
import { ButtonRowCustom, ButtonRowDefault } from "@src/Components/Buttons/ButtonRow";
import { DescriptionList } from "@src/Components/DescriptionList";
import { ErrorContainer } from "@src/Components/ErrorContainer";
import { FieldLabel, Fieldset, InputField } from "@src/Components/Input/InputGroup";
import { H2, H3 } from "@src/Components/Text";
import {
  ChartKey,
  CrossplaneConfigField,
  FetchCrossplaneConfigQuery,
  FetchCrossplaneConfigQueryVariables,
  LatLng
} from "@src/generated/graphql";
import { useSiteTree } from "@src/Hooks/siteTree";
import { SiteTree } from "@src/Infrastructure/FileTree/SiteTree";
import { MapAction, MapState } from "@src/Map/mapReducer";

import { PositionSelector } from "../PositionSelector";
import { CrossplaneChartSelect } from "./CrossplaneChartSelect";
import { CrossplaneConfig } from "./CrossplaneConfig";
import { EditCrossplaneDeviceValues, initFieldValues, ProvisionValues } from "./crossplaneUtils";
import FetchCrossplaneConfig from "./FetchCrossplaneConfig.graphql";

const LocationTitle = styled(H3)`
  margin-bottom: 0;
`;

interface CrossplaneFormProps extends FormikProps<ProvisionValues | EditCrossplaneDeviceValues> {
  mapState: MapState;
  dispatch: Dispatch<MapAction>;
  toggleReviewScreen: () => void;
  onComplete: () => void;
  isEdit?: boolean;
  originalConfigValues?: CrossplaneConfigField[];
}

export function CrossplaneDeviceFormInner({
  mapState,
  dispatch,
  toggleReviewScreen,
  onComplete,
  isEdit = false,
  originalConfigValues,
  ...formikProps
}: CrossplaneFormProps) {
  const { errors, values, setFieldValue, isValid, isSubmitting } = formikProps;

  const setChart = useCallback(
    (chart: ChartKey) => {
      setFieldValue("chart", chart);
      if (!chart) setFieldValue("config", []);
    },
    [setFieldValue]
  );

  const setPosition = useCallback(
    (center: LatLng) => {
      setFieldValue("position", center, false);
    },
    [setFieldValue]
  );

  const setOriginalConfig = useCallback(
    (data: FetchCrossplaneConfigQuery) => {
      const config = initFieldValues(data?.crossplaneChart?.originalConfig || []);
      setFieldValue("config", config);
    },
    [setFieldValue]
  );

  const [fetchChartConfig, { data }] = useLazyQuery<
    FetchCrossplaneConfigQuery,
    FetchCrossplaneConfigQueryVariables
  >(FetchCrossplaneConfig, {
    onCompleted: setOriginalConfig
  });

  const treeProps = useSiteTree({ navigate: null, ancestors: null });

  useEffect(() => {
    setFieldValue("controllerSite", treeProps.state.selected);
  }, [setFieldValue, treeProps.state.selected]);

  const originalConfig = originalConfigValues || data?.crossplaneChart?.originalConfig || [];

  return (
    <>
      <H2>{isEdit ? "Edit Crossplane Device" : "Provisioning"}</H2>

      <Form>
        <Fieldset>
          <FieldLabel htmlFor="displayName">display name</FieldLabel>
          <InputField name="displayName" errors={errors} />
        </Fieldset>

        {!isEdit && (
          <CrossplaneChartSelect setChart={setChart} fetchChartConfig={fetchChartConfig} />
        )}

        {values?.config?.length > 0 && (
          <CrossplaneConfig
            crossplaneConfig={values.config}
            errors={errors}
            originalConfig={originalConfig}
          />
        )}

        {!isEdit && (
          <>
            <H3>Controller site</H3>
            <SiteTree readOnly {...treeProps} />
          </>
        )}

        <LocationTitle>Location</LocationTitle>
        <PositionSelector
          fieldValue={values.position}
          setFieldValue={setPosition}
          mapState={mapState}
          dispatch={dispatch}
        />
        <Fieldset>
          <ButtonRowCustom
            onClickBack={onComplete}
            onClickSubmit={toggleReviewScreen}
            isValid={isValid}
            isSubmitting={isSubmitting}
          />
        </Fieldset>
      </Form>
    </>
  );
}

interface ReviewFormProps extends FormikProps<ProvisionValues | EditCrossplaneDeviceValues> {
  toggleReviewScreen: () => void;
  isEdit?: boolean;
  submitErrors: readonly GraphQLError[];
}

export function CrossplaneReviewForm({
  values,
  isSubmitting,
  toggleReviewScreen,
  submitErrors,
  isEdit = false
}: ReviewFormProps) {
  return (
    <>
      <H2>Review device details</H2>
      {submitErrors ? (
        <ErrorContainer>
          <H3>Failed to add device</H3>
          <p>Please review the details and try again:</p>
          <ul>
            {submitErrors.map((err, i) => (
              <li key={i}>{err.message}</li>
            ))}
          </ul>
        </ErrorContainer>
      ) : null}
      <Form>
        <DescriptionList>
          <dt>Display Name</dt>
          <dd>{values.displayName}</dd>
          <dt>Position</dt>
          <dd>
            {values.position.lat}, {values.position.lng}
          </dd>
        </DescriptionList>
        <ButtonRowDefault
          onClickBack={toggleReviewScreen}
          isSubmitting={isSubmitting}
          backText="Back"
          submitText={isEdit ? "Update" : "Add"}
        />
      </Form>
    </>
  );
}
