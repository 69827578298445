import { Title } from "@src/Components/BlockSelector/BlockSelectorList";

import { styled } from "../theme";

export const ConfigWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  padding-top: 0;
  border: 1px solid ${({ theme }) => theme.backgroundLight};
  box-sizing: border-box;
`;

export const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;

  ${Title} {
    align-self: start;
  }
`;

export const ParameterButton = styled.button<{ selected: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  border: none;
  background: ${({ selected, theme }) => (selected ? theme.backgroundLight : "white")};
  text-transform: capitalize;
  padding: 5px 10px;
  width: 100%;
  text-align: start;
  border-radius: 5px;
  margin-bottom: 10px;
`;

const ValueStatus = styled.span<{ unsaved?: boolean; invalid?: boolean }>`
  position: relative;
  margin-left: auto;
  font-weight: bold;
  text-align: center;
  width: 16px;
`;

export const ValueStatusEditor = styled(ValueStatus)`
  color: ${({ unsaved, theme }) => (unsaved ? theme.warning : theme.grey)};
  font-size: ${({ unsaved }) => (unsaved ? "10px" : "12px")};
`;
export const ValueStatusForm = styled(ValueStatus)`
  color: ${({ invalid, theme }) => (invalid ? theme.warning : theme.success)};
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 250px minmax(800px, 5fr);
  gap: 20px;
  position: relative;
`;
