import React, { useCallback } from "react";
import styled from "styled-components";

import ChartIcon from "@img/chart-bar-regular.svg";
import DashboardIcon from "@img/desktop-solid.svg";
import VideoIcon from "@img/film-solid.svg";
import { Clickable } from "@src/Components/Buttons/Clickable";
import { Connection } from "@src/generated/graphql";

import { iconSize } from "./iconSize";

const Chart = styled(ChartIcon)`
  ${iconSize}
  color: inherit;
`;

const Dashboard = styled(DashboardIcon)`
  ${iconSize}
  color: inherit;
`;

const ExtLink = styled.a`
  padding: 0 8px;
  color: inherit;
`;

const VideoButton = styled(Clickable)`
  color: inherit;
`;

const Video = styled(VideoIcon)`
  ${iconSize}
  fill: #848cb2;
`;

interface ConnectionsProps {
  connections?: Connection[];
  setVideoUrl?: (url: string) => void;
}

export function Connections({ connections, setVideoUrl }: ConnectionsProps) {
  const videos = connections?.filter(c => c.category === "Video");
  const telemetry = connections?.filter(c => c.category === "url.telemetry");
  const dashboards = connections?.filter(c => c.category === "Dashboard");

  return (
    <>
      {videos?.map(conn => (
        <VideoLink key={conn.name} connection={conn} setVideoUrl={setVideoUrl} />
      ))}
      {telemetry?.map(conn => (
        <ExtLink
          key={conn.name}
          href={conn.source}
          title={conn.name}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Chart />
        </ExtLink>
      ))}
      {dashboards?.map(conn => (
        <ExtLink
          key={conn.name}
          href={conn.source}
          title={conn.name}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Dashboard />
        </ExtLink>
      ))}
    </>
  );
}

interface VideoLinkProps {
  connection: Connection;
  setVideoUrl: (url: string) => void;
}

export function VideoLink({ connection, setVideoUrl }: VideoLinkProps) {
  const onClick = useCallback(() => {
    setVideoUrl(connection.source);
  }, [connection.source, setVideoUrl]);

  return (
    <VideoButton onClick={onClick} title={connection.name}>
      <Video />
    </VideoButton>
  );
}
