import React from "react";
import { NavLink } from "react-router-dom";
import { CellProps, Column, Row } from "react-table";

import EditIcon from "@img/edit.svg";
import EyeIcon from "@img/eye.svg";
import RemoveIcon from "@img/remove.svg";
import { Clickable } from "@src/Components/Buttons/Clickable";
import { Tag } from "@src/Components/StatusTag";
import { ActionsColumn, TableComponent } from "@src/Components/Table/TableComponent";
import { styled } from "@src/Components/theme";
import { FederationGuest, FederationHost } from "@src/generated/graphql";
import { Entity } from "@src/Hooks/removeRow";
import { iconSize } from "@src/Instances/ActionsCell/iconSize";

export const WrapperActions = styled.div`
  display: flex;
  gap: 10px;
`;

export const Eye = styled(EyeIcon)`
  ${iconSize}
  color: ${({ theme }) => theme.primary};
`;
export const Edit = styled(EditIcon)`
  ${iconSize}
  fill: ${({ theme }) => theme.primary};
`;
export const Remove = styled(RemoveIcon)`
  ${iconSize}
  fill: ${({ theme }) => theme.primary};
`;
const RemoveButton = styled(Clickable)`
  padding: 0;
`;

const EnabledTag = styled(Tag)`
  color: white;
  background-color: ${({ theme }) => theme.success};
`;
const DisabledTag = styled(Tag)`
  color: white;
  background-color: ${({ theme }) => theme.failure};
`;

export type Partner = FederationGuest | FederationHost;

interface PartnerTableProps {
  partners: Partner[];
  linkTo: string;
  openRowModal: (entity: Entity, row: Row) => void;
}

export function PartnerTable({ partners, linkTo, openRowModal }: PartnerTableProps) {
  const columns: Array<Column<Partner>> = [
    {
      Header: "Federation Name",
      accessor: "federationName",
      width: "15%"
    },
    {
      Header: "Operator Name",
      accessor: "operatorName",
      width: "15%"
    },
    {
      Header: "Country",
      accessor: "countryCode",
      width: "10%"
    },
    {
      Header: "MCC",
      accessor: "mcc",
      width: "10%"
    },
    {
      Header: "Status",
      accessor: "status",
      width: "15%",
      Cell: ({ value }: CellProps<object, Partner["status"]>) => {
        return value === "ENABLED" ? (
          <EnabledTag>Enabled</EnabledTag>
        ) : (
          <DisabledTag>Disabled</DisabledTag>
        );
      }
    }
  ];

  const actionColumn = {
    Header: "actions",
    accessor: "_actions" as ActionsColumn,
    width: "10%",
    disableSortBy: true,
    Cell: ({ row }: CellProps<object>) => {
      const { federationName, operatorName, id } = row.original as Partner;
      return (
        <WrapperActions>
          <NavLink title={`view ${federationName} details`} to={`partner/${linkTo}/info/${id}`}>
            <Eye />
          </NavLink>
          <NavLink title={`edit ${federationName}`} to={`partner/${linkTo}/edit/${id}`}>
            <Edit />
          </NavLink>
          <RemoveButton
            title={`remove ${federationName}-${operatorName}`}
            onClick={() => openRowModal({ id, displayName: federationName, type: "partner" }, row)}
          >
            <Remove />
          </RemoveButton>
        </WrapperActions>
      );
    }
  };
  return <TableComponent columnHeaders={columns} items={partners} rightActions={actionColumn} />;
}
