import React, { ChangeEvent, useCallback, useState } from "react";
import styled from "styled-components";

import Editor from "@monaco-editor/react";
import { FileInput } from "@src/Components/Input/FileInput";
import { H3 } from "@src/Components/Text";
import { UploadFieldType, useFileUpload } from "@src/Hooks/fileUpload";
import { useEditorTheme } from "@src/ServiceDesigner/editorTheme";

const yamlInventory = "yaml";

const TopLine = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;
`;

interface InventoryProps {
  inventory: string;
  setInventory: (val: string) => void;
}

export function Inventory({ inventory, setInventory }: InventoryProps) {
  const [source, setSource] = useState("");
  const onChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    setSource(e.target.value);
  }, []);

  const { theme } = useEditorTheme();

  const handleFileUpload = useFileUpload({
    type: UploadFieldType.Yaml,
    setValue: setInventory,
    setError: null
  });

  return (
    <>
      <H3>Inventory source</H3>

      <TopLine>
        <select onChange={onChange}>
          <option value="">none</option>
          <option disabled>Lenovo Cloud Automation (LOC-A)</option>
          <option value={yamlInventory}>YAML</option>
          <option disabled>Netbox</option>
          <option disabled>VC4</option>
          <option disabled>Patch Manager</option>
        </select>
        {source === yamlInventory && <FileInput onChange={handleFileUpload} />}
      </TopLine>

      {source === yamlInventory && (
        <Editor
          language="yaml"
          height="400px"
          theme={theme}
          value={inventory}
          onChange={setInventory}
        />
      )}
    </>
  );
}
