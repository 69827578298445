import baseStyled, {
  css as baseCss,
  ThemedCssFunction,
  ThemedStyledInterface
} from "styled-components";

export const theme = {
  yellow: "#ffbb3c",
  orange: "#ff6315",
  red: "#f22737",
  darkblue: "#19216c",
  blue: "#0045ad",
  lightblue: "#00a0df",
  text: "#1d232a",
  primary: "#484886",
  primaryLight: "#8e8ed4",
  error: "#e05c31",
  warning: "#edbb4f",
  success: "#7bc29b",
  backgroundLight: "#f0f0f0",
  border: "#dddddd",
  purple: "#444b88",
  grey: "#cccccc",
  failure: "#e75e31",
  status: {
    running: "#78a4eb"
  }
};

export type Theme = typeof theme;
export const styled = baseStyled as ThemedStyledInterface<Theme>;
export const css = baseCss as ThemedCssFunction<Theme>;
