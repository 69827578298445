import { Form, Formik, FormikProps } from "formik";
import { GraphQLError } from "graphql";
import React, { Fragment, useCallback, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import * as Yup from "yup";

import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { ButtonRowCustom, ButtonRowDefault } from "@src/Components/Buttons/ButtonRow";
import { DescriptionList } from "@src/Components/DescriptionList";
import { ErrorContainer } from "@src/Components/ErrorContainer";
import { FieldLabel, Fieldset, SelectField } from "@src/Components/Input/InputGroup";
import { Loading } from "@src/Components/Loading/Loading";
import { H2, H3 } from "@src/Components/Text";
import { styled } from "@src/Components/theme";
import {
  EditableUserQuery,
  EditableUserQueryVariables,
  EditUserRoleMutation,
  EditUserRoleMutationVariables,
  OrgNameQuery,
  OrgNameQueryVariables,
  OrgUsersTableQuery,
  OrgUsersTableQueryVariables,
  Role
} from "@src/generated/graphql";
import { useIsSuperAdmin } from "@src/Hooks/isSuperAdmin";
import { useToggle } from "@src/Hooks/toggle";
import { UserManagementContext } from "@src/Settings/userManagementContext";
import { allOrgsId } from "@src/Settings/UserManagementTable";
import OrgUsersTable from "@src/User/OrgUsersTable.graphql";
import { Shape } from "@src/yupTypes";

import EditableUser from "./EditableUser.graphql";
import EditUserRole from "./EditUserRole.graphql";
import OrgName from "./OrgName.graphql";
import { RoleOption } from "./UserRoleSelector";

interface OrgRole {
  id: string;
  role?: Role;
}

interface SetRoleInput {
  id: string;
  name: string;
  email: string;
  roles: OrgRole[];
}

const schema = Yup.lazy(() =>
  Yup.object<Shape<SetRoleInput>>().shape({
    id: Yup.string().required(),
    name: Yup.string().required(),
    email: Yup.string().required(),
    roles: Yup.array().of(
      Yup.object<Shape<{ id: string; role: Role }>>().shape({
        id: Yup.string(),
        role: Yup.mixed().oneOf(Object.values(Role), "role is required")
      })
    )
  })
);

export function EditUserForm() {
  const { id, orgId } = useParams<{ id: string; orgId: string }>();
  const { navigateToOrg } = useOutletContext<UserManagementContext>();
  const navigateBack = useCallback(() => navigateToOrg(orgId), [navigateToOrg, orgId]);

  const { data } = useQuery<EditableUserQuery, EditableUserQueryVariables>(EditableUser, {
    variables: {
      user: id,
      org: orgId == allOrgsId ? null : orgId
    },
    fetchPolicy: "network-only"
  });

  const user = data?.user;

  const initialValues: SetRoleInput =
    user && user.orgRoles?.[0]?.role
      ? {
          id,
          name: user.name,
          email: user.email,
          roles: user.orgRoles.filter(o => o.id).sort((a, b) => a.id.localeCompare(b.id))
        }
      : null;

  const { state: showReviewScreen, toggle: toggleReviewScreen } = useToggle();
  const [setRole] = useMutation<EditUserRoleMutation, EditUserRoleMutationVariables>(EditUserRole);
  const [submitErrors, setSubmitErrors] = useState<Readonly<GraphQLError[]>>();

  return initialValues ? (
    <Formik<SetRoleInput>
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={async values => {
        setSubmitErrors(null);
        try {
          values.roles.forEach(async ({ id: org, role }) => {
            await setRole({
              variables: {
                org,
                user: id,
                role
              },
              onCompleted: () => navigateBack(),
              update(cache, { data: { setRole } }) {
                cache.updateQuery<OrgUsersTableQuery, OrgUsersTableQueryVariables>(
                  { query: OrgUsersTable, variables: { org } },
                  data => ({
                    ...data,
                    org: {
                      ...data?.org,
                      users: data?.org?.users.map(user => (user.id !== setRole.id ? user : setRole))
                    }
                  })
                );
              }
            });
          });
        } catch (e) {
          const err: ApolloError = e;
          setSubmitErrors(err.graphQLErrors);
        }
      }}
    >
      {formikProps =>
        formikProps.isSubmitting ? (
          <Loading />
        ) : showReviewScreen ? (
          <ReviewForm
            {...formikProps}
            submitErrors={submitErrors}
            toggleReviewScreen={toggleReviewScreen}
          />
        ) : (
          <FormInner
            {...formikProps}
            navigateBack={navigateBack}
            toggleReviewScreen={toggleReviewScreen}
          />
        )
      }
    </Formik>
  ) : (
    <Loading />
  );
}

const TextField = styled.p`
  grid-column: 2;
`;

interface FormInnerProps extends FormikProps<SetRoleInput> {
  navigateBack: () => void;
  toggleReviewScreen: () => void;
}

function FormInner({ navigateBack, toggleReviewScreen, ...formikProps }: FormInnerProps) {
  const { values } = formikProps;
  const { name, email, roles } = values;

  return (
    <>
      <H2>Edit user role</H2>
      <Form>
        <Fieldset>
          <FieldLabel htmlFor="name">name</FieldLabel>
          <TextField>{name}</TextField>
          <FieldLabel htmlFor="email">email</FieldLabel>
          <TextField>{email}</TextField>
          {roles.map(({ id, role }, i) => (
            <RoleSelect key={id} index={i} id={id} role={role} />
          ))}
          <ButtonRowCustom
            onClickBack={navigateBack}
            onClickSubmit={toggleReviewScreen}
            isValid={formikProps.isValid}
            isSubmitting={formikProps.isSubmitting}
          />
        </Fieldset>
      </Form>
    </>
  );
}

const Name = styled(H3)`
  grid-column: 2;
`;

interface RoleSelectProps extends OrgRole {
  index: number;
}

function RoleSelect({ id, role, index }: RoleSelectProps) {
  const { data } = useQuery<OrgNameQuery, OrgNameQueryVariables>(OrgName, { variables: { id } });
  const { isSuperAdmin } = useIsSuperAdmin();
  const roles = Object.entries(Role).filter(([, role]) => isSuperAdmin || role !== Role.Mno);
  return (
    <Fragment>
      <Name>{data?.org?.displayName}</Name>
      <FieldLabel htmlFor="role">role</FieldLabel>
      <SelectField name={`roles[${index}].role`} value={role}>
        {roles.map(([key, r]) => (
          <RoleOption key={key} value={r} allCaps={r === Role.Mno}>
            {key}
          </RoleOption>
        ))}
      </SelectField>
    </Fragment>
  );
}
const StrikethroughP = styled.span`
  text-decoration: line-through;
  margin-right: 10px;
  color: ${({ theme }) => theme.error};
`;

interface NewRoleProps {
  newRole: string;
  previousRole?: string;
}

function NewRole({ newRole, previousRole }: NewRoleProps) {
  return (
    <dd>
      {previousRole && <StrikethroughP>{previousRole}</StrikethroughP>}
      <span>{newRole}</span>
    </dd>
  );
}

interface ReviewFormProps extends FormikProps<SetRoleInput> {
  toggleReviewScreen: () => void;
  submitErrors: readonly GraphQLError[];
}

function ReviewForm({ toggleReviewScreen, submitErrors, ...formikProps }: ReviewFormProps) {
  const { values, initialValues, isSubmitting } = formikProps;

  return (
    <>
      <H2>Review user details</H2>
      {submitErrors ? (
        <ErrorContainer>
          <H3>Failed to create user</H3>
          <p>Please review the details and try again:</p>
          <ul>
            {submitErrors.map((err, i) => (
              <li key={i}>{err.message}</li>
            ))}
          </ul>
        </ErrorContainer>
      ) : null}
      <Form>
        <DescriptionList>
          <dt>Name</dt>
          <dd>{values.name}</dd>
          <dt>Email</dt>
          <dd>{values.email}</dd>
          {values.roles.map(({ id, role }, i) => (
            <RoleReview key={id} id={id} initial={initialValues.roles[i].role} role={role} />
          ))}
        </DescriptionList>
        <ButtonRowDefault
          onClickBack={toggleReviewScreen}
          isSubmitting={isSubmitting}
          backText="Back"
          submitText="Update"
        />
      </Form>
    </>
  );
}

interface RoleReviewProps {
  id: string;
  initial: Role;
  role: Role;
}

function RoleReview({ id, initial, role }: RoleReviewProps) {
  const { data } = useQuery<OrgNameQuery, OrgNameQueryVariables>(OrgName, { variables: { id } });

  return (
    <>
      <dt>Role ({data?.org?.displayName})</dt>
      <NewRole newRole={role} previousRole={role !== initial ? initial : null} />
    </>
  );
}
