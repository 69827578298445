import styled from "styled-components";

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ButtonGroupModal = styled.div`
  padding-top: 40px;
  display: flex;
  gap: 20px;
  margin-left: auto;
  width: fit-content;
`;
