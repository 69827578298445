import { Form, FormikProps } from "formik";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import styled from "styled-components";

import { PrimaryButton } from "@src/Components/Buttons/Primary";
import { ErrorLine, Fieldset, InputGroup, SuccessLine } from "@src/Components/Input/InputGroup";

import { DeploymentState } from "./deployServiceChain";
import { MissingSteps } from "./MissingSteps";
import { ServiceForm } from "./serialise";

const Content = styled.div`
  border: ${({ theme }) => `1px solid ${theme.border}`};
  padding: 30px 0;
`;

const Deploy = styled.div`
  margin-top: 30px;
  grid-column: 2;
`;

export interface DeployTabProps extends FormikProps<ServiceForm> {
  deploymentState: DeploymentState;
  setDeploymentState: Dispatch<SetStateAction<DeploymentState>>;
}

function useDeployTabProps() {
  return useOutletContext<DeployTabProps>();
}

export function DeployTab() {
  const { deploymentState, setDeploymentState, ...formikProps } = useDeployTabProps();
  const { errors, isValid, isSubmitting } = formikProps;

  useEffect(() => {
    return () => setDeploymentState(prev => ({ ...prev, success: false, message: null }));
  }, [setDeploymentState]);

  return (
    <Content>
      <Form>
        <Fieldset>
          <InputGroup name="name" label="name" errors={errors} />
          <Deploy>
            <PrimaryButton type="submit" disabled={!isValid || isSubmitting}>
              {isSubmitting ? "Deploying" : "Deploy"}
            </PrimaryButton>
            {deploymentState.submitted && (
              <>
                {deploymentState.success ? (
                  <SuccessLine>{deploymentState.message}</SuccessLine>
                ) : (
                  <ErrorLine>{deploymentState.message}</ErrorLine>
                )}
              </>
            )}

            <MissingSteps errors={errors} />
          </Deploy>
        </Fieldset>
      </Form>
    </Content>
  );
}
