import React from "react";
import { css } from "styled-components";

import { DescriptionList } from "@src/Components/DescriptionList";
import { H2 } from "@src/Components/Text";
import { styled } from "@src/Components/theme";
import { AuditLogEntry } from "@src/generated/graphql";

import { AuditTypeTag, EntityTag } from "./AuditTags";

const contentWidth = css`
  width: 750px;
  max-width: 80vw;
`;
const DetailsWrapper = styled.div`
  ${contentWidth}
`;

const AuditLogValues = styled(DescriptionList)`
  display: grid;
  grid-template-columns: auto minmax(auto, 1fr);
  margin: 0 10px;
  max-height: 55vh;
  width: 100%;
  overflow-y: auto;
  dd {
    font-size: 14px;
    font-weight: 500;
  }
`;

type AuditLogDetailsProps = {
  auditLog: AuditLogEntry;
};

export function AuditLogDetails({ auditLog }: AuditLogDetailsProps) {
  const { type, reason, message, time, entityType, entity, user, org } = auditLog;

  return (
    <DetailsWrapper>
      <H2>Logs Details</H2>
      <AuditLogValues>
        <AuditLogFields label="Reason" value={reason} />
        <>
          <dt>Event Type</dt>
          <dd>
            <AuditTypeTag type={type} />
          </dd>
        </>
        <AuditLogFields label="Message" value={message} />
        <AuditLogFields label="Time" value={time} />
        <>
          <dt>Entity</dt>
          <dd>
            <EntityTag entity={entityType} />
          </dd>
        </>
        <AuditLogFields label="Entity ID" value={entity?.id} />
        <AuditLogFields label="Entity Name" value={entity?.name} />
        <AuditLogFields label="User ID" value={user?.id} />
        <AuditLogFields label="User" value={user?.email} />
        <AuditLogFields label="Org ID" value={org?.id} />
        <AuditLogFields label="Org Name" value={org?.displayName} />
      </AuditLogValues>
    </DetailsWrapper>
  );
}

function AuditLogFields({ label, value }: { label: string; value: string }) {
  return (
    <>
      <dt>{label}</dt>
      <dd>{value}</dd>
    </>
  );
}
