import React from "react";
import styled from "styled-components";

import { DescriptionList } from "@src/Components/DescriptionList";

export const ValuesWrapper = styled(DescriptionList)`
  display: grid;
  grid-template-columns: auto minmax(auto, 1fr);
  dd {
    font-size: 13px;
    font-weight: 400;
  }
`;

export function FieldValues({ label, value }: { label: string; value: string | number }) {
  return (
    <>
      <dt>{label}</dt>
      <dd>{value}</dd>
    </>
  );
}
