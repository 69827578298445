import React, { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { useMutation } from "@apollo/client";
import { RemoveModal } from "@src/Components/Modal/RemoveModal";
import { SidePanel } from "@src/Components/SidePanel";
import {
  Block,
  RollbackRevisionMutation,
  RollbackRevisionMutationVariables
} from "@src/generated/graphql";
import { useToggle } from "@src/Hooks/toggle";

import { useFetchInstanceData } from "./fetchInstanceData";
import { InstanceRevisions } from "./InstanceRevisions";
import { useRemoveServiceChain } from "./removeServiceChain";
import RollbackRevision from "./RollbackRevision.graphql";
import { TopBarInstanceInfo } from "./TopBarInstanceInfo";

const Wrapper = styled.div`
  display: grid;
  height: calc(100vh - 40px);
  grid-template-rows: auto 1fr;
`;

const Main = styled.div`
  display: grid;
  height: 100%;
  grid-template-columns: 0.5fr 1.2fr 2fr;
  grid-column-gap: 15px;
  flex-grow: 1;
  overflow: hidden;
`;
function sortByRevision(a: { revision?: number }, b: { revision?: number }) {
  return (b.revision || 0) - (a.revision || 0);
}
export type InstanceHistory = {
  id: string;
  revision: number;
};

export interface InstanceInfoContext {
  selectedBlock: Block;
  setSelectedBlock: (block: string) => void;
  showResources: boolean;
  blocks: Block[];
  blocksLoading: boolean;
  refetch: () => void;
}
export function Instance({ pollInterval = 5000 }: { pollInterval?: number }) {
  const { serviceChainId: id, revision } = useParams();
  const navigate = useNavigate();
  const close = useCallback(() => navigate("/app/services"), [navigate]);

  const { chain, instanceInfoLoading, blocks, blocksLoading, refetch } =
    useFetchInstanceData(pollInterval);

  const [selectedBlockId, setSelectedBlockId] = useState<string>();

  const { state: showResources, toggle: toggleInfo } = useToggle();

  const {
    state: shouldShowConfirmRemove,
    on: showConfirmation,
    off: hideConfirmation
  } = useToggle();

  const [removeMtn] = useRemoveServiceChain(id);
  const remove = useCallback(() => {
    removeMtn().then(() => {
      hideConfirmation();
      close();
    });
  }, [removeMtn, hideConfirmation, close]);

  const [rollbackMtn] = useMutation<RollbackRevisionMutation, RollbackRevisionMutationVariables>(
    RollbackRevision
  );
  const rollback = useCallback(
    (revision: number) => {
      toast.promise(
        rollbackMtn({ variables: { id, revision } }).then(() => {
          setSelectedBlockId(null);
          navigate(`/app/services/${id}`);
        }),
        {
          loading: "Rolling back",
          success: "Rollback successful",
          error: "Error when executing rollback"
        }
      );
    },
    [id, rollbackMtn, setSelectedBlockId, navigate]
  );

  const setRevision = useCallback(
    (revisionId: number) => {
      const currentRevision = chain?.revision;
      if (revision) {
        if (currentRevision === revisionId) navigate(`../${id}`);
        else navigate(`${revisionId}`);
      } else {
        if (revisionId !== currentRevision) navigate(`${revisionId}`);
      }
      setSelectedBlockId(null);
    },
    [revision, setSelectedBlockId, chain, navigate, id]
  );

  const currentRevision = { id: id.concat(`-${chain?.revision}`), revision: chain?.revision };
  const history = (chain?.history || []).slice().sort(sortByRevision);
  const selectedBlock = blocks.find(block => block?.id === selectedBlockId);

  const context: InstanceInfoContext = {
    selectedBlock,
    setSelectedBlock: setSelectedBlockId,
    showResources,
    blocks,
    refetch,
    blocksLoading
  };

  return (
    <SidePanel show={!!id && !instanceInfoLoading} close={close}>
      <Wrapper>
        <TopBarInstanceInfo
          chain={chain}
          showResources={showResources}
          toggleInfo={toggleInfo}
          showConfirmationDelete={showConfirmation}
          selectedBlock={selectedBlock}
        />
        <Main>
          <InstanceRevisions
            history={[currentRevision, ...history]}
            rollback={rollback}
            selectRevision={setRevision}
          />
          <Outlet context={context} />
        </Main>
      </Wrapper>
      {shouldShowConfirmRemove && (
        <RemoveModal
          remove={remove}
          hideModal={hideConfirmation}
          entity={{
            id,
            displayName: chain?.name,
            type: "service chain"
          }}
        />
      )}
    </SidePanel>
  );
}
