import { Form, Formik, FormikProps } from "formik";
import { GraphQLError } from "graphql";
import React, { useCallback, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import * as Yup from "yup";

import { isApolloError, useMutation, useQuery } from "@apollo/client";
import { ButtonRowCustom, ButtonRowDefault } from "@src/Components/Buttons/ButtonRow";
import { DescriptionList } from "@src/Components/DescriptionList";
import { ErrorContainer } from "@src/Components/ErrorContainer";
import { FieldLabel, Fieldset } from "@src/Components/Input/InputGroup";
import { Loading } from "@src/Components/Loading/Loading";
import { H2, H3 } from "@src/Components/Text";
import { styled } from "@src/Components/theme";
import {
  AllUsersTableQuery,
  AllUsersTableQueryVariables,
  EditableUserQuery,
  EditableUserQueryVariables,
  FetchOrganizationsQuery,
  FetchOrganizationsQueryVariables,
  RecruitableUserQuery,
  RecruitableUserQueryVariables,
  RecruitUserMutation,
  RecruitUserMutationVariables,
  Role,
  Site
} from "@src/generated/graphql";
import { useToggle } from "@src/Hooks/toggle";
import FetchOrganizations from "@src/Settings/FetchOrganizations.graphql";
import { UserManagementContext } from "@src/Settings/userManagementContext";
import OrgUsersTable from "@src/User/OrgUsersTable.graphql";
import { Shape } from "@src/yupTypes";

import AllUsersTable from "./AllUsersTable.graphql";
import EditableUser from "./EditableUser.graphql";
import { OrgFormField } from "./OrgFormField";
import RecruitableUser from "./RecruitableUser.graphql";
import RecruitUser from "./RecruitUser.graphql";
import { updateAllUsersTableCache } from "./updateAllUsersTableCache";
import { UserRoleSelector } from "./UserRoleSelector";

export interface RecruitUserInput {
  user: string;
  org: string;
  role: Role;
}

const schema = Yup.object<Shape<RecruitUserInput>>().shape({
  user: Yup.string().required(),
  org: Yup.string().required(),
  role: Yup.mixed().oneOf(Object.values(Role), "role is required")
});

export function RecruitUserForm() {
  const { id, orgId } = useParams<{ id: string; orgId: string }>();
  const { navigateToOrg } = useOutletContext<UserManagementContext>();
  const navigateBack = useCallback(() => navigateToOrg(orgId), [navigateToOrg, orgId]);

  const { data } = useQuery<FetchOrganizationsQuery, FetchOrganizationsQueryVariables>(
    FetchOrganizations
  );

  const { data: userData } = useQuery<EditableUserQuery, EditableUserQueryVariables>(EditableUser, {
    variables: { user: id }
  });

  const userOrgRoles = userData?.user?.orgRoles || [];
  const organizations = data?.orgs
    ?.filter(({ id }) => !userOrgRoles.map(role => role.id).includes(id))
    .map(s => ({ id: s.id, displayName: s.displayName }));

  const initialValues: RecruitUserInput = {
    user: id,
    org: "",
    role: Role.Viewer
  };
  const { state: showReviewScreen, toggle: toggleReviewScreen } = useToggle();
  const [recruitUser] = useMutation<RecruitUserMutation, RecruitUserMutationVariables>(RecruitUser);
  const [submitErrors, setSubmitErrors] = useState<Readonly<GraphQLError[]>>();

  return (
    <Formik<RecruitUserInput>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnMount
      onSubmit={async values => {
        setSubmitErrors(null);
        try {
          await recruitUser({
            variables: values,
            refetchQueries: [{ query: OrgUsersTable, variables: { org: values.org } }],
            update(cache, { data: { recruitUser } }) {
              cache.updateQuery<AllUsersTableQuery, AllUsersTableQueryVariables>(
                { query: AllUsersTable },
                data => updateAllUsersTableCache(data, values, recruitUser)
              );
            }
          });
          navigateToOrg(values.org);
        } catch (e) {
          if (isApolloError(e)) {
            setSubmitErrors(e.graphQLErrors);
          }
          console.error(e);
        }
      }}
    >
      {formikProps =>
        formikProps.isSubmitting ? (
          <Loading />
        ) : showReviewScreen ? (
          <ReviewForm
            {...formikProps}
            submitErrors={submitErrors}
            organizations={organizations}
            toggleReviewScreen={toggleReviewScreen}
          />
        ) : (
          <FormInner
            {...formikProps}
            organizations={organizations}
            navigateBack={navigateBack}
            toggleReviewScreen={toggleReviewScreen}
          />
        )
      }
    </Formik>
  );
}

const TextField = styled.p`
  grid-column: 2;
`;

interface FormInnerProps extends FormikProps<RecruitUserInput> {
  navigateBack: () => void;
  toggleReviewScreen: () => void;
  organizations: Site[];
}

function FormInner({
  navigateBack,
  toggleReviewScreen,
  organizations,
  ...formikProps
}: FormInnerProps) {
  const { values, errors, isValid, isSubmitting } = formikProps;

  const { data } = useQuery<RecruitableUserQuery, RecruitableUserQueryVariables>(RecruitableUser, {
    variables: { user: values.user }
  });
  const user = data?.user;

  return (
    <>
      <H2>Recruit user to another org</H2>
      <Form>
        <Fieldset>
          <FieldLabel htmlFor="name">name</FieldLabel>
          <TextField>{user?.name}</TextField>
          <FieldLabel htmlFor="email">email</FieldLabel>
          <TextField>{user?.email}</TextField>
          <OrgFormField orgs={organizations} errors={errors} />
          <FieldLabel htmlFor="role">role</FieldLabel>
          <UserRoleSelector name="role" errors={errors} />
          <ButtonRowCustom
            onClickBack={navigateBack}
            onClickSubmit={toggleReviewScreen}
            isValid={isValid}
            isSubmitting={isSubmitting}
            submitText="Save"
          />
        </Fieldset>
      </Form>
    </>
  );
}

interface ReviewFormProps extends FormikProps<RecruitUserInput> {
  toggleReviewScreen: () => void;
  organizations: Site[];
  submitErrors: readonly GraphQLError[];
}

function ReviewForm({
  toggleReviewScreen,
  submitErrors,
  organizations,
  ...formikProps
}: ReviewFormProps) {
  const { values, isSubmitting } = formikProps;

  const { data } = useQuery<RecruitableUserQuery, RecruitableUserQueryVariables>(RecruitableUser, {
    variables: { user: values.user }
  });
  const user = data?.user;

  const orgName = organizations?.find(o => o.id === values.org)?.displayName;
  return (
    <>
      <H2>Review user details</H2>
      {submitErrors ? (
        <ErrorContainer>
          <H3>Failed to create user</H3>
          <p>Please review the details and try again:</p>
          <ul>
            {submitErrors.map((err, i) => (
              <li key={i}>{err.message}</li>
            ))}
          </ul>
        </ErrorContainer>
      ) : null}
      <Form>
        <DescriptionList>
          <dt>Name</dt>
          <dd>{user?.name}</dd>
          <dt>Email</dt>
          <dd>{user?.email}</dd>
          <dt>Organization</dt>
          <dd>{orgName}</dd>
          <dt>Role</dt>
          <dd>{values.role}</dd>
        </DescriptionList>
        <ButtonRowDefault
          onClickBack={toggleReviewScreen}
          isSubmitting={isSubmitting}
          backText="Back"
          submitText="Add"
        />
      </Form>
    </>
  );
}
