import { useQuery } from "@apollo/client";
import { TemplatesQuery, TemplatesQueryVariables } from "@src/generated/graphql";

import Templates from "./Templates.graphql";

export function useTemplates() {
  const { loading, error, data, refetch } = useQuery<TemplatesQuery, TemplatesQueryVariables>(
    Templates,
    {
      variables: { category: null, vendors: [] },
      errorPolicy: "all",
      notifyOnNetworkStatusChange: true
    }
  );

  const charts = data?.templateCharts ?? [];

  const repoUrl = data?.settings?.templateRepoUrl;

  return {
    loading,
    error,
    refetch,
    charts,
    repoUrl
  };
}
