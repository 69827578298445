import React, { useCallback } from "react";
import toast from "react-hot-toast";
import { NavigateFunction } from "react-router-dom";
import styled from "styled-components";

import { SettingsFlow, UpdateSettingsFlowBody } from "@ory/client";
import { handleGetFlowError } from "@src/Auth/errors";
import { Flow } from "@src/Auth/Flow";
import { kratos } from "@src/Auth/kratos";
import { UserSettingsNodeInput } from "@src/Auth/NodeFieldInput";
import { NodeInput } from "@src/Auth/NodeInput";
import { Placeholders } from "@src/Auth/Placeholders";
import { ErrorLine } from "@src/Components/Input/InputGroup";
import { ToastNotification } from "@src/Components/ToastNotification";
import {
  getNodeId,
  isUiNodeInputAttributes,
  Methods,
  useSelfService
} from "@src/Hooks/selfService";

const NodeInputWrapper = styled.div`
  grid-column: 2;
`;

const placeholders: Placeholders<UpdateSettingsFlowBody> = {
  "traits.email": "Email",
  "traits.name": "Name",
  password: "Password"
};

interface UserSettingsFormProps {
  only: Methods;
  flow?: SettingsFlow;
  setFlow: (flow: SettingsFlow) => void;
  navigate: NavigateFunction;
}

export function UserSettingsForm({ only, flow, setFlow, navigate }: UserSettingsFormProps) {
  const onSubmit = useCallback(
    (values: UpdateSettingsFlowBody) => {
      return kratos
        .updateSettingsFlow({
          flow: String(flow?.id),
          updateSettingsFlowBody: values
        })
        .then(({ data }) => {
          setFlow(data);
          toast.success(<ToastNotification title={`${only} updated successfully`} />);
        })
        .catch(handleGetFlowError(navigate, "settings", setFlow))
        .catch(err => {
          if (err.response?.status === 400) {
            setFlow(err.response?.data);
            toast.error(<ToastNotification title={`an error ocurred updating your ${only}`} />);
            return;
          }
          return Promise.reject(err);
        });
    },
    [navigate, flow, setFlow, only]
  );

  const { filteredNodes, state, onChange, handleSubmit } = useSelfService({
    flow,
    only,
    onSubmit
  });

  return (
    <Flow flow={flow} onSubmit={handleSubmit} isSettingsPage hideGlobalMessages>
      {filteredNodes.map(node => {
        const id = getNodeId(node) as keyof UpdateSettingsFlowBody;
        return (
          <NodeInputWrapper key={id}>
            <NodeInput
              input={UserSettingsNodeInput}
              placeholder={placeholders[id]}
              node={node}
              attributes={isUiNodeInputAttributes(node.attributes) ? node.attributes : undefined}
              disabled={state.isLoading}
              value={state.values[id] ?? ""}
              setValue={value => onChange(id, value)}
            />
            {(node.messages || []).map(msg =>
              msg.type === "error" ? (
                <ErrorLine key={msg.id}>{msg.text}</ErrorLine>
              ) : (
                <p key={msg.id}>{msg.text}</p>
              )
            )}
          </NodeInputWrapper>
        );
      })}
    </Flow>
  );
}
