import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import React, { useCallback } from "react";
import styled from "styled-components";
import * as Yup from "yup";

import { useMutation, useQuery } from "@apollo/client";
import { disabledStyles, PrimaryButton } from "@src/Components/Buttons/Primary";
import { Input } from "@src/Components/Input/Input";
import { FieldLabel, Fieldset, InputGroup } from "@src/Components/Input/InputGroup";
import { LoadingPage } from "@src/Components/Loading/LoadingPage";
import { SuccessModal } from "@src/Components/Modal/SuccessModal";
import { H2, H3 } from "@src/Components/Text";
import {
  BlockRepoUrlQuery,
  ProvisionRepoUrlQuery,
  SetBlockRepoMutation,
  SetBlockRepoMutationVariables,
  SetProvisionRepoMutation,
  SetProvisionRepoMutationVariables,
  SetTemplateRepoMutation,
  SetTemplateRepoMutationVariables,
  TemplateRepoUrlQuery
} from "@src/generated/graphql";
import { useIsSuperAdmin } from "@src/Hooks/isSuperAdmin";
import { useToggle } from "@src/Hooks/toggle";
import { MaybeMocked } from "@src/MaybeMocked";
import { Shape } from "@src/yupTypes";

import BlockRepoUrl from "./BlockRepoUrl.graphql";
import { customizationsMocks } from "./customizationsMocks";
import ProvisionRepoUrl from "./ProvisionRepoUrl.graphql";
import SetBlockRepo from "./SetBlockRepo.graphql";
import SetProvisionRepo from "./SetProvisionRepo.graphql";
import SetTemplateRepo from "./SetTemplateRepo.graphql";
import TemplateRepoUrl from "./TemplateRepoUrl.graphql";

const ValidatingForm = styled.form`
  &:invalid button[type="submit"] {
    ${disabledStyles}
  }
`;

const UploadButton = styled(PrimaryButton)`
  margin-left: 15px;
`;

const StyledFieldset = styled(Fieldset)`
  grid-auto-rows: 80px;
`;

export function Customizations() {
  const { state: shouldShowSuccessModal, on: showSuccess, off: hideSuccess } = useToggle();
  const { isSuperAdmin } = useIsSuperAdmin();

  if (isSuperAdmin == null) return <LoadingPage />;
  return (
    <>
      <H2>Customizations</H2>
      <H3>Uploads</H3>
      {!isSuperAdmin ? (
        <>
          <UploadForm
            action="/upload/logo"
            label="Custom organization logo"
            fieldName="logo"
            accept=".png"
          />
          <UploadForm
            action="/upload/nztp-iso"
            label="nZTP Server Bootstrap ISO"
            fieldName="iso"
            accept=".iso"
          />
          <UploadForm
            action="/upload/provision-iso"
            label="Provisioner ISO (Beta)"
            fieldName="iso"
            accept=".iso"
          />
          <UploadForm
            action="/upload/onboard-chart"
            label="K8s Cloudlet onboarding helm chart"
            fieldName="chart"
            accept=".tgz"
          />
          <UploadForm
            action="/upload/onboard-ova"
            label="vSphere Cluster onboarding OVA"
            fieldName="ova"
            accept=".ova"
          />
          <UploadForm
            action="/upload/cert"
            label="Custom Registry cert (PEM format)"
            fieldName="cert"
          />
          <MaybeMocked condition={MOCK_CUSTOMIZATIONS} mocks={customizationsMocks}>
            <>
              <BlockChartRepoInput showSuccess={showSuccess} />
              <ProvisionChartRepoInput showSuccess={showSuccess} />
              <TemplatesChartRepoInput showSuccess={showSuccess} />
            </>
          </MaybeMocked>
          <SuccessModal show={shouldShowSuccessModal} onOutsideClick={hideSuccess}>
            Credentials saved
          </SuccessModal>
        </>
      ) : (
        <UploadForm action="/upload/branding" label="Custom dashboard branding" fieldName="logo" />
      )}
    </>
  );
}

interface UploadFormProps {
  action: string;
  label: string;
  fieldName: string;
  accept?: string;
}

function UploadForm({ action, label, fieldName, accept }: UploadFormProps) {
  return (
    <ValidatingForm encType="multipart/form-data" action={action} method="post">
      <StyledFieldset>
        <FieldLabel textTransform="none" htmlFor={fieldName}>
          {label}
        </FieldLabel>
        <Input required accept={accept} type="file" name={fieldName} />
        <UploadButton type="submit">upload</UploadButton>
      </StyledFieldset>
    </ValidatingForm>
  );
}

interface UrlForm {
  url: string;
  username: string;
  password: string;
}

interface RepoInputProps {
  showSuccess: () => void;
}

function BlockChartRepoInput({ showSuccess }: RepoInputProps) {
  const { data, loading } = useQuery<BlockRepoUrlQuery>(BlockRepoUrl);
  const [setUrl] = useMutation<SetBlockRepoMutation, SetBlockRepoMutationVariables>(SetBlockRepo);

  const handleSubmit = useCallback(
    async (
      { url, username: username, password: password }: UrlForm,
      { setSubmitting }: FormikHelpers<UrlForm>
    ) => {
      await setUrl({
        variables: { repo: { url, username, password } },
        update(cache, { data: { setBlockRepo } }) {
          cache.updateQuery<BlockRepoUrlQuery>({ query: BlockRepoUrl }, data => ({
            ...data,
            settings: {
              ...data?.settings,
              blockRepoUrl: setBlockRepo
            }
          }));
          cache.evict({ id: "ROOT_QUERY", fieldName: "blockCharts", broadcast: false });
          cache.gc();
        }
      });

      setSubmitting(false);
      showSuccess();
    },
    [setUrl, showSuccess]
  );

  return (
    <Formik<UrlForm>
      enableReinitialize
      onSubmit={handleSubmit}
      initialValues={{
        url: data?.settings?.blockRepoUrl || "",
        username: "",
        password: ""
      }}
      validationSchema={urlSchema}
    >
      {formikProps => <SetUrlForm label="Blocks" loading={loading} {...formikProps} />}
    </Formik>
  );
}

function ProvisionChartRepoInput({ showSuccess }: RepoInputProps) {
  const { data, loading } = useQuery<ProvisionRepoUrlQuery>(ProvisionRepoUrl);
  const [setUrl] = useMutation<SetProvisionRepoMutation, SetProvisionRepoMutationVariables>(
    SetProvisionRepo
  );

  const handleSubmit = useCallback(
    async (
      { url, username: username, password: password }: UrlForm,
      { setSubmitting }: FormikHelpers<UrlForm>
    ) => {
      await setUrl({
        variables: { repo: { url, username, password } },
        update(cache, { data: { setProvisionRepo } }) {
          cache.updateQuery<ProvisionRepoUrlQuery>({ query: ProvisionRepoUrl }, data => ({
            ...data,
            settings: {
              ...data.settings,
              provisionRepoUrl: setProvisionRepo
            }
          }));
          cache.evict({ id: "ROOT_QUERY", fieldName: "provisionCharts", broadcast: false });
          cache.gc();
        }
      });

      setSubmitting(false);
      showSuccess();
    },
    [setUrl, showSuccess]
  );

  return (
    <Formik<UrlForm>
      enableReinitialize
      onSubmit={handleSubmit}
      initialValues={{
        url: data?.settings?.provisionRepoUrl || "",
        username: "",
        password: ""
      }}
      validationSchema={urlSchema}
    >
      {formikProps => <SetUrlForm label="Provisions" loading={loading} {...formikProps} />}
    </Formik>
  );
}
function TemplatesChartRepoInput({ showSuccess }: RepoInputProps) {
  const { data, loading } = useQuery<TemplateRepoUrlQuery>(TemplateRepoUrl);
  const [setUrl] = useMutation<SetTemplateRepoMutation, SetTemplateRepoMutationVariables>(
    SetTemplateRepo
  );

  const handleSubmit = useCallback(
    async (
      { url, username: username, password: password }: UrlForm,
      { setSubmitting }: FormikHelpers<UrlForm>
    ) => {
      await setUrl({
        variables: { repo: { url, username, password } },
        update(cache, { data: { setTemplateRepo } }) {
          cache.updateQuery<TemplateRepoUrlQuery>({ query: TemplateRepoUrl }, data => ({
            ...data,
            settings: {
              ...data.settings,
              templateRepoUrl: setTemplateRepo
            }
          }));
          cache.evict({ id: "ROOT_QUERY", fieldName: "templateCharts", broadcast: false });
          cache.gc();
        }
      });

      setSubmitting(false);
      showSuccess();
    },
    [setUrl, showSuccess]
  );

  return (
    <Formik<UrlForm>
      enableReinitialize
      onSubmit={handleSubmit}
      initialValues={{
        url: data?.settings?.templateRepoUrl || "",
        username: "",
        password: ""
      }}
      validationSchema={urlSchema}
    >
      {formikProps => <SetUrlForm label="Templates" loading={loading} {...formikProps} />}
    </Formik>
  );
}

interface SetUrlFormProps extends FormikProps<UrlForm> {
  label: string;
  loading: boolean;
}

function SetUrlForm({ label, loading, dirty, isValid, errors, isSubmitting }: SetUrlFormProps) {
  return (
    <Form>
      <H3>{label}</H3>
      <StyledFieldset>
        <InputGroup label="URL" name="url" disabled={loading} errors={errors} />
        <InputGroup
          label="Username"
          name="username"
          placeholder="set new username..."
          disabled={loading}
          errors={errors}
        />
        <InputGroup
          label="Password"
          name="password"
          type="password"
          autoComplete="new-password"
          placeholder="set new password..."
          disabled={loading}
          errors={errors}
        />
        <UploadButton type="submit" disabled={!dirty || !isValid || isSubmitting}>
          save
        </UploadButton>
      </StyledFieldset>
    </Form>
  );
}

type URLSchema = {
  url: string;
  username: string;
  password: string;
};

const urlSchema = Yup.object<Shape<URLSchema>>().shape({
  url: Yup.string().url("valid URL is required").required("valid URL is required"),
  username: Yup.string().when("password", {
    is: (val: string) => !!val,
    then: Yup.string().required("cannot set a password without a username")
  }),
  password: Yup.string()
});
