import { css } from "styled-components";

import { baseButtonStyles } from "@src/Components/Buttons/Base";
import { styled } from "@src/Components/theme";

export const authFormButtonStyles = css`
  cursor: pointer;
  border: 2px solid;
  border-radius: 4px;
  border-color: #ff6315;
  color: #ff6315;
  background: white;
  text-transform: uppercase;
  font-size: 12px;
  padding: 6px 24px;

  &:disabled {
    border-color: #8d9194;
    color: #8d9194;
  }

  &:active {
    color: white;
    border: 0;
    padding: 8px 26px;
    background-image: linear-gradient(to right, #ffbb3c, #f22737);
  }
`;

export const FieldInput = styled.input`
  width: 100%;
  padding: 10px;
  box-sizing: border-box;

  ${({ type }) => type === "submit" && authFormButtonStyles};
`;

export const UserSettingsNodeInput = styled.input`
  width: 100%;
  height: 45px;
  box-sizing: border-box;
  border-radius: 3px;
  border: 1px solid rgb(221, 221, 221);
  font-size: 14px;
  font-weight: 500;
  padding: 14px 14px 15px;
  color: rgb(124, 132, 149);

  &[type="submit"] {
    ${baseButtonStyles}
    grid-column: 2;
    cursor: pointer;
    width: 200px;
    background-color: #484886;
    color: white;
  }
`;
