import React, { useCallback } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

import { useMutation } from "@apollo/client";
import { RemoveOrgMutation, RemoveOrgMutationVariables } from "@src/generated/graphql";
import { useRemoveRow } from "@src/Hooks/removeRow";
import { SiteTreeProps } from "@src/Hooks/siteTree";

import { useFetchInfrastructureOrgInfo } from "./fetchInfrastructureOrgInfo";
import { InfrastructureInformation } from "./InfrastructureInformation";
import RemoveOrg from "./RemoveOrg.graphql";

function useSiteTreeProps() {
  return useOutletContext<SiteTreeProps>();
}
export function InfrastructureOrgInformation() {
  const { orgId } = useParams();
  const navigate = useNavigate();
  const treeProps = useSiteTreeProps();

  const informationProps = useFetchInfrastructureOrgInfo(orgId, treeProps.expandTree);

  const { modalShown, entity: selectedSite, hide: hideModal, show: showModal } = useRemoveRow();

  const redirect = useCallback(() => {
    navigate(`/app/infrastructure`);
    hideModal();
  }, [hideModal, navigate]);

  const [remove] = useMutation<RemoveOrgMutation, RemoveOrgMutationVariables>(RemoveOrg, {
    variables: { id: orgId },
    onCompleted: redirect,
    update(cache, { data: { removeOrg } }) {
      cache.evict({
        id: cache.identify({ __typename: "Org", id: removeOrg })
      });
      cache.gc();
    }
  });

  return (
    <InfrastructureInformation
      remove={remove}
      modalShown={modalShown}
      selectedSite={selectedSite}
      hideModal={hideModal}
      showModal={showModal}
      {...informationProps}
      {...treeProps}
    />
  );
}
