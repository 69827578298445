import React, { ReactNode } from "react";

import {
  BlockDescription,
  BlockName,
  BlockProvider,
  FirstLine,
  Image
} from "@src/Components/BlockSelector/BlockStyles";
import { BlockWrapper } from "@src/Components/Grid/BlockWrapper";
import { BlockChart } from "@src/generated/graphql";

type BlockProps = {
  block: BlockChart;
  view: number;
  children: ReactNode;
  openBlockInformation: (name: string, version: string) => void;
};

export function Block({ view, children, openBlockInformation, block }: BlockProps) {
  const { name, version, description, displayName, logoUrl, vendor } = block;

  return (
    <BlockWrapper
      title={`${displayName} block`}
      onClick={() => openBlockInformation(name, version)}
    >
      {vendor && (
        <FirstLine isGrid={view == 1}>
          <Image src={logoUrl} alt={`${vendor} logo`} />
          <div>
            <BlockName>{displayName}</BlockName>
            <BlockProvider>by {vendor}</BlockProvider>
          </div>
        </FirstLine>
      )}
      <BlockDescription>{description}</BlockDescription>
      {children}
    </BlockWrapper>
  );
}
