import { FormikProps } from "formik";
import React from "react";
import styled from "styled-components";

import { FieldLabel, InputField } from "../Components/Input/InputGroup";
import { workflows } from "./workflows";

export interface NztpValues {
  mac: string;
  workflow: string;
}

const Workflows = styled.div`
  margin-bottom: 35px;
`;

export const Info = styled.span`
  margin-left: 5px;
`;

export function NztpFields({ values, setFieldValue, errors }: FormikProps<NztpValues>) {
  return (
    <>
      <FieldLabel htmlFor="mac">MAC address</FieldLabel>
      <InputField name="mac" errors={errors} />
      <WorkflowSelector values={values} setFieldValue={setFieldValue} />
    </>
  );
}

interface Workflow {
  workflow: string;
}

interface WorkflowSelectorProps {
  values: Workflow;
  setFieldValue: FormikProps<Workflow>["setFieldValue"];
}

export function WorkflowSelector({ values, setFieldValue }: WorkflowSelectorProps) {
  return (
    <>
      <FieldLabel htmlFor="workflow">workflow</FieldLabel>
      <Workflows>
        <select value={values.workflow} onChange={e => setFieldValue("workflow", e.target.value)}>
          {workflows.map(w => (
            <option key={w}>{w}</option>
          ))}
        </select>
      </Workflows>
    </>
  );
}
