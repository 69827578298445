import { useEffect } from "react";

import { useApolloClient } from "@apollo/client";

export function useOnResetStorage(cb: CallableFunction) {
  const client = useApolloClient();
  useEffect(() => {
    const unsubscribe = client.onResetStore(() => {
      return new Promise(res => res(cb()));
    });
    return () => unsubscribe();
  }, [client, cb]);
}
