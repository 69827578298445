import { useQuery } from "@apollo/client";
import {
  FetchOrgInformationQuery,
  FetchOrgInformationQueryVariables,
  FetchOrgQuery,
  FetchOrgQueryVariables
} from "@src/generated/graphql";

import FetchOrg from "./FetchOrg.graphql";
import FetchOrgInformation from "./FetchOrgInformation.graphql";

export function useFetchInfrastructureOrgInfo(
  orgId: string,
  initTree: (id: string, ancestors: string[]) => void
) {
  const { data, loading, error } = useQuery<
    FetchOrgInformationQuery,
    FetchOrgInformationQueryVariables
  >(FetchOrgInformation, {
    variables: {
      id: orgId
    },
    onCompleted: () => initTree(orgId, [orgId])
  });

  return {
    data: data?.org,
    loading,
    error
  };
}

export function useFetchOrgQuery(orgId: string) {
  const { data, loading, error } = useQuery<FetchOrgQuery, FetchOrgQueryVariables>(FetchOrg, {
    variables: {
      id: orgId
    }
  });

  return {
    data: data?.org,
    loading,
    error
  };
}
