import { Clickable } from "../Buttons/Clickable";
import { Reload } from "../Buttons/Reload";
import { css, styled } from "../theme";

export const Wrapper = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 300px minmax(500px, 4fr);
`;
export const SelectedCategoryitem = css`
  cursor: initial;
  background-color: ${({ theme }) => theme.backgroundLight};
  padding-right: 21px;
  color: ${({ theme }) => theme.primary};

  & > span {
    display: block;
  }
`;

export const CategoryItem = styled.div<{ selected: boolean }>`
  cursor: pointer;
  position: relative;
  margin-bottom: 5px;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: transparent;
  & > span {
    display: none;
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 5px;
    height: 16px;
    width: 16px;
    float: right;

    ::before,
    ::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      height: 2px;
      width: 100%;
      background-color: ${({ theme }) => theme.primaryLight};
    }

    ::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    ::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
  ${({ selected }) => selected && SelectedCategoryitem}
`;

export const NoBlocksMessage = styled.div`
  height: 50px;
  font-style: italic;
  display: flex;
  grid-column: 1 / -1;
  margin: auto;
`;

export const Title = styled.h4<{ mTop?: string }>`
  color: black;
  text-transform: uppercase;
  display: inline-block;
  align-self: center;
  ${({ mTop }) =>
    mTop &&
    css`
      margin-top: ${mTop};
    `};
`;

export const InnerFirstLine = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.backgroundLight};
  margin-bottom: 20px;
  justify-content: center;
`;

export const TotalBlocks = styled.div`
  margin-right: auto;
`;

export const Select = styled.select`
  border: none;
  height: 30px;
  margin: auto 15px auto 5px;
  background: none;
`;

export const BlocksContent = styled.div<{ view: number }>`
  display: grid;
  grid-template-columns: ${({ view }) => (view == 1 ? "repeat(5, 1fr)" : "1fr")};
  gap: 10px;
  width: 100%;

  @media screen and (max-width: 1800px) {
    grid-template-columns: ${({ view }) => (view == 1 ? "repeat(4, 1fr)" : "1fr")};
  }

  @media screen and (max-width: 1500px) {
    grid-template-columns: ${({ view }) => (view == 1 ? "repeat(3, 1fr)" : "1fr")};
  }

  @media screen and (max-width: 1300px) {
    grid-template-columns: ${({ view }) => (view == 1 ? "repeat(2, 1fr)" : "1fr")};
  }
`;

export const ReloadClickable = styled(Clickable)`
  display: inline;
`;
export const ReloadBlocks = styled(Reload)`
  width: 20px;
  cursor: inherit;
`;
