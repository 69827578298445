export const MarketplaceStateKey = "MarketplaceState";

export enum viewEnum {
  grid = 1,
  list
}

export enum sortEnum {
  AZ = 1,
  ZA
}

export interface MarketplaceState {
  selectedCategory: string;
  selectedVendors: string[];
  view: viewEnum;
  sort: sortEnum;
}

export const initialState: MarketplaceState = {
  selectedCategory: null,
  selectedVendors: [],
  view: viewEnum.grid,
  sort: sortEnum.AZ
};

export type MarketplaceAction =
  | { type: "selectCategory" | "swapView" | "swapSort"; value: string }
  | { type: "removeCategory" | "removeVendors" | "cleanFilters" }
  | { type: "toggleVendor"; value: string }
  | { type: "removeVendor"; value: string };

export function marketplaceReducer(
  state: MarketplaceState,
  action: MarketplaceAction
): MarketplaceState {
  let newState: MarketplaceState;
  switch (action.type) {
    case "selectCategory":
      newState = { ...state, selectedCategory: action.value };
      break;
    case "removeCategory":
      newState = { ...state, selectedCategory: null };
      break;
    case "toggleVendor":
      newState = state.selectedVendors.includes(action.value)
        ? marketplaceReducer(state, { type: "removeVendor", value: action.value })
        : {
            ...state,
            selectedVendors: [...state.selectedVendors, action.value]
          };
      break;
    case "removeVendor":
      newState = {
        ...state,
        selectedVendors: state.selectedVendors.filter(v => v !== action.value)
      };
      break;
    case "removeVendors":
      newState = { ...state, selectedVendors: [] };
      break;
    case "swapView":
      newState = { ...state, view: parseInt(action.value) };
      break;
    case "swapSort":
      newState = { ...state, sort: parseInt(action.value) };
      break;
    case "cleanFilters":
      newState = { ...state, selectedVendors: [], selectedCategory: null };
      break;
    default:
      newState = state;
      break;
  }
  window.localStorage.setItem(MarketplaceStateKey, JSON.stringify(newState));
  return newState;
}
