import React, { Component, ReactNode } from "react";
import { H1 } from "./Components/Text";
import styled from "styled-components";

const ErrorContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface ErrorBoundaryProps {
  pathname: string;
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidUpdate(prevProps: ErrorBoundaryProps) {
    if (prevProps.pathname !== this.props.pathname) {
      this.setState({ hasError: false });
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorContainer>
          <div>
            <H1>Something went wrong</H1>
            <p>An unexpected error occurred</p>
          </div>
        </ErrorContainer>
      );
    }

    return this.props.children;
  }
}
