import { useToggle } from "@src/Hooks/toggle";
import { useUserOrgs } from "@src/Hooks/userOrgs";

interface useSelectSiteModalProps {
  siteId: string;
}

export function useSelectSiteModal({ siteId }: useSelectSiteModalProps) {
  const { userOrgs } = useUserOrgs();

  const siteIsOrg = userOrgs.some(org => org.id === siteId);
  const { state, toggle } = useToggle();

  return { siteIsOrg, showModal: state, toggleModal: toggle };
}
