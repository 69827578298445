import React from "react";
import styled from "styled-components";

import TickIcon from "../../../img/tick.svg";
import { Modal, ModalProps } from "./Modal";

type SuccessModalProps = ModalProps;

const Icon = styled.div`
  text-align: center;
`;

const Children = styled.div`
  text-align: center;
  font-size: 18px;
  line-height: 1.06;
  letter-spacing: 0.3px;
  color: #444444;
  margin-top: 1em;
`;

export function SuccessModal({ children, ...rest }: SuccessModalProps) {
  return (
    <Modal {...rest}>
      <Icon>
        <svg height={55} width={55} viewBox="-7.5 -7.5 55 55">
          <circle cx={20} cy={20} r={27.5} fill="#98cd96" />
          <TickIcon height={40} width={40} fill="white" />
        </svg>
      </Icon>
      <Children>{children}</Children>
    </Modal>
  );
}
