import { FormikErrors } from "formik";
import React, { Fragment } from "react";

import { FieldLabel, SelectField } from "@src/Components/Input/InputGroup";

import { countryCodes } from "./countryCodes";
import { FederationGuestFormInput, FederationHostFormInput } from "./formSchemas";

export function CountryCodeSelect({
  selectedCode,
  errors
}: {
  selectedCode: string;
  errors: FormikErrors<FederationGuestFormInput | FederationHostFormInput>;
}) {
  return (
    <Fragment>
      <FieldLabel htmlFor="countryCode">Country Code</FieldLabel>
      <SelectField name={`countryCode`} value={selectedCode} errors={errors}>
        <option key={"empty"} value="">
          Select a Country Code
        </option>
        {Object.entries(countryCodes).map(([code, country]) => (
          <option key={code} value={code}>
            {country} - {code}
          </option>
        ))}
      </SelectField>
    </Fragment>
  );
}
