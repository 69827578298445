import { Field, Form, Formik } from "formik";
import React from "react";
import styled from "styled-components";

import { PrimaryTextButton, SecondaryTextButton } from "./Buttons/Text";

export const Name = styled.span`
  font-weight: 600;
`;

interface ConfirmRemoveProps {
  entityName: string;
  entityType: string;
  actionName: string;
  action: () => void;
  cancel: () => void;
}

export function ConfirmRemove({
  entityName,
  entityType,
  actionName,
  action,
  cancel
}: ConfirmRemoveProps) {
  return (
    <div>
      Enter <Name>{entityName}</Name> to {actionName} this {entityType}
      <ConfirmAction
        actionName={actionName}
        matchText={entityName}
        doAction={action}
        cancel={cancel}
      />
    </div>
  );
}

interface ConfirmActionProps {
  actionName: string;
  matchText: string;
  doAction: () => void;
  cancel: () => void;
}

const ConfirmForm = styled(Form)`
  display: flex;
  align-items: center;
  gap: 0.5em;
`;

export function ConfirmAction({ actionName, matchText, doAction, cancel }: ConfirmActionProps) {
  return (
    <Formik<{ name: string }>
      key={matchText}
      onSubmit={doAction}
      initialValues={{ name: "" }}
      enableReinitialize={true}
      validate={({ name }) => {
        if (name === matchText) return {};
        return {
          name: "must match name"
        };
      }}
    >
      {({ dirty, errors }) => (
        <ConfirmForm>
          <Field name="name" autoFocus />
          {dirty &&
            (errors.name ? (
              <span>{errors.name}</span>
            ) : (
              <PrimaryTextButton type="submit">{actionName}</PrimaryTextButton>
            ))}
          <SecondaryTextButton type="button" onClick={cancel}>
            cancel
          </SecondaryTextButton>
        </ConfirmForm>
      )}
    </Formik>
  );
}
