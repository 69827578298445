import React from "react";

import { useMutation, useQuery } from "@apollo/client";
import { Loading } from "@src/Components/Loading/Loading";
import { RemoveModal, RemoveModalProps } from "@src/Components/Modal/RemoveModal";
import {
  FederationGuestsQuery,
  RemoveFederationGuestMutation,
  RemoveFederationGuestMutationVariables
} from "@src/generated/graphql";
import { useRemoveRow } from "@src/Hooks/removeRow";

import { FederationGuests } from "./FetchFederationGuests.graphql";
import { PartnerTable } from "./PartnerTable";
import { RemoveFederationGuest } from "./RemoveFederationGuest.graphql";

export function GuestTable() {
  const { data, loading } = useQuery<FederationGuestsQuery>(FederationGuests);

  const {
    modalShown,
    entity: selectedPartner,
    hide: hideModal,
    openRowModal,
    onCompleted
  } = useRemoveRow();

  const [remove] = useMutation<
    RemoveFederationGuestMutation,
    RemoveFederationGuestMutationVariables
  >(RemoveFederationGuest, {
    variables: { id: selectedPartner?.id },
    onCompleted,
    update(cache, { data: { removeFederationGuest } }) {
      cache.evict({
        id: cache.identify({ __typename: "FederationGuest", id: removeFederationGuest })
      });
      cache.gc();
    }
  });

  const removeModalProps: RemoveModalProps = {
    entity: selectedPartner,
    hideModal,
    remove
  };
  return (
    <>
      {!loading ? (
        <PartnerTable
          partners={data?.federationGuests || []}
          linkTo="guest"
          openRowModal={openRowModal}
        />
      ) : (
        <Loading />
      )}
      {modalShown && <RemoveModal {...removeModalProps} />}
    </>
  );
}
