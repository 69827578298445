import { FormikProps } from "formik";
import React, { ChangeEvent, useCallback, useRef } from "react";
import toast from "react-hot-toast";

import { ApolloQueryResult } from "@apollo/client";
import Remove from "@img/remove.svg";
import {
  BlockListWrapper,
  FirstLineButtonGroup,
  FlexRow,
  Grid,
  Item,
  ItemLine,
  Provider,
  ScrollDiv,
  Select,
  StyledClickable,
  StyledH3
} from "@src/Components/BlockSelector/BlockListStyles";
import { BlockName, Image } from "@src/Components/BlockSelector/BlockStyles";
import { ConfirmActionModal } from "@src/Components/Modal/ConfirmActionModal";
import { ChartMetaInput, FetchTemplateChartQuery } from "@src/generated/graphql";
import { useToggle } from "@src/Hooks/toggle";

import { PublisherEditorAction } from "./editorPublisherReducer";
import { BlockPublisherForm } from "./serialise";

interface SelectedTemplateBlockProps extends FormikProps<BlockPublisherForm> {
  block: BlockPublisherForm;
  removeTemplate: () => void;
  editorDispatch: React.Dispatch<PublisherEditorAction>;
  fetchBlockVersion: (variables?: {
    name: string;
    version?: string;
  }) => Promise<ApolloQueryResult<FetchTemplateChartQuery>>;
  templateChart: Omit<FetchTemplateChartQuery["templateChart"], "name" | "version">;
}

export function SelectedTemplateBlock({
  block,
  removeTemplate,
  editorDispatch,
  fetchBlockVersion,
  templateChart,
  ...formikProps
}: SelectedTemplateBlockProps) {
  const { displayName, selectedVersion, templateName: name } = block;
  const { setFieldValue, setFormikState } = formikProps;

  const { state: isOpenRemove, on: openRemove, off: hideRemove } = useToggle();
  const removeItem = useCallback(() => {
    removeTemplate();
    hideRemove();
  }, [removeTemplate, hideRemove]);

  const selectedVersionRef = useRef(selectedVersion);

  const onChangeVersion = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const version = e.target.value;
      if (selectedVersionRef.current === version) return;
      selectedVersionRef.current = version;

      if (
        confirm(
          "Do you want to overwrite current values in the editor with the defaults for the selected version?"
        )
      ) {
        fetchBlockVersion({ name, version }).then(({ data }) => {
          if (data?.templateChart) {
            editorDispatch({
              type: "changeVersion",
              payload: {
                values: data.templateChart.valuesYaml,
                overrides: data.templateChart.overridesYaml
              }
            });
            const newDescriptor: ChartMetaInput = {
              name: "",
              version: "",
              displayName: data.templateChart.displayName,
              vendor: data.templateChart.vendor,
              description: data.templateChart.description,
              iconUrl: data.templateChart.logoUrl,
              categories: data.templateChart.categories
            };
            setFormikState(prev => ({
              ...prev,
              values: {
                ...prev.values,
                selectedVersion: version,
                descriptor: newDescriptor,
                values: data.templateChart.valuesYaml,
                overrides: data.templateChart.overridesYaml
              }
            }));
          } else {
            toast.error("failed to fetch defaults for selected version");
          }
        });
      } else {
        setFieldValue("selectedVersion", version);
      }
    },
    [editorDispatch, fetchBlockVersion, name, setFieldValue, setFormikState]
  );

  const hideBlock = !templateChart || name.length === 0;

  return (
    <BlockListWrapper>
      <FlexRow>
        <StyledH3>Selected template</StyledH3>
      </FlexRow>
      <ScrollDiv>
        {!hideBlock && (
          <Item selected={true} title={`${displayName} block`}>
            <ItemLine>
              <Image src={templateChart?.logoUrl} alt={`${templateChart?.vendor} logo`} />
              <FirstLineButtonGroup>
                <StyledClickable onClick={openRemove} title="remove block list">
                  <Remove width="15px" height="15px" />
                </StyledClickable>
              </FirstLineButtonGroup>
            </ItemLine>
            <Grid>
              <div>
                <BlockName>{displayName}</BlockName>
                <Provider>by {templateChart?.vendor}</Provider>
              </div>
              <div>
                <BlockName>version</BlockName>
                <Select onChange={onChangeVersion} value={selectedVersion}>
                  {templateChart?.availableVersions.map(v => (
                    <option key={v} value={v}>
                      {v}
                    </option>
                  ))}
                </Select>
              </div>
            </Grid>
          </Item>
        )}

        <ConfirmActionModal
          hideModal={hideRemove}
          modalShown={isOpenRemove}
          confirmAction={removeItem}
          title={`Remove ${block.displayName}`}
        >
          <p>The current configuration will be lost.</p>
        </ConfirmActionModal>
      </ScrollDiv>
    </BlockListWrapper>
  );
}
