import { FormikProps } from "formik";
import React, { useCallback, useState } from "react";
import toast from "react-hot-toast";

import { useMutation } from "@apollo/client";
import { ToastNotification } from "@src/Components/ToastNotification";
import {
  PopulateMarketplaceQuery,
  PopulateMarketplaceQueryVariables,
  PublishBlockChartInput,
  PublishBlockChartMutation,
  PublishBlockChartMutationVariables
} from "@src/generated/graphql";

import PopulateMarketplace from "../ServiceDesigner/PopulateMarketplace.graphql";
import { emptyTemplateForm } from "./PublisherPage";
import PublishBlockChart from "./PushBlock.graphql";
import { BlockPublisherForm, serialiseBlock } from "./serialise";

export type PublishState = {
  submitted: boolean;
  success: boolean;
  message: string | null;
};

export function usePublishBlock() {
  const [publishBlockChart] = useMutation<
    PublishBlockChartMutation,
    PublishBlockChartMutationVariables
  >(PublishBlockChart, {
    update(cache, { data: { publishBlockChart } }) {
      cache.updateQuery<PopulateMarketplaceQuery, PopulateMarketplaceQueryVariables>(
        { query: PopulateMarketplace, variables: { category: null, vendors: [] } },

        data => ({
          ...data,
          blockCharts: [...(data?.blockCharts || []), { ...publishBlockChart }]
        })
      );
    }
  });

  const [publishState, setPublishState] = useState<PublishState>({
    submitted: false,
    success: false,
    message: null
  });

  const publish = useCallback(
    async (
      form: BlockPublisherForm,
      { resetForm, setSubmitting }: FormikProps<BlockPublisherForm>
    ) => {
      let serialised: PublishBlockChartInput;
      try {
        serialised = serialiseBlock(form);
      } catch (e) {
        const errorTitle = "failed to publish block";
        console.error(errorTitle, e);

        toast.error(<ToastNotification title={errorTitle} msg={String(e)} />);
        setPublishState(prev => ({ ...prev, message: String(e), submitted: true }));

        setSubmitting(false);
        return;
      }

      try {
        const pushResult = await publishBlockChart({
          variables: {
            input: serialised
          }
        });
        // We must get a name to show the user
        const { displayName } = pushResult.data.publishBlockChart;
        const readableDate = new Date().toLocaleString();
        const successMessage = `successfully published ${displayName} at ${readableDate}`;
        toast.success(<ToastNotification title={successMessage} />);
        setPublishState(prev => ({
          ...prev,
          success: true,
          submitted: true,
          message: "Block has been published"
        }));
      } catch (e) {
        const errorTitle = "failed to publish";
        console.error(errorTitle, e);

        toast.error(<ToastNotification title={errorTitle} msg={String(e)} />);
        setPublishState(prev => ({ ...prev, message: String(e), submitted: true }));

        setSubmitting(false);

        return;
      }

      resetForm({
        values: {
          ...emptyTemplateForm
        }
      });

      setSubmitting(false);
    },
    [publishBlockChart]
  );

  return { publish, publishState, setPublishState };
}
