import React, { Dispatch, useEffect } from "react";
import { CircleLayer, Layer } from "react-map-gl";

import { MapAction } from "./mapReducer";

interface InteractiveLayerProps extends CircleLayer {
  dispatch: Dispatch<MapAction>;
}

export function InteractiveLayer({ dispatch, id, ...props }: InteractiveLayerProps) {
  useEffect(() => {
    dispatch({ type: "addInteractiveLayer", id });
    return () => dispatch({ type: "removeInteractiveLayer", id });
  }, [dispatch, id]);

  return <Layer id={id} {...props} />;
}
