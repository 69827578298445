import { Form, Formik } from "formik";
import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

import { useMutation, useQuery } from "@apollo/client";
import { ButtonRowDefault } from "@src/Components/Buttons/ButtonRow";
import { ErrorContainer } from "@src/Components/ErrorContainer";
import { FieldLabel, Fieldset, InputField, TextAreaField } from "@src/Components/Input/InputGroup";
import { Loading } from "@src/Components/Loading/Loading";
import { H2 } from "@src/Components/Text";
import {
  AddOrgMutation,
  AddOrgMutationVariables,
  EditOrgMutation,
  EditOrgMutationVariables,
  FetchOrganizationsQuery,
  FetchOrgQuery,
  FetchOrgQueryVariables,
  OrgInput
} from "@src/generated/graphql";
import AddOrg from "@src/Infrastructure/sites/AddOrg.graphql";
import EditOrg from "@src/Infrastructure/sites/EditOrg.graphql";
import FetchOrg from "@src/Infrastructure/sites/FetchOrg.graphql";
import { Shape } from "@src/yupTypes";

import FetchOrganizations from "./FetchOrganizations.graphql";

const schema = Yup.lazy(() =>
  Yup.object<Shape<OrgInput>>().shape({
    description: Yup.string(),
    displayName: Yup.string().required("display name is required")
  })
);

export function OrganizationForm() {
  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);
  const { id } = useParams<{ id: string }>();
  const isEdit = !!id;
  const { data, loading } = useQuery<FetchOrgQuery, FetchOrgQueryVariables>(FetchOrg, {
    variables: {
      id
    },
    skip: !isEdit
  });
  const [addOrganization] = useMutation<AddOrgMutation, AddOrgMutationVariables>(AddOrg, {
    update(cache, { data: { createOrg } }) {
      cache.updateQuery<FetchOrganizationsQuery>({ query: FetchOrganizations }, data => ({
        ...data,
        orgs: [...data.orgs, createOrg]
      }));
    }
  });
  const [editOrganization] = useMutation<EditOrgMutation, EditOrgMutationVariables>(EditOrg);

  const initialValues: OrgInput = {
    description: isEdit ? data?.org?.description : "",
    displayName: isEdit ? data?.org?.displayName : ""
  };

  if (loading) return <Loading />;
  if (isEdit && !data?.org) return <ErrorContainer>failed to load device info</ErrorContainer>;

  return (
    <Formik<OrgInput>
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={async values => {
        if (isEdit) {
          try {
            await editOrganization({
              variables: {
                id,
                org: values
              }
            });
          } catch (e) {}
        } else {
          try {
            await addOrganization({
              variables: {
                org: values
              }
            });
          } catch (e) {}
        }
        goBack();
      }}
    >
      {formikProps =>
        formikProps.isSubmitting ? (
          <Loading />
        ) : (
          <>
            <H2>{isEdit ? "Edit" : "Add"} organization</H2>
            <Form>
              <Fieldset>
                <FieldLabel htmlFor="displayName">display name</FieldLabel>
                <InputField name="displayName" errors={formikProps.errors} />
                <FieldLabel htmlFor="description">description</FieldLabel>
                <TextAreaField name="description" errors={formikProps.errors} />
                <ButtonRowDefault
                  onClickBack={goBack}
                  isValid={formikProps.isValid}
                  isSubmitting={formikProps.isSubmitting}
                  backText="Cancel"
                  submitText={isEdit ? "Update" : "Create"}
                />
              </Fieldset>
            </Form>
          </>
        )
      }
    </Formik>
  );
}
