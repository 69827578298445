import React, { Suspense } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import styled from "styled-components";

import ArrowUpDD from "@img/arrow_light_up.svg";

import PublisherIcon from "../img/cloud-arrow-up-solid.svg";
import AboutIcon from "../img/info-solid.svg";
import NetworkIcon from "../img/network.svg";
import ServiceDesignerIcon from "../img/project-diagram-solid.svg";
import ReportIcon from "../img/reports.svg";
import ServicesIcon from "../img/services.svg";
import SettingsIcon from "../img/system.svg";
import { BottomPanel } from "./Components/BottomPanel";
import { Clickable } from "./Components/Buttons/Clickable";
import { DelayedRedirect } from "./Components/DelayedRedirect";
import { LoadingPage } from "./Components/Loading/LoadingPage";
import { NavItem } from "./Components/Navigation/NavItem";
import { ErrorBoundary } from "./ErrorBoundary";
import { Header } from "./Header";
import { useIsAdmin } from "./Hooks/isAdmin";
import { useIsMno } from "./Hooks/isMno";
import { useIsSuperAdmin } from "./Hooks/isSuperAdmin";
import { useToggle } from "./Hooks/toggle";
import { LogsPanel } from "./LogsPanel/LogsPanel";
import { useSession } from "./Session";

const Page = styled.div`
  display: grid;
  position: relative;
  grid-template-areas: "sidebar header" "sidebar main" "sidebar panel";
  grid-template-columns: 100px auto;
  grid-template-rows: 50px 1fr;
  min-height: 100vh;
`;

const Sidebar = styled.nav`
  grid-area: sidebar;
  background-image: linear-gradient(
      195deg,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0) 171px,
      #333167 171px,
      #333167
    ),
    linear-gradient(180deg, rgba(16, 73, 152, 0.48), rgba(218, 40, 63, 0.48) 300px);
  background-color: var(--nav-bar-background-color, #333167);

  text-align: center;

  min-height: 100%;
  display: grid;
  grid-template-rows: min-content auto auto;
`;

const Logo = styled(Link)`
  width: 90px;
  height: 134px;
  margin: 0px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NavIcons = styled.div`
  margin: 90px 0 139px;

  position: sticky;
  top: 0;
`;

const Main = styled.main`
  grid-area: main;
  position: relative;
  padding: 0 60px 30px;
`;

const LoggedOutWarning = styled.div`
  padding: 0.5em 60px;
  background-color: ${props => props.theme.warning};
  color: white;
  text-align: center;
`;

const AboutLink = styled.div`
  margin-top: auto;
`;

const PanelWrapper = styled.div`
  grid-area: panel;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  position: sticky;
  bottom: 0;
`;

const OpenPanelButton = styled(Clickable)`
  background-color: white;
  margin-right: 5%;
  font-size: medium;
  padding: 10px 30px;
  border-radius: 4px 4px 0 0;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
  gap: 6px;
`;

export function Layout() {
  const { isLoggedOut } = useSession();
  const { isMno } = useIsMno();
  const { isAdmin } = useIsAdmin();
  const { isSuperAdmin } = useIsSuperAdmin();
  const { pathname } = useLocation();

  const { state, on: open, off: close } = useToggle();

  return (
    <Page>
      <Sidebar>
        <Logo to="/">
          <img src="/upload/nav-logo.png" alt="logo" />
        </Logo>
        <NavIcons>
          <NavItem
            disabled={isSuperAdmin}
            linkTo="/app/services"
            label="Services"
            icon={ServicesIcon}
          />
          <NavItem
            disabled={isSuperAdmin}
            linkTo="/app/service-designer"
            label="Designer"
            icon={ServiceDesignerIcon}
          />
          <NavItem
            disabled={!isAdmin || isSuperAdmin}
            linkTo="/app/publisher"
            label="Publisher"
            icon={PublisherIcon}
          />
          <NavItem
            disabled={isSuperAdmin}
            linkTo="/app/infrastructure"
            label="Infrastructure"
            icon={NetworkIcon}
          />
          <NavItem
            disabled={isSuperAdmin}
            linkTo="/app/reports"
            label="Reports"
            icon={ReportIcon}
          />
          <NavItem
            disabled={!isAdmin && !isMno}
            linkTo="/app/settings"
            label="Settings"
            icon={SettingsIcon}
          />
        </NavIcons>
        <AboutLink>
          <NavItem linkTo="/app/about" label="About" icon={AboutIcon} />
        </AboutLink>
      </Sidebar>
      <Header />
      <Main>
        {isLoggedOut ? (
          <>
            <LoggedOutWarning>You are not logged in. Redirecting to login page...</LoggedOutWarning>
            <DelayedRedirect delay={5000} to="/auth/login" />
          </>
        ) : null}
        <ErrorBoundary pathname={pathname}>
          <Suspense fallback={<LoadingPage />}>
            <Outlet />
          </Suspense>
        </ErrorBoundary>
      </Main>
      <PanelWrapper>
        <OpenPanelButton onClick={open}>
          Logs <ArrowUpDD height={20} width={20} />
        </OpenPanelButton>
      </PanelWrapper>
      <BottomPanel show={state}>
        <LogsPanel close={close} />
      </BottomPanel>
    </Page>
  );
}
