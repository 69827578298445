import React, { useRef } from "react";
import { useOnClickOutside } from "usehooks-ts";

import ArrowDownDD from "@img/arrow_light_down.svg";
import ArrowUpDD from "@img/arrow_light_up.svg";
import Config from "@img/configure.svg";
import { Clickable } from "@src/Components/Buttons/Clickable";
import { baseInputStyles } from "@src/Components/Input/Input";
import { styled } from "@src/Components/theme";
import { useToggle } from "@src/Hooks/toggle";

import { ColumnFilterModal } from "./ColumnFilterModal";

const ConfigureColumnsButton = styled(Clickable)`
  ${baseInputStyles}
  display: flex;
  gap: 6px;
  width: auto;
  padding: 8px 16px;
`;

const FilterWrapper = styled.div`
  position: relative;
`;

export const hidableColumns = ["Entity Name", "Organization", "User", "Reason"];
export const allColumns = [
  "Time",
  "Entity Type",
  "Entity Name",
  "Organization",
  "User",
  "Type",
  "Reason",
  "Message"
];

interface LogsColumnFilterProps {
  selectedFilters: string[];
  toggleFilterTag: (status: string) => void;
}
export function LogsColumnFilter({ selectedFilters, toggleFilterTag }: LogsColumnFilterProps) {
  const { state: isOpen, off: close, toggle } = useToggle();

  const filterRef = useRef();
  useOnClickOutside(filterRef, close, "mouseup");

  return (
    <FilterWrapper ref={filterRef}>
      <ConfigureColumnsButton onClick={toggle}>
        <Config height={24} width={24} />
        Configure Columns
        {isOpen ? <ArrowUpDD height={14} width={14} /> : <ArrowDownDD height={14} width={14} />}
      </ConfigureColumnsButton>
      {isOpen && (
        <ColumnFilterModal selectedFilters={selectedFilters} toggleFilterTag={toggleFilterTag} />
      )}
    </FilterWrapper>
  );
}
