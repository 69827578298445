import React, { ChangeEvent, useCallback, useState } from "react";
import toast from "react-hot-toast";

import { useMutation } from "@apollo/client";
import { PrimaryButton } from "@src/Components/Buttons/Primary";
import { baseInputStyles } from "@src/Components/Input/Input";
import { FieldLabel } from "@src/Components/Input/InputGroup";
import { H2 } from "@src/Components/Text";
import { css, styled } from "@src/Components/theme";
import {
  BlockControl as BlockControlType,
  UpdateControlMutation,
  UpdateControlMutationVariables
} from "@src/generated/graphql";

import { DismissModalButton } from "./DismissModalButton";
import FetchInstanceBlocks from "./FetchInstanceBlocks.graphql";
import UpdateControl from "./UpdateControl.graphql";

const Select = styled.select`
  ${baseInputStyles}
  padding: 8px 12px;
  height: 100%;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const UpdateButton = styled(PrimaryButton)`
  width: auto;
  padding: 0 30px;
`;
const contentWidth = css`
  width: 750px;
  max-width: 80vw;
`;
const ControlsWrapper = styled.div`
  ${contentWidth}
`;
const ControlsList = styled.div`
  display: grid;
  max-width: 80vw;
  max-height: 50vh;
  overflow-y: auto;
  grid-template-columns: auto minmax(auto, 1fr) auto;
  grid-gap: 30px;
  padding: 30px 40px;
`;

const ControlLabel = styled(FieldLabel)`
  padding: 0;
  text-align: left;
  font-size: medium;
`;

const ControlOption = styled.option`
  font-size: medium;
`;

type BlockControlsContainerProps = {
  blockName: string;
  controls: BlockControlType[];
  hideModal: () => void;
};

export function BlockControlsContainer({
  blockName,
  controls,
  hideModal
}: BlockControlsContainerProps) {
  return (
    <ControlsWrapper>
      <DismissModalButton onClick={hideModal} />
      <H2>
        Controls for: <b>{blockName}</b>
      </H2>
      <ControlsList>
        {controls.map(control => (
          <BlockControl key={control.id} control={control} />
        ))}
      </ControlsList>
    </ControlsWrapper>
  );
}

type BlockControlProps = {
  control: BlockControlType;
};

function BlockControl({ control }: BlockControlProps) {
  const { displayName, value, options, id } = control;
  const [update] = useMutation<UpdateControlMutation, UpdateControlMutationVariables>(
    UpdateControl
  );

  const [selectorValue, setSelectorValue] = useState(value);

  const handleOnChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    setSelectorValue(e.target.value);
  }, []);

  const updateControl = useCallback(() => {
    toast.promise(
      update({
        variables: { id, value: selectorValue },
        refetchQueries: [FetchInstanceBlocks]
      }),
      {
        loading: "Updating control...",
        success: `Updated ${displayName} to ${selectorValue}`,
        error: `Unable to update ${displayName} to ${selectorValue}`
      }
    );
  }, [update, id, displayName, selectorValue]);

  return (
    <>
      <ControlLabel htmlFor={displayName}>{displayName}:</ControlLabel>
      <Select defaultValue={value} onChange={handleOnChange}>
        {options.map(item => (
          <ControlOption key={item} value={item}>
            {item}
          </ControlOption>
        ))}
      </Select>
      <UpdateButton disabled={selectorValue === value} onClick={updateControl}>
        Update
      </UpdateButton>
    </>
  );
}
