import React from "react";

import Editor from "@monaco-editor/react";
import { CheckboxSlider, CheckBoxWrapper } from "@src/Components/Input/CheckboxSlider";
import { Table } from "@src/Components/Table/Table";
import { H3 } from "@src/Components/Text";
import { styled } from "@src/Components/theme";
import { Block } from "@src/generated/graphql";
import { useToggle } from "@src/Hooks/toggle";
import { useEditorTheme } from "@src/ServiceDesigner/editorTheme";
import { ReadOnly } from "@src/ServiceDesigner/ReadOnlyEditor";

import { ResourceRow } from "./ServiceChainRow";
import { defaultSort } from "./sortServices";

interface InstanceResourcesProps {
  block: Block;
  refetch: () => void;
  showResources: boolean;
}
const Wrapper = styled.div`
  height: 100%;
  overflow: hidden;
`;
const ResourcesWrapper = styled.div`
  height: 85%;
  overflow: auto;
  overflow-x: hidden;
  margin-bottom: 2rem;
  padding-right: 10px;
`;
const EditorLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const BasicAdvancedToggleWrapper = styled(CheckBoxWrapper)`
  justify-content: flex-end;
`;

export function InstanceBlockInfo({ block, refetch, showResources }: InstanceResourcesProps) {
  const blockLabel = showResources ? "Resources" : "Values";
  const { theme } = useEditorTheme();
  const { state: showAdvanced, toggle: toggleView } = useToggle();

  const blockResources = (block?.resources || [])
    .slice()
    .sort(defaultSort)
    .filter(r => showAdvanced || r.kind === "ChartRelease");

  return (
    <Wrapper>
      {block ? (
        <>
          <H3>
            {blockLabel} for <strong>{block.displayName}</strong>
          </H3>
          {showResources ? (
            <>
              <BasicAdvancedToggleWrapper>
                <CheckboxSlider
                  name="basic or advanced"
                  checked={showAdvanced}
                  onChange={toggleView}
                  labelLeft="Basic"
                  labelRight="Advanced"
                />
              </BasicAdvancedToggleWrapper>
              <ResourcesWrapper>
                {blockResources.length > 0 ? (
                  <Table>
                    <tbody>
                      {blockResources.map(r => (
                        <ResourceRow key={r.id} resource={r} refetch={refetch} />
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <p>no resources matching this filter</p>
                )}
              </ResourcesWrapper>
            </>
          ) : (
            <EditorLabelWrapper>
              <ReadOnly>read only</ReadOnly>
              <Editor
                language="yaml"
                height="600px"
                theme={theme}
                value={block.values || ""}
                options={{ readOnly: true }}
              />
            </EditorLabelWrapper>
          )}
        </>
      ) : (
        <>
          <H3>{blockLabel}</H3>
          <p>no block selected</p>
        </>
      )}
    </Wrapper>
  );
}
