import React, { lazy, Suspense } from "react";
import { Toaster } from "react-hot-toast";
import { Navigate, Route, Routes } from "react-router-dom";

import { Licenses } from "./About/Licenses";
import { LicensesView } from "./About/LicensesView";
import { LicensesWeb } from "./About/LicensesWeb";
import { Error } from "./Auth/Error";
import { Login } from "./Auth/Login";
import { Recover } from "./Auth/Recover";
import { LoadingPage } from "./Components/Loading/LoadingPage";
import { NotFoundPage } from "./Components/Navigation/NotFoundPage";
import { SettingsProtectedRoute } from "./Components/Navigation/ProtectedRoute";
import { AddDeviceForm } from "./Devices/AddDeviceForm";
import { EditCrossplaneDeviceForm } from "./Devices/Crossplane/EditCrossplaneDeviceForm";
import { ProvisionCrossplaneForm } from "./Devices/Crossplane/ProvisionCrossplaneForm";
import { EditDeviceForm } from "./Devices/EditDeviceForm";
import { Provision } from "./Devices/Provision/Provision";
import { ProvisionDeviceForm } from "./Devices/Provision/ProvisionDeviceForm";
import { ProvisioningOrgTab, ProvisioningSiteTab } from "./Devices/Provision/ProvisionPage";
import { SelectInventoryType } from "./Devices/SelectInventoryType";
import { DeviceInformation } from "./Infrastructure/devices/DeviceInformation";
import { InfrastructureOrgInformation } from "./Infrastructure/sites/InfrastructureOrgInformation";
import { InfrastructureSiteInformation } from "./Infrastructure/sites/InfrastructureSiteInformation";
import { Inventory } from "./Infrastructure/sites/Inventory";
import { OrgInventoryTab, SiteInventoryTab } from "./Infrastructure/sites/InventoryTable";
import { OrganizationOverview, SiteOverview } from "./Infrastructure/sites/Overview";
import { RedirectFirstSite } from "./Infrastructure/sites/RedirectFirstSite";
import { SiteServices } from "./Infrastructure/sites/SiteServices";
import { InitialRedirect } from "./InitialRedirect";
import { Instance } from "./Instances/Instance";
import { InstanceInfo } from "./Instances/InstanceInfo";
import { Layout } from "./Layout";
import { Editor } from "./Publisher/Editor";
import { PublishTab } from "./Publisher/Publish";
import { TemplateInformation } from "./Publisher/TemplateInformation";
import { Templates } from "./Publisher/Templates";
import { Audits } from "./Reports/Audits";
import { ReportsRedirect } from "./Reports/TimeFilter/ReportsRedirect";
import { BlockInformation } from "./ServiceDesigner/BlockInformation";
import { DeployTab } from "./ServiceDesigner/DeployTab";
import { Designer } from "./ServiceDesigner/Designer";
import { EditServicePage } from "./ServiceDesigner/EditServicePage";
import { Marketplace } from "./ServiceDesigner/Marketplace";
import { NewServicePage } from "./ServiceDesigner/NewServicePage";
import { AllUsersManagementTable } from "./Settings/AllUsersManagementTable";
import { Completions } from "./Settings/Completions/Completions";
import { Customizations } from "./Settings/Customizations";
import { AddGuestForm } from "./Settings/Federation/AddGuestForm";
import { AddHostForm } from "./Settings/Federation/AddHostForm";
import { EditGuestForm } from "./Settings/Federation/EditGuestForm";
import { EditHostForm } from "./Settings/Federation/EditHostForm";
import { Federations } from "./Settings/Federation/Federations";
import { FederationsTab } from "./Settings/Federation/FederationsTab";
import { IdentityForm } from "./Settings/Federation/IdentityForm";
import { GuestPartnerInfo, HostPartnerInfo } from "./Settings/Federation/PartnerInfo";
import { OrganizationForm } from "./Settings/OrganizationForm";
import { Organizations } from "./Settings/Organizations";
import { OrganizationsTable } from "./Settings/OrganizationsTable";
import { RedirectFirstOrg } from "./Settings/RedirectFirstOrg";
import { SettingsRedirect } from "./Settings/SettingsRedirect";
import { UserManagement } from "./Settings/UserManagement";
import { UserManagementTable } from "./Settings/UserManagementTable";
import { AddUserForm } from "./User/AddUserForm";
import { EditUserForm } from "./User/EditUserForm";
import { RecruitUserForm } from "./User/RecruitUserForm";
import { UserSettings } from "./User/UserSettings";

const Auth = lazy(() => import("./Auth/Auth"));

const AboutPage = lazy(() => import("./About/AboutPage"));
const ServicesPage = lazy(() => import("./Services/ServicesPage"));
const PublisherPage = lazy(() => import("./Publisher/PublisherPage"));
const InfrastructurePage = lazy(() => import("./Infrastructure/InfrastructurePage"));
const ReportsPage = lazy(() => import("./Reports/ReportsPage"));
const SettingsPage = lazy(() => import("./Settings/Settings"));
const MapSettings = lazy(() => import("./Settings/MapSettings"));

export function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<InitialRedirect />} />
          <Route path="app/services" element={<ServicesPage />}>
            <Route path=":serviceChainId" element={<Instance />}>
              <Route index element={<InstanceInfo />} />
              <Route path=":revision" element={<InstanceInfo />} />
            </Route>
          </Route>
          <Route path="app/service-designer">
            <Route element={<NewServicePage />}>
              <Route index element={<Navigate to="marketplace" />} />
              <Route path="marketplace" element={<Marketplace />}>
                <Route path=":name" element={<BlockInformation />} />
              </Route>
              <Route path="designer" element={<Designer />} />
              <Route path="deploy" element={<DeployTab />} />
            </Route>
            <Route path="edit/:id" element={<EditServicePage />}>
              <Route index element={<Navigate to="designer" />} />
              <Route path="marketplace" element={<Marketplace />}>
                <Route path=":name" element={<BlockInformation />} />
              </Route>
              <Route path="designer" element={<Designer />} />
              <Route path="deploy" element={<DeployTab />} />
            </Route>
          </Route>
          <Route path="app/publisher">
            <Route element={<PublisherPage />}>
              <Route index element={<Navigate to="templates" />} />
              <Route path="templates" element={<Templates />}>
                <Route path=":name" element={<TemplateInformation />} />
              </Route>
              <Route path="editor" element={<Editor />} />
              <Route path="publish" element={<PublishTab />} />
            </Route>
          </Route>

          <Route path="app/infrastructure" element={<InfrastructurePage />}>
            <Route index element={<RedirectFirstSite />} />
            <Route path="org/:orgId">
              <Route element={<InfrastructureOrgInformation />}>
                <Route index element={<Navigate to="overview" />} />
                <Route path="overview" element={<OrganizationOverview />} />
                <Route path="inventory" element={<Inventory />}>
                  <Route index element={<OrgInventoryTab />} />
                  <Route path=":deviceId" element={<DeviceInformation />} />
                  <Route path=":deviceId/edit" element={<EditDeviceForm />} />
                  <Route path=":deviceId/edit-crossplane" element={<EditCrossplaneDeviceForm />} />
                </Route>
                <Route path="provision" element={<Provision />}>
                  <Route index element={<ProvisioningOrgTab />} />
                  <Route path="new" element={<ProvisionDeviceForm />} />
                </Route>
                <Route path="services" element={<SiteServices />}>
                  <Route path=":serviceChainId">
                    <Route index element={<Instance />} />
                  </Route>
                </Route>
              </Route>

              <Route path="site/:siteId" element={<InfrastructureSiteInformation />}>
                <Route index element={<Navigate to="overview" />} />
                <Route path="overview" element={<SiteOverview />} />
                <Route path="inventory" element={<Inventory />}>
                  <Route index element={<SiteInventoryTab />} />
                  <Route path="add">
                    <Route index element={<SelectInventoryType />} />
                    <Route path="cloudlet" element={<AddDeviceForm deviceType="cloudlet" />} />
                    <Route path="crossplane" element={<ProvisionCrossplaneForm />} />
                    <Route path="nztp" element={<AddDeviceForm deviceType="nztp" />} />
                    <Route path="other" element={<AddDeviceForm />} />
                  </Route>
                  <Route path=":deviceId" element={<DeviceInformation />} />
                  <Route path=":deviceId/edit" element={<EditDeviceForm />} />
                  <Route path=":deviceId/edit-crossplane" element={<EditCrossplaneDeviceForm />} />
                </Route>
                <Route path="provision" element={<Provision />}>
                  <Route index element={<ProvisioningSiteTab />} />
                  <Route path="new" element={<ProvisionDeviceForm />} />
                </Route>
                <Route path="services" element={<SiteServices />}>
                  <Route path=":serviceChainId" element={<Instance />}>
                    <Route index element={<InstanceInfo />} />
                    <Route path=":revision" element={<InstanceInfo />} />
                  </Route>{" "}
                </Route>
              </Route>
            </Route>
          </Route>

          <Route path="app/reports" element={<ReportsPage />}>
            <Route index element={<ReportsRedirect />} />
            <Route path="audits" element={<Audits />} />
          </Route>

          <Route path="app/settings" element={<SettingsPage />}>
            <Route index element={<SettingsRedirect />} />
            <Route
              path="federation"
              element={
                <SettingsProtectedRoute relation="mno">
                  <Federations />
                </SettingsProtectedRoute>
              }
            >
              <Route index element={<FederationsTab />} />
              <Route path="edit-identity" element={<IdentityForm />} />
              <Route path="partner">
                <Route path="host/add" element={<AddHostForm />} />
                <Route path="host/edit/:id" element={<EditHostForm />} />
                <Route path="host/info/:id" element={<HostPartnerInfo />} />
                <Route path="guest/add" element={<AddGuestForm />} />
                <Route path="guest/edit/:id" element={<EditGuestForm />} />
                <Route path="guest/info/:id" element={<GuestPartnerInfo />} />
              </Route>
            </Route>
            <Route
              path="map-settings"
              element={
                <SettingsProtectedRoute relation="admin">
                  <MapSettings />
                </SettingsProtectedRoute>
              }
            />
            <Route
              path="completions"
              element={
                <SettingsProtectedRoute relation="admin">
                  <Completions />
                </SettingsProtectedRoute>
              }
            />
            <Route
              path="customizations"
              element={
                <SettingsProtectedRoute relation="admin">
                  <Customizations />
                </SettingsProtectedRoute>
              }
            />
            <Route
              path="user-management"
              element={
                <SettingsProtectedRoute relation="admin">
                  <UserManagement />
                </SettingsProtectedRoute>
              }
            >
              <Route index element={<RedirectFirstOrg />} />
              <Route path=":orgId">
                <Route index element={<UserManagementTable />} />
                <Route path="add" element={<AddUserForm />} />
                <Route path="edit/:id" element={<EditUserForm />} />
                <Route path="recruit/:id" element={<RecruitUserForm />} />
              </Route>
              <Route path="all-organizations" element={<AllUsersManagementTable />} />
            </Route>
            <Route
              path="organizations"
              element={
                <SettingsProtectedRoute relation="admin">
                  <Organizations />
                </SettingsProtectedRoute>
              }
            >
              <Route index element={<OrganizationsTable />} />
              <Route path="add" element={<OrganizationForm />} />
              <Route path="edit/:id" element={<OrganizationForm />} />
            </Route>
          </Route>
          <Route path="app/about" element={<AboutPage />}>
            <Route path="licenses" element={<LicensesView />}>
              <Route path="web" element={<LicensesWeb />} />
              <Route path=":component" element={<Licenses />} />
            </Route>
          </Route>
          <Route path="user/settings" element={<UserSettings />} />
        </Route>
        <Route
          path="/auth"
          element={
            <Suspense fallback={<LoadingPage />}>
              <Auth />
            </Suspense>
          }
        >
          <Route path="login" element={<Login />} />
          <Route path="recovery" element={<Recover />} />
          <Route path="error" element={<Error />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Toaster />
    </>
  );
}
