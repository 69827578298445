import React from "react";
import { Link, Navigate } from "react-router-dom";

import { useQuery } from "@apollo/client";
import { Loading } from "@src/Components/Loading/Loading";
import { OrgSelectQuery } from "@src/generated/graphql";
import { useIsSuperAdmin } from "@src/Hooks/isSuperAdmin";
import { useOrgInfo } from "@src/Hooks/orgInfo";

import OrgSelect from "./OrgSelect.graphql";

export function RedirectFirstOrg() {
  const { isSuperAdmin } = useIsSuperAdmin();
  const { orgId: userOrgId } = useOrgInfo();
  const { data } = useQuery<OrgSelectQuery>(OrgSelect, { skip: !isSuperAdmin });

  // if super admin, wait for the data with the list of orgs
  // to be available, then redirect to the first one in the list
  // else redirect to the currently logged in user's org
  if (isSuperAdmin == null) return null;

  if (isSuperAdmin || userOrgId === "*") {
    if (!data) return <Loading />;
    const orgId = data.orgs?.[0]?.id;
    if (orgId) return <Navigate to={data.orgs?.[0]?.id} />;
    else
      return (
        <p>
          No Organizations found. <Link to="../../organizations">Create an Organization</Link>{" "}
          before adding users.
        </p>
      );
  } else {
    if (!userOrgId) return <Loading />;
    return <Navigate to={userOrgId} />;
  }
}
