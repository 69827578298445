import { FormikProps } from "formik";
import React, { Dispatch, useCallback, useEffect } from "react";

import { useQuery } from "@apollo/client";
import { Title } from "@src/Components/BlockSelector/BlockSelectorList";
import { ConfigWrapper, GridContainer, Sidebar } from "@src/Components/BlockSelector/ConfigStyles";
import { Loading } from "@src/Components/Loading/Loading";
import { NoCharts } from "@src/Components/NoCharts";
import { H2 } from "@src/Components/Text";
import { FetchBlockChartQuery, FetchBlockChartQueryVariables } from "@src/generated/graphql";

import { BlockDescriptorViewer } from "./BlockDescriptorViewer";
import { EditingSection } from "./Designer";
import { DesignerEditorAction, DesignerEditorState } from "./editorDesignerReducer";
import EditorSectionButton from "./EditorSectionButton";
import { useEditorTheme } from "./editorTheme";
import { EmbeddedEditor } from "./EmbeddedEditor";
import FetchBlockChart from "./FetchBlockChart.graphql";
import { FormBlock, ServiceForm } from "./serialise";

interface ConfigurationPanelProps extends FormikProps<ServiceForm> {
  editorStatus: DesignerEditorState;
  editorDispatch: Dispatch<DesignerEditorAction>;
  editingSection: EditingSection;
  setEditingSection: (section: EditingSection) => void;
  isEditing: boolean;
}

export function ConfigurationPanel({
  editorStatus,
  editorDispatch,
  editingSection,
  setEditingSection,
  isEditing,
  ...formikProps
}: ConfigurationPanelProps) {
  const {
    values: { blocks }
  } = formikProps;

  const block = Object.values(blocks).find(b => b.displayName === editorStatus.selectedBlock);

  const isBlockSelected = !block?.name || !block?.selectedVersion;

  const { data } = useQuery<FetchBlockChartQuery, FetchBlockChartQueryVariables>(FetchBlockChart, {
    variables: {
      name: block?.name,
      version: block?.selectedVersion
    },
    skip: isBlockSelected
  });

  if (isBlockSelected)
    return (
      <ConfigWrapper>
        <NoCharts link="/app/service-designer/marketplace" linkText="marketplace" entity="block" />
      </ConfigWrapper>
    );

  if (!data) return null;

  return (
    <ConfigWrapper>
      <ConfigureBlock
        key={editorStatus.selectedBlock}
        editorStatus={editorStatus}
        editorDispatch={editorDispatch}
        editingSection={editingSection}
        setEditingSection={setEditingSection}
        isEditing={isEditing}
        block={block}
        originalBlock={data?.blockChart}
        {...formikProps}
      />
    </ConfigWrapper>
  );
}

interface ConfigureBlockProps extends FormikProps<ServiceForm> {
  editorStatus: DesignerEditorState;
  editorDispatch: Dispatch<DesignerEditorAction>;
  editingSection: EditingSection;
  setEditingSection: (section: EditingSection) => void;
  isEditing: boolean;
  block: FormBlock;
  originalBlock: FetchBlockChartQuery["blockChart"];
}

function ConfigureBlock({
  editorStatus,
  editorDispatch,
  editingSection,
  setEditingSection,
  isEditing,
  block,
  originalBlock,
  ...formikProps
}: ConfigureBlockProps) {
  const { values: formikValues, setValues } = formikProps;
  const { displayName } = block;
  const { theme, changeTheme } = useEditorTheme();

  const editBlockValue = useCallback(
    (values: string) => {
      setValues({
        ...formikValues,
        blocks: {
          ...formikValues.blocks,
          [displayName]: {
            ...formikValues.blocks?.[displayName],
            values
          }
        }
      });
    },
    [displayName, formikValues, setValues]
  );
  const originalValues = originalBlock?.overridesYaml;

  useEffect(() => {
    editorDispatch({
      type: "initOriginalValues",
      payload: { block: editorStatus.selectedBlock, value: originalValues }
    });
  }, [editorDispatch, editorStatus.selectedBlock, originalValues]);

  const changeSection = useCallback(
    (section: EditingSection) => {
      setEditingSection(section);
      editorDispatch({ type: "save", payload: { block: displayName } });
    },
    [displayName, editorDispatch, setEditingSection]
  );

  return (
    <>
      <H2>
        Configure <strong>{displayName}</strong>
      </H2>
      <GridContainer>
        <Sidebar>
          <Title>block fields</Title>
          <EditorSectionButton
            section={editingSection}
            sectionName={EditingSection.Descriptor}
            setSection={changeSection}
          />
          <EditorSectionButton
            section={editingSection}
            sectionName={EditingSection.Values}
            setSection={changeSection}
            editorState={editorStatus.blocks[editorStatus.selectedBlock].status}
            titleLabel="values"
          />
        </Sidebar>
        {originalBlock &&
          (editingSection === EditingSection.Values ? (
            block ? (
              <EmbeddedEditor
                key={block.displayName}
                blockDisplayName={block.displayName}
                defaultYaml={editorStatus.blocks[editorStatus.selectedBlock].original}
                theme={theme}
                configurationParameter={editingSection}
                isEditing={isEditing}
                changeTheme={changeTheme}
                editBlockValue={editBlockValue}
                editorValues={block.values}
                previousValues={editorStatus.blocks[editorStatus.selectedBlock].previous}
                editorDispatch={editorDispatch}
                savedValue={editorStatus.blocks[editorStatus.selectedBlock].saved}
              />
            ) : (
              <Loading />
            )
          ) : (
            <BlockDescriptorViewer blockName={block.name} blockVersion={block.selectedVersion} />
          ))}
      </GridContainer>
    </>
  );
}
