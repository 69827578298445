import { FieldArray, FormikErrors } from "formik";
import React from "react";

import { CheckboxField, FieldLabel, Fieldset, InputField } from "@src/Components/Input/InputGroup";
import { H3 } from "@src/Components/Text";
import { CrossplaneConfigField } from "@src/generated/graphql";

import {
  ConfigField,
  EditCrossplaneDeviceValues,
  ProvisionValues,
  ValueType
} from "./crossplaneUtils";

interface CrossplaneConfigProps {
  crossplaneConfig: ConfigField[];
  originalConfig?: CrossplaneConfigField[];
  errors: FormikErrors<ProvisionValues | EditCrossplaneDeviceValues>;
}

const validateNumbers = (currentValue: number, type: string) => {
  if (type === "integer" && currentValue % 1 !== 0)
    return "field must be a non floating point number";
};

export function CrossplaneConfig({
  crossplaneConfig,
  originalConfig = [],
  errors
}: CrossplaneConfigProps) {
  const config = crossplaneConfig || [];
  const formFields = applyPlaceholders(config, originalConfig);
  return (
    <>
      <H3>Provider Configuration</H3>
      <FieldArray
        name="config"
        render={() => (
          <Fieldset>
            {formFields.map(({ label, ...fieldInfo }, index) => (
              <React.Fragment key={label}>
                <FieldLabel htmlFor={label}>{label}</FieldLabel>
                <ConfigInput fieldInfo={fieldInfo} errors={errors} index={index} />
              </React.Fragment>
            ))}
          </Fieldset>
        )}
      />
    </>
  );
}

function applyPlaceholders(configData: ConfigField[], originalConfig: CrossplaneConfigField[]) {
  return configData.map(({ label, type, value }) => {
    const placeHolder = originalConfig.find(({ label: l }) => l === label)?.value || "";
    return { label, type, placeHolder, value };
  });
}

export interface ConfigInputProps {
  fieldInfo: {
    type: string;
    value: ValueType;
    placeHolder: string;
  };
  index: number;
  errors: FormikErrors<ProvisionValues>;
}
export function ConfigInput({ fieldInfo, index, errors }: ConfigInputProps) {
  const { type, value, placeHolder } = fieldInfo;

  switch (type) {
    case "integer":
    case "number":
      return (
        <InputField
          name={`config.${index}.value`}
          placeholder={placeHolder}
          errors={errors}
          type="number"
          autocomplete="off"
          validate={(currentValue: number) => validateNumbers(currentValue, type)}
          min={0}
        />
      );
    case "boolean":
      return (
        <CheckboxField checked={value as boolean} name={`config.${index}.value`} errors={errors} />
      );

    default:
      return (
        <InputField
          type="text"
          name={`config.${index}.value`}
          placeholder={placeHolder}
          errors={errors}
        />
      );
  }
}
