import React, { useCallback } from "react";
import toast from "react-hot-toast";
import { useNavigate, useOutletContext, useParams, useSearchParams } from "react-router-dom";

import { ChartInformation } from "@src/Components/BlockSelector/ChartInformation";
import { useDeleteBlockChart } from "@src/Components/BlockSelector/deleteBlock";
import { RemoveModal } from "@src/Components/Modal/RemoveModal";
import { SidePanel } from "@src/Components/SidePanel";
import { ToastNotification } from "@src/Components/ToastNotification";
import { BlockChart } from "@src/generated/graphql";
import { useToggle } from "@src/Hooks/toggle";

import { useFetchBlockInformation } from "./fetchBlockInformation";

interface useBlockInformationProps {
  addVersionBlock: (chart: BlockChart) => void;
}

export function BlockInformation() {
  const navigate = useNavigate();
  const { name } = useParams();
  const [searchParams] = useSearchParams();
  const version = searchParams.get("v");
  const { addVersionBlock } = useOutletContext<useBlockInformationProps>();
  const templateInfo = useFetchBlockInformation(name, version);

  const {
    state: shouldShowConfirmRemove,
    on: showConfirmation,
    off: hideConfirmation
  } = useToggle();

  const { chart } = templateInfo;
  const deleteBlock = useDeleteBlockChart(
    chart,
    () => {
      hideConfirmation();
      toast.success(
        <ToastNotification title="deleted chart version" msg={`${chart?.name}/${chart?.version}`} />
      );
      navigate("..");
    },
    () => toast.error(<ToastNotification title="failed to delete chart version" />)
  );

  const close = useCallback(() => {
    navigate("..");
  }, [navigate]);

  return (
    <SidePanel show={true} close={close}>
      <ChartInformation
        addVersionChart={addVersionBlock}
        addButtonText="Add selected version to Designer"
        showDeleteModal={showConfirmation}
        {...templateInfo}
      />
      {shouldShowConfirmRemove && (
        <RemoveModal
          remove={deleteBlock}
          hideModal={hideConfirmation}
          entity={{
            id: chart?.id,
            displayName: chart?.name,
            type: "block chart"
          }}
        />
      )}
    </SidePanel>
  );
}
