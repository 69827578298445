import { Formik } from "formik";
import React from "react";
import toast from "react-hot-toast";
import { useOutletContext, useParams } from "react-router-dom";

import { useMutation, useQuery } from "@apollo/client";
import { Loading } from "@src/Components/Loading/Loading";
import { H2 } from "@src/Components/Text";
import { ToastNotification } from "@src/Components/ToastNotification";
import {
  EditFederationGuestInput,
  EditFederationGuestMutation,
  EditFederationGuestMutationVariables,
  FederationGuestQuery,
  FederationGuestQueryVariables
} from "@src/generated/graphql";

import EditFederationGuest from "./EditFederationGuest.graphql";
import { FederationsContext } from "./federationsContext";
import FederationGuest from "./FetchFederationGuest.graphql";
import { FederationGuestFormInput, guestSchema } from "./formSchemas";
import { GuestFormInner } from "./GuestFormInner";

export function EditGuestForm() {
  const { id } = useParams();
  const { navigateBack } = useOutletContext<FederationsContext>();

  const { data, loading } = useQuery<FederationGuestQuery, FederationGuestQueryVariables>(
    FederationGuest,
    {
      variables: { id }
    }
  );

  const [editGuestPartner] = useMutation<
    EditFederationGuestMutation,
    EditFederationGuestMutationVariables
  >(EditFederationGuest);

  const initialValues: FederationGuestFormInput = {
    id: data?.federationGuest?.id,
    federationName: data?.federationGuest?.federationName,
    operatorName: data?.federationGuest?.operatorName,
    countryCode: data?.federationGuest?.countryCode,
    mcc: data?.federationGuest?.mcc,
    mncs: data?.federationGuest?.mncs.join(","),
    parentSite: data?.federationGuest?.parentSite
  };

  if (loading) return null;
  return (
    <Formik<FederationGuestFormInput>
      initialValues={initialValues}
      validationSchema={guestSchema}
      validateOnMount
      onSubmit={async values => {
        try {
          const guestInput: EditFederationGuestInput = {
            id: values.id,
            countryCode: values.countryCode,
            mcc: values.mcc,
            mncs: values.mncs.split(",")
          };
          editGuestPartner({
            variables: { info: guestInput },
            onCompleted: navigateBack
          });
        } catch (e) {
          console.error(e);
          toast.error(<ToastNotification title="Failed to edit Guest Partner" />);
        }
      }}
    >
      {formikProps =>
        formikProps.isSubmitting ? (
          <Loading />
        ) : (
          <>
            <H2>Edit Guest Partner</H2>
            <GuestFormInner {...formikProps} navigateBack={navigateBack} isEdit />
          </>
        )
      }
    </Formik>
  );
}
