import { FormikProps } from "formik";
import React, { useCallback } from "react";
import { Outlet, useNavigate, useOutletContext } from "react-router-dom";

import { BlockDisplay } from "@src/Components/BlockSelector/BlockDisplay";
import {
  BlocksContent,
  NoBlocksMessage,
  Wrapper
} from "@src/Components/BlockSelector/BlockSelectorList";
import { CategoriesFilter } from "@src/Components/BlockSelector/CategoriesFilter";
import { BlockChart } from "@src/generated/graphql";
import { useOnResetStorage } from "@src/Hooks/onResetStorage";

import { useBlocks, useMarketplace } from "../Hooks/marketplace";
import { DesignerBlock } from "./DesignerBlock";
import { ServiceForm } from "./serialise";

function useMarketplaceProps() {
  return useOutletContext<FormikProps<ServiceForm>>();
}

export function Marketplace() {
  const navigate = useNavigate();
  const formikProps = useMarketplaceProps();
  const marketplaceProps = useMarketplace(useBlocks());
  const {
    allCategories,
    allVendors,
    selectedCategory,
    selectedVendors,
    filteredBlocks,
    view,
    loading,
    dispatch,
    repoUrl
  } = marketplaceProps;

  useOnResetStorage(() => dispatch({ type: "cleanFilters" }));

  const openBlockInformation = useCallback(
    (templateName: string, version: string) => {
      navigate(`${templateName}?v=${version}`);
    },
    [navigate]
  );

  const {
    values: { blocks },
    setFieldValue
  } = formikProps;

  const addVersionBlock = useCallback(
    (block: BlockChart) => {
      let { displayName } = block;

      let i = 2;
      while (blocks[displayName]) {
        displayName = block.displayName + ` (${i++})`;
      }
      setFieldValue("blocks", {
        ...blocks,
        [displayName]: {
          id: null,
          displayName,
          selectedVersion: block.version,
          values: block.overridesYaml || "",
          name: block.name
        }
      });
      navigate("..");
    },
    [blocks, setFieldValue, navigate]
  );

  const context = { addVersionBlock };

  return (
    <Wrapper>
      <CategoriesFilter
        loading={loading}
        allCategories={allCategories}
        allVendors={allVendors}
        selectedCategory={selectedCategory}
        selectedVendors={selectedVendors}
        dispatch={dispatch}
      />

      <div>
        <BlockDisplay {...marketplaceProps} chartType="blocks" />
        <BlocksContent view={view}>
          {filteredBlocks.length > 0 && !loading ? (
            filteredBlocks.map(b => (
              <DesignerBlock
                openBlockInformation={openBlockInformation}
                key={b.id}
                view={view}
                block={b}
                {...formikProps}
              />
            ))
          ) : (
            <NoBlocksMessage>
              {repoUrl === ""
                ? "Block repo URL must be set via the Customizations tab before using the Marketplace."
                : "There are no blocks with the applied filters."}
            </NoBlocksMessage>
          )}
        </BlocksContent>
      </div>
      <Outlet context={context} />
    </Wrapper>
  );
}
