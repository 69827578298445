import { BlockProvider } from "@src/Components/BlockSelector/BlockStyles";
import { Clickable } from "@src/Components/Buttons/Clickable";
import { H3 } from "@src/Components/Text";
import { css, styled } from "@src/Components/theme";

export const BlockListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #eee;
`;

export const StyledH3 = styled(H3)`
  padding-left: 10px;
`;

export const Item = styled.div<{ selected: boolean }>`
  cursor: pointer;
  border: 2px solid grey;
  padding: 10px;
  background-color: white;
  height: fit-content;

  ${({ selected, theme }) =>
    selected &&
    css`
      border: 2px solid ${theme.primaryLight};
      box-shadow: rgb(0 0 0 / 20%) 0px 2px 5px;
    `}

  :first-child {
    margin-top: 0;
  }
`;

export const ItemLine = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-left: 10px;
  }
`;

export const FlexRow = styled.div`
  display: flex;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
`;

export const FirstLineButtonGroup = styled.div`
  height: 30px;
  display: flex;
  gap: 10px;
  margin: auto 0 auto auto;
`;

export const StyledClickable = styled(Clickable)`
  margin: auto;
`;

export const ScrollBarStyle = css`
  //firefox
  scrollbar-width: thin;
  scrollbar-color: #5a5a5a #e9e9e9;
  //others
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background: #e9e9e9;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #5a5a5a;
  }
`;

export const ScrollDiv = styled.div`
  ${ScrollBarStyle};
  padding-right: 5px;
  overflow-y: auto;
  padding: 10px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 375px;
  max-height: calc(100vh - 75px);
`;

export const Provider = styled(BlockProvider)`
  margin-bottom: 0;
`;

export const Select = styled.select`
  width: 100%;
  box-sizing: border-box;
  border-radius: 3px;
  border: solid 1px #dddddd;
  padding: 5px;
  color: #7c8495;
  ::placeholder {
    color: #dddddd;
    opacity: 1;
  }

  :focus {
    box-shadow: 0 2px 4px 0 rgba(60, 155, 216, 0.55);
    border: solid 1px rgba(60, 155, 216, 0.53);
  }
`;
