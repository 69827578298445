import styled, { css } from "styled-components";

export const baseInputStyles = css`
  width: 100%;
  height: 45px;
  box-sizing: border-box;
  border-radius: 3px;
  border: solid 1px #dddddd;
  font-size: 14px;
  font-weight: 500;
  padding: 14px 14px 15px;
  color: #7c8495;

  ::placeholder {
    color: #dddddd;
    opacity: 1;
  }

  :focus {
    box-shadow: 0 2px 4px 0 rgba(60, 155, 216, 0.55);
    border: solid 1px rgba(60, 155, 216, 0.53);
  }

  :disabled {
    opacity: 0.5;
    font-weight: 300;
    color: #777777;
    background: white;
  }
`;

export const Input = styled.input`
  ${baseInputStyles}
`;
