import { Formik } from "formik";
import React from "react";
import toast from "react-hot-toast";
import { useOutletContext } from "react-router-dom";

import { useMutation } from "@apollo/client";
import { Loading } from "@src/Components/Loading/Loading";
import { H2 } from "@src/Components/Text";
import { ToastNotification } from "@src/Components/ToastNotification";
import {
  AddFederationHostMutation,
  AddFederationHostMutationVariables,
  FederationHostInput,
  FederationHostsQuery
} from "@src/generated/graphql";

import AddFederationHost from "./AddFederationHost.graphql";
import { FederationsContext } from "./federationsContext";
import FederationHosts from "./FetchFederationHosts.graphql";
import { FederationHostFormInput, hostSchema } from "./formSchemas";
import { HostFormInner } from "./HostFormInner";

const initialValues: FederationHostFormInput = {
  federationName: "",
  operatorName: "",
  countryCode: "",
  mcc: "",
  mncs: "",
  hostUrl: "",
  siteId: "",
  clientId: "",
  clientSecret: ""
};

export function AddHostForm() {
  const { navigateBack } = useOutletContext<FederationsContext>();

  const [addHost] = useMutation<AddFederationHostMutation, AddFederationHostMutationVariables>(
    AddFederationHost
  );

  return (
    <Formik<FederationHostFormInput>
      initialValues={initialValues}
      validationSchema={hostSchema}
      onSubmit={async values => {
        try {
          const hostInput: FederationHostInput = {
            ...values,
            mncs: values.mncs.split(",")
          };
          await addHost({
            variables: { host: hostInput },
            update(cache, { data: { createFederationHost } }) {
              cache.updateQuery<FederationHostsQuery>({ query: FederationHosts }, data => ({
                ...data,
                federationHosts: [...(data?.federationHosts || []), createFederationHost]
              }));
            },
            onCompleted: navigateBack
          });
        } catch (e) {
          console.error(e);
          toast.error(<ToastNotification title="Failed to create Host Partner" />);
        }
      }}
    >
      {formikProps =>
        formikProps.isSubmitting ? (
          <Loading />
        ) : (
          <>
            <H2>Add Host Partner</H2>
            <HostFormInner {...formikProps} navigateBack={navigateBack} />
          </>
        )
      }
    </Formik>
  );
}
