import { FormikProps } from "formik";
import React, { useCallback, useReducer } from "react";
import { NavigateFunction, useOutletContext } from "react-router-dom";

import { useQuery } from "@apollo/client";
import { ConfigWrapper } from "@src/Components/BlockSelector/ConfigStyles";
import { NoCharts } from "@src/Components/NoCharts";
import { H2 } from "@src/Components/Text";
import { styled } from "@src/Components/theme";
import { FetchTemplateChartQuery, FetchTemplateChartQueryVariables } from "@src/generated/graphql";

import { BlockConfigPanel } from "./BlockConfigPanel";
import { editorPublisherReducer, EditorStatus } from "./editorPublisherReducer";
import FetchTemplateChart from "./FetchTemplateChart.graphql";
import { emptyTemplateForm } from "./PublisherPage";
import { SelectedTemplateBlock } from "./SelectedTemplateBlock";
import { BlockPublisherForm } from "./serialise";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(1250px, 5fr);
  flex-grow: 1;
`;
export enum EditingSection {
  Values = "Block values",
  Descriptor = "Block descriptor",
  Overrides = "Block overrides"
}

interface EditorProps extends FormikProps<BlockPublisherForm> {
  navigate: NavigateFunction;
}

function useEditorProps() {
  return useOutletContext<EditorProps>();
}

export function Editor() {
  const { navigate, ...formikProps } = useEditorProps();
  const { values: newBlock, setFormikState } = formikProps;
  const removeTemplate = useCallback(() => {
    setFormikState(prev => ({
      ...prev,
      values: {
        ...emptyTemplateForm
      }
    }));
    navigate("templates");
  }, [setFormikState, navigate]);

  const { templateName, selectedVersion, overrides, values } = newBlock;

  const { data, refetch } = useQuery<FetchTemplateChartQuery, FetchTemplateChartQueryVariables>(
    FetchTemplateChart,
    {
      variables: {
        name: templateName,
        version: selectedVersion
      },
      skip: !templateName || !selectedVersion
    }
  );

  const template = data?.templateChart;

  const [editorState, editorDispatch] = useReducer(editorPublisherReducer, {
    overrides: {
      status: EditorStatus.Default,
      current: overrides,
      original: "",
      saved: overrides
    },
    values: {
      status: EditorStatus.Default,
      current: values,
      original: "",
      saved: values
    }
  });

  return (
    <Wrapper>
      <SelectedTemplateBlock
        block={newBlock}
        editorDispatch={editorDispatch}
        removeTemplate={removeTemplate}
        fetchBlockVersion={refetch}
        templateChart={template}
        {...formikProps}
      />
      {template ? (
        <BlockConfigPanel
          editorState={editorState}
          editorDispatch={editorDispatch}
          originalTemplate={template}
          {...formikProps}
        />
      ) : (
        <ConfigWrapper>
          <H2>There are no blocks to configure</H2>
          <NoCharts link="/app/publisher/templates" linkText="templates tab" entity="template" />
        </ConfigWrapper>
      )}
    </Wrapper>
  );
}
