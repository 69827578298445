import { CircleLayer, LineLayer, MapLayerMouseEvent } from "mapbox-gl";
import React, { Dispatch } from "react";
import { Layer, LayerProps, Source } from "react-map-gl";

import { InteractiveLayer } from "@src/Map/InteractiveLayer";
import { MapAction } from "@src/Map/mapReducer";

import { palette } from "./StatusDeviceMarkers";

const SOURCE_EXPANDED_DEVICES = "status-devices-expanded-cluster";
export const LAYER_DEVICE_EXPANDED_CLUSTER = "status-devices-expanded-cluster-markers";
const SOURCE_DEVICE_LINES = "status-devices-lines";
const LAYER_DEVICE_EXPANDED_LINES = "status-devices-lines-layers";
const SOURCE_DEVICE_CENTER = "status-devices-center";
const LAYER_DEVICE_CENTER_POINT = "status-devices-center-point";
const LAYER_DEVICE_EXPANDED_LABELS = "status-devices-expanded-cluster-labels";

const expandedClusterCircle: CircleLayer = {
  id: LAYER_DEVICE_EXPANDED_CLUSTER,
  type: "circle",
  filter: ["!", ["has", "point_count"]],
  paint: {
    "circle-color": [
      "case",
      ["get", "ready"],
      palette.ready,
      ["get", "down"],
      palette.down,
      ["get", "provisioning"],
      palette.neutral,
      palette.unknown
    ],
    "circle-radius": 5,
    "circle-stroke-width": 1,
    "circle-stroke-color": ["case", ["get", "down"], "#fff", "#4a4a4a"]
  },
  layout: {}
};
const expandedClusterCircleCenter: CircleLayer = {
  id: LAYER_DEVICE_CENTER_POINT,
  type: "circle",
  paint: {
    "circle-radius": 3
  },
  layout: {}
};

const expandedClusterLine: LineLayer = {
  id: LAYER_DEVICE_EXPANDED_LINES,
  type: "line",
  paint: {
    "line-color": palette.unknown,
    "line-width": 2
  },
  layout: {}
};
const expandedLabel: LayerProps = {
  id: LAYER_DEVICE_EXPANDED_LABELS,
  type: "symbol",
  paint: {
    "icon-translate": [-15, 0],
    "text-color": { type: "identity", property: "color" },
    "text-translate": [-18, 0]
  },
  layout: {
    "icon-image": "tooltip",
    "icon-text-fit": "both",
    "icon-text-fit-padding": [4, 12, 4, 12],
    "text-field": ["get", "title"],
    "text-anchor": "right"
  }
};

interface SpiderCluster {
  center: number[];
  devices: { [id: string]: GeoJSON.Feature };
}

interface SpiderDeviceMarkersProps {
  spiderCluster: SpiderCluster;
  dispatch: Dispatch<MapAction>;
}

export function SpiderDeviceMarkers({ spiderCluster, dispatch }: SpiderDeviceMarkersProps) {
  const spideredDevices: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
    type: "FeatureCollection",
    features: Object.values(spiderCluster.devices)
  };

  const spiderLines: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
    type: "FeatureCollection",
    features: Object.values(spiderCluster.devices).map(d => {
      if (d.geometry.type === "Point") {
        const pointOffset = [d.geometry.coordinates[0], d.geometry.coordinates[1]];
        return {
          ...d,
          geometry: {
            type: "LineString",
            coordinates: [spiderCluster.center, pointOffset],
            properties: {}
          }
        };
      }
    })
  };

  const spiderCenter: GeoJSON.Feature<GeoJSON.Geometry> = {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: spiderCluster.center
    },
    properties: {}
  };

  return (
    <>
      <Source id={SOURCE_DEVICE_LINES} type="geojson" data={spiderLines}>
        <Layer {...expandedClusterLine} />
      </Source>
      <Source id={SOURCE_DEVICE_CENTER} type="geojson" data={spiderCenter}>
        <Layer {...expandedClusterCircleCenter} />
      </Source>
      <Source id={SOURCE_EXPANDED_DEVICES} type="geojson" data={spideredDevices}>
        <InteractiveLayer dispatch={dispatch} {...expandedClusterCircle} />
        <Layer {...expandedLabel} />
      </Source>
    </>
  );
}

export function onDeviceExpandedClusterClick(e: MapLayerMouseEvent) {
  return e.features.find(f => f.source === SOURCE_EXPANDED_DEVICES)?.properties;
}
