import React, { useCallback } from "react";
import { Outlet, useNavigate, useOutletContext } from "react-router-dom";
import { Row } from "react-table";
import styled from "styled-components";

import { useMutation } from "@apollo/client";
import { Clickable } from "@src/Components/Buttons/Clickable";
import { RemoveModal, RemoveModalProps } from "@src/Components/Modal/RemoveModal";
import { DisableableNavLink } from "@src/Components/Navigation/DisableableNavLink";
import RemoveDevice from "@src/Devices/RemoveDevice.graphql";
import {
  Device,
  RemoveDeviceMutation,
  RemoveDeviceMutationVariables
} from "@src/generated/graphql";
import { Entity, useRemoveRow } from "@src/Hooks/removeRow";
import { ExpandedObject } from "@src/Hooks/siteTree";

export const ActionLink = styled(DisableableNavLink)`
  display: flex;
  justify-items: center;
  align-items: center;
`;

export const RemoveButton = styled(Clickable)`
  padding: 0;
`;

export const Tag = styled.span`
  display: inline-block;
  border-radius: 2px;
  font-size: 11px;
  font-weight: 600;
  line-height: 22px;
  margin-right: 7px;
  border: 1px solid #dddddd;
  background-color: white;
  padding: 0 6px;
`;

interface InventoryProps {
  selectSitePage: (siteId: string, expanded: ExpandedObject) => void;
}

function useInventoryProps() {
  return useOutletContext<InventoryProps>();
}

export interface InventoryContext {
  openRowModal: (entity: Entity, row: Row) => void;
  showRemoveModal: (device: Pick<Device, "displayName" | "id">) => void;
  returnToInventory: () => void;
  selectSitePage: (siteId: string, expanded: ExpandedObject) => void;
}

export function Inventory() {
  const {
    modalShown,
    entity: selectedDevice,
    hide: hideModal,
    show: showRemoveModal,
    openRowModal,
    onCompleted
  } = useRemoveRow();

  const { selectSitePage } = useInventoryProps();

  const [remove] = useMutation<RemoveDeviceMutation, RemoveDeviceMutationVariables>(RemoveDevice, {
    variables: { id: selectedDevice ? selectedDevice.id : "" },
    onCompleted,
    update(cache, { data: { removeDevice } }) {
      cache.evict({
        id: cache.identify({ __typename: "Device", id: removeDevice })
      });
      cache.gc();
    }
  });

  const removeModalProps: RemoveModalProps = {
    entity: selectedDevice,
    hideModal,
    remove
  };

  const navigate = useNavigate();
  const returnToInventory = useCallback(() => navigate("."), [navigate]);

  const context: InventoryContext = {
    openRowModal,
    showRemoveModal,
    returnToInventory,
    selectSitePage
  };

  return (
    <>
      <Outlet context={context} />
      {modalShown && <RemoveModal {...removeModalProps} />}
    </>
  );
}
