import React from "react";
import { Link, useOutletContext, useParams } from "react-router-dom";

import { useQuery } from "@apollo/client";
import { GhostButton } from "@src/Components/Buttons/Ghost";
import { Loading } from "@src/Components/Loading/Loading";
import { H2 } from "@src/Components/Text";
import { styled } from "@src/Components/theme";
import {
  FederationGuestQuery,
  FederationGuestQueryVariables,
  FederationHostQuery,
  FederationHostQueryVariables
} from "@src/generated/graphql";

import { FieldValues, ValuesWrapper } from "./FederationInformation";
import { FederationsContext } from "./federationsContext";
import FederationGuest from "./FetchFederationGuest.graphql";
import FederationHost from "./FetchFederationHost.graphql";
import { useParentSiteInfo } from "./parentSiteInfo";

const PartnerInfoValuesWrapper = styled(ValuesWrapper)`
  padding-left: 5%;
`;

const GoBackButton = styled(GhostButton)`
  grid-column: 2;
  justify-self: end;
`;

export function HostPartnerInfo() {
  const { id } = useParams();
  const { navigateBack } = useOutletContext<FederationsContext>();

  const { data, loading } = useQuery<FederationHostQuery, FederationHostQueryVariables>(
    FederationHost,
    {
      variables: { id }
    }
  );

  const hostPartner = data?.federationHost;
  const { parentSiteName, linkUrl } = useParentSiteInfo(data?.federationHost?.siteId);

  return (
    <>
      <H2>Host Partner Information</H2>

      {!loading ? (
        <PartnerInfoValuesWrapper>
          <FieldValues label="federation name" value={hostPartner?.federationName} />
          <FieldValues label="operator name" value={hostPartner?.operatorName} />
          <FieldValues label="Country Code" value={hostPartner?.countryCode} />
          <FieldValues label="MCC" value={hostPartner?.mcc} />
          <FieldValues label="MNCs" value={hostPartner?.mncs?.join(",")} />
          <FieldValues label="Federation URL" value={hostPartner?.hostUrl} />
          <>
            <dt>Site ID</dt>
            <dd>
              <Link to={linkUrl}>{parentSiteName}</Link>
            </dd>
          </>
          <GoBackButton onClick={navigateBack}>Go Back</GoBackButton>
        </PartnerInfoValuesWrapper>
      ) : (
        <Loading />
      )}
    </>
  );
}
export function GuestPartnerInfo() {
  const { id } = useParams();
  const { navigateBack } = useOutletContext<FederationsContext>();

  const { data, loading } = useQuery<FederationGuestQuery, FederationGuestQueryVariables>(
    FederationGuest,
    {
      variables: { id }
    }
  );

  const guestPartner = data?.federationGuest;
  const { parentSiteName, linkUrl } = useParentSiteInfo(data?.federationGuest?.parentSite);

  return (
    <>
      <H2>Guest Partner Information</H2>
      {!loading ? (
        <PartnerInfoValuesWrapper>
          <FieldValues label="federation name" value={guestPartner?.federationName} />
          <FieldValues label="operator name" value={guestPartner?.operatorName} />
          <FieldValues label="Country Code" value={guestPartner?.countryCode} />
          <FieldValues label="MCC" value={guestPartner?.mcc} />
          <FieldValues label="MNCs" value={guestPartner?.mncs.join(",")} />
          <>
            <dt>Parent Site</dt>
            <dd>
              <Link to={linkUrl}>{parentSiteName}</Link>
            </dd>
          </>
          <GoBackButton onClick={navigateBack}>Go Back</GoBackButton>
        </PartnerInfoValuesWrapper>
      ) : (
        <Loading />
      )}
    </>
  );
}
