import React, { useRef } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { useOnClickOutside } from "usehooks-ts";

import DownArrow from "../img/arrow_light_down.svg";
import UpArrow from "../img/arrow_light_up.svg";
import Download from "../img/download.svg";
import User from "../img/user.svg";
import { useLogoutHandler } from "./Auth/Logout";
import { Clickable } from "./Components/Buttons/Clickable";
import { TextButton } from "./Components/Buttons/Text";
import { HeaderLabel } from "./Components/HeaderLabel";
import { boxShadow } from "./Components/Table/Table";
import { HeaderItem } from "./Header/HeaderItem";
import HeaderOrgSelector from "./Header/HeaderOrgSelector";
import { useSelectOrg } from "./Hooks/selectOrg";
import { useToggle } from "./Hooks/toggle";
import { useSession } from "./Session";
import { ClientLogo } from "./User/ClientLogo";

const headerPadding = 32;

const HeaderWrapper = styled.header`
  grid-area: header;
  height: 50px;
  background: white;
  display: flex;
  gap: 10px;
  align-items: stretch;
  justify-content: end;
  padding: 0 ${headerPadding}px;
  border: 1px solid #dddddd;
`;

const UserMenu = styled.div`
  position: absolute;
  top: 50px;
  right: 0;
  background: white;
  border: 1px solid #dddddd;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 14px 16px;
  box-sizing: border-box;
  cursor: default;
  z-index: 1;
  ${boxShadow}
`;

const DownloadsMenu = styled(UserMenu)`
  width: 280px;
`;

const Separator = styled.hr`
  color: #dddddd;
  width: 100%;
  margin: 14px 0;
`;

const SeparatorUser = styled(Separator)`
  &:first-of-type {
    margin-top: auto;
  }
`;

const linkStyles = css`
  font-size: 14px;
  letter-spacing: 0.2px;
  color: #666666;
  text-decoration: none;
`;

const MenuA = styled.a`
  ${linkStyles}
`;
const MenuLink = styled(Link)`
  ${linkStyles}
`;

const MenuButton = styled(TextButton)`
  font-size: 14px;
  letter-spacing: 0.2px;
  color: #666666;
`;

export function Header() {
  const { state: isOpenUser, toggle: toggleMenuUser, off: closeMenuUser } = useToggle();
  const {
    state: isOpenDownload,
    toggle: toggleMenuDownloads,
    off: closeMenuDownloads
  } = useToggle();
  const { session } = useSession();
  const userName = session?.identity?.traits?.name;

  const { selectOrg } = useSelectOrg();
  const logout = useLogoutHandler();

  const downloadsMenu = useRef();
  useOnClickOutside(downloadsMenu, closeMenuDownloads);

  const userMenu = useRef();
  useOnClickOutside(userMenu, closeMenuUser);

  return (
    <HeaderWrapper>
      <HeaderOrgSelector selectOrg={selectOrg} />
      <ClientLogo />
      <HeaderItem ref={downloadsMenu}>
        <Clickable onClick={toggleMenuDownloads}>
          <Download height={20} width={20} />
          <HeaderLabel>Downloads</HeaderLabel>
          {isOpenDownload ? (
            <UpArrow height={14} width={14} />
          ) : (
            <DownArrow height={14} width={14} />
          )}
        </Clickable>
        {isOpenDownload ? (
          <DownloadsMenu>
            <MenuA href="/upload/nztp.iso" title="download nZTP Server Bootstrap ISO" download>
              nZTP Server Bootstrap ISO
            </MenuA>
            <Separator />
            <MenuA href="/upload/provision.iso" title="download Provisioner ISO" download>
              Provisioner ISO (Beta)
            </MenuA>
            <Separator />
            <MenuA
              href="/upload/onboard.tgz"
              title="download K8s Cloudlet onboarding helm chart"
              download
            >
              K8s Cloudlet onboarding helm chart
            </MenuA>
            <Separator />
            <MenuA
              href="/upload/onboard.ova"
              title="download vSphere Cluster onboarding OVA"
              download
            >
              vSphere Cluster onboarding OVA
            </MenuA>
          </DownloadsMenu>
        ) : null}
      </HeaderItem>
      <HeaderItem ref={userMenu}>
        <Clickable onClick={toggleMenuUser}>
          <User height={30} width={30} />
          <HeaderLabel>{userName}</HeaderLabel>
          {isOpenUser ? <UpArrow height={14} width={14} /> : <DownArrow height={14} width={14} />}
        </Clickable>
        {isOpenUser ? (
          <UserMenu>
            <User height={160} width={160} />
            <SeparatorUser />
            <MenuLink to="user/settings">Profile settings</MenuLink>
            <SeparatorUser />
            <MenuButton onClick={logout}>Sign out</MenuButton>
          </UserMenu>
        ) : null}
      </HeaderItem>
    </HeaderWrapper>
  );
}
