import { useMemo } from "react";

import WebMercatorViewport from "@math.gl/web-mercator";

import { MapState } from "../mapReducer";

export function useBbox(mapState: MapState) {
  return useMemo(() => {
    if (mapState.longitude == null && mapState.longitude == null) return null;
    const viewport = new WebMercatorViewport(mapState);
    const [sw, ne] = viewport.getBounds();
    return {
      nw: {
        lat: sw[1],
        lng: sw[0]
      },
      se: {
        lat: ne[1],
        lng: ne[0]
      }
    };
  }, [mapState]);
}
