export const workflows = [
  "edge-node-ubuntu-base-nearbyone",
  "edge-node-centos-base-nearbyone",
  "5g-in-a-box-casa-vanilla",
  "5g-in-a-box-casa-rancher",
  "edge-node-centos-base-openness",
  "edge-node-centos-base-saguna",
  "edge-node-ubuntu-base",
  "edge-node-centos-base",
  "discover-base",
  "packstack-base",
  "rancher-base",
  "wrcp-base"
];
