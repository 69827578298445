import React, { Fragment, useEffect, useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";

import { FlowError } from "@ory/client";
import { DescriptionList } from "@src/Components/DescriptionList";
import { Title } from "@src/Components/Modal/Modal";

import { fetchErrors } from "./kratos";

export function Error() {
  const [requestResponse, setRequestResponse] = useState<FlowError>();
  const [endTimeOut, setEndTimeout] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const error = searchParams.get("error");

    if (error) {
      fetchErrors(error).then(errs => {
        if (errs) {
          setRequestResponse(errs);
        }
      });
    }
  }, [searchParams]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setEndTimeout(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, [searchParams]);

  return (
    <>
      {endTimeOut ? (
        <Navigate to="/" />
      ) : (
        <>
          <Title>Error</Title>
          <DescriptionList>
            {Object.entries(requestResponse?.error || {}).map(([key, value]) => (
              <Fragment key={key}>
                <dt>{key}</dt>
                <dd>{value}</dd>
              </Fragment>
            ))}
          </DescriptionList>
          <p>
            Redirecting to home page, click <a href="/">here</a> if you are not automatically
            redirected
          </p>
        </>
      )}
    </>
  );
}
