import React from "react";

import { Tab } from "@src/Components/Navigation/Tab";
import { Role } from "@src/generated/graphql";
import { useUserRole } from "@src/Hooks/userRole";

export function TabBar() {
  const { userRole } = useUserRole();
  const isViewer = (userRole ?? Role.Viewer) === Role.Viewer;
  return (
    <nav>
      <Tab to="overview">Overview</Tab>
      <Tab to="inventory">Inventory</Tab>
      <Tab disabled={isViewer} to="provision">
        Provisioning (beta)
      </Tab>
      <Tab to="services">Services</Tab>
    </nav>
  );
}
