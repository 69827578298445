import mapboxgl from "mapbox-gl";
import React, { Dispatch, useCallback, useEffect, useRef, useState } from "react";
import { MapLayerMouseEvent, MapRef } from "react-map-gl";

import { lngLatToWorld, worldToLngLat } from "@math.gl/web-mercator";
import { styled } from "@src/Components/theme";
import { Device } from "@src/generated/graphql";
import {
  clusterFromEvent,
  LAYER_DEVICE_CIRCLES,
  LAYER_DEVICE_CLUSTERS,
  onDeviceMarkersClick,
  StatusDeviceMarkers
} from "@src/Infrastructure/StatusDeviceMarkers";
import { MapboxMap } from "@src/Map/MapboxMap";
import { MapAction, MapState } from "@src/Map/mapReducer";
import { useMapSettings } from "@src/Map/mapState";

import {
  LAYER_DEVICE_EXPANDED_CLUSTER,
  onDeviceExpandedClusterClick,
  SpiderDeviceMarkers
} from "./SpiderDeviceMarkers";

const Wrapper = styled.div`
  height: 600px;
`;

interface MapViewProps {
  mapState: MapState;
  dispatch: Dispatch<MapAction>;
  onMarkerClick: (deviceId: string) => void;
  devices: Device[];
}

interface SpiderDeviceState {
  center: number[];
  devices: { [id: string]: GeoJSON.Feature };
}

export function MapView({ mapState, dispatch, onMarkerClick, devices }: MapViewProps) {
  const mapIsReady = useMapSettings(mapState, dispatch);
  useEffect(() => {
    if (mapIsReady && devices.length > 0) {
      const bounds = devices.reduce(
        (bounds, { position }) => bounds.extend([position.lng, position.lat]),
        new mapboxgl.LngLatBounds()
      );
      dispatch({
        type: "setDeviceBounds",
        bounds: [bounds.getSouthWest(), bounds.getNorthEast()]
      });
    }
  }, [dispatch, mapIsReady, devices]);

  const mapRef = useRef<MapRef>();

  const [spiderCluster, setSpiderCluster] = useState<SpiderDeviceState>({
    center: [],
    devices: {}
  });

  const renderSpiderCluster = useCallback(
    (geometry: GeoJSON.Geometry, features: GeoJSON.Feature<GeoJSON.Geometry>[]) => {
      if (geometry.type === "Point") {
        const { coordinates } = geometry;
        const radius = 0.001;
        const angle_step = (2 * Math.PI) / features.length;
        const center = [coordinates[0], coordinates[1]];
        const pixelCenter = lngLatToWorld(center);
        const devices: { [id: string]: GeoJSON.Feature } = {};
        features.forEach((f, i) => {
          const angle = angle_step * i;
          const pixelOffset = [
            pixelCenter[0] + Math.cos(angle) * radius,
            pixelCenter[1] + Math.sin(angle) * radius
          ];
          const realWorldOffset = worldToLngLat(pixelOffset);
          devices[f.properties.id] = {
            ...f,
            geometry: {
              type: "Point",
              coordinates: realWorldOffset
            }
          };
        });

        setSpiderCluster({ center, devices });
      }
    },
    []
  );

  const zoomOnDeviceCluster = useCallback(
    (geometry: GeoJSON.Geometry, zoom: number) => {
      if (geometry.type === "Point") {
        dispatch({
          type: "setCenterZoom",
          center: {
            lng: geometry.coordinates[0],
            lat: geometry.coordinates[1]
          },
          zoom: zoom
        });
      }
    },
    [dispatch]
  );

  const onClick = useCallback(
    (e: MapLayerMouseEvent) => {
      const dev = onDeviceMarkersClick(e) || onDeviceExpandedClusterClick(e);
      if (dev?.id) {
        onMarkerClick(dev.id);
        return;
      }
      const cluster = clusterFromEvent(e);
      if (cluster) {
        const source = mapRef.current.getSource(cluster.source);
        if (source.type === "geojson") {
          source.getClusterExpansionZoom(
            cluster.properties.cluster_id,
            (err: Record<string, unknown>, zoom: number) => {
              if (!err) {
                zoomOnDeviceCluster(cluster.geometry, Math.min(zoom, 15));
                if (zoom > 15) {
                  // Set spiderGeojson
                  source.getClusterLeaves(
                    cluster.properties.cluster_id,
                    cluster.properties.point_count,
                    0,
                    (err, features) => {
                      if (err) return;
                      renderSpiderCluster(cluster.geometry, features);
                    }
                  );
                }
              }
            }
          );
        }
        return;
      }
    },
    [onMarkerClick, renderSpiderCluster, zoomOnDeviceCluster]
  );

  const statusMarkDevices = (devices || []).filter(d => !spiderCluster.devices[d.id]);

  const cursorHoverLayerIds = [
    LAYER_DEVICE_CIRCLES,
    LAYER_DEVICE_CLUSTERS,
    LAYER_DEVICE_EXPANDED_CLUSTER
  ];

  return (
    <Wrapper>
      <MapboxMap
        ref={mapRef}
        onClick={onClick}
        mapState={mapState}
        dispatch={dispatch}
        interactiveLayerIds={cursorHoverLayerIds}
      >
        <StatusDeviceMarkers dispatch={dispatch} devices={statusMarkDevices} />
        {spiderCluster && <SpiderDeviceMarkers spiderCluster={spiderCluster} dispatch={dispatch} />}
      </MapboxMap>
    </Wrapper>
  );
}
