import { Form, Formik, FormikProps } from "formik";
import { GraphQLError } from "graphql";
import React, { Dispatch, useCallback, useEffect, useReducer, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";

import { isApolloError, useMutation } from "@apollo/client";
import { ButtonRowCustom, ButtonRowDefault } from "@src/Components/Buttons/ButtonRow";
import { DescriptionList } from "@src/Components/DescriptionList";
import {
  ErrorLine,
  FieldLabel,
  Fieldset,
  InputErrors,
  InputField,
  PaddedError
} from "@src/Components/Input/InputGroup";
import { Loading } from "@src/Components/Loading/Loading";
import { H2, H3 } from "@src/Components/Text";
import {
  DeviceStatus,
  FetchProvisionsQuery,
  FetchProvisionsQueryVariables,
  LatLng,
  ProvisionDeviceMutation,
  ProvisionDeviceMutationVariables
} from "@src/generated/graphql";
import { useSiteTree } from "@src/Hooks/siteTree";
import { useToggle } from "@src/Hooks/toggle";
import { SiteTree } from "@src/Infrastructure/FileTree/SiteTree";
import { initialMapState, MapAction, mapReducer, MapState } from "@src/Map/mapReducer";
import { useMapSettings } from "@src/Map/mapState";
import { Shape } from "@src/yupTypes";

import { ErrorContainer } from "../../Components/ErrorContainer";
import { PositionSelector } from "../PositionSelector";
import FetchProvisions from "./FetchProvisions.graphql";
import { Inventory } from "./Inventory";
import { Profile, ProfileSelect } from "./ProfileSelect";
import { ProvisionContext } from "./Provision";
import ProvisionDevice from "./ProvisionDevice.graphql";

interface ProvisionValues {
  displayName: string;
  site: string;
  position: {
    lat: number;
    lng: number;
  };
  provisionerSite: string;
  inventory: string;
  profile: Profile;
}

const schema = Yup.object<Shape<ProvisionValues>>().shape({
  displayName: Yup.string().required("display name is required"),
  site: Yup.string().required(),
  position: Yup.object<Shape<LatLng>>().shape({
    lat: Yup.number().label("latitude").required("latitude is required"),
    lng: Yup.number().label("longitude").required("longitude is required")
  }),
  provisionerSite: Yup.string().required(),
  inventory: Yup.string().required(),
  profile: Yup.object<Shape<Profile>>().shape({
    name: Yup.string().required(),
    version: Yup.string().required()
  })
});

export function ProvisionDeviceForm() {
  const { returnToProvision: onComplete } = useOutletContext<ProvisionContext>();
  const { siteId } = useParams<{ siteId: string }>();
  const [submitErrors, setSubmitErrors] = useState<Readonly<GraphQLError[]>>();
  const [mapState, dispatch] = useReducer(mapReducer, initialMapState);
  const { longitude: lng, latitude: lat } = mapState;
  const mapIsReady = useMapSettings(mapState, dispatch);

  const { state: showReviewScreen, toggle: toggleReviewScreen } = useToggle();

  const initialValues: ProvisionValues = {
    displayName: "",
    site: siteId,
    provisionerSite: "",
    position: {
      lat,
      lng
    },
    inventory: "",
    profile: null
  };

  const [provision] = useMutation<ProvisionDeviceMutation, ProvisionDeviceMutationVariables>(
    ProvisionDevice
  );

  const onSubmit = useCallback(
    async (values: ProvisionValues) => {
      try {
        await provision({
          variables: {
            input: {
              displayName: values.displayName,
              position: values.position,
              site: values.site,
              provisionerSite: values.provisionerSite,
              chartName: values.profile.name,
              chartVersion: values.profile.version,
              provisionInventory: values.inventory
            }
          },
          update(
            cache,
            {
              data: {
                provision: { id }
              }
            }
          ) {
            cache.updateQuery<FetchProvisionsQuery, FetchProvisionsQueryVariables>(
              {
                query: FetchProvisions,
                variables: { id: siteId }
              },
              data => ({
                ...data,
                provisions: [
                  ...(data?.provisions || []),
                  {
                    __typename: "Provision",
                    id,
                    displayName: values.displayName,
                    status: DeviceStatus.Unknown
                  }
                ]
              })
            );
          }
        });
        onComplete();
      } catch (e) {
        if (isApolloError(e)) {
          setSubmitErrors(e.graphQLErrors);
        } else {
          console.error(e);
        }
      }
    },
    [onComplete, provision, siteId]
  );

  return !mapIsReady ? (
    <Loading />
  ) : (
    <Formik<ProvisionValues>
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {formikProps =>
        formikProps.isSubmitting ? (
          <Loading />
        ) : showReviewScreen ? (
          <ReviewForm
            {...formikProps}
            toggleReviewScreen={toggleReviewScreen}
            submitErrors={submitErrors}
          />
        ) : (
          <FormInner
            {...formikProps}
            mapState={mapState}
            dispatch={dispatch}
            toggleReviewScreen={toggleReviewScreen}
            onComplete={onComplete}
          />
        )
      }
    </Formik>
  );
}

const LocationTitle = styled(H3)`
  margin-bottom: 0;
`;

interface FormInnerProps extends FormikProps<ProvisionValues> {
  mapState: MapState;
  dispatch: Dispatch<MapAction>;
  toggleReviewScreen: () => void;
  onComplete: () => void;
}

function FormInner({
  mapState,
  dispatch,
  toggleReviewScreen,
  onComplete,
  ...formikProps
}: FormInnerProps) {
  const { errors, values, setFieldValue, isValid, isSubmitting } = formikProps;

  const setPosition = useCallback(
    (center: LatLng) => {
      setFieldValue("position", center, false);
    },
    [setFieldValue]
  );

  const setInventory = useCallback(
    (inventory: string) => {
      setFieldValue("inventory", inventory);
    },
    [setFieldValue]
  );

  const setProfile = useCallback(
    (profile: Profile) => {
      setFieldValue("profile", profile);
    },
    [setFieldValue]
  );

  const treeProps = useSiteTree({ navigate: null, ancestors: null });
  useEffect(() => {
    setFieldValue("provisionerSite", treeProps.state.selected);
  }, [setFieldValue, treeProps.state.selected]);

  return (
    <>
      <H2>Provisioning</H2>

      <Form>
        <Fieldset>
          <FieldLabel htmlFor="displayName">display name</FieldLabel>
          <InputField name="displayName" errors={errors} />
        </Fieldset>

        <ProfileSelect profile={values.profile} setProfile={setProfile} />
        <PaddedError>
          {errors.profile && <ErrorLine>profile is a required field</ErrorLine>}
        </PaddedError>

        <Inventory inventory={values.inventory} setInventory={setInventory} />
        <PaddedError>
          <InputErrors errors={errors} name="inventory" />
        </PaddedError>

        <H3>Provisioner site</H3>
        <SiteTree readOnly {...treeProps} />
        <PaddedError>
          {errors.provisionerSite && <ErrorLine>provisioner site is a required field</ErrorLine>}
        </PaddedError>

        <LocationTitle>Location</LocationTitle>
        <PositionSelector
          fieldValue={values.position}
          setFieldValue={setPosition}
          mapState={mapState}
          dispatch={dispatch}
        />
        <Fieldset>
          <ButtonRowCustom
            onClickBack={onComplete}
            onClickSubmit={toggleReviewScreen}
            isValid={isValid}
            isSubmitting={isSubmitting}
          />
        </Fieldset>
      </Form>
    </>
  );
}

interface ReviewFormProps extends FormikProps<ProvisionValues> {
  toggleReviewScreen: () => void;
  submitErrors: readonly GraphQLError[];
}

function ReviewForm({ values, isSubmitting, toggleReviewScreen, submitErrors }: ReviewFormProps) {
  return (
    <>
      <H2>Review device details</H2>
      {submitErrors ? (
        <ErrorContainer>
          <H3>Failed to add device</H3>
          <p>Please review the details and try again:</p>
          <ul>
            {submitErrors.map((err, i) => (
              <li key={i}>{err.message}</li>
            ))}
          </ul>
        </ErrorContainer>
      ) : null}
      <Form>
        <DescriptionList>
          <dt>Display Name</dt>
          <dd>{values.displayName}</dd>
          <dt>Position</dt>
          <dd>
            {values.position.lat}, {values.position.lng}
          </dd>
        </DescriptionList>
        <ButtonRowDefault
          onClickBack={toggleReviewScreen}
          isSubmitting={isSubmitting}
          backText="Back"
          submitText="Add"
        />
      </Form>
    </>
  );
}
