import React, { useCallback, useState } from "react";

import { ApolloError, MutationFunctionOptions } from "@apollo/client";
import { ConfirmRemove } from "@src/Components/ConfirmRemove";
import { ErrorMessage } from "@src/Components/ErrorMessage";
import { Modal, Title } from "@src/Components/Modal/Modal";
import { Entity } from "@src/Hooks/removeRow";

export interface RemoveModalProps {
  entity: Entity;
  hideModal: () => void;
  remove: (options?: MutationFunctionOptions) => void;
}

export function RemoveModal({ entity, hideModal, remove: removeMutation }: RemoveModalProps) {
  const [removeDeviceError, setRemoveDeviceError] = useState("");
  const remove = useCallback(() => {
    removeMutation({
      onError: (err: ApolloError) => {
        setRemoveDeviceError(err.message);
      }
    });
  }, [removeMutation]);

  return (
    <>
      {entity && (
        <Modal show onOutsideClick={hideModal}>
          <Title>Confirm {entity.type} removal</Title>
          <ConfirmRemove
            entityName={entity.displayName}
            entityType={entity.type}
            actionName="delete"
            action={remove}
            cancel={hideModal}
          />
          {removeDeviceError && <ErrorMessage error={removeDeviceError} />}
        </Modal>
      )}
    </>
  );
}
