import { Form, Formik, FormikProps } from "formik";
import React from "react";
import toast from "react-hot-toast";
import { useOutletContext } from "react-router-dom";
import * as Yup from "yup";

import { useMutation } from "@apollo/client";
import { ButtonRowDefault } from "@src/Components/Buttons/ButtonRow";
import { Fieldset, InputGroup } from "@src/Components/Input/InputGroup";
import { Loading } from "@src/Components/Loading/Loading";
import { H2 } from "@src/Components/Text";
import { ToastNotification } from "@src/Components/ToastNotification";
import { EditFederationMutation, EditFederationMutationVariables } from "@src/generated/graphql";
import { Shape } from "@src/yupTypes";

import { CountryCodeSelect } from "./CountryCodeSelect";
import EditFederation from "./EditFederation.graphql";
import { FederationsContext } from "./federationsContext";
import { IdentityForm, useOrgIdentity } from "./orgIdentity";

const schema = Yup.object<Shape<IdentityForm>>().shape({
  operatorName: Yup.string().required("Name must not be empty"),
  countryCode: Yup.string().required("A country code must be selected"),
  mcc: Yup.string()
    .required("MCC must not be empty")
    .matches(/^\d{3}$/, "MCC must be a 3 digit number"),
  mncs: Yup.string()
    .required("MNCs must not be empty")
    .matches(/^\d{2,3}(,\d{2,3})*$/, "MNCs must be a comma-separated list of 2-3 digit numbers"),
  federationUrl: Yup.string()
    .required("Federation URL must not be empty")
    .url("Federation URL must be a valid URL")
});

export function IdentityForm() {
  const { navigateBack } = useOutletContext<FederationsContext>();

  const { orgIdentity: initialValues, orgId } = useOrgIdentity();

  const [editIdentity] = useMutation<EditFederationMutation, EditFederationMutationVariables>(
    EditFederation
  );

  return initialValues ? (
    <Formik<IdentityForm>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnMount={true}
      onSubmit={async values => {
        try {
          editIdentity({
            variables: {
              id: orgId,
              fed: {
                operatorName: values.operatorName,
                countryCode: values.countryCode,
                mcc: values.mcc,
                mncs: values.mncs.split(","),
                federationUrl: values.federationUrl
              }
            }
          });
          navigateBack();
        } catch (e) {
          console.error(e);
          toast.error(<ToastNotification title="Failed to edit Operator Identity" />);
        }
      }}
    >
      {formikProps =>
        formikProps.isSubmitting ? (
          <Loading />
        ) : (
          <FormInner {...formikProps} navigateBack={navigateBack} />
        )
      }
    </Formik>
  ) : (
    <Loading />
  );
}

interface FormInnerProps extends FormikProps<IdentityForm> {
  navigateBack: () => void;
}

function FormInner({ navigateBack, ...formikProps }: FormInnerProps) {
  const { values, errors } = formikProps;
  const { countryCode } = values;

  return (
    <>
      <H2>Edit Operator Identity</H2>
      <Form>
        <Fieldset>
          <InputGroup label="Operator Name" name="operatorName" errors={errors} />
          <CountryCodeSelect selectedCode={countryCode} errors={errors} />
          <InputGroup label="MCC" name="mcc" errors={errors} />
          <InputGroup label="MNCs" name="mncs" errors={errors} />
          <InputGroup label="Federation URL" name="federationUrl" errors={errors} />

          <ButtonRowDefault
            onClickBack={navigateBack}
            isValid={formikProps.isValid}
            isSubmitting={formikProps.isSubmitting}
            backText="Cancel"
            submitText="Save"
          />
        </Fieldset>
      </Form>
    </>
  );
}
