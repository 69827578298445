import { FormikErrors } from "formik";
import React from "react";
import styled from "styled-components";

import { InfoIcon } from "../Components/InfoIcon";
import { ServiceForm } from "./serialise";

interface MissingStepsProps {
  errors: FormikErrors<ServiceForm>;
}

const I = styled(InfoIcon)`
  margin-left: -2px;
`;

export function MissingSteps({ errors }: MissingStepsProps) {
  const hasDesignerErrors = Object.values(errors.blocks || {}).some(b => b?.values);

  return (
    hasDesignerErrors && (
      <div>
        <I>In order to deploy a service, the following steps must be completed</I>
        <ul>{hasDesignerErrors && <li>Some blocks contain invalid/missing fields</li>}</ul>
      </div>
    )
  );
}
