import React from "react";

import { Loading } from "@src/Components/Loading/Loading";

import { FieldValues, ValuesWrapper } from "./FederationInformation";
import { useOrgIdentity } from "./orgIdentity";

export function IdentityDetails() {
  const { orgIdentity, loading } = useOrgIdentity();

  if (loading || !orgIdentity) return <Loading />;

  const { operatorName, countryCode, mcc, mncs, federationUrl } = orgIdentity;
  return (
    <ValuesWrapper>
      <FieldValues label="Operator Name" value={operatorName} />
      <FieldValues label="Country Code" value={countryCode} />
      <FieldValues label="MCC" value={mcc} />
      <FieldValues label="MNCs" value={mncs} />
      <FieldValues label="Federation URL" value={federationUrl} />
    </ValuesWrapper>
  );
}
