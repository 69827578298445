import styled from "styled-components";

export const Td = styled.td`
  padding-left: 25px;
  background: white;
  font-size: 14px;
  font-weight: 600;
  color: #666666;
  letter-spacing: 0.6px;
  border-style: solid;
  border-width: 0 0 1px;
  border-color: rgba(204, 204, 204, 0.5);
`;
