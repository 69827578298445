import { Formik } from "formik";
import { GraphQLError } from "graphql";
import React, { useEffect, useReducer, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";

import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { ErrorContainer } from "@src/Components/ErrorContainer";
import { Loading } from "@src/Components/Loading/Loading";
import {
  EditableDeviceQuery,
  EditableDeviceQueryVariables,
  UpdateCrossplaneDeviceMutation,
  UpdateCrossplaneDeviceMutationVariables
} from "@src/generated/graphql";
import { useToggle } from "@src/Hooks/toggle";
import { InventoryContext } from "@src/Infrastructure/sites/Inventory";
import { initialMapState, mapReducer } from "@src/Map/mapReducer";
import { useMapSettings } from "@src/Map/mapState";

import EditableDevice from "../EditableDevice.graphql";
import { CrossplaneDeviceFormInner, CrossplaneReviewForm } from "./CrossplaneFormComponents";
import {
  EditCrossplaneDeviceValues,
  initFieldValues,
  updateCrossplaneSchema as schema
} from "./crossplaneUtils";
import UpdateCrossplaneDevice from "./UpdateCrossplaneDevice.graphql";

export function EditCrossplaneDeviceForm() {
  const { returnToInventory: onComplete } = useOutletContext<InventoryContext>();
  const { deviceId: id } = useParams<{ deviceId: string }>();

  const [submitErrors, setSubmitErrors] = useState<Readonly<GraphQLError[]>>();

  const [mapState, dispatch] = useReducer(mapReducer, initialMapState);
  const mapIsReady = useMapSettings(mapState, dispatch);

  const { data, loading } = useQuery<EditableDeviceQuery, EditableDeviceQueryVariables>(
    EditableDevice,
    { variables: { id }, fetchPolicy: "network-only" }
  );
  const device = data?.devices?.devices?.[0];

  useEffect(() => {
    if (!mapIsReady && device) {
      dispatch({ type: "init", center: device.position, zoom: 15 });
    }
  }, [mapIsReady, device]);

  const { state: showReviewScreen, toggle: toggleReviewScreen } = useToggle();

  const [updateCrossplane] = useMutation<
    UpdateCrossplaneDeviceMutation,
    UpdateCrossplaneDeviceMutationVariables
  >(UpdateCrossplaneDevice);

  if (loading) return <Loading />;
  if (!device) return <ErrorContainer>failed to load device info</ErrorContainer>;

  const currentConfig = initFieldValues(device?.crossplaneChart?.latestConfig || [], true);
  const initialValues: EditCrossplaneDeviceValues = {
    displayName: device?.displayName,
    config: currentConfig,
    position: device?.position
  };

  return (
    <Formik<EditCrossplaneDeviceValues>
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={async values => {
        try {
          const configTouched = JSON.stringify(currentConfig) !== JSON.stringify(values.config);
          const config = values.config.map(({ label, value }) => ({
            label,
            value: String(value)
          }));
          updateCrossplane({
            variables: {
              input: {
                id: device.id,
                displayName: values.displayName,
                providerConfig: configTouched ? config : null,
                position: values.position,
                deviceSite: device.site
              }
            }
          });
          onComplete();
        } catch (e) {
          const err: ApolloError = e;
          setSubmitErrors(err.graphQLErrors);
        }
      }}
    >
      {formikProps =>
        formikProps.isSubmitting ? (
          <Loading />
        ) : showReviewScreen ? (
          <CrossplaneReviewForm
            {...formikProps}
            toggleReviewScreen={toggleReviewScreen}
            submitErrors={submitErrors}
            isEdit
          />
        ) : (
          <CrossplaneDeviceFormInner
            {...formikProps}
            mapState={mapState}
            dispatch={dispatch}
            toggleReviewScreen={toggleReviewScreen}
            onComplete={onComplete}
            isEdit
            originalConfigValues={device?.crossplaneChart?.originalConfig}
          />
        )
      }
    </Formik>
  );
}
