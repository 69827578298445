import React from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { useElementSize } from "usehooks-ts";

import Clock from "@img/clock.svg";
import { Clickable } from "@src/Components/Buttons/Clickable";
import { baseInputStyles } from "@src/Components/Input/Input";
import { useToggle } from "@src/Hooks/toggle";

import { dateToFormattedString } from "./dateUtils";
import { TimePickerModalBody } from "./TimePickerModalBody";

const DateRangePickerWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
`;

const TogglePickerButton = styled(Clickable)`
  ${baseInputStyles}
  display: flex;
  gap: 6px;
  width: auto;
  padding: 8px 16px;
`;

const TextButtonWrapper = styled.div`
  display: flex;
  align-items: baseline;
  gap: 10px;
`;

const PickerLabel = styled.label`
  font-weight: 600;
  font-size: small;
`;

const ClockIcon = styled(Clock)`
  height: 20px;
  width: 20px;
`;

export function DateRangePicker() {
  const { state, off: close, on: open } = useToggle();
  const [searchParams] = useSearchParams();

  const [buttonRef, position] = useElementSize<HTMLButtonElement>();

  const from = Number(searchParams.get("from"));
  const to = Number(searchParams.get("to"));
  const alias = searchParams.get("alias");

  const timeRangeString = alias
    ? alias.replace(/-/g, " ")
    : `${dateToFormattedString(Number(from))} - ${dateToFormattedString(Number(to))}`;

  return (
    <TextButtonWrapper>
      <PickerLabel>Showing logs from:</PickerLabel>
      <DateRangePickerWrapper>
        <TogglePickerButton ref={buttonRef} onClick={open}>
          {timeRangeString}
          <ClockIcon />
        </TogglePickerButton>
        <TimePickerModalBody show={state} closeModal={close} position={position} />
      </DateRangePickerWrapper>
    </TextButtonWrapper>
  );
}
