import { useCallback } from "react";

import { useMutation } from "@apollo/client";
import {
  BlockChart,
  DeleteBlockMutation,
  DeleteBlockMutationVariables
} from "@src/generated/graphql";

import DeleteBlock from "./DeleteBlock.graphql";

export function useDeleteBlockChart(
  chart: BlockChart,
  onCompleted: () => void,
  onError: () => void
) {
  const [deleteBlock] = useMutation<DeleteBlockMutation, DeleteBlockMutationVariables>(
    DeleteBlock,
    {
      variables: {
        name: chart?.name,
        version: chart?.version
      }
    }
  );
  return useCallback(
    () =>
      deleteBlock({
        onCompleted,
        onError,
        update(cache) {
          cache.evict({
            id: cache.identify({ __typename: "BlockChart", id: chart?.id })
          });
          cache.gc();
        }
      }),
    [chart?.id, deleteBlock, onCompleted, onError]
  );
}
