import React, { DOMAttributes } from "react";
import styled from "styled-components";

const Cross = styled.span`
  position: absolute;
  right: 25px;
  top: 25px;
  cursor: pointer;
  font-size: 20px;
`;

export function DismissModalButton(props: DOMAttributes<HTMLSpanElement>) {
  return (
    <Cross title="close modal" {...props}>
      ✕
    </Cross>
  );
}
