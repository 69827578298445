import React, { useCallback } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

import { useMutation } from "@apollo/client";
import { RemoveSiteMutation, RemoveSiteMutationVariables } from "@src/generated/graphql";
import { useRemoveRow } from "@src/Hooks/removeRow";
import { SiteTreeProps } from "@src/Hooks/siteTree";

import { useFetchInfrastructureSiteInfo } from "./fetchInfrastructureSiteInfo";
import { InfrastructureInformation } from "./InfrastructureInformation";
import RemoveSite from "./RemoveSite.graphql";

function useSiteTreeProps() {
  return useOutletContext<SiteTreeProps>();
}

export function InfrastructureSiteInformation() {
  const { siteId, orgId } = useParams();
  const treeProps = useSiteTreeProps();
  const navigate = useNavigate();

  const informationProps = useFetchInfrastructureSiteInfo(siteId, treeProps.expandTree);
  const { data } = informationProps;

  const { modalShown, entity: selectedSite, hide: hideModal, show: showModal } = useRemoveRow();

  const redirect = useCallback(() => {
    const ancestors = data?.ancestors || [];
    if (ancestors.length)
      navigate(`/app/infrastructure/org/${orgId}/site/${ancestors[ancestors.length - 1].id}`);
    else navigate(`/app/infrastructure/org/${orgId}`);
    hideModal();
  }, [data?.ancestors, hideModal, navigate, orgId]);

  const [remove] = useMutation<RemoveSiteMutation, RemoveSiteMutationVariables>(RemoveSite, {
    variables: { id: siteId },
    onCompleted: redirect,
    update(cache, { data: { removeSite } }) {
      cache.evict({
        id: cache.identify({ __typename: "Site", id: removeSite })
      });
      cache.gc();
    }
  });

  return (
    <InfrastructureInformation
      remove={remove}
      modalShown={modalShown}
      selectedSite={selectedSite}
      hideModal={hideModal}
      showModal={showModal}
      {...informationProps}
      {...treeProps}
    />
  );
}
