import React from "react";

import Collapse from "@img/collapse.svg";
import { Title } from "@src/Components/BlockSelector/BlockSelectorList";
import { Clickable } from "@src/Components/Buttons/Clickable";
import { CheckboxSlider, CheckBoxWrapper, CheckLabel } from "@src/Components/Input/CheckboxSlider";
import { styled } from "@src/Components/theme";
import { useInfrastructureSettings } from "@src/Hooks/infrastructure";
import { SiteTreeProps } from "@src/Hooks/siteTree";

import { SiteTree as Tree } from "./SiteTree";

const Wrapper = styled.div`
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const ActionButton = styled(Clickable)`
  position: relative;
  margin: 0 2px;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  opacity: 0.4;

  :hover {
    opacity: 1;
  }

  & > svg {
    height: 12px;
    width: 12px;
  }
`;

const FirstLine = styled.div`
  display: flex;
  align-items: center;
`;

const LeftTitle = styled(Title)`
  align-self: start;
  margin-right: auto;
  margin-bottom: 6px;
`;

export function InfrastructureFileTree(props: SiteTreeProps) {
  const { collapseAll } = props;

  const { showAll, toggleCheckbox } = useInfrastructureSettings();

  return (
    <Wrapper>
      <FirstLine>
        <LeftTitle>Organizations</LeftTitle>
        <ActionButton onClick={collapseAll} title="collapse">
          <Collapse />
        </ActionButton>
      </FirstLine>
      <CheckBoxWrapper>
        <CheckLabel>Show:</CheckLabel>
        <CheckboxSlider
          name="show all"
          checked={showAll}
          onChange={toggleCheckbox}
          labelLeft="Current"
          labelRight="All"
        />
      </CheckBoxWrapper>
      <Tree {...props} showAll={showAll} />
    </Wrapper>
  );
}
