import { useQuery } from "@apollo/client";
import { FetchOrgSitesQuery, FetchOrgSitesQueryVariables } from "@src/generated/graphql";

import FetchOrgSites from "./FetchOrgSites.graphql";

export function useFetchTopSites(orgId: string) {
  const { data, loading: siteLoading } = useQuery<FetchOrgSitesQuery, FetchOrgSitesQueryVariables>(
    FetchOrgSites,
    {
      variables: {
        id: orgId
      }
    }
  );
  const topSites = (data?.org?.sites || []).map(site => site.id);

  return {
    topSites,
    siteLoading
  };
}
