import DeviceSpecs from "@src/Devices/DeviceSpecs.graphql";
import DeviceView from "@src/Devices/DeviceView.graphql";
import {
  DeviceSpecsQuery,
  DeviceStatus,
  DeviceViewQuery,
  FetchSiteDevicesQuery,
  SiteTitleQuery
} from "@src/generated/graphql";
import { mockedResponses } from "@src/Mock/mockedResponse";

import SiteTitle from "./FetchSite.graphql";
import FetchSiteDevices from "./FetchSiteDevices.graphql";

const siteDevices: FetchSiteDevicesQuery = {
  __typename: "Query",
  site: {
    id: "794b2e6e-7499-4843-8280-6b6ea5d01047",
    __typename: "Site",
    devices: {
      __typename: "DevicesResult",
      devices: [
        {
          id: "794b2e6e-7499-4843-8280-6b6ea5d01001",
          __typename: "Device",
          displayName: "Dev1 - Pompeu Fabra",
          position: {
            lat: 40.93,
            lng: 42.2,
            __typename: "LatLng"
          },
          site: "site1",
          status: DeviceStatus.Ready,
          tags: ["antenna", "probed"]
        },
        {
          id: "794b2e6e-7499-4843-8280-6b6ea5d01002",
          __typename: "Device",
          displayName: "Dev2 - Llacuna",
          position: {
            lat: 40.93,
            lng: 42.2,
            __typename: "LatLng"
          },
          site: "site1",
          status: DeviceStatus.Deploying,
          tags: ["camera"]
        },
        {
          id: "794b2e6e-7499-4843-8280-6b6ea5d01003",
          __typename: "Device",
          displayName: "Dev3 - Fira",
          position: {
            lat: 40.93,
            lng: 42.2,
            __typename: "LatLng"
          },
          site: "site1.1",
          status: DeviceStatus.Unknown,
          tags: ["camera"]
        }
      ]
    }
  }
};

const device: DeviceViewQuery = {
  __typename: "Query",
  devices: {
    __typename: "DevicesResult",
    devices: [
      {
        id: "794b2e6e-7499-4843-8280-6b6ea5d01001",
        __typename: "Device",
        displayName: "Dev1 - Pompeu Fabra",
        position: {
          lat: 40.93,
          lng: 42.2,
          __typename: "LatLng"
        },
        site: "site1",
        status: DeviceStatus.Ready,
        progress: {
          goal: 4,
          step: 4,
          __typename: "Progress"
        },
        mac: "00-00-00-00-00-00",
        nztpWorkflow: "e2:e0:00:00:00:02",
        tags: ["antenna", "probed"],
        connections: [
          {
            id: "df16b748-6770-5bba-aab5-b9443b9c619d",
            category: "Dashboard",
            kind: "URL",
            name: "connection 2",
            source: "https://example.com/2",
            __typename: "Connection"
          },
          {
            id: "fd90b425-f415-5802-93b9-34d8c2f68b44",
            category: "url.telemetry",
            kind: "AMQP",
            name: "connection 1 edited",
            source: "https://example.com/1/edited",
            __typename: "Connection"
          }
        ]
      }
    ]
  }
};

export const mockedResponseSiteDevices = mockedResponses(
  {
    request: {
      query: FetchSiteDevices,
      variables: {
        id: "89e1a528-a4e4-46c4-b92c-89a70bf320d7"
      }
    },
    result: {
      data: siteDevices
    }
  },
  {
    request: {
      query: DeviceView,
      variables: {
        id: "794b2e6e-7499-4843-8280-6b6ea5d01001"
      }
    },
    result: {
      data: device
    }
  }
);

const title: SiteTitleQuery = {
  __typename: "Query",
  site: {
    __typename: "Site",
    id: "794b2e6e-7499-4843-8280-6b6ea5d01047",
    displayName: "site1",
    ancestors: [
      {
        __typename: "Site",
        id: "org1",
        displayName: "organization"
      }
    ]
  }
};

export const titleMockedResponse = mockedResponses({
  request: {
    query: SiteTitle,
    variables: {
      id: "89e1a528-a4e4-46c4-b92c-89a70bf320d7"
    }
  },
  result: {
    data: title
  }
});

const specs: DeviceSpecsQuery = {
  __typename: "Query",
  devices: {
    devices: [
      {
        id: "794b2e6e-7499-4843-8280-6b6ea5d01001",
        specs:
          '{"ansible_architecture": "x86_64", "ansible_bios_date": "01/14/2021", "ansible_bios_vendor": "American Megatrends International, LLC.", "ansible_bios_version": "5.21", "ansible_board_asset_tag": "Base Board Asset Tag", "ansible_board_name": "WHITLEY", "ansible_board_serial": "Default string", "ansible_board_vendor": "Intel Corporation", "ansible_board_version": "Default string", "ansible_all_ipv4_addresses": ["172.17.2.1", "172.17.3.1", "172.17.0.1"],  "ansible_processor_threads_per_core": 2, "ansible_processor_vcpus": 64, "ansible_product_name": "WHITLEY", "ansible_product_serial": "Default string", "ansible_product_uuid": "NA", "ansible_product_version": "1.0", "ansible_python": {"executable": "/usr/bin/python", "has_sslcontext": true, "type": "CPython", "version": {"major": 2, "micro": 5, "minor": 7, "releaselevel": "final", "serial": 0}, "version_info": [2, 7, 5, "final", 0]}}'
      }
    ]
  }
};

export const mockSpec = mockedResponses({
  request: {
    query: DeviceSpecs,
    variables: {
      id: "794b2e6e-7499-4843-8280-6b6ea5d01001"
    }
  },
  result: {
    data: specs
  }
});
