import React from "react";

import { H1, H2 } from "../Components/Text";

interface CustomError extends Error {
  status?: number;
}

interface ErrorPageProps {
  error: CustomError;
}

export function ErrorPage({ error }: ErrorPageProps) {
  return (
    <div>
      <H1>{error.message}</H1>
      <H2>{error.status}</H2>
      <pre>{error.stack}</pre>
    </div>
  );
}
