import { FormikErrors } from "formik";
import React from "react";

import { SelectField } from "@src/Components/Input/InputGroup";
import { styled } from "@src/Components/theme";
import { Role } from "@src/generated/graphql";
import { useIsSuperAdmin } from "@src/Hooks/isSuperAdmin";

import { CreateUserInput } from "./AddUserForm";
import { RecruitUserInput } from "./RecruitUserForm";

export const RoleOption = styled.option<{ allCaps: boolean }>`
  text-transform: ${props => (props.allCaps ? "uppercase" : "none")};
`;

type UserRoleSelectorProps = {
  name: string;
  errors: FormikErrors<RecruitUserInput | CreateUserInput>;
};

export function UserRoleSelector({ name, errors }: UserRoleSelectorProps) {
  const { isSuperAdmin } = useIsSuperAdmin();
  const roles = Object.entries(Role).filter(([, role]) => isSuperAdmin || role !== Role.Mno);
  return (
    <SelectField name={name} errors={errors}>
      {roles.map(([key, r]) => (
        <RoleOption key={key} value={r} allCaps={r === Role.Mno}>
          {key}
        </RoleOption>
      ))}
    </SelectField>
  );
}
