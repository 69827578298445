import React, { useCallback } from "react";
import { Outlet, useNavigate, useOutletContext } from "react-router-dom";

import { SiteTreeProps } from "@src/Hooks/siteTree";

export interface ProvisionContext {
  returnToProvision: () => void;
  selectSitePage: SiteTreeProps["selectSitePage"];
}

interface ProvisionProps {
  selectSitePage: SiteTreeProps["selectSitePage"];
}

function useProvisionProps() {
  return useOutletContext<ProvisionProps>();
}
export function Provision() {
  const navigate = useNavigate();
  const returnToProvision = useCallback(() => navigate("."), [navigate]);
  const { selectSitePage } = useProvisionProps();

  const context: ProvisionContext = {
    returnToProvision,
    selectSitePage
  };

  return <Outlet context={context} />;
}
