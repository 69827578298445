import React, {
  cloneElement,
  ReactElement,
  useCallback,
  useEffect,
  useReducer,
  createContext,
  ReactNode,
  useContext
} from "react";
import { render } from "react-dom";
import { usePortal } from "./portal";
import styled from "styled-components";

interface SvgDefTrackerState {
  [id: string]: { element: ReactElement; count: number };
}

export const initialState: SvgDefTrackerState = {};

export interface RegisterAction {
  type: "register";
  id: string;
  element: ReactElement;
}

export interface UnregisterAction {
  type: "unregister";
  id: string;
}

export function reducer(
  state: SvgDefTrackerState,
  action: RegisterAction | UnregisterAction
): SvgDefTrackerState {
  switch (action.type) {
    case "register":
      return {
        ...state,
        [action.id]: {
          count: (state[action.id] ? state[action.id].count : 0) + 1,
          element: action.element
        }
      };
    case "unregister":
      const { [action.id]: registered, ...rest } = state;
      if (!registered) return state;
      if (registered.count > 1) {
        return {
          ...rest,
          [action.id]: {
            ...state[action.id],
            count: state[action.id].count - 1
          }
        };
      }
      return {
        ...rest
      };
  }
}

export interface SvgDefTracker {
  register: (id: string, element: ReactElement) => void;
  unregister: (id: string) => void;
}

const SVG = styled.svg`
  position: absolute;
  top: 0;
  z-index: -1;
  visibility: hidden;
`;

export function useSvgDefTracker(): SvgDefTracker {
  const [state, dispatch] = useReducer(reducer, initialState);

  const register = useCallback((id: string, element: ReactElement) => {
    dispatch({
      type: "register",
      id,
      element
    });
  }, []);

  const unregister = useCallback((id: string) => {
    dispatch({
      type: "unregister",
      id
    });
  }, []);

  const target = usePortal("defs");
  useEffect(() => {
    if (!target) return;
    const entries = Object.entries(state);
    render(
      <SVG>
        <defs>{entries.map(([key, val]) => cloneElement(val.element, { key }))}</defs>
      </SVG>,
      target
    );
  }, [state, target]);

  return { register, unregister };
}

export const SvgDefTrackerContext = createContext<SvgDefTracker>(null);

interface SvgDefTrackerProviderProps {
  children: ReactNode;
}

export const SvgDefTrackerProvider = ({ children }: SvgDefTrackerProviderProps) => (
  <SvgDefTrackerContext.Provider value={useSvgDefTracker()}>
    {children}
  </SvgDefTrackerContext.Provider>
);

export const useSvgDef = (id: string, element: ReactElement) => {
  const { register, unregister } = useContext(SvgDefTrackerContext);
  useEffect(() => {
    register(id, element);
    return () => unregister(id);
  }, [id, element, register, unregister]);
};
