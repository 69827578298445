import { useQuery } from "@apollo/client";
import {
  FetchBlockInformationQuery,
  FetchBlockInformationQueryVariables
} from "@src/generated/graphql";

import FetchBlockInformation from "./FetchBlockInformation.graphql";

export function useFetchBlockInformation(name: string, version: string) {
  const query = useQuery<FetchBlockInformationQuery, FetchBlockInformationQueryVariables>(
    FetchBlockInformation,
    {
      variables: { name, version },
      skip: !name
    }
  );

  return {
    ...query,
    chart: query.data?.blockChart
  };
}
