import { ServiceDesignerPreviousValuesQuery } from "@src/generated/graphql";

import { FormBlock } from "./serialise";

export enum EditorStatus {
  Default = "default",
  Unsaved = "unsaved",
  Saved = "saved"
}

export interface BlockValues {
  current: string;
  original: string;
  previous: string;
  saved: string;
  status: EditorStatus;
}

export interface DesignerEditorState {
  selectedBlock: string;
  isEditing: boolean;
  blocks: { [id: string]: BlockValues };
}

interface ChangeValue {
  value: string;
  block: string;
}
interface BlockSelector {
  block: string;
}

export type DesignerEditorAction =
  | { type: "change"; payload: ChangeValue }
  | { type: "unlock"; payload: ChangeValue }
  | { type: "cancel"; payload: BlockSelector }
  | { type: "save"; payload: BlockSelector }
  | { type: "selectBlock"; payload: BlockSelector }
  | { type: "loadPrevious"; payload: BlockSelector }
  | { type: "loadOriginal"; payload: BlockSelector }
  | { type: "initOriginalValues"; payload: ChangeValue }
  | { type: "changeVersion"; payload: ChangeValue }
  | { type: "changeBlockName"; payload: ChangeValue };

export const initialState: DesignerEditorState = {
  selectedBlock: "",
  isEditing: false,
  blocks: {}
};

export function editorDesignerReducer(
  state = initialState,
  action: DesignerEditorAction
): DesignerEditorState {
  if (!action || !action?.payload?.block) return state;
  const { type, payload } = action;

  const defaultValue = state.isEditing
    ? state.blocks[payload.block]?.previous
    : state.blocks[payload.block]?.original;

  switch (type) {
    case "change":
      const changeStatus =
        payload.value === defaultValue
          ? EditorStatus.Default
          : payload.value === state.blocks[payload.block].saved
          ? EditorStatus.Saved
          : EditorStatus.Unsaved;
      return {
        ...state,
        blocks: {
          ...state.blocks,
          [payload.block]: {
            ...state.blocks[payload.block],
            status: changeStatus,
            current: payload.value
          }
        }
      };

    case "unlock":
      return {
        ...state,
        blocks: {
          ...state.blocks,
          [payload.block]: {
            ...state.blocks[payload.block],
            saved: payload.value
          }
        }
      };

    case "cancel":
      return {
        ...state,
        blocks: {
          ...state.blocks,
          [payload.block]: {
            ...state.blocks[payload.block],
            current: state.blocks[payload.block].saved,
            status:
              state.blocks[payload.block].saved === defaultValue
                ? EditorStatus.Default
                : EditorStatus.Saved
          }
        }
      };

    case "save":
      return {
        ...state,
        blocks: {
          ...state.blocks,
          [payload.block]: {
            ...state.blocks[payload.block],
            saved: state.blocks[payload.block].current,
            status:
              state.blocks[payload.block].current === defaultValue
                ? EditorStatus.Default
                : EditorStatus.Saved
          }
        }
      };

    case "selectBlock":
      return {
        ...state,
        selectedBlock: payload.block
      };

    case "changeBlockName":
      const newState: DesignerEditorState = {
        ...state,
        selectedBlock: payload.value,
        blocks: {
          ...state.blocks,
          [payload.value]: {
            ...state.blocks[payload.block]
          }
        }
      };
      delete newState.blocks[`${payload.block}`];

      return newState;

    case "loadPrevious":
      return {
        ...state,
        blocks: {
          ...state.blocks,
          [payload.block]: {
            ...state.blocks[payload.block],
            current: state.blocks[payload.block].previous,
            saved: state.blocks[payload.block].previous,
            status: EditorStatus.Default
          }
        }
      };

    case "loadOriginal":
      return {
        ...state,
        blocks: {
          ...state.blocks,
          [payload.block]: {
            ...state.blocks[payload.block],
            current: state.blocks[payload.block].original,
            saved: state.blocks[payload.block].original,
            status:
              state.blocks[payload.block].original === defaultValue
                ? EditorStatus.Default
                : EditorStatus.Saved
          }
        }
      };
    case "initOriginalValues":
      const valueToCompare = state.isEditing ? state.blocks[payload.block].previous : payload.value;
      const initialStatus =
        state.blocks[payload.block].current === (valueToCompare || "")
          ? EditorStatus.Default
          : EditorStatus.Saved;
      return {
        ...state,
        blocks: {
          ...state.blocks,
          [payload.block]: {
            ...state.blocks[payload.block],
            original: payload.value,
            status: initialStatus
          }
        }
      };

    case "changeVersion":
      const status = !state.isEditing
        ? EditorStatus.Default
        : payload.value === valueToCompare
        ? EditorStatus.Default
        : EditorStatus.Saved;
      return {
        ...state,
        blocks: {
          ...state.blocks,
          [payload.block]: {
            ...state.blocks[payload.block],
            status: status,
            current: payload.value,
            saved: payload.value,
            original: state.isEditing ? state.blocks[payload.block].original : payload.value
          }
        }
      };
  }
  return state;
}

type PreviouslyDeployedBlocks = ServiceDesignerPreviousValuesQuery["serviceChain"]["blocks"];

export function initBlocksObject(
  blocks: { [id: string]: FormBlock },
  previouslyDeployedBlocks: PreviouslyDeployedBlocks
): {
  [id: string]: BlockValues;
} {
  const initialBlocks: { [key: string]: BlockValues } = {};
  Object.entries(blocks).map(([displayName, block]) => {
    const previousDeployedValues = previouslyDeployedBlocks.find(
      b => b.displayName === block?.displayName
    )?.values;

    initialBlocks[displayName] = {
      current: block.values || "",
      original: "",
      previous: !previousDeployedValues ? "" : previousDeployedValues,
      saved: block.values || "",
      status: EditorStatus.Default
    };
  });
  return initialBlocks;
}
