import { editor } from "monaco-editor";
import React, { ChangeEvent, Dispatch, useCallback, useRef, useState } from "react";
import { css } from "styled-components";

import Lock from "@img/lock.svg";
import Editor, { DiffEditor } from "@monaco-editor/react";
import { Title } from "@src/Components/BlockSelector/BlockSelectorList";
import { ButtonGroup } from "@src/Components/Buttons/ButtonGroup";
import { Clickable } from "@src/Components/Buttons/Clickable";
import { GhostButton } from "@src/Components/Buttons/Ghost";
import { PrimaryButton } from "@src/Components/Buttons/Primary";
import { Modal } from "@src/Components/Modal/Modal";
import { styled } from "@src/Components/theme";
import { useToggle } from "@src/Hooks/toggle";
import { EditedChart, PublisherEditorAction } from "@src/Publisher/editorPublisherReducer";

import { Action, ConfigurationOptions } from "./ConfigurationOptions";
import { DiffEditorOptions, Options } from "./DiffEditorOptions";
import { DesignerEditorAction } from "./editorDesignerReducer";
import { ReadOnly } from "./ReadOnlyEditor";
import { useSiteIdCompletion } from "./siteIdCompletion";
import { SiteIdSelector } from "./SiteIdSelector";
import { ThemeSelect } from "./ThemeSelect";

const Wrapper = styled.div`
  width: 100%;
`;

const FirstLine = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
  align-items: center;
`;

const CodeEditorStatus = styled(ReadOnly)<{ unlock: boolean }>`
  margin-right: 10px;
  ${({ unlock, theme }) =>
    unlock &&
    css`
      background-color: ${theme.success};
      color: white;
    `}
`;

const Subtitle = styled(Title)`
  width: fit-content;
  margin-bottom: 0;
`;

const StyledClickable = styled(Clickable)`
  height: 25px;
  width: 25px;
`;

const buttonCss = css`
  width: 100px;
  height: 30px;
`;

const BtnGroup = styled(ButtonGroup)`
  button {
    ${buttonCss}
  }
`;

interface EmbeddedEditorProps {
  blockDisplayName: string;
  defaultYaml: string;
  theme: string;
  configurationParameter: string;
  isEditing: boolean;
  changeTheme: (e: ChangeEvent<HTMLSelectElement>) => void;
  editBlockValue: (input: string, field?: EditedChart) => void;
  editedChart?: EditedChart;
  editorValues: string;
  editorDispatch?: Dispatch<PublisherEditorAction | DesignerEditorAction>;
  previousValues?: string;
  savedValue?: string;
}

export function EmbeddedEditor({
  blockDisplayName,
  defaultYaml,
  theme,
  configurationParameter,
  isEditing,
  changeTheme,
  editedChart,
  editorValues,
  editBlockValue,
  editorDispatch,
  previousValues,
  savedValue
}: EmbeddedEditorProps) {
  const [diffEditorState, setDiffEditorState] = useState(1);
  const onChangeDiffEditorState = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setDiffEditorState(parseInt(e.target.value));
    },
    [setDiffEditorState]
  );

  const { state: isUnlock, on: unlock, off: lock } = useToggle();

  const lockEditorAndSave = useCallback(() => {
    editorDispatch({ type: "save", payload: { chart: editedChart, block: blockDisplayName } });
    lock();
  }, [blockDisplayName, editedChart, editorDispatch, lock]);

  const cancelEditConfiguration = useCallback(() => {
    editBlockValue(savedValue, editedChart);
    editorDispatch({ type: "cancel", payload: { chart: editedChart, block: blockDisplayName } });
    lock();
  }, [editBlockValue, editedChart, savedValue, lock, editorDispatch, blockDisplayName]);

  const loadDefaultYaml = useCallback(() => {
    editBlockValue(defaultYaml, editedChart);
    editorDispatch({
      type: "loadOriginal",
      payload: { chart: editedChart, block: blockDisplayName }
    });
  }, [defaultYaml, editBlockValue, editedChart, editorDispatch, blockDisplayName]);

  const loadPreviousDeployedValues = useCallback(() => {
    editBlockValue(previousValues, editedChart);
    editorDispatch({
      type: "loadPrevious",
      payload: { block: blockDisplayName }
    });
  }, [blockDisplayName, editBlockValue, editedChart, editorDispatch, previousValues]);

  const unlockEditor = useCallback(() => {
    editorDispatch({
      type: "unlock",
      payload: { chart: editedChart, value: editorValues, block: blockDisplayName }
    });
    setDiffEditorState(1);
    unlock();
  }, [setDiffEditorState, unlock, editorValues, editorDispatch, editedChart, blockDisplayName]);

  const editor = useRef<editor.IStandaloneDiffEditor | editor.IStandaloneCodeEditor>();
  const { modalIsShown, hideModal, insertSite } = useSiteIdCompletion(editor.current);
  const onMountEditor = useCallback(
    (diffEditor: editor.IStandaloneDiffEditor) => {
      editor.current = diffEditor;
      const modifiedEditor = diffEditor.getModifiedEditor();
      modifiedEditor.onDidChangeModelContent(() => {
        editBlockValue(modifiedEditor.getValue(), editedChart);
      });
    },
    [editBlockValue, editedChart]
  );

  const createActions: Action[] = [
    {
      displayName: "reset to block defaults",
      action: loadDefaultYaml,
      disabled: false
    }
  ];

  const editActions: Action[] = [
    ...createActions,
    {
      displayName: "revert to current values",
      action: loadPreviousDeployedValues,
      disabled: !previousValues
    }
  ];

  const insertSiteCallback = useCallback(
    (siteId: string) => {
      insertSite(siteId);
    },
    [insertSite]
  );

  const onEditorChange = useCallback(
    (value: string) => {
      editBlockValue(value, editedChart);
      editorDispatch({
        type: "change",
        payload: {
          chart: editedChart,
          value,
          block: blockDisplayName
        }
      });
    },
    [blockDisplayName, editBlockValue, editedChart, editorDispatch]
  );

  return (
    <>
      <Wrapper>
        <Subtitle>{configurationParameter}</Subtitle>
        <FirstLine>
          <CodeEditorStatus unlock={isUnlock}>{isUnlock ? "unlocked" : "locked"}</CodeEditorStatus>
          {isUnlock ? (
            <>
              <BtnGroup>
                <GhostButton onClick={cancelEditConfiguration}>Cancel</GhostButton>
                <PrimaryButton disabled={!isUnlock} onClick={lockEditorAndSave}>
                  Save
                </PrimaryButton>
              </BtnGroup>
            </>
          ) : (
            <StyledClickable onClick={unlockEditor} title={"unlock configuration"}>
              <Lock />
            </StyledClickable>
          )}
          <ThemeSelect changeTheme={changeTheme} />
          <ConfigurationOptions actions={isEditing ? editActions : createActions} />
        </FirstLine>
        {isUnlock && (
          <DiffEditorOptions
            isEditing={isEditing}
            hasPreviousValues={!!previousValues}
            onChange={onChangeDiffEditorState}
            state={diffEditorState}
          />
        )}
        {theme &&
          (isUnlock && diffEditorState !== Options.NoDiff ? (
            <DiffEditor
              language="yaml"
              height="900px"
              theme={theme}
              original={
                isEditing && diffEditorState === Options.Deployed ? previousValues : defaultYaml
              }
              modified={editorValues || ""}
              onMount={onMountEditor}
              options={{ diffCodeLens: true }}
            />
          ) : (
            <Editor
              language="yaml"
              height="900px"
              theme={theme}
              value={editorValues || ""}
              onChange={onEditorChange}
              onMount={e => (editor.current = e)}
              options={{ readOnly: !isUnlock, codeLens: isUnlock }}
            />
          ))}
      </Wrapper>
      <Modal show={modalIsShown} onOutsideClick={hideModal}>
        <SiteIdSelector onSelectSite={insertSiteCallback} modalTitle="Insert site ID" />
      </Modal>
    </>
  );
}
