import styled from "styled-components";

export const TextButton = styled.button`
  font-family: "rawline", sans-serif;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
  font-size: 14px;
  letter-spacing: 0.4px;

  :disabled {
    cursor: not-allowed;
  }
`;

export const PrimaryTextButton = styled(TextButton)`
  font-weight: 600;
  color: #484886;
  fill: #484886;

  :hover {
    color: #8888b0;
    fill: #8888b0;
    text-decoration: underline;
  }
`;

export const SecondaryTextButton = styled(TextButton)`
  color: #3c9bd8;

  :hover {
    font-weight: 600;
    color: #b0b0b0;
  }
`;
