import React, { ReactNode, useEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { useOnClickOutside } from "usehooks-ts";

import { styled } from "./theme";

const transitionName = "sideOut";

const Animation = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: white;

  ::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
  }

  &.${transitionName}-enter {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  &.${transitionName}-enter-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: opacity 200ms, transform 300ms ease-out;
  }
  &.${transitionName}-exit {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  &.${transitionName}-exit-active {
    opacity: 1;
    transform: translate3d(100%, 0, 0);
    transition: opacity 200ms, transform 300ms ease-in;
  }
`;

const Wrapper = styled.div`
  position: relative;
  height: 100vh;
  width: 75vw;
  min-width: 1100px;
  padding: 40px;
  box-sizing: border-box;
  background-color: white;
  border-left: 1px solid black;
  overflow: auto;
`;

const Cross = styled.span`
  position: absolute;
  right: 40px;
  top: 40px;
  cursor: pointer;
  font-size: 20px;
`;

interface SidePanelProps {
  show: boolean;
  close: () => void;
  children: ReactNode;
}

export function SidePanel({ show, close, children }: SidePanelProps) {
  const ref = useRef();
  useOnClickOutside(ref, close);
  useEffect(() => {
    document.body.style.overflowY = "hidden";
    return () => {
      document.body.style.overflowY = "initial";
    };
  }, []);

  return (
    <CSSTransition in={show} timeout={200} appear={true} unmountOnExit classNames={transitionName}>
      <Animation>
        <Wrapper ref={ref}>
          <Cross title="close" onClick={close}>
            ✕
          </Cross>
          {children}
        </Wrapper>
      </Animation>
    </CSSTransition>
  );
}
