import React, { ChangeEvent, useCallback } from "react";

import {
  InnerFirstLine,
  ReloadBlocks,
  ReloadClickable,
  Select,
  Title,
  TotalBlocks
} from "@src/Components/BlockSelector/BlockSelectorList";
import { MarketplaceProps } from "@src/Hooks/marketplace";
import { Filters } from "@src/ServiceDesigner/Filters";

import { sortOptions, viewOptions } from "../../Hooks/marketplace";
import { H2 } from "../Text";

interface BlockDisplayProps extends MarketplaceProps {
  chartType: string;
}

export function BlockDisplay({ chartType, ...marketplaceProps }: BlockDisplayProps) {
  const { selectedCategory, filteredBlocks, view, sort, loading, dispatch, refetch } =
    marketplaceProps;

  const swapView = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => dispatch({ type: "swapView", value: e.target.value }),
    [dispatch]
  );

  const swapSort = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => dispatch({ type: "swapSort", value: e.target.value }),
    [dispatch]
  );

  return (
    <>
      <H2>
        {selectedCategory || "All"} {chartType}
        <ReloadClickable onClick={() => refetch()} disabled={loading}>
          <ReloadBlocks />
        </ReloadClickable>
      </H2>
      <Filters {...marketplaceProps} />
      <InnerFirstLine>
        <TotalBlocks>
          <Title>viewing {loading ? 0 : filteredBlocks.length} items</Title>
        </TotalBlocks>
        <Title>view</Title>
        <Select name="view" id="view" value={view} onChange={swapView}>
          {Object.entries(viewOptions).map(([key, value]) => (
            <option value={value} key={key}>
              {key}
            </option>
          ))}
        </Select>
        <Title>sort</Title>
        <Select name="sort" id="sort" value={sort} onChange={swapSort}>
          {Object.entries(sortOptions).map(([key, value]) => (
            <option value={value} key={key}>
              {key}
            </option>
          ))}
        </Select>
      </InnerFirstLine>
    </>
  );
}
