import React, { Dispatch, useCallback } from "react";
import styled from "styled-components";
import { MarketplaceAction } from "./marketplaceReducer";

const Wrapper = styled.div`
  padding: 5px 10px;
  position: relative;
  & > input {
    margin-left: 0;
    margin-right: 10px;
    cursor: pointer;
  }
  & > label {
    cursor: pointer;
  }
`;

const Name = styled.span`
  padding-left: 0.5em;
`;

interface VendorItemProps {
  vendorName: string;
  selectedVendors: string[];
  dispatch: Dispatch<MarketplaceAction>;
}

export function VendorItem({ vendorName, selectedVendors, dispatch }: VendorItemProps) {
  const isChecked = selectedVendors.includes(vendorName);

  const toggleVendor = useCallback(() => {
    dispatch({
      type: "toggleVendor",
      value: vendorName
    });
  }, [dispatch, vendorName]);

  return (
    <Wrapper>
      <label>
        <input type="checkbox" onChange={toggleVendor} checked={isChecked} value={vendorName} />
        <Name>{vendorName || <em>no vendor</em>}</Name>
      </label>
    </Wrapper>
  );
}
