import { H1 } from "@src/Components/Text";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 0;
`;

export function NotFound() {
  return (
    <Wrapper>
      <H1>Oops! Not found</H1>
      <p>
        The resource you are looking for does not exist or you do not have the permissions to see
        it.
      </p>
      <p>Use the infrastructure tree to navigate through your organization.</p>
    </Wrapper>
  );
}
