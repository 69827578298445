import React, { ChangeEvent } from "react";
import styled from "styled-components";
import { RadioInput } from "@src/Components/Input/RadioInput";

export enum Options {
  NoDiff = 1,
  Default,
  Deployed
}

const Wrapper = styled.div`
  display: flex;
  gap: 20px;
`;

const Title = styled.label`
  font-weight: 600;
`;

interface DiffEditorOptionsProps {
  isEditing: boolean;
  hasPreviousValues: boolean;
  state: number;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export function DiffEditorOptions({
  isEditing,
  hasPreviousValues,
  state,
  onChange
}: DiffEditorOptionsProps) {
  return (
    <Wrapper>
      <Title>Editor mode: </Title>
      <RadioInput
        label="show override values only"
        name="noDiff"
        value={Options.NoDiff}
        checked={state === Options.NoDiff}
        onChange={onChange}
      />
      <RadioInput
        label="compare with built-in values"
        name="defaultValues"
        value={Options.Default}
        checked={state === Options.Default}
        onChange={onChange}
      />
      {isEditing && hasPreviousValues && (
        <RadioInput
          label="compare with last deployed overrides"
          name="deployedValues"
          value={Options.Deployed}
          checked={state === Options.Deployed}
          onChange={onChange}
        />
      )}
    </Wrapper>
  );
}
