import React from "react";
import styled from "styled-components";

import { ButtonGroup } from "@src/Components/Buttons/ButtonGroup";
import { GhostButton } from "@src/Components/Buttons/Ghost";
import { PrimaryButton } from "@src/Components/Buttons/Primary";

export const Wrapper = styled(ButtonGroup)`
  margin: 32px 0 32px auto;
  grid-column: 1 / -1;
`;

interface ButtonRowProps {
  onClickBack: () => void;
  isValid?: boolean;
  isSubmitting: boolean;
  backText: string;
  submitText: string;
}

export function ButtonRowDefault({
  onClickBack,
  isValid = true,
  isSubmitting,
  backText,
  submitText
}: ButtonRowProps) {
  return (
    <Wrapper>
      <GhostButton type="button" onClick={onClickBack}>
        {backText}
      </GhostButton>
      <PrimaryButton disabled={!isValid || isSubmitting} type="submit">
        {submitText}
      </PrimaryButton>
    </Wrapper>
  );
}

interface ButtonRowCustomProps {
  onClickBack: () => void;
  onClickSubmit: () => void;
  isValid?: boolean;
  isSubmitting: boolean;
  backText?: string;
  submitText?: string;
}

export function ButtonRowCustom({
  onClickBack,
  onClickSubmit,
  isValid = true,
  isSubmitting,
  backText = "Cancel",
  submitText = "Continue"
}: ButtonRowCustomProps) {
  return (
    <Wrapper>
      <GhostButton type="button" onClick={onClickBack}>
        {backText}
      </GhostButton>
      <PrimaryButton
        disabled={!isValid || isSubmitting}
        type="submit"
        onClick={e => {
          e.preventDefault();
          onClickSubmit();
        }}
      >
        {submitText}
      </PrimaryButton>
    </Wrapper>
  );
}
