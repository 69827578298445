import React from "react";

import { Tag } from "@src/Components/StatusTag";
import { styled } from "@src/Components/theme";
import { AuditLogEntityType, AuditLogEventType } from "@src/generated/graphql";

const ServiceChain = styled(Tag)`
  background-color: ${({ theme }) => theme.blue};
  color: white;
`;

const Unknown = styled(Tag)`
  border: 1px solid ${({ theme }) => theme.grey};
  color: #ccc;
`;

interface EntityTagProps {
  entity: AuditLogEntityType;
}

export function EntityTag({ entity }: EntityTagProps) {
  switch (entity) {
    case AuditLogEntityType.Chain:
      return <ServiceChain>Service</ServiceChain>;
    default:
      return <Unknown>Unknown</Unknown>;
  }
}

const Warning = styled(Tag)`
  background-color: ${({ theme }) => theme.warning};
  color: white;
`;

const Normal = styled(Tag)`
  background-color: #ccc;
  color: white;
`;

interface AuditTypeProps {
  type: AuditLogEventType;
}

export function AuditTypeTag({ type }: AuditTypeProps) {
  switch (type) {
    case AuditLogEventType.Normal:
      return <Normal>Normal</Normal>;
    case AuditLogEventType.Warning:
      return <Warning>Warning</Warning>;
    default:
      return <Unknown>Unknown</Unknown>;
  }
}
