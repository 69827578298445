import { usePermissionCheck } from "@src/Hooks/permissionsCheck";

export function useCanRemoveServiceChain(serviceChainId: string) {
  const { allowed } = usePermissionCheck({
    relation: "delete",
    objectType: "chains",
    objectId: serviceChainId
  });

  return allowed || false;
}
export function useCanEditServiceChain(serviceChainId: string) {
  const { allowed } = usePermissionCheck({
    relation: "write",
    objectType: "chains",
    objectId: serviceChainId
  });

  return allowed || false;
}
