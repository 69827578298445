import { useQuery } from "@apollo/client";
import { FetchUserRoleQuery } from "@src/generated/graphql";
import FetchUserRole from "@src/User/FetchUserRole.graphql";

export function useUserRole() {
  const { data, loading } = useQuery<FetchUserRoleQuery>(FetchUserRole);

  const userRole = data?.user?.loggedInRole;

  return {
    userRole,
    loading
  };
}
