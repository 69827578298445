import { useSelect, UseSelectStateChange } from "downshift";
import React from "react";

import ArrowDownDD from "@img/arrow_light_down.svg";
import ArrowUpDD from "@img/arrow_light_up.svg";
import { DropdownMenuItemWrapper, Menu, MenuAnchor } from "@src/Components/DropdownMenu";
import { baseInputStyles } from "@src/Components/Input/Input";
import { styled } from "@src/Components/theme";
import { LogEndpoint, LogSource } from "@src/generated/graphql";

export const Select = styled.div`
  ${baseInputStyles}
  padding: 8px;
  height: auto;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SelectorEndpointWrapper = styled.div`
  min-width: 300px;
  max-width: 600px;
`;

const SelectorSourceWrapper = styled.div`
  min-width: 400px;
  max-width: 700px;
`;

const SourceSelectorMenuItem = styled(DropdownMenuItemWrapper)`
  font-size: 12px;
`;

const ArrowUpIcon = styled(ArrowUpDD)`
  height: 12px;
  width: 12px;
  padding-left: 16px;
`;
const ArrowDownIcon = styled(ArrowDownDD)`
  height: 12px;
  width: 12px;
  padding-left: 16px;
`;

interface SourceSelectorProps {
  selectedSource: LogSource;
  sources: LogSource[];
  selectSource: (selectorState: UseSelectStateChange<LogSource>) => void;
}

export function SourcesSelector({ sources, selectSource, selectedSource }: SourceSelectorProps) {
  const {
    getToggleButtonProps,
    selectedItem,
    getMenuProps,
    isOpen,
    highlightedIndex,
    getItemProps
  } = useSelect({
    items: sources,
    selectedItem: selectedSource,
    onSelectedItemChange: selectSource
  });
  return (
    <SelectorSourceWrapper>
      <Select type="button" {...getToggleButtonProps()}>
        {selectedItem?.name || selectedItem?.id || `select a source`}
        {isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </Select>
      <MenuAnchor {...getMenuProps()}>
        {isOpen && (
          <Menu>
            {sources.map((item, index) => (
              <SourceSelectorMenuItem
                key={item?.id || index}
                highlighted={highlightedIndex === index}
                {...getItemProps({ item, index })}
              >
                {item?.name !== "" ? item?.name : item?.id}
              </SourceSelectorMenuItem>
            ))}
          </Menu>
        )}
      </MenuAnchor>
    </SelectorSourceWrapper>
  );
}

const EndpointSelect = styled(Select)`
  font-size: 16px;
`;

const EndpointSelectorMenuItem = styled(SourceSelectorMenuItem)`
  font-size: 16px;
`;

interface LogEndpointSelectorProps {
  initialEndpoint?: LogEndpoint;
  logEndpoints: LogEndpoint[];
  selectEndpoint: (selectorState: UseSelectStateChange<LogEndpoint>) => void;
}

export function LogEndpointSelector({
  logEndpoints,
  selectEndpoint,
  initialEndpoint
}: LogEndpointSelectorProps) {
  const {
    getToggleButtonProps,
    selectedItem,
    getMenuProps,
    isOpen,
    highlightedIndex,
    getItemProps
  } = useSelect({
    items: logEndpoints,
    initialSelectedItem: initialEndpoint,
    onSelectedItemChange: selectEndpoint
  });

  if (logEndpoints.length === 0) return null;
  return (
    <SelectorEndpointWrapper>
      <EndpointSelect type="button" {...getToggleButtonProps()}>
        {selectedItem?.name || selectedItem?.id || `select an endpoint`}
        {isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </EndpointSelect>
      <MenuAnchor {...getMenuProps()}>
        {isOpen && (
          <Menu>
            {logEndpoints.map((item, index) => (
              <EndpointSelectorMenuItem
                key={item?.id || index}
                highlighted={highlightedIndex === index}
                {...getItemProps({ item, index })}
              >
                {item?.name !== "" ? item?.name : item?.id}
              </EndpointSelectorMenuItem>
            ))}
          </Menu>
        )}
      </MenuAnchor>
    </SelectorEndpointWrapper>
  );
}
