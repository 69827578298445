import { Field } from "formik";
import React, { KeyboardEvent, ReactNode, useCallback } from "react";

import PencilIcon from "@img/edit.svg";
import RemoveIcon from "@img/remove.svg";
import TickIcon from "@img/tick.svg";
import { Clickable } from "@src/Components/Buttons/Clickable";
import { Input } from "@src/Components/Input/Input";
import { css, styled } from "@src/Components/theme";
import { iconSize } from "@src/Instances/ActionsCell/iconSize";

const Tick = styled(TickIcon)`
  ${iconSize}
`;
const Pencil = styled(PencilIcon)`
  ${iconSize}
`;
const Remove = styled(RemoveIcon)`
  ${iconSize}
`;

const Line = styled.div<{ isSelected: boolean }>`
  display: flex;

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      outline: 1px solid ${theme.border};
      font-weight: 600;
    `}
`;

export const Value = styled.span`
  cursor: pointer;
  flex-grow: 1;
  padding: 14px 14px 15px;
`;

interface KeyProps {
  isSelected?: boolean;
  isEditing: boolean;
  toggleEdit: () => void;
  remove: () => void;
  name: string;
  children: ReactNode;
}

export function Editor({ isSelected, isEditing, toggleEdit, remove, name, children }: KeyProps) {
  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key == "Enter") {
        event.preventDefault();
        toggleEdit();
      }
    },
    [toggleEdit]
  );

  return (
    <Line isSelected={isSelected && !isEditing}>
      {isEditing ? <Field autoFocus as={Input} name={name} onKeyDown={onKeyDown} /> : children}
      {<Clickable onClick={toggleEdit}>{isEditing ? <Tick /> : <Pencil />}</Clickable>}
      <Clickable onClick={remove}>
        <Remove />
      </Clickable>
    </Line>
  );
}
