import { infrastructureStorageKey } from "@src/Infrastructure/infrastructureStorageKey";
import { useCallback, useEffect, useState } from "react";

interface InfrastructureSettings {
  showAll: boolean;
}

export function useInfrastructureSettings() {
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    const settingsJson = localStorage.getItem(infrastructureStorageKey);
    if (!settingsJson) return;

    try {
      const settings = JSON.parse(settingsJson);
      setShowAll(settings.showAll);
    } catch (e) {}
  }, []);

  useEffect(() => {
    const settings: InfrastructureSettings = { showAll };
    localStorage.setItem(infrastructureStorageKey, JSON.stringify(settings));
  }, [showAll]);

  const toggleCheckbox = useCallback(() => {
    setShowAll(showAll => !showAll);
  }, []);

  return {
    showAll,
    toggleCheckbox
  };
}
