import { Form, FormikProps } from "formik";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import styled from "styled-components";

import { PrimaryButton } from "@src/Components/Buttons/Primary";
import { ErrorLine, Fieldset, SuccessLine, WarningLine } from "@src/Components/Input/InputGroup";

import { PublishState } from "./publishBlock";
import { PublishBlockMissingSteps } from "./PublishBlockMissingSteps";
import { BlockPublisherForm } from "./serialise";

const Content = styled.div`
  border: ${({ theme }) => `1px solid ${theme.border}`};
  padding: 30px;
`;

const Publish = styled.div`
  grid-column: 2;
`;

export interface PublishTabProps extends FormikProps<BlockPublisherForm> {
  publishState: PublishState;
  setPublishState: Dispatch<SetStateAction<PublishState>>;
}

function useDeployTabProps() {
  return useOutletContext<PublishTabProps>();
}

export function PublishTab() {
  const { publishState, setPublishState, ...formikProps } = useDeployTabProps();
  const { errors, isValid, isSubmitting, values } = formikProps;
  useEffect(() => {
    return () => setPublishState(prev => ({ ...prev, success: false, message: null }));
  }, [setPublishState]);

  return (
    <Content>
      <Form>
        <Fieldset>
          <Publish>
            <div>
              <p>Publish block to registry</p>
              <PrimaryButton type="submit" disabled={!isValid || isSubmitting}>
                {isSubmitting ? "Publishing" : "Publish"}
              </PrimaryButton>
            </div>
            {values.overrides === "" && !publishState.submitted && (
              <WarningLine>
                Block overrides is empty. This means that no values can be configured at deploy time
              </WarningLine>
            )}
            {publishState.submitted && (
              <>
                {publishState.success ? (
                  <SuccessLine>{publishState.message}</SuccessLine>
                ) : (
                  <ErrorLine>{publishState.message}</ErrorLine>
                )}
              </>
            )}

            <PublishBlockMissingSteps errors={errors} />
          </Publish>
        </Fieldset>
      </Form>
    </Content>
  );
}
