import React from "react";
import styled from "styled-components";

import { useQuery } from "@apollo/client";
import Editor from "@monaco-editor/react";

import { Loading } from "../Components/Loading/Loading";
import { DeviceSpecsQuery, DeviceSpecsQueryVariables } from "../generated/graphql";
import DeviceSpecs from "./DeviceSpecs.graphql";

const Wrapper = styled.div`
  grid-column: 1 /-1;
  border: 1px solid ${({ theme }) => theme.border};
  padding: 5px 0;
`;

interface SpecsViewProps {
  deviceId: string;
}

export function SpecsView({ deviceId }: SpecsViewProps) {
  const { loading, data: specs } = useQuery<DeviceSpecsQuery, DeviceSpecsQueryVariables>(
    DeviceSpecs,
    {
      fetchPolicy: "network-only",
      pollInterval: 5000,
      variables: { id: deviceId }
    }
  );

  if (loading) return <Loading />;

  let prettySpecs: string;

  const data =
    (specs &&
      specs.devices &&
      specs.devices.devices.length > 0 &&
      specs.devices.devices[0].specs) ||
    "no inventory available";

  try {
    const obj = JSON.parse(data);
    prettySpecs = JSON.stringify(obj, null, 2);
  } catch (e) {
    prettySpecs = data;
  }

  return <DeviceViewEditor content={prettySpecs} />;
}

interface DeviceViewEditorProps {
  content: string;
}

export function DeviceViewEditor({ content }: DeviceViewEditorProps) {
  return (
    <Wrapper>
      <Editor
        language="yaml"
        height="500px"
        theme="visual studio"
        value={content}
        options={{ readOnly: true }}
      />
    </Wrapper>
  );
}
