import { editor } from "monaco-editor";
import React, { useCallback, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useElementSize } from "usehooks-ts";

import { ApolloError } from "@apollo/client";
import RemoveIcon from "@img/remove.svg";
import Editor from "@monaco-editor/react";
import {
  Actions,
  AddBlockButton,
  BlockInfoText,
  BlockInfoWrapper,
  Categories,
  Category,
  Content,
  FirstLine,
  Info,
  Label,
  Logo,
  Title,
  VersionSelect
} from "@src/Components/BlockSelector/BlockInfoStyles";
import { ErrorMessage } from "@src/Components/ErrorMessage";
import { BlockChart } from "@src/generated/graphql";
import { useIsAdmin } from "@src/Hooks/isAdmin";
import { iconSize } from "@src/Instances/ActionsCell/iconSize";

import { PrimaryTextButton } from "../Buttons/Text";
import { Loading } from "../Loading/Loading";
import { styled } from "../theme";

const Remove = styled(RemoveIcon)`
  ${iconSize}
`;

const LabelRemoveButtonWrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

const RemoveBlockButton = styled(PrimaryTextButton)`
  display: inline-flex;
  align-items: center;
  padding: 10px;
`;

interface BlockInformationProps {
  addVersionChart: (chart: BlockChart) => void;
  addButtonText: string;
  showDeleteModal?: () => void;
  loading: boolean;
  chart: BlockChart;
  error?: ApolloError;
}

export function ChartInformation({
  addVersionChart,
  addButtonText,
  showDeleteModal,
  loading,
  chart,
  error
}: BlockInformationProps) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const version = searchParams.get("v");
  const { isAdmin } = useIsAdmin();
  const changeVersion = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      navigate(`../${chart?.name}?v=${e.target.value}`);
    },
    [navigate, chart]
  );
  const [blockInfoRef, { width }] = useElementSize();

  const editorDefVal = useRef<editor.IStandaloneDiffEditor | editor.IStandaloneCodeEditor>();

  const renderNumbers = width > 800;

  const addBlock = useCallback(() => {
    addVersionChart(chart);
  }, [chart, addVersionChart]);

  useEffect(() => {
    editorDefVal.current?.layout();
  }, [width]);

  if (loading) return <Loading />;
  if (error) return <ErrorMessage error="Could not fetch block information" />;
  return (
    <Info>
      <FirstLine>
        <Logo src={chart?.logoUrl} alt={`${chart?.vendor} logo`} />
        <div>
          <Title>{chart?.displayName}</Title>
          <BlockInfoText>by {chart?.vendor}</BlockInfoText>
          <Actions>
            <Label>Selected version:</Label>
            <VersionSelect onChange={changeVersion} value={version}>
              {chart?.availableVersions.map(v => (
                <option key={v} value={v}>
                  {v}
                </option>
              ))}
            </VersionSelect>
            <AddBlockButton onClick={addBlock} disabled={!chart}>
              {addButtonText}
            </AddBlockButton>
          </Actions>
        </div>
      </FirstLine>
      <Content>
        <Categories>
          <Label>Categories</Label>
          {chart?.categories.map(c => (
            <Category key={c}>{c}</Category>
          ))}
        </Categories>
        <BlockInfoWrapper ref={blockInfoRef}>
          <LabelRemoveButtonWrapper>
            <Label>Description</Label>
            {isAdmin && showDeleteModal && (
              <RemoveBlockButton
                title="remove the selected block version"
                onClick={showDeleteModal}
              >
                remove version <Remove />
              </RemoveBlockButton>
            )}
          </LabelRemoveButtonWrapper>
          <BlockInfoText>{chart?.description}</BlockInfoText>
          <Label>Version tag</Label>
          <BlockInfoText>{chart?.version}</BlockInfoText>
          <Label>Block default values</Label>
          {chart && (
            <Editor
              language="yaml"
              height="500px"
              theme="vs-dark"
              value={chart.overridesYaml || ""}
              onMount={e => (editorDefVal.current = e)}
              options={{
                readOnly: true,
                lineNumbers: renderNumbers ? "on" : "off",
                minimap: {
                  enabled: false
                }
              }}
            />
          )}
        </BlockInfoWrapper>
      </Content>
    </Info>
  );
}
