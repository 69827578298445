import { css } from "styled-components";

import { styled } from "@src/Components/theme";

export const ArrowSelected = css`
  transform: rotate(45deg) translate(0, -5px);
`;

export const ArrowCss = css<{ expanded: boolean }>`
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  margin: auto 0 auto 5px;
  padding: 5px;
  transition: transform 300ms;
  ${({ expanded }) =>
    expanded
      ? ArrowSelected
      : css`
          transform: rotate(-45deg);
        `};
`;

export const Arrow = styled.div<{ expanded: boolean }>`
  width: 30px;
  span {
    ${ArrowCss}
  }
`;
