import { styled } from "@src/Components/theme";

export const InfoIcon = styled.span`
  margin-left: 15px;

  &::before {
    content: "ⓘ";
    font-size: 18px;
    margin-right: 5px;
  }
`;
