import React, { useCallback, useState } from "react";
import { Row } from "react-table";

import { ButtonGroup } from "@src/Components/Buttons/ButtonGroup";
import { DangerButton } from "@src/Components/Buttons/Danger";
import { GhostButton } from "@src/Components/Buttons/Ghost";
import { Modal, ModalProps, Title } from "@src/Components/Modal/Modal";
import { RemoveModal } from "@src/Components/Modal/RemoveModal";
import { OrgSelector } from "@src/Components/OrgSelector";
import { styled } from "@src/Components/theme";
import { User } from "@src/generated/graphql";

import { useDeleteUserModal } from "./deleteUserModal";

export const OrgSelectorWrapper = styled.div`
  display: flex;
  align-items: baseline;
  gap: 1em;

  > span {
    font-size: 14px;
  }
`;

const Wrapper = styled(ButtonGroup)`
  margin: 32px 0 0 auto;
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

interface DeleteUserModalProps extends ModalProps {
  userToDelete: { user: User; row: Row };
}

export default function DeleteUserModal({
  show,
  onOutsideClick,
  userToDelete
}: DeleteUserModalProps) {
  const [selectedOrg, setSelectedOrg] = useState<string>();
  const orgs = (userToDelete?.user?.orgRoles || []).map(role => ({
    id: role?.id,
    displayName: role?.org?.displayName
  }));
  const selectorOrgs = [{ id: "all-orgs", displayName: "All Orgs" }, ...orgs];

  const {
    dismissModalProps,
    removeModalProps,
    modalDismissUserShown,
    modalRemoveUserShown,
    openRowDismissModal,
    openRowRemoveModal,
    isDeleting
  } = useDeleteUserModal(selectedOrg);

  const selectOrg = useCallback((org: string) => {
    setSelectedOrg(org);
  }, []);

  const deleteUser = useCallback(() => {
    const { user, row } = userToDelete;
    if (selectedOrg === "all-orgs") {
      openRowRemoveModal({ id: user.id, displayName: user.name, type: "user" }, row);
    } else {
      openRowDismissModal({ id: user.id, displayName: user.name, type: "user" }, row);
    }
    onOutsideClick();
  }, [userToDelete, openRowRemoveModal, openRowDismissModal, selectedOrg, onOutsideClick]);

  return (
    <>
      <Modal show={show} onOutsideClick={onOutsideClick}>
        <Title>
          Select where to delete <strong>{userToDelete?.user?.name}</strong> from
        </Title>
        <OrgSelectorWrapper>
          <span>Selected Organization:</span>
          <OrgSelector selectedOrg={""} organizations={selectorOrgs} selectOrg={selectOrg} />
        </OrgSelectorWrapper>
        <Wrapper>
          <GhostButton onClick={onOutsideClick}>Cancel</GhostButton>
          <DangerButton disabled={!selectedOrg || isDeleting} onClick={deleteUser}>
            Delete
          </DangerButton>
        </Wrapper>
      </Modal>
      {modalDismissUserShown && (
        <RemoveModal key={"all-orgs-dismiss-user"} {...dismissModalProps} />
      )}
      {modalRemoveUserShown && <RemoveModal key={"remove-user"} {...removeModalProps} />}
    </>
  );
}
