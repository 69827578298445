import { FormikProps } from "formik";
import React, { useCallback } from "react";
import { NavigateFunction, Outlet, useOutletContext } from "react-router-dom";

import { BlockDisplay as TemplatesDisplay } from "@src/Components/BlockSelector/BlockDisplay";
import {
  BlocksContent as TemplatesContent,
  NoBlocksMessage as NoTemplatesMessage,
  Wrapper
} from "@src/Components/BlockSelector/BlockSelectorList";
import { CategoriesFilter } from "@src/Components/BlockSelector/CategoriesFilter";
import { BlockChart } from "@src/generated/graphql";
import { useOnResetStorage } from "@src/Hooks/onResetStorage";

import { useMarketplace } from "../Hooks/marketplace";
import { BlockPublisherForm } from "./serialise";
import { TemplateBlock } from "./TemplateBlock";
import { useTemplates } from "./templates";

interface PublisherProps extends FormikProps<BlockPublisherForm> {
  navigate: NavigateFunction;
}

function usePublisherProps() {
  return useOutletContext<PublisherProps>();
}

export function Templates() {
  const { navigate, ...formikProps } = usePublisherProps();

  const marketplaceProps = useMarketplace(useTemplates());
  const {
    allCategories,
    allVendors,
    selectedCategory,
    selectedVendors,
    filteredBlocks: filteredTemplates,
    view,
    loading,
    dispatch,
    repoUrl
  } = marketplaceProps;

  useOnResetStorage(() => dispatch({ type: "cleanFilters" }));

  const openTemplateInformation = useCallback(
    (templateName: string, version: string) => {
      navigate(`templates/${templateName}?v=${version}`);
    },
    [navigate]
  );

  const { setValues } = formikProps;

  const addVersionTemplate = useCallback(
    (chart: BlockChart) => {
      const template: BlockPublisherForm = {
        templateName: chart.name,
        displayName: chart.displayName,
        selectedVersion: chart.version,
        values: chart.valuesYaml || "",
        overrides: chart.overridesYaml || "",
        descriptor: {
          name: "",
          version: "",
          displayName: chart.displayName,
          vendor: chart.vendor,
          description: chart.description,
          iconUrl: chart.logoUrl,
          categories: chart.categories
        }
      };
      setValues(template);
      navigate("editor");
    },
    [setValues, navigate]
  );

  const context = { addVersionTemplate };

  return (
    <>
      <Wrapper>
        <CategoriesFilter
          loading={loading}
          allCategories={allCategories}
          allVendors={allVendors}
          selectedCategory={selectedCategory}
          selectedVendors={selectedVendors}
          dispatch={dispatch}
        />

        <div>
          <TemplatesDisplay {...marketplaceProps} chartType="templates" />
          <TemplatesContent view={view}>
            {filteredTemplates.length > 0 && !loading ? (
              filteredTemplates.map(b => (
                <TemplateBlock
                  openTemplateInformation={openTemplateInformation}
                  key={b.id}
                  view={view}
                  template={b}
                  addVersionTemplate={addVersionTemplate}
                  {...formikProps}
                />
              ))
            ) : (
              <NoTemplatesMessage>
                {repoUrl === ""
                  ? "Template repo URL must be set via the Customizations tab before using the Marketplace."
                  : "There are no blocks with the applied filters."}
              </NoTemplatesMessage>
            )}
          </TemplatesContent>
        </div>
      </Wrapper>
      <Outlet context={context} />
    </>
  );
}
