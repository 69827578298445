import { UseSelectStateChange } from "downshift";
import { FormikErrors } from "formik";
import React, { useCallback } from "react";
import styled from "styled-components";

import { baseInputStyles } from "@src/Components/Input/Input";
import { FieldLabel } from "@src/Components/Input/InputGroup";

import { DeviceValues } from "./AddDeviceForm";
import { ConnectionSelect } from "./ConnectionSelect";
import { connectionTypes } from "./connectionTypes";
import { EditDeviceValues } from "./EditDeviceForm";

const FixedCategory = styled.span`
  ${baseInputStyles}
  display: flex;
  align-items: center;
  margin-bottom: 35px;
`;

interface ConnectionCategoryKindInputsProps {
  name: string;
  errors: FormikErrors<DeviceValues | EditDeviceValues>;
  selectedCategory: string;
  selectedKind: string;
  setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void;
  onCategoryChangeOverwrite?: (changes: UseSelectStateChange<string>) => void;
}

export function ConnectionCategoryKindInputs({
  name,
  errors,
  selectedCategory,
  selectedKind,
  setFieldValue
}: ConnectionCategoryKindInputsProps) {
  const onKindChange = useCallback(
    (changes: UseSelectStateChange<string>) => {
      setFieldValue(`${name}.kind`, changes.selectedItem);
    },
    [name, setFieldValue]
  );
  return (
    <>
      <FieldLabel htmlFor={`${name}.category`}>Category</FieldLabel>
      <FixedCategory>{selectedCategory}</FixedCategory>

      <ConnectionSelect
        key={`${name}.${selectedCategory}`}
        fieldName={`${name}.kind`}
        disabledMessage={selectedCategory ? null : "no category selected"}
        label="kind"
        items={connectionTypes[selectedCategory]?.map(({ kind }) => kind) || []}
        initialSelectedItem={selectedKind}
        onSelectedItemChange={onKindChange}
        errors={errors}
      />
    </>
  );
}
