import { useParams } from "react-router-dom";

import { useQuery } from "@apollo/client";
import {
  FetchInstanceBlocksQuery,
  FetchInstanceBlocksQueryVariables,
  FetchInstanceInfoQuery,
  FetchInstanceInfoQueryVariables,
  FetchRevisionQuery,
  FetchRevisionQueryVariables
} from "@src/generated/graphql";
import { useToastGraphqlError } from "@src/Hooks/toastGraphqlError";

import FetchInstanceBlocks from "./FetchInstanceBlocks.graphql";
import FetchInstanceInfo from "./FetchInstanceInfo.graphql";
import FetchRevision from "./FetchRevision.graphql";

export function useFetchInstanceData(pollInterval: number) {
  const { serviceChainId: id, revision } = useParams();
  const isRevision = !!revision;

  const {
    data: instanceInfo,
    error: instanceError,
    loading: instanceInfoLoading
  } = useQuery<FetchInstanceInfoQuery, FetchInstanceInfoQueryVariables>(FetchInstanceInfo, {
    variables: { id },
    skip: !id,
    errorPolicy: "all",
    fetchPolicy: "network-only",
    pollInterval: pollInterval
  });
  useToastGraphqlError(instanceError, "chart", "error fetching chart information from block repo");

  const {
    data: currentInstanceData,
    error: currentBlocksError,
    loading: loadingBlocks,
    refetch: refetchBlocksInfo
  } = useQuery<FetchInstanceBlocksQuery, FetchInstanceBlocksQueryVariables>(FetchInstanceBlocks, {
    variables: { id },
    skip: !id || !!revision,
    errorPolicy: "all",
    fetchPolicy: "network-only",
    pollInterval: pollInterval
  });

  useToastGraphqlError(
    currentBlocksError,
    "chart",
    "error fetching current instance chart information from block repo"
  );

  const {
    data: revisionData,
    error: revisionError,
    loading: loadingRevisions,
    refetch: refetchRevisionInfo
  } = useQuery<FetchRevisionQuery, FetchRevisionQueryVariables>(FetchRevision, {
    variables: { id, revision: +revision },
    skip: !id || !revision,
    errorPolicy: "all",
    fetchPolicy: "network-only",
    pollInterval: pollInterval
  });
  useToastGraphqlError(
    revisionError,
    "chart",
    "error fetching revision chart information from block repo"
  );

  const chain = instanceInfo?.serviceChain;

  const currentInstanceBlocks = currentInstanceData?.serviceChain?.blocks;
  const revisionBlocks = revisionData?.serviceChain?.history?.[0]?.blocks;
  const blocks = isRevision ? revisionBlocks : currentInstanceBlocks;
  const refetchBlocks = isRevision ? refetchRevisionInfo : refetchBlocksInfo;

  return {
    chain,
    instanceInfoLoading,
    blocks: blocks || [],
    blocksLoading: loadingBlocks || loadingRevisions,
    refetch: refetchBlocks
  };
}
