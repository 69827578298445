import React from "react";

import { OrgSelector, Select, SelectorWrapper } from "@src/Components/OrgSelector";
import { useIsSuperAdmin } from "@src/Hooks/isSuperAdmin";
import { useOrgInfo } from "@src/Hooks/orgInfo";
import { useUserOrgs } from "@src/Hooks/userOrgs";

import { HeaderItem } from "./HeaderItem";

interface HeaderOrgSelectorProps {
  selectOrg: (org: string) => void;
}

const HeaderOrgSelector = ({ selectOrg }: HeaderOrgSelectorProps) => {
  const { isSuperAdmin } = useIsSuperAdmin();
  const { orgId } = useOrgInfo();
  const { userOrgs, loading } = useUserOrgs();

  if (loading || isSuperAdmin || isSuperAdmin == null) return null;

  const selectedOrg = orgId;
  const organizations = userOrgs;

  return (
    <HeaderItem>
      Org:
      {selectedOrg && organizations ? (
        <OrgSelector
          selectedOrg={selectedOrg}
          organizations={organizations}
          selectOrg={selectOrg}
        />
      ) : (
        <SelectorWrapper>
          <Select>loading</Select>
        </SelectorWrapper>
      )}
    </HeaderItem>
  );
};

export default HeaderOrgSelector;
