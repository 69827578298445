import React, { ReactNode } from "react";

import { ButtonGroupModal } from "../Buttons/ButtonGroup";
import { DangerButton } from "../Buttons/Danger";
import { PrimaryTextButton } from "../Buttons/Text";
import { Modal, Title } from "./Modal";

interface ConfirmActionModalProps {
  modalShown: boolean;
  hideModal: () => void;
  confirmAction: () => void;
  title: string;
  children: ReactNode;
}

export function ConfirmActionModal({
  modalShown,
  hideModal,
  confirmAction,
  title,
  children
}: ConfirmActionModalProps) {
  return (
    <Modal show={modalShown} onOutsideClick={hideModal}>
      <Title>{title}</Title>
      {children}
      <ButtonGroupModal>
        <PrimaryTextButton onClick={hideModal}>cancel</PrimaryTextButton>
        <DangerButton onClick={confirmAction}>confirm</DangerButton>
      </ButtonGroupModal>
    </Modal>
  );
}
