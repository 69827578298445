import React, { useCallback } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { CellProps, Column } from "react-table";
import styled from "styled-components";

import { useMutation, useQuery } from "@apollo/client";
import RemoveIcon from "@img/remove.svg";
import { PrimaryButton } from "@src/Components/Buttons/Primary";
import { Modal } from "@src/Components/Modal/Modal";
import { RemoveModal } from "@src/Components/Modal/RemoveModal";
import { DeviceStatusTag } from "@src/Components/StatusTag";
import { ActionsColumn, TableComponent } from "@src/Components/Table/TableComponent";
import { H2 } from "@src/Components/Text";
import {
  FetchProvisionsQuery,
  FetchProvisionsQueryVariables,
  FetchSiteQuery,
  FetchSiteQueryVariables,
  Provision,
  RemoveProvisionMutation,
  RemoveProvisionMutationVariables
} from "@src/generated/graphql";
import { useRemoveRow } from "@src/Hooks/removeRow";
import { ExpandedObject, SiteTreeProps } from "@src/Hooks/siteTree";
import { useFetchTopSites } from "@src/Infrastructure/sites/fetchTopSites";
import { RemoveButton } from "@src/Infrastructure/sites/Inventory";
import { iconSize } from "@src/Instances/ActionsCell/iconSize";
import { DismissModalButton } from "@src/Instances/DismissModalButton";
import { SiteOnlyIdSelector } from "@src/ServiceDesigner/SiteIdSelector";

import FetchSite from "../../Infrastructure/sites/FetchSite.graphql";
import { useSelectSiteModal } from "../selectSiteModal";
import FetchProvisions from "./FetchProvisions.graphql";
import { ProgressBar } from "./ProgressBar";
import RemoveProvision from "./RemoveProvision.graphql";

const ProgressCell = styled.div`
  display: flex;
  gap: 5px;
`;

export const FirstLine = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
`;

const WrapperActions = styled.div`
  display: flex;
  gap: 10px;
`;

const Remove = styled(RemoveIcon)`
  ${iconSize}
  fill: ${({ theme }) => theme.purple};
`;

const columns: Array<Column<Provision>> = [
  {
    Header: "Name",
    accessor: "displayName",
    width: "16em"
  },
  {
    Header: "Progress",
    accessor: "stages",
    Cell: ({ value }) => (
      <ProgressCell>
        {value?.map(({ name, progress }) => (
          <ProgressBar key={name} name={name} progress={progress} />
        ))}
      </ProgressCell>
    )
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ value }) => <DeviceStatusTag status={value} />,
    width: "16em"
  }
];

export function ProvisioningOrgTab() {
  const { orgId } = useParams<{ orgId: string }>();
  return <ProvisionPage key={orgId} id={orgId} ancestors={[]} loading={false} />;
}
export function ProvisioningSiteTab() {
  const { siteId } = useParams<{ siteId: string }>();
  const { data, loading } = useQuery<FetchSiteQuery, FetchSiteQueryVariables>(FetchSite, {
    variables: {
      id: siteId
    },
    pollInterval: 3000
  });
  const ancestors = (data?.site?.ancestors || []).map(a => a.id);
  return <ProvisionPage key={siteId} id={siteId} ancestors={ancestors} loading={loading} />;
}

interface ProvisionPageProps {
  selectSitePage: SiteTreeProps["selectSitePage"];
}

function useProvisionPageProps() {
  return useOutletContext<ProvisionPageProps>();
}

function ProvisionPage({
  id,
  ancestors,
  loading
}: {
  id: string;
  ancestors: string[];
  loading: boolean;
}) {
  const { orgId } = useParams();
  const { data } = useQuery<FetchProvisionsQuery, FetchProvisionsQueryVariables>(FetchProvisions, {
    variables: { id: id },
    pollInterval: 3000
  });

  const { topSites, siteLoading } = useFetchTopSites(orgId);

  const { selectSitePage } = useProvisionPageProps();

  const { modalShown, entity, hide, openRowModal, onCompleted } = useRemoveRow();

  const [remove] = useMutation<RemoveProvisionMutation, RemoveProvisionMutationVariables>(
    RemoveProvision,
    {
      variables: { id: entity ? entity.id : "" },
      onCompleted,
      update(cache, { data: { removeProvision } }) {
        cache.evict({
          id: cache.identify({ __typename: "Provision", id: removeProvision })
        });
        cache.gc();
      }
    }
  );

  const actionColumn = {
    Header: "actions",
    accessor: "_actions" as ActionsColumn,
    width: "15%",
    disableSortBy: true,
    Cell: ({ row }: CellProps<object>) => {
      const { id, displayName } = row.original as Provision;
      return (
        <WrapperActions>
          <RemoveButton
            title={`remove ${id}`}
            onClick={() => openRowModal({ id, displayName, type: "provision" }, row)}
          >
            <Remove />
          </RemoveButton>
        </WrapperActions>
      );
    }
  };

  const { siteIsOrg, showModal, toggleModal } = useSelectSiteModal({
    siteId: id
  });

  const goToNewProvision = useCallback(
    (siteId: string, expanded: ExpandedObject) => {
      selectSitePage(siteId, expanded, "provision/new", orgId);
    },
    [selectSitePage, orgId]
  );

  const viewModal = showModal && !loading && !siteLoading;

  const siteAncestors = [...ancestors, id];

  return (
    <>
      <H2>Provisions</H2>
      <FirstLine>
        <PrimaryButton onClick={toggleModal}>New Provision</PrimaryButton>
      </FirstLine>
      <TableComponent<Provision>
        columnHeaders={columns}
        items={data?.provisions || []}
        rightActions={actionColumn}
      />
      {modalShown && <RemoveModal entity={entity} hideModal={hide} remove={remove} />}
      {viewModal && (
        <Modal show={true} onOutsideClick={toggleModal}>
          <SiteOnlyIdSelector
            onSelectSite={goToNewProvision}
            modalTitle="Select Site to add a new provision to"
            ancestors={siteAncestors}
            selectedSite={!siteIsOrg ? id : null}
            sites={topSites}
            toggleModal={toggleModal}
          />
          <DismissModalButton onClick={toggleModal} />
        </Modal>
      )}
    </>
  );
}
