import { useReducer } from "react";

interface CompletionsState {
  editingKey: number;
  editingValue: number;
  showingValues: number;
}

type CompletionsAction =
  | { type: "selectEditKey"; index: number }
  | { type: "toggleEditKey"; index: number }
  | { type: "showValues"; index: number }
  | { type: "selectEditValue"; index: number }
  | { type: "toggleEditValue"; index: number };

const initialState: CompletionsState = {
  editingKey: null,
  editingValue: null,
  showingValues: null
};

function completionsReducer(state: CompletionsState, action: CompletionsAction): CompletionsState {
  switch (action.type) {
    case "selectEditKey":
      return {
        ...state,
        editingKey: action.index,
        editingValue: null
      };
    case "toggleEditKey":
      return {
        ...state,
        editingKey: state.editingKey === action.index ? null : action.index,
        editingValue: null,
        showingValues: action.index
      };
    case "showValues":
      return {
        ...state,
        editingKey: null,
        editingValue: null,
        showingValues: action.index
      };
    case "selectEditValue":
      return {
        ...state,
        editingKey: null,
        editingValue: action.index
      };
    case "toggleEditValue":
      return {
        ...state,
        editingKey: null,
        editingValue: state.editingValue === action.index ? null : action.index
      };
  }

  return state;
}

export function useCompletions() {
  return useReducer(completionsReducer, initialState);
}
