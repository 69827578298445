import React from "react";
import styled from "styled-components";
import { Tag } from "../generated/graphql";

interface SelectedTagsProps {
  fixedTags: Tag[];
  tags: Tag[];
  remove: (index: number) => void;
}

const TagContainer = styled.div`
  grid-column: 2;
  min-height: 30px;
`;

export const TagMarker = styled.span<{ inc: boolean }>`
  background: lightgray;
  display: inline-block;
  padding: 4px;
  text-decoration: ${props => !props.inc && "line-through"};
  margin: 5px 10px 5px 0;
  border-radius: 12px;
`;

const FixedTag = styled(TagMarker)`
  color: gray;
`;

export function SelectedTags({ fixedTags, tags, remove }: SelectedTagsProps) {
  return (
    <TagContainer>
      {fixedTags.map((t, i) => (
        <FixedTag key={t.key + i} inc={t.inc}>
          {t.key}
        </FixedTag>
      ))}
      {tags.map((t, i) => (
        <TagMarker key={t.key + i} inc={t.inc}>
          {t.key}{" "}
          <button type="button" style={{ border: 0 }} onClick={() => remove(i)}>
            x
          </button>{" "}
        </TagMarker>
      ))}
    </TagContainer>
  );
}
