import React from "react";
import { keyframes } from "styled-components";

import { styled, theme } from "@src/Components/theme";

const loading = keyframes`
  from {
    left: 0;
  }
  to {
    left: 100%;
  }
`;

const barHeight = "30px";

const Bar = styled.div`
  position: relative;
  text-align: center;
  height: ${barHeight};
  width: 100%;
  background: transparent;
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 5px;
  white-space: nowrap;
`;

const Progress = styled.span<{
  backgroundColor: string;
  percentage: number;
  showAnimation: boolean;
}>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: ${({ percentage }) => percentage * 100 + "%"};
  background-color: ${({ backgroundColor }) => backgroundColor};

  ::before {
    display: ${({ showAnimation }) => (showAnimation ? "initial" : "none")};
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 5px;
    background-color: white;
    filter: blur(5px);
    border-radius: 50%;
    opacity: 0.5;

    animation: ${loading} 2s infinite;
  }

}`;

const Label = styled.span`
  position: absolute;
  padding: 0 3px;
  width: 100%;
  box-sizing: border-box;
  left: 0;
  line-height: ${barHeight};
  display: inline-flex;
  justify-content: center;
  column-gap: 0.5em;
`;

const Text = styled.span`
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface LoadingBarProps {
  steps: number;
  currentStep: number;
  color?: string;
  text?: string;
  animation?: boolean;
}

export function LoadingBar({
  steps,
  currentStep,
  color = theme.status.running,
  animation = true,
  text
}: LoadingBarProps) {
  const percentage = currentStep / steps;
  const showAnimation = animation && percentage > 0 && percentage < 1;

  return (
    <Bar>
      <Progress percentage={percentage} backgroundColor={color} showAnimation={showAnimation} />
      <Label title={text}>
        <Text>{text}</Text>
        <span>{`(${currentStep}/${steps})`}</span>
      </Label>
    </Bar>
  );
}
