import { UseSelectStateChange } from "downshift";
import React, { useCallback, useEffect, useState } from "react";

import Editor from "@monaco-editor/react";
import { Reload } from "@src/Components/Buttons/Reload";
import { InlineLoading, Loading } from "@src/Components/Loading/Loading";
import LoadingOverlay from "@src/Components/Loading/LoadingOverlay";
import { H3 } from "@src/Components/Text";
import { css, styled } from "@src/Components/theme";
import { LogEndpoint, LogSource } from "@src/generated/graphql";

import { DismissModalButton } from "../DismissModalButton";
import { useFetchBlockLogs } from "./fetchLogs";
import { LogEndpointSelector, SourcesSelector } from "./LogsSelectors";

const contentWidth = css`
  width: 800px;
  max-width: 80vw;
`;
const ModalWrapper = styled.div`
  ${contentWidth}
`;
const LabelSelectorWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`;

const EditorWrapper = styled.div`
  ${contentWidth}
  height: 650px;
  max-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  position: relative;
`;

type ConnectionsLogModalProps = {
  serviceChainId: string;
  selectedBlock: string;
  hideLogs: () => void;
};

export function ConnectionsLogModal({
  serviceChainId,
  selectedBlock,
  hideLogs
}: ConnectionsLogModalProps) {
  const [selectedEndpoint, setSelectedEndpoint] = useState<LogEndpoint>();
  const [selectedSource, setSelectedSource] = useState<LogSource>();

  const {
    endpoints,
    oneEndpoint,
    sources,
    logs,
    loadingEndpoints,
    loadingSources,
    loadingLogs,
    refetchLogs
  } = useFetchBlockLogs(serviceChainId, selectedBlock, selectedEndpoint, selectedSource);

  useEffect(() => {
    if (endpoints && endpoints.length === 1 && !selectedEndpoint) {
      setSelectedEndpoint(endpoints[0]);
    }
  }, [endpoints, selectedEndpoint, setSelectedEndpoint]);

  const selectEndpoint = useCallback(
    (selectorState: UseSelectStateChange<LogEndpoint>) => {
      const { selectedItem } = selectorState;
      setSelectedEndpoint(selectedItem);
      setSelectedSource(null);
    },
    [setSelectedEndpoint, setSelectedSource]
  );

  const selectSource = useCallback(
    (selectorState: UseSelectStateChange<LogSource>) => {
      const { selectedItem } = selectorState;
      setSelectedSource(selectedItem);
    },
    [setSelectedSource]
  );

  const refreshLogs = useCallback(() => {
    refetchLogs();
  }, [refetchLogs]);

  if (endpoints.length === 0) return <H3>No endpoints found</H3>;
  if (loadingEndpoints) return <Loading />;
  return (
    <ModalWrapper>
      <DismissModalButton onClick={hideLogs} />
      <LabelSelectorWrapper>
        <H3>Logs for</H3>
        {endpoints.length <= 1 ? (
          <>{oneEndpoint?.name !== "" ? oneEndpoint?.name : oneEndpoint.id}</>
        ) : (
          <LogEndpointSelector logEndpoints={endpoints} selectEndpoint={selectEndpoint} />
        )}
      </LabelSelectorWrapper>
      <LabelSelectorWrapper>
        Source:
        {loadingSources ? (
          <InlineLoading />
        ) : (
          <SourcesSelector
            sources={sources}
            selectedSource={selectedSource}
            selectSource={selectSource}
          />
        )}
        {logs.length !== 0 && <Reload onClick={refreshLogs} />}
      </LabelSelectorWrapper>
      <EditorWrapper>
        <LoadingOverlay loading={loadingLogs} />
        <Editor
          theme="vs-dark"
          value={
            sources.length === 0
              ? "Select an Endpoint and Source to receive logs"
              : logs || "no logs available for this source"
          }
          options={{ readOnly: true, wordWrap: "on" }}
        />
      </EditorWrapper>
    </ModalWrapper>
  );
}
