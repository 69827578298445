import React, { useCallback } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

import { useQuery } from "@apollo/client";
import EditIcon from "@img/edit.svg";
import RemoveIcon from "@img/remove.svg";
import { Clickable } from "@src/Components/Buttons/Clickable";
import { fieldLabel } from "@src/Components/Input/InputGroup";
import { LoadingPage } from "@src/Components/Loading/LoadingPage";
import { DeviceStatusTag } from "@src/Components/StatusTag";
import { H2 } from "@src/Components/Text";
import { styled } from "@src/Components/theme";
import { SmallConnectionMarker } from "@src/Devices/ConnectionMarker";
import DeviceView from "@src/Devices/DeviceView.graphql";
import { Marker, MarkerContainer } from "@src/Devices/PositionSelector";
import { DeviceViewEditor, SpecsView } from "@src/Devices/SpecsView";
import { ErrorPage } from "@src/Error/ErrorPage";
import { Device, DeviceViewQuery, DeviceViewQueryVariables } from "@src/generated/graphql";
import { useToggle } from "@src/Hooks/toggle";
import { iconSize } from "@src/Instances/ActionsCell/iconSize";
import { StaticMapboxMap } from "@src/Map/StaticMapboxMap";
import { MaybeMocked } from "@src/MaybeMocked";

import { ArrowCss, ArrowSelected } from "../../Components/arrowStyles";
import { DescriptionList } from "../../Components/DescriptionList";
import { mockSpec } from "../sites/ClusterMockedReponse";
import { Tag } from "../sites/Inventory";
import { ExternalResources } from "./ExternalResources";

export const StyledH2 = styled(H2)`
  padding-bottom: 10px;
  margin-bottom: 0;
  width: fit-content;
  margin-right: auto;
`;

const InformationWrapper = styled.div`
  padding: 0 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
`;

const StyledLabel = styled.div`
  ${fieldLabel}
  margin-top: 20px;
  grid-column: 1 / -1;
`;

const SpecsLabel = styled(StyledLabel)<{ expanded: boolean }>`
  cursor: pointer;
  width: fit-content;
  span {
    ${ArrowCss}
    border: solid #858585;
    border-width: 0 1px 1px 0;
    padding: 4px;
  }
  :hover > span {
    ${ArrowSelected}
  }
`;

const ConnectionsWrapper = styled.div`
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px 20px;
`;

const MapRow = styled.div`
  grid-column: 1 / -1;
  height: 500px;
  width: 100%;
  margin-top: auto;
  margin-bottom: 10px;
  border: 1px solid ${({ theme }) => theme.border};
`;

const MapPoint = styled.circle<{ status: string }>`
  fill: ${({ status, theme }) => {
    switch (status) {
      case "READY":
        return theme.success;

      default:
        return "#ccc";
    }
  }};
  stroke: #4a4a4a;
  stroke-width: 1;
`;

const FirstLine = styled.div`
  display: flex;
  gap: 10px;
  align-items: end;
`;

const Button = styled(Clickable)`
  height: 30px;
  margin-bottom: 10px;
`;

const Remove = styled(RemoveIcon)`
  ${iconSize}
`;

const Edit = styled(EditIcon)`
  height: 30px;
  width: 30px;
`;

interface DetailsProps {
  label: string;
  value: string | number;
}

function Details({ label, value }: DetailsProps) {
  return value ? (
    <>
      <dt>{label}</dt>
      <dd>{value}</dd>
    </>
  ) : null;
}

interface DeviceInformationProps {
  showRemoveModal: (device: Pick<Device, "displayName" | "id">) => void;
}

function useDeviceInformationProps() {
  return useOutletContext<DeviceInformationProps>();
}

export function DeviceInformation() {
  const { deviceId: id } = useParams<{ deviceId: string }>();
  const { showRemoveModal } = useDeviceInformationProps();
  const navigate = useNavigate();
  const { data, loading, error } = useQuery<DeviceViewQuery, DeviceViewQueryVariables>(DeviceView, {
    variables: { id }
  });
  const device = data?.devices?.devices[0];
  const sortedTags = (device?.tags || []).slice().sort();
  const { state: specsShown, toggle: toggleSpecs } = useToggle();

  const edit = useCallback(() => {
    if (!device) return;
    const editUrl = !device.crossplaneChart ? "edit" : "edit-crossplane";
    navigate(editUrl);
  }, [navigate, device]);

  return loading ? (
    <LoadingPage />
  ) : error ? (
    <ErrorPage error={error} />
  ) : (
    device && (
      <>
        <FirstLine>
          <StyledH2>{device.displayName}</StyledH2>
          <Button onClick={edit} title={`edit ${device.displayName}`}>
            Edit <Edit />
          </Button>
          <Button
            title={`remove ${device.displayName}`}
            onClick={() => showRemoveModal({ id, displayName: device.displayName })}
          >
            Remove <Remove />
          </Button>
        </FirstLine>
        <InformationWrapper>
          <DescriptionList>
            <Details label="ID" value={id} />
            <Details label="Position" value={`${device.position.lat}, ${device.position.lng}`} />
            <dt>Status</dt>
            <dd>
              <DeviceStatusTag status={device.status} />
            </dd>
            <dt>Tags</dt>
            <dd>
              {sortedTags.map(t => (
                <Tag key={t}>{t}</Tag>
              ))}
            </dd>
            {device.mac && <Details label="MAC address" value={device.mac} />}
            {device.nztpWorkflow && <Details label="Workflow" value={device.nztpWorkflow} />}
          </DescriptionList>
          {device.telemetryUrl.length > 0 && (
            <ExternalResources telemetryUrls={device.telemetryUrl} />
          )}
          {device.metadata && (
            <>
              <StyledLabel>metadata</StyledLabel>
              <DeviceViewEditor content={device.metadata} />
            </>
          )}
          {device.connections && device.connections.length > 0 && (
            <>
              <StyledLabel>connections</StyledLabel>
              <ConnectionsWrapper>
                {device.connections.map(conn => (
                  <SmallConnectionMarker key={conn.id} connection={conn} />
                ))}
              </ConnectionsWrapper>
            </>
          )}
          <StyledLabel>location</StyledLabel>
          <MapRow>
            <StaticMapboxMap device={device} zoom={10}>
              <MarkerContainer>
                <Marker>
                  <MapPoint r="4" cx={48} cy={48} status={status} />
                </Marker>
              </MarkerContainer>
            </StaticMapboxMap>
          </MapRow>
          <SpecsLabel
            expanded={specsShown}
            title={`${specsShown ? "hide" : "show"} specs`}
            onClick={toggleSpecs}
          >
            specs
            <span />
          </SpecsLabel>
          {specsShown && (
            <MaybeMocked condition={MOCK_DEVICE_METRICS} mocks={mockSpec}>
              <SpecsView deviceId={id} />
            </MaybeMocked>
          )}
        </InformationWrapper>
      </>
    )
  );
}
