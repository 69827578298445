import React, { useCallback } from "react";
import styled, { css } from "styled-components";

import { useQuery } from "@apollo/client";
import Editor from "@monaco-editor/react";
import { Reload } from "@src/Components/Buttons/Reload";
import LoadingOverlay from "@src/Components/Loading/LoadingOverlay";
import { Modal } from "@src/Components/Modal/Modal";
import { H3 } from "@src/Components/Text";
import {
  OktoResource,
  OktoResourceLogsQuery,
  OktoResourceLogsQueryVariables
} from "@src/generated/graphql";

import { DismissModalButton } from "./DismissModalButton";
import { OktoResourceLogs } from "./OktoResourceLogs.graphql";

const contentWidth = css`
  width: 900px;
  max-width: 80vw;
`;

const EditorWrapper = styled.div`
  ${contentWidth}
  height: 700px;
  max-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TopBar = styled.div`
  ${contentWidth}
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

const Title = styled(H3)`
  margin-top: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

interface LogsModalProps {
  resource: OktoResource;
  show: boolean;
  toggleModal: () => void;
}

export function LogsModal({ resource, show, toggleModal }: LogsModalProps) {
  const { id } = resource;
  const { data, loading, refetch } = useQuery<
    OktoResourceLogsQuery,
    OktoResourceLogsQueryVariables
  >(OktoResourceLogs, {
    variables: {
      id
    },
    skip: !show,
    notifyOnNetworkStatusChange: true
  });

  const reload = useCallback(() => refetch(), [refetch]);

  return (
    <Modal show={show} onOutsideClick={toggleModal}>
      <DismissModalButton onClick={toggleModal} />
      <TopBar>
        <Title>
          Logs: <strong>{resource.name}</strong>
        </Title>
        <Reload onClick={reload} />
      </TopBar>
      <EditorWrapper>
        <LoadingOverlay loading={loading} />
        <Editor
          theme="vs-dark"
          value={data?.oktoResourceLogs || "no logs available for this resource"}
          options={{ readOnly: true, wordWrap: "on" }}
        />
      </EditorWrapper>
    </Modal>
  );
}
