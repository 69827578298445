import { useReducer } from "react";

import { ApolloError, useQuery } from "@apollo/client";
import {
  BlockChart,
  PopulateMarketplaceQuery,
  PopulateMarketplaceQueryVariables
} from "@src/generated/graphql";

import {
  initialState,
  MarketplaceAction,
  marketplaceReducer,
  MarketplaceState,
  MarketplaceStateKey,
  sortEnum,
  viewEnum
} from "../ServiceDesigner/marketplaceReducer";
import PopulateMarketplace from "../ServiceDesigner/PopulateMarketplace.graphql";

type ListOptions = {
  [key: string]: viewEnum | sortEnum;
};

export const viewOptions: ListOptions = {
  Grid: viewEnum.grid,
  List: viewEnum.list
};

export const sortOptions: ListOptions = {
  AZ: sortEnum.AZ,
  ZA: sortEnum.ZA
};

export interface MarketplaceProps extends MarketplaceState {
  allCategories: string[];
  allVendors: string[];
  filteredBlocks: BlockChart[];
  loading: boolean;
  error: ApolloError;
  dispatch: React.Dispatch<MarketplaceAction>;
  repoUrl: string;
  refetch: () => void;
}

export interface MarketplaceParams {
  repoUrl: string;
  refetch: () => void;
  charts: BlockChart[];
  loading: boolean;
  error: ApolloError;
}

export function useBlocks() {
  const { loading, error, data, refetch } = useQuery<
    PopulateMarketplaceQuery,
    PopulateMarketplaceQueryVariables
  >(PopulateMarketplace, {
    variables: { category: null, vendors: [] },
    errorPolicy: "all",
    notifyOnNetworkStatusChange: true
  });

  const charts = data?.blockCharts ?? [];
  const repoUrl = data?.settings?.blockRepoUrl;

  return {
    loading,
    error,
    refetch,
    charts,
    repoUrl
  };
}

export function useMarketplace({
  loading,
  error,
  refetch,
  charts,
  repoUrl
}: MarketplaceParams): MarketplaceProps {
  const savedMarketplaceState = JSON.parse(
    window.localStorage.getItem(MarketplaceStateKey)
  ) as MarketplaceState;

  const [state, dispatch] = useReducer(marketplaceReducer, savedMarketplaceState ?? initialState);
  const { selectedCategory, selectedVendors, view, sort } = state;

  const allVendors = [...new Set(charts.map(bC => bC.vendor))].sort();
  const allCategories = Array.from(
    charts.reduce((prev, c) => new Set([...prev, ...(c.categories || [])]), new Set<string>())
  ).sort();

  const filteredBlocks = charts
    .filter(b => !selectedCategory || b.categories?.includes(selectedCategory))
    .filter(b => selectedVendors.length === 0 || selectedVendors.includes(b.vendor))
    .sort(sortByDisplayName(sort));

  return {
    allCategories,
    allVendors,
    filteredBlocks,
    selectedCategory,
    selectedVendors,
    sort,
    view,
    dispatch,
    repoUrl,
    loading,
    error,
    refetch
  };
}

const sortByDisplayName = (sort: number) => (b1: BlockChart, b2: BlockChart) => {
  if (sort === sortEnum.AZ)
    return b1.displayName?.toLowerCase() < b2.displayName?.toLowerCase() ? -1 : 1;
  else return b1.displayName?.toLowerCase() > b2.displayName?.toLowerCase() ? -1 : 1;
};
