import { useEffect, useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";

export function useDebounce<T>(value: T, delay: number, useEffectHook = useEffect) {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffectHook(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export function useDeepCompareDebounce<T>(value: T, delay: number): T {
  return useDebounce(value, delay, useDeepCompareEffect);
}
