import React, { useCallback } from "react";
import { useParams } from "react-router-dom";

import EditIcon from "@img/edit.svg";
import { styled } from "@src/Components/theme";
import { usePermissionCheck } from "@src/Hooks/permissionsCheck";
import { useToggle } from "@src/Hooks/toggle";

import { DisplayNameMiniForm, FormValues } from "./DisplayNameMiniForm";

export const Name = styled.span`
  font-weight: 700;
  cursor: pointer;
  display: inline-flex;
  align-items: center;

  :hover svg {
    opacity: 1;
  }
`;

const HoverEdit = styled(EditIcon)`
  opacity: 0.2;

  margin-left: 5px;

  height: 1em;
  width: 1em;
`;

interface EditableDisplayNameProps {
  id: string;
  displayName: string;
  mutate: (id: string, displayName: FormValues) => void;
}

export function EditableDisplayName({ id, displayName, mutate }: EditableDisplayNameProps) {
  const { siteId } = useParams();

  const { allowed: canEdit } = usePermissionCheck({
    relation: "write",
    objectType: "sites",
    objectId: siteId
  });
  const { state: edit, toggle: toggleEdit } = useToggle();

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      try {
        mutate(id, values);
      } catch {}
      toggleEdit();
    },
    [id, mutate, toggleEdit]
  );

  return edit ? (
    <DisplayNameMiniForm
      initialValue={displayName}
      handleSubmit={handleSubmit}
      onBlur={toggleEdit}
    />
  ) : (
    <Name onClick={canEdit ? toggleEdit : null} title="edit site display name">
      {displayName} {canEdit && <HoverEdit />}
    </Name>
  );
}
