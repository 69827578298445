import React, { ReactNode } from "react";
import styled from "styled-components";

interface CheckboxGroupProps {
  checked: boolean;
  onChange: () => void;
  disabled?: boolean;
  children?: ReactNode;
  noMargins?: boolean;
}

export const CheckboxField = styled.label`
  display: flex;
  align-items: baseline;
  padding-left: 10px;
`;

const Wrapper = styled.div<{ noMargins: boolean }>`
  margin: ${({ noMargins }) => (noMargins ? "0" : "15px 0 29px")};
`;

export const CheckboxInput = styled.input.attrs({ type: "checkbox" })`
  margin: 0;

  :disabled {
    cursor: not-allowed;
  }
`;

export function CheckboxGroup({ children, noMargins = false, ...props }: CheckboxGroupProps) {
  return (
    <CheckboxField>
      <Wrapper noMargins={noMargins}>
        <CheckboxInput {...props} />
      </Wrapper>
      {children}
    </CheckboxField>
  );
}
