import React, { useCallback } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import { useMutation } from "@apollo/client";
import { RemoveModal, RemoveModalProps } from "@src/Components/Modal/RemoveModal";
import { styled } from "@src/Components/theme";
import {
  DismissUserMutation,
  DismissUserMutationVariables,
  OrgUsersTableQuery,
  OrgUsersTableQueryVariables
} from "@src/generated/graphql";
import { useOnResetStorage } from "@src/Hooks/onResetStorage";
import { useRemoveRow } from "@src/Hooks/removeRow";
import { DismissUser } from "@src/User/DismissUser.graphql";
import { OrgUsersTable } from "@src/User/OrgUsersTable.graphql";

import { UserManagementContext } from "./userManagementContext";

export const FirstLine = styled.div`
  display: flex;
  gap: 10px;
`;

export function UserManagement() {
  const {
    modalShown,
    entity: selectedUser,
    hide: hideModal,
    openRowModal,
    onCompleted
  } = useRemoveRow();

  const { orgId } = useParams();

  const [remove] = useMutation<DismissUserMutation, DismissUserMutationVariables>(DismissUser, {
    variables: { id: selectedUser?.id, orgId },
    onCompleted,
    update(cache, { data: { dismissUser } }) {
      cache.updateQuery<OrgUsersTableQuery, OrgUsersTableQueryVariables>(
        { query: OrgUsersTable, variables: { org: orgId } },
        data => ({
          ...data,
          org: {
            ...data?.org,
            users: data?.org?.users.filter(user => user.id !== dismissUser) || []
          }
        })
      );
    }
  });

  const removeModalProps: RemoveModalProps = {
    entity: selectedUser,
    hideModal,
    remove
  };

  const navigate = useNavigate();
  useOnResetStorage(() => {
    navigate(`.`);
  });
  const navigateToOrg = useCallback(
    (orgId: string) => {
      navigate(orgId);
    },
    [navigate]
  );

  const context: UserManagementContext = {
    openRowModal,
    navigateToOrg
  };

  return (
    <>
      <Outlet context={context} />
      {modalShown && <RemoveModal key={"dismiss-user"} {...removeModalProps} />}
    </>
  );
}
