import { AllUsersTableQuery, User } from "@src/generated/graphql";

import { RecruitUserInput } from "./RecruitUserForm";

export function updateAllUsersTableCache(
  data: AllUsersTableQuery,
  values: RecruitUserInput,
  recruitUser: User
) {
  const orgRole = {
    id: values.org,
    role: values.role
  };
  const updatedUserArray = (data?.users || []).map(user =>
    user.id !== recruitUser.id
      ? user
      : {
          ...user,
          orgRoles: [...user.orgRoles, orgRole]
        }
  );
  return updatedUserArray.length
    ? {
        ...data,
        users: updatedUserArray
      }
    : undefined;
}
