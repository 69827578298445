import { useQuery } from "@apollo/client";
import { UserOrgsQuery } from "@src/generated/graphql";
import UserOrgs from "@src/User/UserOrgs.graphql";

export function useUserOrgs() {
  const { data, loading } = useQuery<UserOrgsQuery>(UserOrgs);

  const userOrgs = (data?.user?.orgRoles || []).map(rol => rol?.org);

  return {
    userOrgs,
    loading
  };
}
