import React from "react";
import styled from "styled-components";

import Globe from "@img/globe-solid.svg";
import { FieldLabel } from "@src/Components/Input/InputGroup";
import { TelemetryUrl } from "@src/generated/graphql";

const Wrapper = styled.div`
  padding: 5px 10px;
  height: fit-content;
  position: relative;

  ::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 10px;
    border: 3px solid #ccc;
    border-right: 0;
  }
`;

const Label = styled(FieldLabel)`
  display: block;
  padding: 0;
  text-align: start;
`;

const GlobeIcon = styled(Globe)`
  height: 20px;
  width: 20px;
`;

const A = styled.a`
  cursor: pointer;
  display: flex;
  height: 30px;
  gap: 5px;
  justify-content: space;
  align-items: center;
  width: fit-content;
  color: black;
  text-decoration: none;
  padding: 2px 5px;

  :hover {
    background-color: #ccc;
    border-radius: 5px;
  }
`;

interface ExternalResourcesProps {
  telemetryUrls: TelemetryUrl[];
}

export function ExternalResources({ telemetryUrls }: ExternalResourcesProps) {
  return (
    <Wrapper>
      <Label>External resources</Label>
      {telemetryUrls.map(({ url, displayName }, index) => (
        <A key={`${displayName}-${url}-${index}`} href={url} target="_blank">
          {displayName ? displayName : `Telemetry-${index + 1}`} <GlobeIcon />
        </A>
      ))}
    </Wrapper>
  );
}
