export const tagList = [
  "mec",
  "datacenter",
  "antenna",
  "node",
  "fognode",
  "ipcamera",
  "network",
  "edgenode",
  "cloudnode",
  "cabinet",
  "streetlight",
  "trafficlight",
  "switch",
  "router",
  "vswitch",
  "vrouter",
  "powerpanel",
  "iotgateway",
  "nearbybox",
  "pereiv",
  "llacuna",
  "campusnord",
  "asr9000",
  "csr1kv",
  "fira"
];

const reservedTags = new Set(["cloudlet", "nztp", "probed"]);

export function splitTags(tags: string[]) {
  return (tags || []).reduce(
    (prev, tag) => {
      const { fixedTags, tags } = prev;

      return reservedTags.has(tag)
        ? { ...prev, fixedTags: [...fixedTags, tag] }
        : { ...prev, tags: [...tags, tag] };
    },
    { fixedTags: [] as string[], tags: [] as string[] }
  );
}
