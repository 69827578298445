import React from "react";
import styled, { css } from "styled-components";

import Editor from "@monaco-editor/react";
import { Reload } from "@src/Components/Buttons/Reload";
import { Modal } from "@src/Components/Modal/Modal";
import { H3 } from "@src/Components/Text";

import { DismissModalButton } from "./DismissModalButton";

const contentWidth = css`
  width: 900px;
  max-width: 80vw;
`;

const EditorWrapper = styled.div`
  ${contentWidth}
  height: 700px;
  max-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TopBar = styled.div`
  ${contentWidth}
  position: relative;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

const Title = styled(H3)`
  margin-top: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

interface ManifestModalProps {
  resourceName: string;
  manifest: string;
  show: boolean;
  refetch: () => void;
  toggleModal: () => void;
}

export function ManifestModal({
  resourceName,
  manifest,
  show,
  refetch,
  toggleModal
}: ManifestModalProps) {
  return (
    <Modal show={show} onOutsideClick={toggleModal}>
      <DismissModalButton onClick={toggleModal} />
      <TopBar>
        <Title>
          Manifest: <strong>{resourceName}</strong>
        </Title>
        <Reload onClick={refetch} />
      </TopBar>
      <EditorWrapper>
        <Editor
          language="yaml"
          theme="vs-dark"
          value={manifest || ""}
          options={{ readOnly: true }}
        />
      </EditorWrapper>
    </Modal>
  );
}
