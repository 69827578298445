import { FormikProps } from "formik";
import React, { useCallback, useEffect, useRef } from "react";
import styled from "styled-components";

import { GhostButton } from "@src/Components/Buttons/Ghost";
import { PrimaryButton } from "@src/Components/Buttons/Primary";
import { InputGroup } from "@src/Components/Input/InputGroup";

import { DeviceValues } from "./AddDeviceForm";
import { ConnectionCategoryKindInputs } from "./ConnectionCategoryKindInputs";
import { connectionTypes } from "./connectionTypes";
import { EditDeviceValues } from "./EditDeviceForm";

const WrapperNewConnection = styled.div`
  margin: 30px 0;
  padding: 20px 30px;
  background-color: white;
  grid-template-columns: 1fr minmax(400px, 1fr) 1fr;
  grid-template-areas: ". form .";
`;

const NewConnection = styled.div`
  grid-area: form;
  display: grid;
  grid-template-columns: 100px 1fr;
`;

const WrapperSaveButton = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0 10px;
`;

interface ConnectionsFormProps extends FormikProps<DeviceValues | EditDeviceValues> {
  name: string;
  cancelConnection: () => void;
  saveConnection: () => void;
  isNew: boolean;
}

export const ConnectionForm = ({
  name,
  cancelConnection,
  saveConnection,
  isNew,
  ...formikProps
}: ConnectionsFormProps) => {
  const { getFieldMeta, setFieldValue, errors } = formikProps;
  const isFirstMount = useRef(true);
  const initialValue = useRef<DeviceValues>();

  useEffect(() => {
    if (!isFirstMount.current) return;
    isFirstMount.current = false;
    const { value } = getFieldMeta<DeviceValues>(name);
    initialValue.current = value;
  }, [getFieldMeta, name]);

  const cancel = useCallback(() => {
    if (!isNew) {
      setFieldValue(name, initialValue.current);
    }
    cancelConnection();
  }, [isNew, name, setFieldValue, cancelConnection]);

  const selectedCategory = "Video";
  const selectedKind = getFieldMeta(`${name}.kind`).value as string;

  return (
    <WrapperNewConnection>
      <NewConnection>
        <InputGroup name={`${name}.name`} label="name" errors={errors} />
        <ConnectionCategoryKindInputs
          name={name}
          errors={errors}
          selectedCategory={selectedCategory}
          selectedKind={selectedKind}
          setFieldValue={setFieldValue}
        />
        <InputGroup
          name={`${name}.source`}
          label="source"
          errors={errors}
          disabled={!(selectedCategory && selectedKind)}
          placeholder={
            (selectedCategory &&
              selectedKind &&
              connectionTypes[selectedCategory]?.find(({ kind }) => kind === selectedKind)
                ?.placeholder) ||
            "https://example.com, 10.0.0.1:36412, ..."
          }
        />
      </NewConnection>
      <WrapperSaveButton>
        <PrimaryButton onClick={cancel}>cancel</PrimaryButton>
        <GhostButton disabled={!!errors.connections} onClick={saveConnection}>
          {isNew ? "add" : "save"}
        </GhostButton>
      </WrapperSaveButton>
    </WrapperNewConnection>
  );
};
