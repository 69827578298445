import React, { useState, useCallback } from "react";
import { styled } from "../theme";
import { PrimaryTextButton } from "./Text";
import CopySVG from "@img/copy-regular.svg";

const Wrapper = styled.span`
  position: relative;
  margin-left: 0.5em;
`;

const CopyIcon = styled(CopySVG)`
  height: 1em;
`;

const Copied = styled.span`
  position: absolute;
  right: 0;
  bottom: -1.4em;
  white-space: nowrap;
`;

interface CopyButtonProps {
  value: string;
  btnText?: string;
}

export function CopyButton({ btnText, value }: CopyButtonProps) {
  const [copied, setCopied] = useState(false);
  const copy = useCallback(() => {
    navigator.clipboard.writeText(value);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  }, [value]);

  return (
    <Wrapper>
      <PrimaryTextButton onClick={copy}>
        <CopyIcon />
        {btnText}
      </PrimaryTextButton>
      {copied && <Copied>Copied to clipboard</Copied>}
    </Wrapper>
  );
}
