import React, { ReactNode, useReducer } from "react";
import { useOutletContext, useParams } from "react-router-dom";

import { Label } from "@src/Components/Label";
import { Loading } from "@src/Components/Loading/Loading";
import { ErrorPage } from "@src/Error/ErrorPage";
import { initialMapState, mapReducer } from "@src/Map/mapReducer";

import { MapView } from "../MapView";
import { OrgDescription, SiteDescription } from "./EditDescription";
import { FetchInfrastructureInformationProps } from "./fetchInfrastructureInformation";
import { useFetchOrgQuery } from "./fetchInfrastructureOrgInfo";
import { useFetchSiteQuery } from "./fetchInfrastructureSiteInfo";

interface OverviewProps extends FetchInfrastructureInformationProps {
  children: ReactNode;
}

interface OverviewContextProps {
  onDeviceMarkerClick: (devId: string) => void;
}

function useOverviewProps() {
  return useOutletContext<OverviewContextProps>();
}

export function OrganizationOverview() {
  const { orgId: id } = useParams<{ orgId: string }>();
  const overviewProps = useFetchOrgQuery(id);
  const { data: org } = overviewProps;
  return (
    <Overview {...overviewProps}>
      <OrgDescription id={id} description={org?.description} />
    </Overview>
  );
}
export function SiteOverview() {
  const { siteId: id } = useParams<{ siteId: string }>();
  const overviewProps = useFetchSiteQuery(id);
  const { data: site } = overviewProps;
  return (
    <Overview {...overviewProps}>
      <SiteDescription id={id} description={site?.description} />
    </Overview>
  );
}

function Overview({ data, loading, error, children: description }: OverviewProps) {
  const { onDeviceMarkerClick } = useOverviewProps();
  const [mapState, dispatch] = useReducer(mapReducer, initialMapState);

  return loading ? (
    <Loading />
  ) : error ? (
    <ErrorPage error={error} />
  ) : (
    data && (
      <>
        <Label>id</Label>
        <p>{data?.id}</p>
        {description}
        <Label>location</Label>
        {data.devices ? (
          <MapView
            devices={data.devices.devices || []}
            dispatch={dispatch}
            mapState={mapState}
            onMarkerClick={onDeviceMarkerClick}
          />
        ) : (
          <Loading />
        )}
      </>
    )
  );
}
