import React from "react";
import styled from "styled-components";

import DotIcon from "@img/dot.svg";
import { MaybeMocked } from "@src/MaybeMocked";

import { StatusTag } from "../Components/StatusTag";
import { Td, Tr } from "../Components/Table/Table";
import { OktoResource } from "../generated/graphql";
import { useToggle } from "../Hooks/toggle";
import { OktoResourceActionCell } from "./ActionsCell/OktoResourceActionCell";
import { LogsModal } from "./LogsModal";
import { ManifestModal } from "./ManifestModal";
import { mockLogs } from "./mockLogs";

const NameCell = styled(Td)<{ level: number }>`
  padding-left: ${({ level }) => `${25 + 10 * level}px`};
`;

const Kind = styled.span`
  display: block;
  margin-left: 24px;
  label {
    color: #a6a6a6;
    margin-right: 10px;
  }
`;

const Row = styled(Tr)`
  td .options {
    visibility: visible;
  }

  td .icons {
    visibility: hidden;
  }

  &:hover td .options {
    visibility: hidden;
  }

  &:hover td .icons {
    visibility: visible;
  }
`;

const Dot = styled(DotIcon)`
  width: 10px;
  padding-right: 14px;
`;

interface ResourceRowProps {
  resource: OktoResource;
  refetch: () => void;
}

export function ResourceRow({ resource, refetch }: ResourceRowProps) {
  const {
    state: manifestModalShown,
    on: showManifestModal,
    toggle: toggleManifestModal
  } = useToggle();
  const { state: logsModalShown, on: showLogsModal, toggle: toggleLogsModal } = useToggle();
  return (
    <>
      <Row>
        <NameCell level={0}>
          <Dot />
          {resource.name}
          <Kind>
            <label>kind:</label>
            {resource.kind}
          </Kind>
        </NameCell>
        <Td colSpan={3}>
          <StatusTag status={resource.status} />
          <ManifestModal
            refetch={refetch}
            resourceName={resource?.name}
            manifest={resource?.manifest}
            show={manifestModalShown}
            toggleModal={toggleManifestModal}
          />
          <MaybeMocked condition={MOCK_SERVICE_INSTANCES} mocks={mockLogs}>
            <LogsModal resource={resource} show={logsModalShown} toggleModal={toggleLogsModal} />
          </MaybeMocked>
        </Td>
        <OktoResourceActionCell showManifest={showManifestModal} showLogs={showLogsModal} />
      </Row>
    </>
  );
}
