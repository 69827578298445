import { FieldArray, Form, Formik, FormikProps } from "formik";
import { GraphQLError } from "graphql";
import React, { Dispatch, useCallback, useReducer, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";

import { ApolloError, useMutation } from "@apollo/client";
import { ButtonRowCustom, ButtonRowDefault } from "@src/Components/Buttons/ButtonRow";
import { DescriptionList } from "@src/Components/DescriptionList";
import { FileInputGroup } from "@src/Components/Input/FileInputGroup";
import { makeTag } from "@src/Devices/Tag/makeTag";
import { UploadFieldType } from "@src/Hooks/fileUpload";
import { useToggle } from "@src/Hooks/toggle";
import FetchSiteDevices from "@src/Infrastructure/sites/FetchSiteDevices.graphql";
import { InventoryContext } from "@src/Infrastructure/sites/Inventory";
import { LatLng } from "@src/Map/bounds";
import { initialMapState, MapAction, mapReducer, MapState } from "@src/Map/mapReducer";
import { useMapSettings } from "@src/Map/mapState";
import { Shape } from "@src/yupTypes";

import { ErrorContainer } from "../Components/ErrorContainer";
import { FieldLabel, Fieldset, InputField } from "../Components/Input/InputGroup";
import { Loading } from "../Components/Loading/Loading";
import { H2, H3 } from "../Components/Text";
import {
  AddDeviceMutation,
  AddDeviceMutationVariables,
  Connection,
  DeviceStatus,
  FetchSiteDevicesQuery,
  FetchSiteDevicesQueryVariables
} from "../generated/graphql";
import { SelectedTags, TagMarker } from "../ServiceDef/SelectedTags";
import { TagInput } from "../ServiceDef/TagInput";
import { ipv4 } from "../Services/TypeSelector";
import AddDevice from "./AddDevice.graphql";
import { ConnectionMarker } from "./ConnectionMarker";
import { DeviceConnectionsForm } from "./DeviceConnectionsForm";
import { NztpFields, NztpValues } from "./NztpFields";
import { PositionSelector } from "./PositionSelector";
import { workflows } from "./workflows";

interface CloudletValues {
  kubeconfig: string;
}

export interface DeviceValues extends NztpValues, CloudletValues {
  deviceType: DeviceType;
  displayName: string | null;
  site: string | null;
  tags: string[] | null;
  position: {
    lat: number;
    lng: number;
  };
  probeIp: string;
  connections: Connection[];
}

export function isValidMac(mac: string): boolean {
  return /^([0-9A-F]{2}[:-]){5}([0-9A-F]{2})$/i.test(mac);
}

const schema = Yup.lazy((values: DeviceValues) =>
  Yup.object<Shape<DeviceValues>>().shape({
    deviceType: Yup.mixed(),
    displayName: Yup.string().required("display name is required"),
    site: Yup.string().required(),
    position: Yup.object<Shape<LatLng>>().shape({
      lat: Yup.number().label("latitude").required("latitude is required"),
      lng: Yup.number().label("longitude").required("longitude is required")
    }),
    probeIp: Yup.string().test(
      "probe IP",
      "expected valid IPv4 address",
      value => !values.tags.includes("probed") || ipv4(value)
    ),
    mac: Yup.string().test(
      "MAC",
      "expected valid MAC address",
      value => values.deviceType !== "nztp" || isValidMac(value)
    ),
    tags: Yup.array().of(Yup.string()),
    workflow: Yup.string(),
    kubeconfig:
      values.deviceType === "cloudlet"
        ? Yup.string().required("kubeconfig is required for cloudlets")
        : Yup.string(),
    connections: Yup.array().of(
      Yup.object<Shape<Connection>>().shape({
        id: Yup.string(),
        name: Yup.string().required("name is required"),
        category: Yup.string().required("category is required"),
        kind: Yup.string().required("kind is required"),
        source: Yup.string().required("source is required")
      })
    )
  })
);

type DeviceType = "nztp" | "cloudlet";

interface AddDeviceFormProps {
  deviceType?: DeviceType;
}

export function AddDeviceForm({ deviceType }: AddDeviceFormProps) {
  const { returnToInventory: onComplete } = useOutletContext<InventoryContext>();

  const { siteId } = useParams<{ siteId: string }>();
  const [submitErrors, setSubmitErrors] = useState<Readonly<GraphQLError[]>>();
  const [addDevice] = useMutation<AddDeviceMutation, AddDeviceMutationVariables>(AddDevice);

  const [mapState, dispatch] = useReducer(mapReducer, initialMapState);
  const { longitude, latitude } = mapState;
  const mapIsReady = useMapSettings(mapState, dispatch);

  const { state: showReviewScreen, toggle: toggleReviewScreen } = useToggle();

  const initialValues: DeviceValues = {
    deviceType,
    displayName: "",
    site: siteId,
    tags: [],
    position: { lat: latitude, lng: longitude },
    mac: "",
    workflow: workflows[0],
    kubeconfig: "",
    probeIp: "",
    connections: []
  };

  return !mapIsReady ? (
    <Loading />
  ) : (
    <Formik<DeviceValues>
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={async values => {
        setSubmitErrors(null);
        try {
          const newDevice = {
            displayName: values.displayName.trim(),
            tags: values.tags.concat(values.deviceType || []),
            position: values.position,
            site: values.site,
            ...(values.deviceType === "nztp"
              ? {
                  mac: values.mac,
                  nztpServer: "provolone01",
                  nztpWorkflow: values.workflow
                }
              : null),
            ...(values.tags.includes("probed")
              ? {
                  probeIp: values.probeIp
                }
              : null),
            ...(values.deviceType === "cloudlet"
              ? {
                  kubeconfig: values.kubeconfig
                }
              : null),
            ...(values.connections.length > 0
              ? {
                  connections: values.connections
                }
              : null)
          };
          await addDevice({
            variables: {
              dev: newDevice
            },
            update(
              cache,
              {
                data: {
                  device: { id }
                }
              }
            ) {
              cache.updateQuery<FetchSiteDevicesQuery, FetchSiteDevicesQueryVariables>(
                {
                  query: FetchSiteDevices,
                  variables: { id: siteId }
                },
                data => ({
                  ...data,
                  site: {
                    ...data?.site,
                    devices: {
                      ...data?.site?.devices,
                      devices: [
                        ...(data?.site?.devices?.devices || []),
                        {
                          __typename: "Device",
                          ...newDevice,
                          id,
                          position: {
                            __typename: "LatLng",
                            ...newDevice.position
                          },
                          status: DeviceStatus.Unknown
                        }
                      ]
                    }
                  }
                })
              );
            }
          });
          onComplete();
        } catch (e) {
          const err: ApolloError = e;
          setSubmitErrors(err.graphQLErrors);
        }
      }}
    >
      {formikProps =>
        formikProps.isSubmitting ? (
          <Loading />
        ) : showReviewScreen ? (
          <ReviewForm
            {...formikProps}
            toggleReviewScreen={toggleReviewScreen}
            submitErrors={submitErrors}
          />
        ) : (
          <FormInner
            {...formikProps}
            mapState={mapState}
            dispatch={dispatch}
            toggleReviewScreen={toggleReviewScreen}
            onComplete={onComplete}
          />
        )
      }
    </Formik>
  );
}

interface FormInnerProps extends FormikProps<DeviceValues> {
  mapState: MapState;
  dispatch: Dispatch<MapAction>;
  toggleReviewScreen: () => void;
  onComplete: () => void;
}

export const Tags = styled.div`
  margin-bottom: 35px;
`;

function FormInner({
  mapState,
  dispatch,
  toggleReviewScreen,
  onComplete,
  ...formikProps
}: FormInnerProps) {
  const { errors, values, setFieldValue, isValid, isSubmitting } = formikProps;

  const setPosition = useCallback(
    (center: LatLng) => {
      setFieldValue("position", center, false);
    },
    [setFieldValue]
  );

  const tags = values.tags.map(key => makeTag(key));
  return (
    <>
      <H2>Add device</H2>

      <Form>
        <Fieldset>
          <FieldLabel htmlFor="displayName">display name</FieldLabel>
          <InputField name="displayName" errors={errors} />
          <FieldArray name="tags">
            {({ push, remove }) => (
              <>
                <FieldLabel>tags</FieldLabel>
                <Tags>
                  <SelectedTags fixedTags={[]} tags={tags} remove={remove} />
                  <TagInput tags={tags} add={t => push(t.key)} />
                </Tags>
              </>
            )}
          </FieldArray>
          {values.tags.includes("probed") ? (
            <>
              <FieldLabel htmlFor="probeIp">probe IP</FieldLabel>
              <InputField name="probeIp" errors={errors} />
            </>
          ) : null}
          {values.deviceType === "nztp" ? <NztpFields {...formikProps} /> : null}
          {values.deviceType === "cloudlet" ? (
            <FileInputGroup
              label="kubeconfig"
              name="kubeconfig"
              errors={errors}
              type={UploadFieldType.Yaml}
            />
          ) : null}
          <FieldArray name="connections">
            {({ push, remove, pop }) => (
              <DeviceConnectionsForm push={push} remove={remove} pop={pop} {...formikProps} />
            )}
          </FieldArray>
          <PositionSelector
            fieldValue={values.position}
            setFieldValue={setPosition}
            mapState={mapState}
            dispatch={dispatch}
          />

          <ButtonRowCustom
            onClickBack={onComplete}
            onClickSubmit={toggleReviewScreen}
            isValid={isValid}
            isSubmitting={isSubmitting}
          />
        </Fieldset>
      </Form>
    </>
  );
}

interface ReviewFormProps extends FormikProps<DeviceValues> {
  toggleReviewScreen: () => void;
  submitErrors: readonly GraphQLError[];
}

function ReviewForm({ values, isSubmitting, toggleReviewScreen, submitErrors }: ReviewFormProps) {
  return (
    <>
      <H2>Review device details</H2>
      {submitErrors ? (
        <ErrorContainer>
          <H3>Failed to add device</H3>
          <p>Please review the details and try again:</p>
          <ul>
            {submitErrors.map((err, i) => (
              <li key={i}>{err.message}</li>
            ))}
          </ul>
        </ErrorContainer>
      ) : null}
      <Form>
        <DescriptionList>
          <dt>Display Name</dt>
          <dd>{values.displayName}</dd>
          <dt>Tags</dt>
          <dd>
            {values.tags.length ? (
              values.tags.map(t => (
                <TagMarker key={t} inc>
                  {t}
                </TagMarker>
              ))
            ) : (
              <em>none</em>
            )}
          </dd>
          {values.tags.includes("probed") ? (
            <>
              <dt>Probe IP</dt>
              <dd>{values.probeIp}</dd>
            </>
          ) : null}
          {values.deviceType === "nztp" ? (
            <>
              <dt>MAC address</dt>
              <dd>{values.mac}</dd>
              <dt>Workflow</dt>
              <dd>{values.workflow}</dd>
            </>
          ) : null}
          <dt>Position</dt>
          <dd>
            {values.position.lat}, {values.position.lng}
          </dd>
          {values.connections.length > 0 ? (
            <>
              <dt>Connections</dt>
              <dd>
                {values.connections.map(c => (
                  <ConnectionMarker key={c.id} connection={c} status="new" />
                ))}
              </dd>
            </>
          ) : null}
        </DescriptionList>
        <ButtonRowDefault
          onClickBack={toggleReviewScreen}
          isSubmitting={isSubmitting}
          backText="Back"
          submitText="Add"
        />
      </Form>
    </>
  );
}
