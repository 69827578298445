import { ServiceChain } from "@src/generated/graphql";

interface Service {
  id: string;
  name: string;
}

export function defaultSort(a: Service, b: Service) {
  return a.name.localeCompare(b.name) || a.id.localeCompare(b.id);
}

export function sortServices(services: ServiceChain[]) {
  return (services || []).slice().sort(defaultSort);
}
