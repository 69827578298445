import { useQuery } from "@apollo/client";
import { FetchAuditLogsQuery, FetchAuditLogsQueryVariables } from "@src/generated/graphql";

import FetchAuditLogs from "./FetchAuditLogs.graphql";
import { validateParams } from "./TimeFilter/dateUtils";

export function useFetchAuditLogs(start: string, end: string, pageSize: string) {
  const startTime = !!Number(start) ? new Date(Number(start)).toISOString() : start;
  const endTime = !!Number(end) ? new Date(Number(end)).toISOString() : end;

  const { data, loading, refetch } = useQuery<FetchAuditLogsQuery, FetchAuditLogsQueryVariables>(
    FetchAuditLogs,
    {
      variables: { startTime, endTime, pageSize: Number(pageSize) },
      errorPolicy: "all",
      fetchPolicy: "network-only",
      skip: !validateParams(start, end, pageSize)
    }
  );

  return {
    totalCount: data?.auditLogs?.totalCount || 0,
    loading,
    pageInfo: data?.auditLogs?.pageInfo,
    logs: (data?.auditLogs?.edges || []).map(({ node }) => node),
    refetch
  };
}
