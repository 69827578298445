import React from "react";

import { useMutation, useQuery } from "@apollo/client";
import { Loading } from "@src/Components/Loading/Loading";
import { RemoveModal, RemoveModalProps } from "@src/Components/Modal/RemoveModal";
import {
  FederationHostsQuery,
  RemoveFederationHostMutation,
  RemoveFederationHostMutationVariables
} from "@src/generated/graphql";
import { useRemoveRow } from "@src/Hooks/removeRow";

import { FederationHosts } from "./FetchFederationHosts.graphql";
import { PartnerTable } from "./PartnerTable";
import { RemoveFederationHost } from "./RemoveFederationHost.graphql";

export function HostTable() {
  const { data, loading } = useQuery<FederationHostsQuery>(FederationHosts);
  const {
    modalShown,
    entity: selectedPartner,
    hide: hideModal,
    openRowModal,
    onCompleted
  } = useRemoveRow();

  const [remove] = useMutation<RemoveFederationHostMutation, RemoveFederationHostMutationVariables>(
    RemoveFederationHost,
    {
      variables: { id: selectedPartner?.id },
      onCompleted,
      update(cache, { data: { removeFederationHost } }) {
        cache.evict({
          id: cache.identify({ __typename: "FederationHost", id: removeFederationHost })
        });
        cache.gc();
      }
    }
  );

  const removeModalProps: RemoveModalProps = {
    entity: selectedPartner,
    hideModal,
    remove
  };

  return (
    <>
      {!loading ? (
        <PartnerTable
          partners={data?.federationHosts || []}
          linkTo="host"
          openRowModal={openRowModal}
        />
      ) : (
        <Loading />
      )}
      {modalShown && <RemoveModal {...removeModalProps} />}
    </>
  );
}
