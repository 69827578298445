import React from "react";

import Trash from "@img/remove.svg";
import { Clickable } from "@src/Components/Buttons/Clickable";
import { styled } from "@src/Components/theme";

const TrashClickable = styled(Clickable)`
  height: fit-content;
`;
const TrashIcon = styled(Trash)`
  opacity: 0.2;
  height: 18px;
  width: 18px;
  :hover {
    opacity: 1;
    fill: ${({ theme }) => theme.failure};
  }
`;

interface SiteActionProps {
  openModalRemove: () => void;
  removeDisabledReason?: string;
}

export function SiteActions({ openModalRemove, removeDisabledReason }: SiteActionProps) {
  return (
    <TrashClickable
      onClick={openModalRemove}
      disabled={!!removeDisabledReason}
      title={removeDisabledReason || "remove site"}
    >
      <TrashIcon />
    </TrashClickable>
  );
}
