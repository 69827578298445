import React, { useEffect, useState } from "react";

import { H1, H2 } from "@src/Components/Text";
import { useSession } from "@src/Session";

import { FirstLine, Wrapper } from "../ServiceDesigner/ServiceDesignerComponents";
import { BlockPublisher } from "./BlockPublisher";
import { publisherStorageKey } from "./publisherStorageKey";
import { BlockPublisherForm } from "./serialise";
import { TabBar } from "./TabBar";

export const emptyTemplateForm: BlockPublisherForm = {
  templateName: "",
  descriptor: {
    name: "",
    version: "",
    displayName: "",
    vendor: "",
    description: "",
    iconUrl: "",
    categories: []
  },
  values: "",
  overrides: "",
  selectedVersion: "",
  displayName: ""
};

export default function PublisherPage() {
  const { session } = useSession();
  const user = session?.identity?.traits;

  const [formValues, setFormValues] = useState<BlockPublisherForm>();

  useEffect(() => {
    if (!user) return;
    const state = localStorage.getItem(publisherStorageKey);
    try {
      const values = JSON.parse(state);
      const isSameUser = user.email === values.userEmail;
      const formValues = values.formValues as BlockPublisherForm;
      // avoid using old data in localStorage with chart YAML in a single string
      if (!isSameUser || typeof formValues.descriptor === "string") {
        setFormValues(emptyTemplateForm);
      }
      setFormValues(formValues);
    } catch {
      setFormValues(emptyTemplateForm);
    }
  }, [user]);

  return (
    <Wrapper>
      <FirstLine>
        <H1>Publisher</H1>
        <H2>create chart from template</H2>
      </FirstLine>
      <TabBar />
      {formValues && <BlockPublisher formValues={formValues} />}
    </Wrapper>
  );
}
