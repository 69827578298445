import { fieldLabel } from "@src/Components/Input/InputGroup";
import { styled } from "@src/Components/theme";

export const DescriptionList = styled.dl`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-row-gap: 1em;
  line-height: 2;
  height: fit-content;
  align-items: baseline;

  dt {
    ${fieldLabel};
  }
`;
