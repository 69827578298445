import React from "react";

import { styled } from "../theme";
import { Loading } from "./Loading";

const Overlay = styled.div`
  backdrop-filter: blur(3px);
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 10;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type LoadingOverlayProps = {
  loading: boolean;
};

export default function LoadingOverlay({ loading }: LoadingOverlayProps) {
  if (!loading) return null;
  return (
    <Overlay>
      <Loading />
    </Overlay>
  );
}
