import React, { Fragment, useCallback } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { CellProps, Column, Row } from "react-table";
import styled from "styled-components";

import { useQuery } from "@apollo/client";
import EditIcon from "@img/edit.svg";
import EyeIcon from "@img/eye.svg";
import RemoveIcon from "@img/remove.svg";
import { ButtonGroup } from "@src/Components/Buttons/ButtonGroup";
import { PrimaryButton } from "@src/Components/Buttons/Primary";
import { Loading } from "@src/Components/Loading/Loading";
import { LoadingPage } from "@src/Components/Loading/LoadingPage";
import { Modal } from "@src/Components/Modal/Modal";
import { DeviceStatusTag } from "@src/Components/StatusTag";
import { ActionsColumn, TableComponent } from "@src/Components/Table/TableComponent";
import { useSelectSiteModal } from "@src/Devices/selectSiteModal";
import { ErrorPage } from "@src/Error/ErrorPage";
import {
  Device,
  DeviceStatus,
  Role,
  SiteTitleQuery,
  SiteTitleQueryVariables
} from "@src/generated/graphql";
import { Entity } from "@src/Hooks/removeRow";
import { ExpandedObject, SiteTreeProps } from "@src/Hooks/siteTree";
import { useUserRole } from "@src/Hooks/userRole";
import { iconSize } from "@src/Instances/ActionsCell/iconSize";
import { DismissModalButton } from "@src/Instances/DismissModalButton";
import { SiteOnlyIdSelector } from "@src/ServiceDesigner/SiteIdSelector";

import { useFetchTopSites } from "./fetchTopSites";
import { ActionLink, RemoveButton, Tag } from "./Inventory";
import { useFetchOrgInventory, useFetchSiteInventory, UseInventoryFetch } from "./inventoryFetch";
import SiteTitle from "./SiteTitle.graphql";

const WrapperActions = styled.div`
  display: flex;
  gap: 10px;
`;

const Wrapper = styled.div`
  width: 100%;
`;

const Remove = styled(RemoveIcon)`
  ${iconSize}
  fill: #444b88;
`;

const Edit = styled(EditIcon)`
  ${iconSize}
  fill: #444b88;
`;

const Eye = styled(EyeIcon)`
  ${iconSize}
  color: #444b88;
`;

const InventoryTableActions = styled(ButtonGroup)`
  justify-content: flex-end;
`;

export function OrgInventoryTab() {
  const { orgId } = useParams<{ orgId: string }>();
  const inventoryProps = useFetchOrgInventory(orgId);
  return <InventoryTable key={orgId} id={orgId} {...inventoryProps} />;
}

export function SiteInventoryTab() {
  const { siteId } = useParams<{ siteId: string }>();
  const inventoryProps = useFetchSiteInventory(siteId);
  return <InventoryTable key={siteId} id={siteId} {...inventoryProps} />;
}

interface InventoryTableProps {
  openRowModal: (entity: Entity, row: Row) => void;
  selectSitePage: SiteTreeProps["selectSitePage"];
}

function useInventoryTableProps() {
  return useOutletContext<InventoryTableProps>();
}

interface InventoryProps extends UseInventoryFetch {
  id: string;
}

function InventoryTable({ id, devices, ancestors, loading, error }: InventoryProps) {
  const { openRowModal, selectSitePage } = useInventoryTableProps();
  const { orgId } = useParams();
  const { topSites, siteLoading } = useFetchTopSites(orgId);
  const columns: Array<Column<object>> = [
    {
      Header: "Name",
      accessor: "displayName",
      width: "30%"
    },
    {
      Header: "Site",
      accessor: "site",
      Cell: ({ value }: CellProps<object>) => {
        const { data, error, loading } = useQuery<SiteTitleQuery, SiteTitleQueryVariables>(
          SiteTitle,
          {
            variables: { id: value }
          }
        );
        return loading ? <Loading /> : error ? <>{value}</> : <>{data?.site?.displayName}</>;
      },
      width: "20%"
    },
    {
      Header: "Tags",
      accessor: "tags",
      Cell: ({ value }: CellProps<object>) => {
        const sorted = (value || []).slice().sort();
        return (
          <>
            {sorted.map((t: string) => (
              <Tag key={t}>{t}</Tag>
            ))}
          </>
        );
      },
      width: "20%"
    },
    {
      Header: "Status",
      accessor: "status",
      width: "15%",
      Cell: ({ value }: CellProps<object>) => <DeviceStatusTag status={value as DeviceStatus} />
    }
  ];

  const { userRole } = useUserRole();
  const canAdd = (userRole ?? Role.Viewer) !== Role.Viewer;
  const isViewer = !canAdd;

  const actionColumn = {
    Header: "actions",
    accessor: "_actions" as ActionsColumn,
    width: "15%",
    disableSortBy: true,
    Cell: ({ row }: CellProps<object>) => {
      const { id, displayName, crossplaneChart } = row.original as Device;
      const editUrl = !crossplaneChart ? `${id}/edit` : `${id}/edit-crossplane`;
      return (
        <WrapperActions key="actions">
          <ActionLink title={`view ${displayName}`} to={id}>
            <Eye />
          </ActionLink>
          <ActionLink disabled={isViewer} title={`edit ${displayName}`} to={editUrl}>
            <Edit />
          </ActionLink>
          <RemoveButton
            title={`remove ${displayName}`}
            onClick={() => openRowModal({ id, displayName, type: "device" }, row)}
            disabled={isViewer}
          >
            <Remove />
          </RemoveButton>
        </WrapperActions>
      );
    }
  };

  const goToAddInventory = useCallback(
    (siteId: string, expanded: ExpandedObject) => {
      selectSitePage(siteId, expanded, "inventory/add", orgId);
    },
    [selectSitePage, orgId]
  );
  const { siteIsOrg, showModal, toggleModal } = useSelectSiteModal({
    siteId: id
  });

  const siteAncestors = (ancestors || []).map(ancestor => ancestor.id).concat(id);

  return (
    <>
      <Wrapper>
        {loading ? (
          <LoadingPage />
        ) : error ? (
          <ErrorPage error={error} />
        ) : (
          <>
            <TableComponent
              columnHeaders={columns}
              items={devices || []}
              rightActions={actionColumn}
              rowSelection={true}
              pagination={true}
            />
            <InventoryTableActions>
              {canAdd ? (
                <PrimaryButton onClick={toggleModal}>Add</PrimaryButton>
              ) : (
                <PrimaryButton disabled>add</PrimaryButton>
              )}
            </InventoryTableActions>
          </>
        )}
      </Wrapper>
      {showModal && !siteLoading && (
        <Modal show={true} onOutsideClick={toggleModal}>
          <SiteOnlyIdSelector
            sites={topSites}
            onSelectSite={goToAddInventory}
            modalTitle="Select a Site to add a device to"
            ancestors={siteAncestors}
            selectedSite={!siteIsOrg ? id : null}
            toggleModal={toggleModal}
          />
          <DismissModalButton onClick={toggleModal} />
        </Modal>
      )}
    </>
  );
}
