import { FormikProps } from "formik";
import React, { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import { NavigateFunction, Outlet, useNavigate } from "react-router-dom";

import { useInterval } from "@src/Hooks/interval";
import { useSession } from "@src/Session";

import { PublishState } from "./publishBlock";
import { publisherStorageKey } from "./publisherStorageKey";
import { BlockPublisherForm } from "./serialise";

interface Context extends FormikProps<BlockPublisherForm> {
  publishState: PublishState;
  setPublishState: Dispatch<SetStateAction<PublishState>>;
  navigate: NavigateFunction;
}

interface BlockPublisherFormProps extends FormikProps<BlockPublisherForm> {
  publishState: PublishState;
  setPublishState: Dispatch<SetStateAction<PublishState>>;
}

export function BlockPublisherForm({
  publishState,
  setPublishState,
  ...formikProps
}: BlockPublisherFormProps) {
  const { values } = formikProps;
  const { session } = useSession();
  const navigate = useNavigate();
  const persistState = useCallback(() => {
    const userEmail = session?.identity?.traits?.email;
    if (document.hidden || !userEmail) return;

    window.localStorage.setItem(
      publisherStorageKey,
      JSON.stringify({
        userEmail,
        formValues: values
      })
    );
  }, [session, values]);

  useEffect(() => {
    return persistState;
  }, [persistState]);

  useInterval(persistState, 2000);

  const context: Context = {
    publishState,
    setPublishState,
    navigate,
    ...formikProps
  };

  return <Outlet context={context} />;
}
