import React from "react";

import { Tab } from "@src/Components/Navigation/Tab";
import { Role } from "@src/generated/graphql";
import { useUserRole } from "@src/Hooks/userRole";

export function TabBar() {
  const { userRole } = useUserRole();

  return (
    <nav>
      <Tab to="marketplace">Marketplace</Tab>
      <Tab to="designer">Designer</Tab>
      <Tab disabled={!(userRole === Role.Admin || userRole === Role.Operator)} to="deploy">
        Deployment
      </Tab>
    </nav>
  );
}
