import { FormikProps } from "formik";
import React, { ChangeEvent, Dispatch, useCallback, useRef } from "react";
import toast from "react-hot-toast";

import { useLazyQuery } from "@apollo/client";
import Reset from "@img/bolt.svg";
import Remove from "@img/remove.svg";
import {
  BlockListWrapper,
  FirstLineButtonGroup,
  FlexRow,
  Grid,
  Item,
  ItemLine,
  Provider,
  ScrollDiv,
  Select,
  StyledClickable,
  StyledH3
} from "@src/Components/BlockSelector/BlockListStyles";
import { BlockName, Image } from "@src/Components/BlockSelector/BlockStyles";
import { ConfirmActionModal } from "@src/Components/Modal/ConfirmActionModal";
import { FetchBlockChartQuery, FetchBlockChartQueryVariables } from "@src/generated/graphql";
import { useToggle } from "@src/Hooks/toggle";

import { BlockItemName } from "./BlockItemName";
import { InstancesModal } from "./BlockModal";
import { DesignerEditorAction, DesignerEditorState } from "./editorDesignerReducer";
import FetchBlockChart from "./FetchBlockChart.graphql";
import { useFetchBlockInformation } from "./fetchBlockInformation";
import { FormBlock, ServiceForm } from "./serialise";

interface ListItemProps extends FormikProps<ServiceForm> {
  block: FormBlock;
  selected: boolean;
  selectBlock: (displayName: string) => void;
  removeMultipleBlocks: (blocks: FormBlock[]) => void;
  editorDispatch: Dispatch<DesignerEditorAction>;
}

function ListItem({
  block,
  selected,
  selectBlock,
  removeMultipleBlocks,
  editorDispatch,
  ...formikProps
}: ListItemProps) {
  const { displayName, selectedVersion, name } = block;
  const { values, setValues } = formikProps;

  const { state: isOpenRemove, on: openRemove, off: hideRemove } = useToggle();
  const removeItem = useCallback(() => {
    removeMultipleBlocks([block]);
    hideRemove();
  }, [removeMultipleBlocks, hideRemove, block]);

  const { chart: blockInfo, loading } = useFetchBlockInformation(name, selectedVersion);

  const selectedVersionRef = useRef(selectedVersion);

  const [fetchChartInfo] = useLazyQuery<FetchBlockChartQuery, FetchBlockChartQueryVariables>(
    FetchBlockChart,
    {
      fetchPolicy: "cache-only"
    }
  );

  const setBlockValues = useCallback(
    (version: string, overrides?: string) => {
      setValues({
        ...values,
        blocks: {
          ...values.blocks,
          [displayName]: {
            ...values.blocks?.[displayName],
            values: overrides || values.blocks?.[displayName]?.values,
            selectedVersion: version
          }
        }
      });
    },
    [displayName, setValues, values]
  );

  const onChangeVersion = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const version = e.target.value;
      if (selectedVersionRef.current === version) return;
      selectedVersionRef.current = version;
      setTimeout(() => {
        if (
          confirm(
            "Do you want to overwrite current values in the editor with the defaults for the selected version?"
          )
        ) {
          fetchChartInfo({
            variables: {
              name: name,
              version: version
            }
          })
            .then(({ data }) => {
              setBlockValues(version, data?.blockChart?.overridesYaml);
              editorDispatch({
                type: "changeVersion",
                payload: {
                  block: displayName,
                  value: data?.blockChart?.overridesYaml || ""
                }
              });
            })
            .catch(e => {
              console.error(e);
              toast.error("failed to fetch defaults for selected version");
            });
        } else {
          setBlockValues(version);
        }
      });
    },
    [displayName, editorDispatch, fetchChartInfo, name, setBlockValues]
  );

  if (loading) return null;

  return (
    <>
      <Item
        onClick={() => selectBlock(displayName)}
        selected={selected}
        title={`${displayName} block`}
      >
        <ItemLine>
          <Image src={blockInfo?.logoUrl} alt={`${blockInfo?.vendor} logo`} />
          <FirstLineButtonGroup>
            <StyledClickable onClick={openRemove} title="remove block list">
              <Remove width="15px" height="15px" />
            </StyledClickable>
          </FirstLineButtonGroup>
        </ItemLine>
        <Grid>
          <div>
            <BlockItemName
              displayName={displayName}
              editorDispatch={editorDispatch}
              {...formikProps}
            />
            <Provider>by {blockInfo?.vendor}</Provider>
          </div>
          <div>
            <BlockName>version</BlockName>
            <Select onChange={onChangeVersion} value={selectedVersion}>
              {blockInfo?.availableVersions.map(v => (
                <option key={v} value={v}>
                  {v}
                </option>
              ))}
            </Select>
          </div>
        </Grid>
      </Item>
      <ConfirmActionModal
        hideModal={hideRemove}
        modalShown={isOpenRemove}
        confirmAction={removeItem}
        title={`Remove ${block.displayName}`}
      >
        <p>The current configuration will be lost.</p>
      </ConfirmActionModal>
    </>
  );
}

interface BlockListProps extends FormikProps<ServiceForm> {
  selectBlock: (displayName: string) => void;
  editorStatus: DesignerEditorState;
  editorDispatch: Dispatch<DesignerEditorAction>;
}

export function BlockList({
  selectBlock,
  editorStatus,
  editorDispatch,
  ...formikProps
}: BlockListProps) {
  const {
    values: { blocks },
    setFieldValue
  } = formikProps;
  const { state: isShownRemove, on: showRemove, off: hideRemove } = useToggle();
  const { state: isShownReset, on: showReset, off: hideReset } = useToggle();

  const removeMultipleBlocks = useCallback(
    (removeBlocks: FormBlock[]) => {
      const blockNames = new Set(removeBlocks.map(b => b.displayName));

      const filteredBlocks = Object.keys(blocks).reduce((prev, n) => {
        if (blockNames.has(n)) return prev;
        return {
          ...prev,
          [n]: blocks[n]
        };
      }, {} as { [displayName: string]: FormBlock });

      if (!filteredBlocks[editorStatus.selectedBlock]) {
        const filteredBlockNames = Object.keys(filteredBlocks);
        selectBlock(filteredBlockNames[0]);
      }
      setFieldValue("blocks", filteredBlocks);
    },
    [blocks, editorStatus.selectedBlock, selectBlock, setFieldValue]
  );

  const resetMultipleBlocks = useCallback(
    (resetBlocks: FormBlock[]) => {
      const blockNames = new Set(resetBlocks.map(b => b.displayName));

      const filteredBlocks = Object.keys(blocks).reduce(
        (prev, n) => ({
          ...prev,
          [n]: {
            ...blocks[n],
            ...(blockNames.has(n) ? { values: null } : null)
          }
        }),
        {} as { [displayName: string]: FormBlock }
      );
      setFieldValue("blocks", filteredBlocks);
    },
    [setFieldValue, blocks]
  );

  return (
    <>
      <BlockListWrapper>
        <FlexRow>
          <StyledH3>Selected blocks</StyledH3>
          <FirstLineButtonGroup>
            <StyledClickable onClick={showReset} title="reset block list">
              <Reset width="20px" height="20px" />
            </StyledClickable>
            <StyledClickable onClick={showRemove} title="remove block list">
              <Remove width="25px" height="25px" />
            </StyledClickable>
          </FirstLineButtonGroup>
        </FlexRow>
        <ScrollDiv>
          {Object.values(blocks).map(b => (
            <ListItem
              block={b}
              key={b.displayName}
              removeMultipleBlocks={removeMultipleBlocks}
              selectBlock={selectBlock}
              editorDispatch={editorDispatch}
              selected={editorStatus.selectedBlock && editorStatus.selectedBlock === b.displayName}
              {...formikProps}
            />
          ))}
        </ScrollDiv>
      </BlockListWrapper>
      <InstancesModal
        selectedBlocks={Object.values(blocks)}
        modalShown={isShownRemove}
        hideModal={hideRemove}
        action={removeMultipleBlocks}
        actionName="remove"
      />
      <InstancesModal
        selectedBlocks={Object.values(blocks)}
        modalShown={isShownReset}
        hideModal={hideReset}
        action={resetMultipleBlocks}
        actionName="reset"
      />
    </>
  );
}
