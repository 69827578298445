import React from "react";
import { useSelect, UseSelectStateChange } from "downshift";

import {
  FieldLabel,
  InputErrors,
  InputFieldWrapper,
  PaddedError
} from "@src/Components/Input/InputGroup";
import { styled } from "@src/Components/theme";
import { Menu, DropdownMenuItemWrapper, MenuAnchor } from "@src/Components/DropdownMenu";
import { baseInputStyles } from "@src/Components/Input/Input";

const Select = styled.button`
  ${baseInputStyles}
  text-align: start;
`;

interface ConnectionSelectProps {
  fieldName: string;
  disabledMessage?: string;
  label: string;
  items: string[];
  initialSelectedItem: string;
  onSelectedItemChange: (changes: UseSelectStateChange<string>) => void;
  errors: Record<string, unknown>;
}

export function ConnectionSelect({
  fieldName,
  disabledMessage,
  label,
  items,
  initialSelectedItem,
  onSelectedItemChange,
  errors
}: ConnectionSelectProps) {
  const {
    getToggleButtonProps,
    selectedItem,
    getMenuProps,
    isOpen,
    highlightedIndex,
    getItemProps
  } = useSelect({
    items,
    initialSelectedItem,
    onSelectedItemChange
  });

  return (
    <>
      <FieldLabel htmlFor={fieldName}>{label}</FieldLabel>
      <InputFieldWrapper>
        <Select disabled={!!disabledMessage} type="button" {...getToggleButtonProps()}>
          {disabledMessage || selectedItem || `select ${label}`}
        </Select>
        <MenuAnchor {...getMenuProps()}>
          {isOpen && (
            <Menu>
              {items.map((item, index) => (
                <DropdownMenuItemWrapper
                  key={item}
                  highlighted={highlightedIndex === index}
                  {...getItemProps({ item, index })}
                >
                  {item}
                </DropdownMenuItemWrapper>
              ))}
            </Menu>
          )}
        </MenuAnchor>
        <PaddedError>
          <InputErrors errors={errors} name={fieldName} />
        </PaddedError>
      </InputFieldWrapper>
    </>
  );
}
