import React, { useCallback } from "react";
import { useParams } from "react-router-dom";

import { useQuery } from "@apollo/client";
import { Loading } from "@src/Components/Loading/Loading";
import { styled } from "@src/Components/theme";
import {
  OrgTitleQuery,
  OrgTitleQueryVariables,
  SiteTitleQuery,
  SiteTitleQueryVariables
} from "@src/generated/graphql";
import { usePermissionCheck } from "@src/Hooks/permissionsCheck";
import { Entity } from "@src/Hooks/removeRow";
import { NavigateSiteItem } from "@src/Hooks/siteTree";

import { StyledH2 } from "../devices/DeviceInformation";
import { SiteActions } from "./Actions";
import { EditableDisplayName, Name } from "./EditableDisplayName";
import { useEditSiteDisplayName } from "./editDisplayName";
import OrgTitle from "./OrgTitle.graphql";
import SiteTitle from "./SiteTitle.graphql";

const TitleSpan = styled.span`
  cursor: pointer;
  padding: 0 5px;
  :hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.primary};
  }
`;

interface ItemTitleProps {
  id: string;
  selectTreeItem: (siteId: string, ancestors: string[], navigateTo: NavigateSiteItem) => void;
  showRemoveModal: (entity: Entity) => void;
  removeDisabledReason: string | null;
}

export function ItemTitle({
  id,
  selectTreeItem,
  showRemoveModal,
  removeDisabledReason
}: ItemTitleProps) {
  const { orgId } = useParams();
  const isOrgTitle = orgId === id;

  const { data, loading } = useQuery<OrgTitleQuery, OrgTitleQueryVariables>(OrgTitle, {
    variables: { id },
    skip: !isOrgTitle
  });

  if (loading) return <Loading />;
  return (
    <>
      {isOrgTitle ? (
        <StyledH2>
          <Name>{data.org.displayName}</Name>
        </StyledH2>
      ) : (
        <SiteItemTitle
          siteId={id}
          selectTreeItem={selectTreeItem}
          showRemoveModal={showRemoveModal}
          removeDisabledReason={removeDisabledReason}
        />
      )}
    </>
  );
}

const NameChainAndActions = styled(StyledH2)`
  display: flex;
  align-items: center;
`;

interface SiteTitleProps {
  siteId: string;
  selectTreeItem: (siteId: string, ancestors: string[], navigateTo: NavigateSiteItem) => void;
  showRemoveModal: (entity: Entity) => void;
  removeDisabledReason: string | null;
}

function SiteItemTitle({
  siteId: id,
  selectTreeItem,
  showRemoveModal,
  removeDisabledReason
}: SiteTitleProps) {
  const { siteId, orgId } = useParams();

  const { data, loading } = useQuery<SiteTitleQuery, SiteTitleQueryVariables>(SiteTitle, {
    variables: { id }
  });

  const ancestors = [data?.site?.org, ...(data?.site?.ancestors || [])];

  const goToSiteTreeItem = useCallback(
    (treeItemId: string, ancestors: string[]) => {
      const treeItemIsOrg = treeItemId === orgId;
      const navigateTo: NavigateSiteItem = treeItemIsOrg
        ? {
            orgId
          }
        : {
            orgId,
            siteId: treeItemId
          };
      selectTreeItem(treeItemId, [orgId, ...ancestors], navigateTo);
    },
    [selectTreeItem, orgId]
  );

  const showRemoveSiteModal = useCallback(() => {
    showRemoveModal({ id: data?.site?.id, displayName: data?.site?.displayName, type: "site" });
  }, [data?.site, showRemoveModal]);

  const { allowed: removePermission } = usePermissionCheck({
    relation: "delete",
    objectType: "sites",
    objectId: siteId
  });

  const { mutateSite } = useEditSiteDisplayName();

  return loading ? (
    <Loading />
  ) : data ? (
    <NameChainAndActions>
      {ancestors.map((a, i) => {
        return (
          <React.Fragment key={a.id}>
            <TitleSpan
              title={`click to navigate to ${a.displayName} site`}
              onClick={() =>
                goToSiteTreeItem(
                  a.id,
                  data.site.ancestors.slice(0, i).map(a => a.id)
                )
              }
            >
              {a.displayName}
            </TitleSpan>
            /
          </React.Fragment>
        );
      })}
      <EditableDisplayName mutate={mutateSite} id={id} displayName={data.site.displayName} />
      {removePermission && (
        <SiteActions
          openModalRemove={showRemoveSiteModal}
          removeDisabledReason={removeDisabledReason}
        />
      )}
    </NameChainAndActions>
  ) : (
    <StyledH2>
      <strong>Site: {id}</strong>
    </StyledH2>
  );
}
