import React, { ReactNode } from "react";
import styled from "styled-components";

import OptionsIcon from "@img/options.svg";
import { Td } from "@src/Instances/Table";

const OptionsWrapper = styled.svg`
  height: 45px;
  width: 45px;
  position: absolute;
`;

function Options() {
  return (
    <OptionsWrapper className="options" viewBox="0 0 512 512">
      <OptionsIcon />
    </OptionsWrapper>
  );
}

const CellInner = styled.div`
  min-width: 100px;
  position: relative;
  display: flex;
  align-items: center;
`;

const Icons = styled.div`
  display: flex;
  border-left: 2px solid #9b9b9b;
  padding-left: 12px;
`;

interface ActionsCellProps {
  children: ReactNode;
}

export function ActionsCell({ children }: ActionsCellProps) {
  return (
    <Td>
      <CellInner>
        <Icons className="icons">{children}</Icons>
        <Options />
      </CellInner>
    </Td>
  );
}
