import React, { ReactNode, useRef } from "react";
import styled from "styled-components";
import { useOnClickOutside } from "usehooks-ts";

import { H2 } from "../Text";

export interface ModalProps {
  show: boolean;
  onOutsideClick: () => void;
  children?: ReactNode;
}

export const Title = styled(H2)`
  margin-top: 0;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.11);
  background: rgba(70, 70, 70, 0.3);
  z-index: 1000;
`;

const Dialog = styled.div`
  position: relative;
  min-width: 600px;
  max-width: 90%;
  max-height: 80vh;
  background: white;
  padding: 80px 92px;
`;

export function Modal({ show, children, onOutsideClick }: ModalProps) {
  const ref = useRef();
  useOnClickOutside(ref, onOutsideClick);

  if (!show) return null;

  return (
    <Container>
      <Dialog ref={ref}>{children}</Dialog>
    </Container>
  );
}

export interface ModalCloseButtonProps {
  show: boolean;
  children?: ReactNode;
}

export function ModalCloseOnButton({ show, children }: ModalCloseButtonProps) {
  if (!show) return null;

  return (
    <Container>
      <Dialog>{children}</Dialog>
    </Container>
  );
}
