import React from "react";
import { Modal, ModalProps, Title } from "@src/Components/Modal/Modal";
import {
  GenerateRecoveryLinkMutation,
  UserNameQuery,
  UserNameQueryVariables
} from "@src/generated/graphql";
import { CopyButton } from "@src/Components/Buttons/Copy";
import { useQuery } from "@apollo/client";
import UserName from "./UserName.graphql";

interface RecoveryLinkModalProps extends ModalProps {
  userId: string;
  link: GenerateRecoveryLinkMutation["generateRecoveryLink"];
}

export function RecoveryLinkModal({ link, userId, ...modalProps }: RecoveryLinkModalProps) {
  const url = link?.link;
  const expiry = new Date(link?.expiresAt).toLocaleString();

  const { data } = useQuery<UserNameQuery, UserNameQueryVariables>(UserName, {
    variables: { id: userId },
    skip: !userId
  });

  return (
    <Modal {...modalProps}>
      <Title>Recovery link generated for {data?.user?.name}</Title>
      <input value={url} readOnly />
      <CopyButton btnText={"copy"} value={url} />
      <p>link expires: {expiry}</p>
    </Modal>
  );
}
