import React, { Fragment, useCallback, useEffect, useState } from "react";
import { NavLink, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { CellProps, Column, Row } from "react-table";

import { useMutation, useQuery } from "@apollo/client";
import KeyIcon from "@img/key-solid.svg";
import RemoveIcon from "@img/remove.svg";
import RecruitIcon from "@img/sitemap-solid.svg";
import { Clickable } from "@src/Components/Buttons/Clickable";
import { Edit } from "@src/Components/Buttons/Edit";
import { PrimaryLinkButton } from "@src/Components/Buttons/PrimaryLink";
import { InlineLoading, Loading } from "@src/Components/Loading/Loading";
import { DisableableNavLink } from "@src/Components/Navigation/DisableableNavLink";
import { OrgSelector } from "@src/Components/OrgSelector";
import { ActionsColumn, TableComponent } from "@src/Components/Table/TableComponent";
import { H2 } from "@src/Components/Text";
import { styled } from "@src/Components/theme";
import {
  AllUsersTableQuery,
  GenerateRecoveryLinkMutation,
  GenerateRecoveryLinkMutationVariables,
  OrgSelectQuery,
  OrgUsersTableQuery,
  OrgUsersTableQueryVariables,
  User
} from "@src/generated/graphql";
import { useIsAdmin } from "@src/Hooks/isAdmin";
import { useIsSuperAdmin } from "@src/Hooks/isSuperAdmin";
import { useOrgInfo } from "@src/Hooks/orgInfo";
import { Entity } from "@src/Hooks/removeRow";
import { useToggle } from "@src/Hooks/toggle";
import { iconSize } from "@src/Instances/ActionsCell/iconSize";
import { useSession } from "@src/Session";
import OrgUsersTable from "@src/User/OrgUsersTable.graphql";
import { RoleTag } from "@src/User/RoleTag";

import query from "../Settings/OrgSelect.graphql";
import DeleteUserModal from "./DeleteUserModal";
import GenerateRecoveryLink from "./GenerateRecoveryLink.graphql";
import { RecoveryLinkModal } from "./RecoveryLinkModal";
import { UserOrg } from "./UserOrg";

export const FirstLine = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
`;

export const WrapperActions = styled.div`
  display: flex;
  gap: 10px;
`;

export const Remove = styled(RemoveIcon)`
  ${iconSize}
  fill: ${({ theme }) => theme.purple};
`;

export const Recovery = styled(KeyIcon)`
  width: 16px;
  fill: ${({ theme }) => theme.purple};
`;

export const Recruit = styled(RecruitIcon)`
  ${iconSize}
  fill: ${({ theme }) => theme.purple};
`;

export const OrgHeading = styled.div`
  display: flex;
  align-items: baseline;
  gap: 1em;

  > span {
    font-size: 14px;
  }
`;

const UserRoles = styled.div`
  list-style: none;
  padding: 14px 0;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 14px 0;

  > *:nth-child(n + 3) {
    border-top: 1px solid #cccccc;
    padding-top: 14px;
  }
`;

const OrgName = styled.span`
  padding-left: 10px;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const allOrgsId = "all-organizations";

interface UserManagementTableProps {
  openRowModal: (entity: Entity, row: Row) => void;
}

function useUserManagementTableProps() {
  return useOutletContext<UserManagementTableProps>();
}

type UserRow = OrgUsersTableQuery["org"]["users"][0] | AllUsersTableQuery["users"][0];

function isAllUsersTable(
  value: UserRow["orgRoles"],
  orgId: string
): value is AllUsersTableQuery["users"][0]["orgRoles"] {
  return orgId === allOrgsId;
}

export function UserManagementTable() {
  const { orgId } = useParams<{ orgId: string }>();

  const { data, loading } = useQuery<OrgUsersTableQuery, OrgUsersTableQueryVariables>(
    OrgUsersTable,
    {
      variables: { org: orgId }
    }
  );
  const users = data?.org?.users || [];

  const navigate = useNavigate();
  const setOrg = useCallback(
    (orgId: string) => {
      navigate(`../../${orgId}`);
    },
    [navigate]
  );

  return (
    <UserManagmentTableInner orgId={orgId} users={users} loadingUsers={loading} setOrg={setOrg} />
  );
}

interface TableInnerProps {
  orgId: string;
  users: User[];
  loadingUsers: boolean;
  setOrg: (orgId: string) => void;
}

export function UserManagmentTableInner({ orgId, users, loadingUsers, setOrg }: TableInnerProps) {
  const { openRowModal } = useUserManagementTableProps();

  const { isSuperAdmin, loading: superAdminLoading } = useIsSuperAdmin();
  const { isAdmin, loading: adminLoading } = useIsAdmin();
  const { orgId: userOrgId } = useOrgInfo();
  const { session } = useSession();
  const userId = session?.identity?.id;

  const { data: allOrgs, loading: loadingOrgs } = useQuery<OrgSelectQuery>(query, {
    skip: !isSuperAdmin
  });

  const orgData = [{ id: allOrgsId, displayName: "All Organizations" }, ...(allOrgs?.orgs || [])];

  useEffect(() => {
    if (isSuperAdmin === false && userOrgId) setOrg(userOrgId);
  }, [isSuperAdmin, setOrg, userOrgId]);

  const loading = superAdminLoading || adminLoading || loadingUsers;

  const [linkFor, setLinkFor] = useState<string>();

  const { state, toggle } = useToggle();
  const [userToDelete, setUserToDelete] = useState<{ user: User; row: Row }>();

  const [generateLink, { data: recoveryLink, reset }] = useMutation<
    GenerateRecoveryLinkMutation,
    GenerateRecoveryLinkMutationVariables
  >(GenerateRecoveryLink);

  const columns: Array<Column<UserRow>> = [
    {
      Header: "Name",
      accessor: "name",
      width: "30%"
    },
    {
      Header: "Email",
      accessor: "email",
      width: "30%"
    },
    {
      Header: "Role",
      accessor: "orgRoles",
      width: "20%",
      Cell: ({ value }: CellProps<object, UserRow["orgRoles"]>) => {
        return isAllUsersTable(value, orgId) ? (
          <UserRoles>
            {value
              .slice()
              .sort((a, b) => a.id.localeCompare(b.id))
              .map(orgRole => (
                <Fragment key={orgRole.id}>
                  <span>
                    <RoleTag role={orgRole.role} />
                  </span>
                  <OrgName>{orgRole.org.displayName}</OrgName>
                </Fragment>
              ))}
          </UserRoles>
        ) : (
          <RoleTag role={value[0].role} />
        );
      }
    }
  ];

  const actionColumn = {
    Header: "actions",
    accessor: "_actions" as ActionsColumn,
    width: "10%",
    disableSortBy: true,
    Cell: ({ row }: CellProps<object>) => {
      const rowUser = row.original as User;
      const { id, name } = rowUser;
      const disableEdit = !isAdmin || (isAdmin && id === userId);
      return (
        <WrapperActions>
          {isAdmin && (
            <>
              <DisableableNavLink title={`edit ${name}`} to={`edit/${id}`} disabled={disableEdit}>
                <Edit />
              </DisableableNavLink>
              <Clickable
                title={`generate recovery link for ${name}`}
                onClick={() => {
                  setLinkFor(id);
                  generateLink({ variables: { user: id } });
                }}
              >
                <Recovery />
              </Clickable>
              {isSuperAdmin && (
                <NavLink title={`invite ${name} to another org`} to={`recruit/${id}`}>
                  <Recruit />
                </NavLink>
              )}
              <Clickable
                title={`remove ${name}`}
                onClick={() => {
                  if (orgId !== "all-organizations")
                    openRowModal({ id, displayName: name, type: "user" }, row);
                  else {
                    setUserToDelete({ user: rowUser, row });
                    toggle();
                  }
                }}
              >
                <Remove />
              </Clickable>
            </>
          )}
        </WrapperActions>
      );
    }
  };

  return (
    <>
      <H2>Users</H2>
      <FirstLine>
        <PrimaryLinkButton to="add">Add new user</PrimaryLinkButton>
      </FirstLine>
      {loading ? (
        <Loading />
      ) : (
        <>
          <OrgHeading>
            <span>showing users for org</span>
            {isSuperAdmin ? (
              loadingOrgs ? (
                <InlineLoading />
              ) : (
                <OrgSelector selectedOrg={orgId} organizations={orgData} selectOrg={setOrg} />
              )
            ) : (
              <UserOrg />
            )}
          </OrgHeading>

          {loading ? (
            <Loading />
          ) : (
            <TableComponent<UserRow>
              columnHeaders={columns}
              items={users}
              rightActions={actionColumn}
              pagination={true}
            />
          )}
        </>
      )}
      <RecoveryLinkModal
        key={linkFor}
        show={!!recoveryLink}
        userId={linkFor}
        link={recoveryLink?.generateRecoveryLink}
        onOutsideClick={reset}
      />
      <DeleteUserModal
        key={userToDelete?.user?.id}
        show={state}
        userToDelete={userToDelete}
        onOutsideClick={toggle}
      />
    </>
  );
}
