import { ChangeEvent, useCallback, useState } from "react";

export const themeLocalStorageKey = "theme";

export function useEditorTheme() {
  const [theme, setTheme] = useState<string>(
    localStorage.getItem(themeLocalStorageKey) || "vs-dark"
  );

  const changeTheme = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      setTheme(e.target.value);
      localStorage.setItem(themeLocalStorageKey, e.target.value);
    },
    [setTheme]
  );

  return { theme, changeTheme };
}
