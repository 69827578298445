import { Formik } from "formik";
import React, { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { useOutletContext } from "react-router-dom";

import { useMutation } from "@apollo/client";
import Copy from "@img/copy-regular.svg";
import { Clickable } from "@src/Components/Buttons/Clickable";
import { GhostButton } from "@src/Components/Buttons/Ghost";
import { ModalCloseOnButton } from "@src/Components/Modal/Modal";
import { H2 } from "@src/Components/Text";
import { styled } from "@src/Components/theme";
import { ToastNotification } from "@src/Components/ToastNotification";
import {
  AddFederationGuestMutation,
  AddFederationGuestMutationVariables,
  ClientCredentials,
  FederationGuestInput,
  FederationGuestsQuery
} from "@src/generated/graphql";
import { useToggle } from "@src/Hooks/toggle";

import AddFederationGuest from "./AddFederationGuest.graphql";
import { ValuesWrapper } from "./FederationInformation";
import { FederationsContext } from "./federationsContext";
import FederationGuests from "./FetchFederationGuests.graphql";
import { FederationGuestFormInput, guestSchema } from "./formSchemas";
import { GuestFormInner } from "./GuestFormInner";

const initialValues: FederationGuestFormInput = {
  federationName: "",
  operatorName: "",
  countryCode: "",
  mcc: "",
  mncs: "",
  parentSite: ""
};

export function AddGuestForm() {
  const { navigateBack } = useOutletContext<FederationsContext>();
  const { state, toggle } = useToggle();

  const [addGuest] = useMutation<AddFederationGuestMutation, AddFederationGuestMutationVariables>(
    AddFederationGuest
  );

  const [guestCredentials, setGuestCredentials] = useState<ClientCredentials>();

  const openCredentialsModal = useCallback(
    (data: AddFederationGuestMutation) => {
      setGuestCredentials(data?.createFederationGuest?.clientCredentials);
      toggle();
    },
    [toggle, setGuestCredentials]
  );

  return (
    <Formik<FederationGuestFormInput>
      initialValues={initialValues}
      validationSchema={guestSchema}
      onSubmit={async values => {
        try {
          const guestInput: FederationGuestInput = {
            ...values,
            mncs: values.mncs.split(",")
          };
          await addGuest({
            variables: { guest: guestInput },
            update(cache, { data: { createFederationGuest } }) {
              cache.updateQuery<FederationGuestsQuery>({ query: FederationGuests }, data => ({
                ...data,
                federationGuests: [
                  ...(data?.federationGuests || []),
                  createFederationGuest?.federationGuest
                ]
              }));
            },
            onCompleted: openCredentialsModal
          });
        } catch (e) {
          console.error(e);
          toast.error(<ToastNotification title="Failed to create Guest Partner" />);
        }
      }}
    >
      {formikProps => (
        <>
          <H2>Add Guest Partner</H2>
          <GuestFormInner {...formikProps} navigateBack={navigateBack} />
          <ModalCloseOnButton show={state}>
            <GuestModalContent credentials={guestCredentials} />
          </ModalCloseOnButton>
        </>
      )}
    </Formik>
  );
}

const GuestModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const GoBackButton = styled(GhostButton)`
  align-self: flex-end;
`;
const Warning = styled.span`
  color: ${({ theme }) => theme.red};
  font-size: 14px;
`;
const CopyIcon = styled(Copy)`
  height: 18px;
  & path {
    fill: ${({ theme }) => theme.primary};
  }
`;
const IdSecretWrapper = styled(ValuesWrapper)`
  font-size: 16px;
`;
const SecretField = styled.dd`
  display: flex;
`;

interface GuestModalContent {
  credentials: ClientCredentials;
}

function GuestModalContent({ credentials }: GuestModalContent) {
  const { navigateBack } = useOutletContext<FederationsContext>();

  const { clientId, clientSecret } = credentials;

  const copyToClipboard = useCallback((token: string) => {
    navigator.clipboard.writeText(token);
    toast.success(<ToastNotification title="copied to clipboard" />);
  }, []);

  return (
    <GuestModalWrapper>
      <H2>Federation with Guest Partner Added</H2>
      <Warning>
        This is the only time to copy this secret. You will not have another opportunity.
      </Warning>
      <IdSecretWrapper>
        <dt>Client ID</dt>
        <SecretField>
          {clientId}
          <Clickable onClick={() => copyToClipboard(clientId)}>
            <CopyIcon />
          </Clickable>
        </SecretField>
        <dt>Client Secret</dt>
        <SecretField>
          {clientSecret}
          <Clickable onClick={() => copyToClipboard(clientSecret)}>
            <CopyIcon />
          </Clickable>
        </SecretField>
      </IdSecretWrapper>
      <GoBackButton onClick={navigateBack}>Close</GoBackButton>
    </GuestModalWrapper>
  );
}
