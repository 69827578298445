import { useEffect, useRef } from "react";
import toast from "react-hot-toast";

import { ApolloError } from "@apollo/client";

export function useToastGraphqlError(error: ApolloError, scope: string, errorMsg: string) {
  const toastPopped = useRef(false);
  useEffect(() => {
    if (toastPopped.current) return;

    if (error?.graphQLErrors.some(e => e.path?.includes(scope))) {
      toast.error(errorMsg);
      toastPopped.current = true;
    }
  }, [error, scope, errorMsg]);
}
