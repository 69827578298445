import React from "react";
import { useOutletContext } from "react-router-dom";

import { Loading } from "@src/Components/Loading/Loading";
import { styled } from "@src/Components/theme";

import { InstanceInfoContext } from "./Instance";
import { InstanceBlockInfo } from "./InstanceBlockInfo";
import { InstanceBlocks } from "./InstanceBlocks";

const MainLoading = styled.div`
  grid-column: 2 / -1;
  align-self: center;
`;
function useInstanceProps() {
  return useOutletContext<InstanceInfoContext>();
}

export function InstanceInfo() {
  const { selectedBlock, setSelectedBlock, showResources, blocks, blocksLoading, refetch } =
    useInstanceProps();

  return blocksLoading ? (
    <MainLoading>
      <Loading />
    </MainLoading>
  ) : (
    <>
      <InstanceBlocks
        blocks={blocks}
        selectedBlock={selectedBlock}
        selectBlock={setSelectedBlock}
      />
      <InstanceBlockInfo showResources={showResources} block={selectedBlock} refetch={refetch} />
    </>
  );
}
