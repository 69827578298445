import { useQuery } from "@apollo/client";
import { OrgIdentityQuery, OrgIdentityQueryVariables } from "@src/generated/graphql";
import { useOrgInfo } from "@src/Hooks/orgInfo";

import OrgIdentity from "./OrgIdentity.graphql";

export interface IdentityForm extends Omit<OrgIdentityQuery["org"]["federation"], "mncs"> {
  mncs: string;
}

const initialMock: IdentityForm = {
  operatorName: "",
  countryCode: "",
  mcc: "",
  mncs: "",
  federationUrl: ""
};

export function useOrgIdentity() {
  const { orgId } = useOrgInfo();

  const { data, loading } = useQuery<OrgIdentityQuery, OrgIdentityQueryVariables>(OrgIdentity, {
    variables: { id: orgId },
    skip: !orgId
  });

  const identity = (data && data?.org?.federation) || (loading ? null : initialMock);

  return { orgIdentity: { ...identity, mncs: identity?.mncs?.toString() }, loading, orgId };
}
