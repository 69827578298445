import { FormikErrors, useField } from "formik";
import React, { useCallback } from "react";
import styled from "styled-components";

import Upload from "@img/file-upload-solid.svg";
import { UploadFieldType, useFileUpload } from "@src/Hooks/fileUpload";

import { FileInput } from "./FileInput";
import { FieldLabel, TextAreaField } from "./InputGroup";

const InputContainer = styled.div`
  position: relative;
`;

const UploadContainer = styled.div`
  position: absolute;
  top: 15px;
  right: 20px;
`;

const UploadLabel = styled.label`
  color: #484886;
  cursor: pointer;

  :hover {
    color: #8888b0;
  }
`;

const UploadIcon = styled(Upload)`
  width: 24px;
`;

const HiddenFileInput = styled(FileInput)`
  display: none;
`;

interface FileInputGroupProps {
  type: UploadFieldType;
  label: string;
  name?: string;
  errors?: FormikErrors<unknown>;
}

export function FileInputGroup({ type, label, name, errors }: FileInputGroupProps) {
  const [omitField, omitMeta, helpers] = useField(name);
  const handleFileUpload = useFileUpload({ type, ...helpers });

  const acceptType = useCallback(() => {
    switch (type) {
      case UploadFieldType.Json:
        return "application/JSON";
      default:
        return "";
    }
  }, [type]);

  return (
    <>
      <FieldLabel htmlFor={name}>{label}</FieldLabel>
      <InputContainer>
        <UploadContainer>
          <UploadButton
            title={`upload ${type} file`}
            accept={acceptType()}
            onChange={handleFileUpload}
          />
        </UploadContainer>
        <TextAreaField name={name} errors={errors} />
      </InputContainer>
    </>
  );
}

interface UploadButtonProps {
  title: string;
  accept: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export function UploadButton({ title, accept, onChange }: UploadButtonProps) {
  return (
    <UploadLabel title={title}>
      <UploadIcon />
      <HiddenFileInput onChange={onChange} accept={accept} />
    </UploadLabel>
  );
}
