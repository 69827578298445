import { useCallback, useState } from "react";

export function useFilterByFields(fieldsList: string[], initialValues?: string[]) {
  const [selectedFilters, setSelectedFilters] = useState([...(initialValues || fieldsList)]);
  const toggleFilterTag = useCallback(
    (field: string) => {
      if (field === "ALL") {
        const selectAll = selectedFilters.length !== fieldsList.length;
        const servicesSelected = selectAll ? [...fieldsList] : [];
        setSelectedFilters(servicesSelected);
        return;
      }

      if (fieldsList.includes(field)) {
        if (selectedFilters.includes(field)) {
          setSelectedFilters(prev => prev.filter(s => field !== s));
        } else {
          setSelectedFilters(prev => [...prev, field]);
        }
      }
    },
    [selectedFilters, fieldsList]
  );

  return {
    toggleFilterTag,
    selectedFilters
  };
}
