import { useEffect, useState } from "react";

import { useQuery } from "@apollo/client";
import { OrgNameQuery, OrgNameQueryVariables } from "@src/generated/graphql";
import { useSession } from "@src/Session";
import OrgName from "@src/User/OrgName.graphql";

export function useOrgInfo(fetchName = false) {
  const { session } = useSession();
  const [orgId, setOrgId] = useState<string>();

  useEffect(() => {
    const sessionOrgId = (session?.identity?.metadata_public as { org?: string })?.org;
    setOrgId(sessionOrgId);
  }, [session?.identity?.metadata_public]);

  const { data, loading } = useQuery<OrgNameQuery, OrgNameQueryVariables>(OrgName, {
    variables: { id: orgId },
    skip: (orgId == null || orgId === "*") && !fetchName
  });

  return {
    orgId,
    orgName: data?.org?.displayName,
    loading
  };
}
