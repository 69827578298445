import { usePermissionCheck } from "./permissionsCheck";

export function useIsSuperAdmin() {
  const { allowed, loading } = usePermissionCheck({
    relation: "admin",
    objectType: "orgs",
    objectId: "*"
  });

  return { isSuperAdmin: allowed, loading };
}
