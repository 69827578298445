import React from "react";
import { useSvgDef } from "./svgDef";

const filter = (
  <filter id="dropShadow" x="-20%" y="-20%" width="200%" height="200%">
    <feOffset result="offOut" in="SourceAlpha" dx="1" dy="1" />
    <feGaussianBlur result="blurOut" in="offOut" stdDeviation="1" />
    <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
  </filter>
);

export function useDropShadowDef() {
  useSvgDef("dropShadow", filter);
}
