import { styled } from "@src/Components/theme";

import { PrimaryButton } from "../Buttons/Primary";
import { Select } from "./BlockSelectorList";
import { Image } from "./BlockStyles";

export const FirstLine = styled.div`
  display: grid;
  grid-template-columns: 250px 1fr;
  border-bottom: 1px solid rgb(204, 204, 204);
  padding-bottom: 20px;
`;

export const Logo = styled(Image)`
  max-height: 80px;
  width: 200px;
  margin: auto 0;
`;

export const Title = styled.h1`
  margin: 0;
`;

export const Label = styled.label`
  font-size: 14px;
  font-weight: bold;
  margin: 10px 0;
  display: block;
`;

export const BlockInfoText = styled.p`
  margin-top: 0;
`;

export const Content = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-grow: 1;
  overflow: hidden auto;
  padding-bottom: 10px;
`;

export const Actions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

export const VersionSelect = styled(Select)`
  height: 30px;
  width: 240px;
  border: 1px solid ${({ theme }) => theme.grey};
  border-radius: 3px;
  margin: auto 0;
`;

export const AddBlockButton = styled(PrimaryButton)`
  margin-left: auto;
`;

export const Categories = styled.div`
  position: sticky;
  top: 0;
  flex: 1 0 200px;
`;

export const Category = styled.span`
  display: block;
  border: 1px solid ${({ theme }) => theme.lightblue};
  background-color: rgba(0, 160, 223, 0.1);
  border-radius: 20px;
  padding: 5px 10px;
  width: fit-content;
  margin: 0 0 5px 1px;
`;

export const Info = styled.div`
  display: flex;
  height: calc(100vh - 40px);
  flex-direction: column;
  gap: 10px;
`;

export const BlockInfoWrapper = styled.div`
  width: 80%;
`;
