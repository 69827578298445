import { FormikProps } from "formik";
import React, { useCallback, useState } from "react";
import uuid from "uuid";

import { SecondaryButton } from "@src/Components/Buttons/Secondary";
import { H3 } from "@src/Components/Text";
import { styled } from "@src/Components/theme";
import { Connection } from "@src/generated/graphql";

import { DeviceValues } from "./AddDeviceForm";
import { CreatedConnections, EditMode, initialEditMode } from "./CreatedConnections";
import { EditDeviceValues } from "./EditDeviceForm";

interface DeviceConnectionsFormProps extends FormikProps<DeviceValues | EditDeviceValues> {
  push: (obj: Connection) => void;
  remove: (index: number) => void;
  pop: () => void;
}

const FieldsContainer = styled.div`
  margin: 105px 32px;
  padding: 0 82px;
  background: #f4f0f0;
`;

const FieldsHeader = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  border-bottom: 3px solid #bbbbbb;
  padding: 10px 0;
`;

export const WideFieldsContainer = styled(FieldsContainer)`
  grid-column: 1 / -1;
`;

export function DeviceConnectionsForm({
  push,
  remove,
  ...formikProps
}: DeviceConnectionsFormProps) {
  const { values } = formikProps;
  const [editMode, setEditMode] = useState<EditMode>(initialEditMode);
  const canCreate = editMode.fieldId === null;

  const newConnection = useCallback(() => {
    const id = uuid.v4();
    push({
      id,
      name: "",
      category: "Video",
      kind: "",
      source: ""
    });
    setEditMode({
      fieldId: id,
      isNew: true
    });
  }, [push, setEditMode]);

  return (
    <WideFieldsContainer>
      <FieldsHeader>
        <H3>Connections</H3>
        <SecondaryButton type="button" disabled={!canCreate} onClick={newConnection}>
          new connection
        </SecondaryButton>
      </FieldsHeader>
      <CreatedConnections
        remove={remove}
        editMode={editMode}
        setEditMode={setEditMode}
        connections={values.connections}
        {...formikProps}
      />
    </WideFieldsContainer>
  );
}
