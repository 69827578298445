import React, { ChangeEvent } from "react";

import Editor from "@monaco-editor/react";
import { Title } from "@src/Components/BlockSelector/BlockSelectorList";
import { styled } from "@src/Components/theme";

import { ThemeSelect } from "./ThemeSelect";

const Wrapper = styled.div`
  width: 100%;
`;

const FirstLine = styled.div`
  display: flex;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
`;

const Subtitle = styled(Title)`
  width: fit-content;
  margin-bottom: 0;
`;

export const ReadOnly = styled.span`
  background-color: ${({ theme }) => theme.backgroundLight};
  text-transform: uppercase;
  padding: 2px 5px;
  height: fit-content;
  border-radius: 5px;
  letter-spacing: 0.5px;
  font-size: 9px;
  margin-right: auto;
  align-self: center;
`;

interface ReadOnlyEditorProps {
  value: string;
  theme: string;
  title: string;
  changeTheme: (e: ChangeEvent<HTMLSelectElement>) => void;
}

export function ReadOnlyEditor({ value, theme, title, changeTheme }: ReadOnlyEditorProps) {
  return (
    <>
      <Wrapper>
        <Subtitle>{title}</Subtitle>
        <FirstLine>
          <ReadOnly>read only</ReadOnly>
          <ThemeSelect changeTheme={changeTheme} />
        </FirstLine>
        {theme && (
          <Editor
            language="yaml"
            height="900px"
            theme={theme}
            value={value || ""}
            options={{ readOnly: true }}
          />
        )}
      </Wrapper>
    </>
  );
}
