import React, { ReactNode } from "react";

import { MockedProvider, MockedProviderProps } from "@apollo/client/testing";

import { cache } from "./apolloClient";

interface MaybeMockedProps extends MockedProviderProps {
  condition: boolean;
  children: ReactNode;
}

export function MaybeMocked({ condition, children, ...props }: MaybeMockedProps) {
  if (process.env.NODE_ENV === "production" || !condition) return <>{children}</>;

  return (
    <MockedProvider cache={cache} {...props}>
      {children}
    </MockedProvider>
  );
}
