import React, { useCallback } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { MutationFunctionOptions } from "@apollo/client";
import { LoadingPage } from "@src/Components/Loading/LoadingPage";
import { RemoveModal, RemoveModalProps } from "@src/Components/Modal/RemoveModal";
import { styled } from "@src/Components/theme";
import { Device } from "@src/generated/graphql";
import { Entity } from "@src/Hooks/removeRow";
import { SiteTreeProps } from "@src/Hooks/siteTree";

import { InfrastructureFileTree } from "../FileTree/InfrastructureFileTree";
import { NotFound } from "../NotFound";
import { UseFetchInfrastructureInformationProps } from "./fetchInfrastructureInformation";
import { ItemTitle } from "./ItemTitle";
import { TabBar } from "./TabBar";

const InformationWrapper = styled.div`
  padding: 0 20px;
`;

const InformationContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-left: none;
  box-sizing: border-box;
`;

export const TitleSpan = styled.span`
  cursor: pointer;
  padding: 0 5px;
  :hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.primary};
  }
`;

const FirstLine = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 2px solid #f2f2f2;
`;

interface InfrastructureInformationProps extends UseFetchInfrastructureInformationProps {
  modalShown: boolean;
  selectedSite: Entity;
  showModal: (entity: Entity) => void;
  hideModal: () => void;
  remove: (options?: MutationFunctionOptions) => void;
}

interface Context {
  onDeviceMarkerClick: (devId: string) => void;
  showRemoveModal: (device: Pick<Device, "displayName" | "id">) => void;
  selectSitePage: SiteTreeProps["selectSitePage"];
}

export function InfrastructureInformation({
  data,
  loading,
  error,
  modalShown,
  selectedSite,
  showModal,
  hideModal,
  remove,
  ...treeProps
}: InfrastructureInformationProps) {
  const navigate = useNavigate();

  const removeModalProps: RemoveModalProps = {
    entity: selectedSite,
    hideModal,
    remove
  };

  const siteNotEmpty = !data || data.sites?.length > 0 || data.devices?.devices?.length > 0;
  const removeDisabledReason = siteNotEmpty ? "cannot remove: site is not empty" : null;

  const onDeviceMarkerClick = useCallback(
    (devId: string) => {
      navigate(`inventory/${devId}`);
    },
    [navigate]
  );

  const context: Context = {
    onDeviceMarkerClick,
    showRemoveModal: showModal,
    selectSitePage: treeProps.selectSitePage
  };

  return (
    <>
      <InfrastructureFileTree {...treeProps} />
      {loading ? (
        <LoadingPage />
      ) : error ? (
        <NotFound />
      ) : (
        <InformationContainer>
          <ItemTitle
            id={data?.id}
            selectTreeItem={treeProps.selectTreeItem}
            showRemoveModal={showModal}
            removeDisabledReason={removeDisabledReason}
          />

          <FirstLine>
            <TabBar />
          </FirstLine>
          <InformationWrapper>
            <Outlet context={context} />
          </InformationWrapper>
          {modalShown && <RemoveModal {...removeModalProps} />}
        </InformationContainer>
      )}
    </>
  );
}
