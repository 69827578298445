import React, { ReactNode } from "react";
import { Map } from "react-map-gl";

import { Device } from "@src/generated/graphql";
import { useMapboxApiToken } from "@src/Hooks/apiToken";

import { MapStyle } from "./mapReducer";

interface StaticMapboxMapProps {
  device: Partial<Device>;
  zoom: number;
  children?: ReactNode;
}

export function StaticMapboxMap({ device, children, zoom }: StaticMapboxMapProps) {
  const apiToken = useMapboxApiToken();
  const {
    position: { lat, lng }
  } = device;

  const staticMapSetting = {
    dragPan: false,
    dragRotate: false,
    scrollZoom: false,
    touchZoom: false,
    touchRotate: false,
    keyboard: false,
    doubleClickZoom: false,
    boxZoom: false,
    cursor: "auto"
  };

  return (
    <>
      {apiToken && (
        <Map
          style={{
            width: "100%",
            height: "100%"
          }}
          initialViewState={{
            latitude: lat,
            longitude: lng,
            zoom: zoom
          }}
          mapboxAccessToken={apiToken}
          mapStyle={MapStyle.light}
          {...staticMapSetting}
        >
          {children}
        </Map>
      )}
    </>
  );
}
