import React, { useCallback } from "react";
import { useParams } from "react-router-dom";

import ResetIcon from "@img/reset.svg";
import { Clickable } from "@src/Components/Buttons/Clickable";
import { ConfirmActionModal } from "@src/Components/Modal/ConfirmActionModal";
import { H3 } from "@src/Components/Text";
import { styled } from "@src/Components/theme";
import { FetchInstanceInfoQuery } from "@src/generated/graphql";
import { useToggle } from "@src/Hooks/toggle";

const Wrapper = styled.div`
  height: 100%;
  overflow: hidden;
`;
const RevisionsList = styled.ol`
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  height: 85%;
  overflow: auto;
  overflow-x: hidden;
  padding-right: 10px;
`;

const RevisionItem = styled.li<{ isActive: boolean; isSelected: boolean }>`
  border: 1px solid ${({ theme }) => theme.border};
  :hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px;
  }
  border-width: ${({ isSelected }) => (isSelected ? "2px" : null)};
  border-color: ${({ isSelected, theme }) => (isSelected ? theme.primaryLight : null)};
  font-weight: ${({ isActive }) => (isActive ? 600 : 400)};
  display: flex;
  justify-content: space-between;
  gap: 10px;
  :last-child {
    margin-bottom: 2rem;
  }
`;

type Revisions = FetchInstanceInfoQuery["serviceChain"]["history"];

interface InstanceRevisionsProps {
  history: Revisions;
  selectRevision: (id: number) => void;
  rollback: (revision: number) => void;
}

export function InstanceRevisions({ history, selectRevision, rollback }: InstanceRevisionsProps) {
  const { revision } = useParams();
  return (
    <Wrapper>
      <H3>Revisions</H3>
      <RevisionsList>
        {history?.length ? (
          history.map((rev, i) => (
            <Revision
              key={rev.id}
              rollback={rollback}
              rev={rev}
              isActive={i === 0}
              isSelected={
                rev.revision === +revision || (!revision && rev.revision === history.length)
              }
              selectRevision={selectRevision}
            />
          ))
        ) : (
          <li>failed to load revision history</li>
        )}
      </RevisionsList>
    </Wrapper>
  );
}

const Label = styled.div`
  cursor: pointer;
  padding: 0.5em 1em;
  flex-grow: 1;
  line-height: calc(1.5em + 20px);
`;

const Actions = styled.div`
  cursor: pointer;
  margin: 10px 0;
  border-left: 2px solid #9b9b9b;
`;

const RollbackButton = styled(Clickable)`
  padding: 0.5em 1em;
  display: inline-flex;
  opacity: 0.2;

  :hover {
    opacity: 1;
  }
`;

const Reset = styled(ResetIcon)`
  height: 1.5em;
`;

interface RevisionProps {
  rev: Revisions[0];
  rollback: (revision: number) => void;
  isActive: boolean;
  isSelected: boolean;
  selectRevision: (id: number) => void;
}

function Revision({ rev, rollback, selectRevision, isActive, isSelected }: RevisionProps) {
  const { state: show, on: showConfirm, off: hideConfirm } = useToggle();

  const onClick = useCallback(() => selectRevision(rev.revision), [rev, selectRevision]);

  const onRollbackClick = useCallback(() => {
    rollback(rev.revision);
    hideConfirm();
  }, [rev.revision, rollback, hideConfirm]);

  return (
    <>
      <RevisionItem isActive={isActive} isSelected={isSelected}>
        <Label title={`view details`} onClick={onClick}>
          {rev.revision} {isActive && "(active)"}
        </Label>
        {!isActive && (
          <Actions>
            <RollbackButton onClick={showConfirm} title={`rollback to revision ${rev.revision}`}>
              <Reset />
            </RollbackButton>
          </Actions>
        )}
      </RevisionItem>
      {show && (
        <ConfirmActionModal
          modalShown={show}
          hideModal={hideConfirm}
          confirmAction={onRollbackClick}
          title={`Roll back to Revision ${rev.revision}?`}
        >
          <p>This will roll back the service chain to the selected revision.</p>
        </ConfirmActionModal>
      )}
    </>
  );
}
