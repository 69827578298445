import React from "react";
import styled from "styled-components";

import EditIcon from "@img/edit.svg";
import { Clickable } from "@src/Components/Buttons/Clickable";

export const Edit = styled(Clickable)`
  grid-area: icon;
  width: 100%;
  height: 100%;
  justify-content: center;
`;

interface FieldEditButtonProps {
  onClick: () => void;
  disabled?: boolean;
}

export function FieldEditButton({ onClick, disabled }: FieldEditButtonProps) {
  return (
    <Edit onClick={onClick} disabled={disabled} title="edit">
      <EditIcon width={60} height={60} fill={disabled ? "#f0f0f0" : "#662099"} />
    </Edit>
  );
}
