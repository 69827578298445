import { FieldArray, Form, Formik, FormikProps } from "formik";
import React, { useCallback } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";

import { useMutation, useQuery } from "@apollo/client";
import DownloadIcon from "@img/download.svg";
import { Clickable } from "@src/Components/Buttons/Clickable";
import { PrimaryButton } from "@src/Components/Buttons/Primary";
import { SecondaryButton } from "@src/Components/Buttons/Secondary";
import { UploadButton } from "@src/Components/Input/FileInputGroup";
import { Loading } from "@src/Components/Loading/Loading";
import { H2, H3 } from "@src/Components/Text";
import { styled } from "@src/Components/theme";
import { ToastNotification } from "@src/Components/ToastNotification";
import { TopLine } from "@src/Components/TopLine";
import {
  FetchCompletionsQuery,
  SetCompletionsMutation,
  SetCompletionsMutationVariables
} from "@src/generated/graphql";
import { UploadFieldType, useFileUpload } from "@src/Hooks/fileUpload";
import { Shape } from "@src/yupTypes";

import { Editor, Value } from "./Editor";
import FetchCompletions from "./FetchCompletions.graphql";
import { useCompletions } from "./reducer";
import SetCompletions from "./SetCompletions.graphql";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr;
  gap: 30px;
`;

const Rows = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  gap: 10px;
`;

const AddButton = styled(SecondaryButton).attrs({ type: "button" })``;

const schema = Yup.object<Shape<CompletionForm>>().shape({
  entries: Yup.array().of(
    Yup.object<Shape<CompletionEntry>>().shape({
      key: Yup.string().required(),
      values: Yup.array().of(Yup.string())
    })
  )
});

interface CompletionEntry {
  key: string;
  values: string[];
}

interface CompletionForm {
  entries: CompletionEntry[];
}

const defaultValues: CompletionForm = { entries: [] };

function parseJson(json: string): CompletionForm {
  try {
    type p = { [key: string]: string[] };
    const parsed: p = JSON.parse(json);
    return {
      entries: Object.entries(parsed).map(([key, values]) => ({ key, values }))
    };
  } catch {
    return defaultValues;
  }
}

function serialiseCompletions(values: CompletionForm) {
  const completions: { [key: string]: string[] } = {};
  values.entries.forEach(({ key, values }) => (completions[key] = values));
  return JSON.stringify(completions);
}

export function Completions() {
  const { data, loading } = useQuery<FetchCompletionsQuery>(FetchCompletions);
  const [setCompletions] = useMutation<SetCompletionsMutation, SetCompletionsMutationVariables>(
    SetCompletions
  );

  let initialValues = defaultValues;
  if (!loading && data?.customCompletions) {
    initialValues = parseJson(data.customCompletions);
  }

  return loading ? (
    <Loading />
  ) : (
    <>
      <Formik<CompletionForm>
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={(values, { setSubmitting }) => {
          setCompletions({ variables: { json: serialiseCompletions(values) } })
            .then(() => toast.success(<ToastNotification title="saved custom completions" />))
            .catch(() =>
              toast.error(<ToastNotification title="failed to save custom completions" />)
            )
            .finally(() => setSubmitting(false));
        }}
      >
        {props => <FormInner {...props} />}
      </Formik>
    </>
  );
}

const Title = styled(H2)`
  display: flex;
  align-items: baseline;
  gap: 15px;
`;

const Download = styled(DownloadIcon)`
  height: 32px;
  fill: ${({ theme }) => theme.primary};
  :hover {
    fill: #8888b0;
  }
`;

type FormInnerProps = FormikProps<CompletionForm>;

function FormInner({ ...props }: FormInnerProps) {
  const [{ editingKey, editingValue, showingValues }, dispatch] = useCompletions();

  const setValue = useCallback(
    (value: string) => {
      props.setValues(parseJson(value), true);
    },
    [props]
  );

  const setError = useCallback((error: string) => {
    toast.error(<ToastNotification title={error} />);
  }, []);

  const handleFileUpload = useFileUpload({ type: UploadFieldType.Json, setValue, setError });
  const handleDownload = useCallback(() => {
    const json = serialiseCompletions(props.values);
    const a = document.createElement("a");
    a.setAttribute("href", "data:application/json;charset=utf-8," + encodeURIComponent(json));
    a.setAttribute("download", "completions.json");
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }, [props.values]);

  return (
    <Form>
      <TopLine>
        <Title>
          Custom completions
          <UploadButton
            title="import completions JSON"
            accept="application/json"
            onChange={handleFileUpload}
          />
          <Clickable title="export completions JSON" onClick={handleDownload}>
            <Download />
          </Clickable>
        </Title>
        <PrimaryButton type="submit" disabled={!props.isValid || props.isSubmitting}>
          save
        </PrimaryButton>
      </TopLine>
      <Container>
        <FieldArray name="entries">
          {({ push, remove }) => (
            <Rows>
              <H3>Keys</H3>
              {props.values.entries.map((k, i) => (
                <Editor
                  key={i}
                  name={`entries[${i}].key`}
                  isEditing={editingKey === i}
                  toggleEdit={() => dispatch({ type: "toggleEditKey", index: i })}
                  remove={() => remove(i)}
                  isSelected={showingValues === i}
                >
                  <Value key={i} onClick={() => dispatch({ type: "showValues", index: i })}>
                    {k.key}
                  </Value>
                </Editor>
              ))}
              <AddButton
                onClick={() => {
                  dispatch({ type: "selectEditKey", index: props.values.entries.length });
                  push({ key: "", values: [] });
                }}
              >
                ＋ add key
              </AddButton>
            </Rows>
          )}
        </FieldArray>
        <Rows>
          {showingValues !== null && (
            <>
              <H3>
                Values for key <strong>{props.values.entries[showingValues].key}</strong>
              </H3>
              <FieldArray name={`entries[${showingValues}].values`}>
                {({ push, remove }) => (
                  <>
                    {props.values.entries[showingValues].values.map((v, i) => (
                      <Editor
                        key={i}
                        name={`entries[${showingValues}].values[${i}]`}
                        isEditing={editingValue === i}
                        toggleEdit={() => dispatch({ type: "toggleEditValue", index: i })}
                        remove={() => remove(i)}
                      >
                        <Value
                          title="edit value"
                          onClick={() => dispatch({ type: "toggleEditValue", index: i })}
                        >
                          {v || <em>empty value</em>}
                        </Value>
                      </Editor>
                    ))}
                    <AddButton
                      onClick={() => {
                        dispatch({
                          type: "selectEditValue",
                          index: props.values.entries[showingValues].values.length
                        });
                        push("");
                      }}
                    >
                      ＋ add value
                    </AddButton>
                  </>
                )}
              </FieldArray>
            </>
          )}
        </Rows>
      </Container>
    </Form>
  );
}
