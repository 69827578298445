import { styled } from "../theme";
import { DisableableNavLink } from "./DisableableNavLink";

export const Tab = styled(DisableableNavLink)`
  display: inline-block;
  text-decoration: none;
  text-transform: capitalize;
  font-size: 14px;
  padding: 10px 20px;
  border-radius: 0;
  border: 2px solid #f2f2f2;
  color: black;
  background: #f2f2f2;

  &.active,
  :hover:not(.active) {
    background: ${({ theme }) => theme.primary};
    color: white;
  }
`;
