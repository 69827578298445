import { FormikProps } from "formik";
import React, { useCallback, useState } from "react";

import { useLazyQuery } from "@apollo/client";
import { Block } from "@src/Components/BlockSelector/Block";
import { AddButton, Instances, SelectedTag } from "@src/Components/BlockSelector/BlockStyles";
import { ErrorMessage } from "@src/Components/ErrorMessage";
import {
  BlockChart,
  TemplateChartInformationQuery,
  TemplateChartInformationQueryVariables
} from "@src/generated/graphql";

import { BlockPublisherForm } from "./serialise";
import TemplateChartInformation from "./TemplateChartInformation.graphql";

interface TemplateBlockProps extends FormikProps<BlockPublisherForm> {
  view: number;
  template: BlockChart;
  openTemplateInformation: (templateName: string, version: string) => void;
  addVersionTemplate: (chart: BlockChart) => void;
}

export function TemplateBlock({
  view,
  template,
  openTemplateInformation,
  addVersionTemplate,
  ...formikProps
}: TemplateBlockProps) {
  const { values } = formikProps;
  const { name, version } = template;

  const [error, setError] = useState<string>(null);

  const [query] = useLazyQuery<
    TemplateChartInformationQuery,
    TemplateChartInformationQueryVariables
  >(TemplateChartInformation, {
    variables: { name, version }
  });
  const addTemplate = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setError(null);
      query({
        variables: {
          name,
          version
        }
      })
        .then(({ data, error }) => {
          if (error) return;
          const template: BlockChart = {
            ...data.templateChart,
            valuesYaml: data?.templateChart?.valuesYaml || "",
            overridesYaml: data?.templateChart?.overridesYaml || ""
          };
          addVersionTemplate(template);
        })
        .catch(e => {
          console.error(e);
          setError("Error adding template to Editor");
        });
    },
    [query, name, version, addVersionTemplate]
  );

  return (
    <Block view={view} openBlockInformation={openTemplateInformation} block={template}>
      <Instances>
        {values.templateName !== template.name ? (
          <AddButton onClick={addTemplate}>+ Select Template</AddButton>
        ) : (
          <SelectedTag>Selected</SelectedTag>
        )}
      </Instances>
      {error && <ErrorMessage error={error} />}
    </Block>
  );
}
