import { useCallback, useState } from "react";

export interface ToggleProps {
  state: boolean;
  toggle: () => void;
  on: () => void;
  off: () => void;
}

export function useToggle(initial = false): ToggleProps {
  const [state, setState] = useState(initial);

  const toggle = useCallback(() => setState(current => !current), []);
  const on = useCallback(() => setState(true), []);
  const off = useCallback(() => setState(false), []);

  return { state, toggle, on, off };
}
