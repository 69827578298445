import React from "react";
import { Outlet } from "react-router-dom";
import { Row } from "react-table";

import { useMutation } from "@apollo/client";
import { RemoveModal, RemoveModalProps } from "@src/Components/Modal/RemoveModal";
import { RemoveOrgMutation, RemoveOrgMutationVariables } from "@src/generated/graphql";
import { Entity, useRemoveRow } from "@src/Hooks/removeRow";
import RemoveOrg from "@src/Infrastructure/sites/RemoveOrg.graphql";

interface Context {
  openRowModal: (entity: Entity, row: Row) => void;
}

export function Organizations() {
  const {
    modalShown,
    entity: selectedOrg,
    hide: hideModal,
    openRowModal,
    onCompleted
  } = useRemoveRow();

  const [remove] = useMutation<RemoveOrgMutation, RemoveOrgMutationVariables>(RemoveOrg, {
    variables: { id: selectedOrg?.id },
    onCompleted,
    update(cache, { data: { removeOrg } }) {
      cache.evict({
        id: cache.identify({ __typename: "Org", id: removeOrg })
      });
      cache.gc();
    }
  });
  const removeModalProps: RemoveModalProps = {
    entity: selectedOrg,
    hideModal,
    remove
  };

  const context: Context = {
    openRowModal
  };

  return (
    <>
      <Outlet context={context} />
      {modalShown && <RemoveModal {...removeModalProps} />}
    </>
  );
}
