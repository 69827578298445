export interface TimeRangeOption {
  from: string;
  to: string;
  label: string;
}

export const validTimeStrings = [
  "now",
  "now-5m",
  "now-15m",
  "now-30m",
  "now-1h",
  "now-3h",
  "now-6h",
  "now-12h",
  "now-24h"
];

export const quickRangeOptions: TimeRangeOption[] = [
  { from: "now-5m", to: "now", label: "Last-5-minutes" },
  { from: "now-15m", to: "now", label: "Last-15-minutes" },
  { from: "now-30m", to: "now", label: "Last-30-minutes" },
  { from: "now-1h", to: "now", label: "Last-1-hour" },
  { from: "now-3h", to: "now", label: "Last-3-hours" },
  { from: "now-6h", to: "now", label: "Last-6-hours" },
  { from: "now-12h", to: "now", label: "Last-12-hours" },
  { from: "now-24h", to: "now", label: "Last-24-hours" }
];

export function parseQuickRangeOption(range: string) {
  const timeUnit = range.slice(-1);
  const value = range.substring(4, range.length - 1);

  const date = new Date();

  if (range === "now") return date.getTime();

  switch (timeUnit) {
    case "m":
      return date.setMinutes(date.getMinutes() - Number(value));
    case "h":
      return date.setHours(date.getHours() - Number(value));
    default:
      return date.getTime();
  }
}
