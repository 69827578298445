import * as Yup from "yup";

import { FederationGuestInput, FederationHostInput } from "@src/generated/graphql";
import { Shape } from "@src/yupTypes";

export interface FederationHostFormInput extends Omit<FederationHostInput, "mncs"> {
  id?: string;
  mncs: string;
}

export const hostSchema = Yup.object<Shape<FederationHostFormInput>>().shape({
  id: Yup.string(),
  federationName: Yup.string().required("Federation name must not be empty"),
  operatorName: Yup.string().required("Operator name must not be empty"),
  countryCode: Yup.string().required("A country code must be selected"),
  mcc: Yup.string()
    .required("MCC must not be empty")
    .matches(/^\d{3}$/, "MCC must be a 3 digit number"),
  mncs: Yup.string()
    .required("MNCs must not be empty")
    .matches(/^\d{2,3}(,\d{2,3})*$/, "MNCs must be a comma-separated list of 2-3 digit numbers"),
  hostUrl: Yup.string()
    .required("Federation url must not be empty")
    .matches(
      /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
      "Field be a valid url"
    ),
  siteId: Yup.string().required("Site ID must not be empty"),
  clientId: Yup.string().required("Client ID must not be empty"),
  clientSecret: Yup.string().required("Client Secret must not be empty")
});
export const hostEditSchema = Yup.object<Shape<FederationHostFormInput>>().shape(
  {
    id: Yup.string(),
    federationName: Yup.string().required("Federation name must not be empty"),
    operatorName: Yup.string().required("Operator name must not be empty"),
    countryCode: Yup.string().required("A country code must be selected"),
    mcc: Yup.string()
      .required("MCC must not be empty")
      .matches(/^\d{3}$/, "MCC must be a 3 digit number"),
    mncs: Yup.string()
      .required("MNCs must not be empty")
      .matches(/^\d{2,3}(,\d{2,3})*$/, "MNCs must be a comma-separated list of 2-3 digit numbers"),
    hostUrl: Yup.string()
      .required("Federation url must not be empty")
      .matches(
        /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
        "Field be a valid url"
      ),
    siteId: Yup.string().required("Site ID must not be empty"),
    clientId: Yup.string().when("clientSecret", {
      is: (val: string) => !!val,
      then: Yup.string().required("Client ID must not be empty to set new credentials")
    }),
    clientSecret: Yup.string().when("clientId", {
      is: (val: string) => !!val,
      then: Yup.string().required("Client ID must not be empty to set new credentials")
    })
  },
  [["clientSecret", "clientId"]]
);

export interface FederationGuestFormInput extends Omit<FederationGuestInput, "mncs"> {
  id?: string;
  mncs: string;
}

export const guestSchema = Yup.object<Shape<FederationGuestFormInput>>().shape({
  federationName: Yup.string().required("Federation name must not be empty"),
  operatorName: Yup.string().required("Operator name must not be empty"),
  countryCode: Yup.string().required("A country code must be selected"),
  mcc: Yup.string()
    .required("MCC must not be empty")
    .matches(/^\d{3}$/, "MCC must be a 3 digit number"),
  mncs: Yup.string()
    .required("MNCs must not be empty")
    .matches(/^\d{2,3}(,\d{2,3})*$/, "MNC must be a comma-separated list of 2-3 digit numbers"),
  parentSite: Yup.string().required("A site must be selected")
});
