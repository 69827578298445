import React from "react";

import { theme } from "@src/Components/theme";
import { Progress } from "@src/generated/graphql";

import { LoadingBar } from "./LoadingBar";

interface ProgressBarProps {
  name: string;
  progress: Progress;
}

export function ProgressBar({ name, progress }: ProgressBarProps) {
  return (
    <LoadingBar
      color={progress.step === progress.goal ? theme.success : theme.status.running}
      text={name}
      currentStep={progress.step}
      steps={progress.goal}
    />
  );
}
