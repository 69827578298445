import React, { lazy, useEffect, useState } from "react";

import { H1, H2 } from "@src/Components/Text";
import { MaybeMocked } from "@src/MaybeMocked";
import { useSession } from "@src/Session";

import { mockMarketplace } from "./mockMarketplace";
import { ServiceForm } from "./serialise";
import { FirstLine, Wrapper } from "./ServiceDesignerComponents";
import { serviceDesignerStorageKey } from "./serviceDesignerStorageKey";
import { TabBar } from "./TabBar";

const ServiceDesigner = lazy(() => import("./ServiceDesigner"));

const emptyForm = {
  name: "",
  blocks: {}
};

export function NewServicePage() {
  const { session } = useSession();
  const user = session?.identity?.traits;

  const [formValues, setFormValues] = useState<ServiceForm>(emptyForm);

  useEffect(() => {
    if (!user) return;
    const state = localStorage.getItem(serviceDesignerStorageKey);
    try {
      const values = JSON.parse(state);
      const isSameUser = user.email === values.userEmail;
      const formValues = values.formValues as ServiceForm;
      setFormValues(isSameUser ? formValues : emptyForm);
    } catch {}
  }, [user]);

  return (
    <Wrapper>
      <FirstLine>
        <H1>Service Designer</H1>
        <H2>new service</H2>
      </FirstLine>
      <TabBar />
      <MaybeMocked condition={MOCK_MARKETPLACE} mocks={mockMarketplace}>
        <ServiceDesigner formValues={formValues} shouldPersistState />
      </MaybeMocked>
    </Wrapper>
  );
}
