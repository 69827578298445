import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useQuery } from "@apollo/client";
import { AllUsersTableQuery, AllUsersTableQueryVariables } from "@src/generated/graphql";
import { AllUsersTable } from "@src/User/AllUsersTable.graphql";

import { allOrgsId, UserManagmentTableInner } from "./UserManagementTable";

export function AllUsersManagementTable() {
  const { data, loading } = useQuery<AllUsersTableQuery, AllUsersTableQueryVariables>(
    AllUsersTable,
    { errorPolicy: "all" }
  );

  const allUsers = (data?.users || []).filter(
    user => user.orgRoles?.length && user.orgRoles[0]?.id !== "*"
  );

  const navigate = useNavigate();
  const setOrg = useCallback(
    (orgId: string) => {
      navigate(`../${orgId}`);
    },
    [navigate]
  );

  return (
    <UserManagmentTableInner
      orgId={allOrgsId}
      users={allUsers}
      loadingUsers={loading}
      setOrg={setOrg}
    />
  );
}
