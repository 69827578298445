import React from "react";
import { Link } from "react-router-dom";

import { BlockWrapper } from "@src/Components/Grid/BlockWrapper";
import { StatusTag } from "@src/Components/StatusTag";
import { H3 } from "@src/Components/Text";
import { styled } from "@src/Components/theme";
import { TopLine } from "@src/Components/TopLine";
import { ServiceChain } from "@src/generated/graphql";

const Deployed = styled.p`
  margin-top: auto;

  ::before {
    content: "deployed: ";
  }
`;
const ElementLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const ServiceBlockWrapper = styled(BlockWrapper)`
  height: 100%;
  box-sizing: border-box;
`;

const InstanceName = styled(H3)`
  overflow: hidden;
  overflow-wrap: break-word;
`;
const InstanceTopLine = styled(TopLine)`
  gap: 10px;
`;

export function InstanceGridElement({ id, name, status, createdAt }: ServiceChain) {
  return (
    <ElementLink to={id}>
      <ServiceBlockWrapper key={id} title="view details">
        <InstanceTopLine>
          <InstanceName>{name}</InstanceName>
          <StatusTag status={status} />
        </InstanceTopLine>
        <Deployed>{new Date(createdAt).toLocaleString()}</Deployed>
      </ServiceBlockWrapper>
    </ElementLink>
  );
}
