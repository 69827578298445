import React from "react";

import { ParameterButton, ValueStatusEditor } from "@src/Components/BlockSelector/ConfigStyles";
import { EditingSection } from "@src/Publisher/Editor";
import { EditorStatus } from "@src/Publisher/editorPublisherReducer";

type EditorSectionButton = {
  section: EditingSection;
  sectionName: EditingSection;
  setSection: (section: EditingSection) => void;
  editorState?: EditorStatus;
  titleLabel?: string;
};

export default function EditorSectionButton({
  section,
  sectionName,
  setSection,
  editorState,
  titleLabel
}: EditorSectionButton) {
  return (
    <ParameterButton onClick={() => setSection(sectionName)} selected={section === sectionName}>
      {sectionName}
      {editorState && editorState !== EditorStatus.Default && (
        <ValueStatusEditor
          unsaved={editorState === EditorStatus.Unsaved}
          title={
            (editorState === EditorStatus.Saved ? "modified" : "unsaved changes to") +
            ` block ${titleLabel}`
          }
        >
          {editorState === EditorStatus.Saved && "M"}
          {editorState === EditorStatus.Unsaved && "⬤"}
        </ValueStatusEditor>
      )}
    </ParameterButton>
  );
}
