import React, { ChangeEvent, useCallback } from "react";

import { useQuery } from "@apollo/client";
import { ErrorLine } from "@src/Components/Input/InputGroup";
import { InlineLoading } from "@src/Components/Loading/Loading";
import { H3 } from "@src/Components/Text";
import { ProfileSelectQuery } from "@src/generated/graphql";

import { ProfileDetails } from "./ProfileDetails";
import query from "./ProfileSelect.graphql";

export interface Profile {
  name: string;
  version: string;
}

interface ProfileSelectProps {
  profile: Profile;
  setProfile: (profile: Profile) => void;
}

export function ProfileSelect({ profile, setProfile }: ProfileSelectProps) {
  const { data, loading } = useQuery<ProfileSelectQuery>(query);

  const onChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const profile: Profile = JSON.parse(e.target.value);
      setProfile(profile);
    },
    [setProfile]
  );

  return (
    <>
      <H3>Profile</H3>
      {loading ? (
        <InlineLoading />
      ) : data ? (
        <select onChange={onChange}>
          <option value="">select profile...</option>
          {data.provisionCharts.map(pc => {
            const key = `${pc.name}-${pc.version}`;
            return (
              <option key={key} value={JSON.stringify(pc)}>
                {key}
              </option>
            );
          })}
        </select>
      ) : (
        <ErrorLine>failed to load profiles</ErrorLine>
      )}

      {profile && <ProfileDetails {...profile} />}
    </>
  );
}
