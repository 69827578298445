import styled from "styled-components";
import { Input } from "./Input";

export const FileInput = styled(Input).attrs({ type: "file" })`
  padding: 8px 15px;

  ::file-selector-button {
    border: 2px solid #484886;
    padding: 0.2em 0.4em;
    border-radius: 0.2em;
    background-color: #484886;
    transition: 1s;
    color: #ffffff;
    transition: color 200ms ease-in-out;
  }

  ::file-selector-button:hover {
    color: #b5b7d0;
    cursor: pointer;
  }
`;
