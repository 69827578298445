import React from "react";

import { styled, Theme } from "@src/Components/theme";
import { Connection } from "@src/generated/graphql";
import { CopyButton } from "@src/Components/Buttons/Copy";

const colorFromStatus = ({ status, theme }: ConnectionMarkerProps & { theme: Theme }) => {
  switch (status) {
    case "updated":
      return theme.lightblue;
    case "deleted":
      return theme.error;
    case "new":
      return theme.success;
    default:
      return theme.border;
  }
};

const ConnectionMarkerContainer = styled.div<{ status: Status }>`
  position: relative;
  border: 1px solid;
  border-color: ${colorFromStatus};
  display: grid;
  grid-template-columns: 70px 1fr;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  min-width: 500px;
`;

const LabelConnection = styled.label`
  justify-self: end;
  margin-right: 10px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #858585;
  text-transform: capitalize;
`;

const PConnection = styled.p`
  margin-top: 0;
  display: inline;

  :last-child {
    margin: 0;
  }
`;

const ModifiedPConnection = styled.p`
  text-decoration: line-through;
  margin: 0;
`;

const ConnectionStatus = styled.span<{ status: Status }>`
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 10px;
  border-radius: 0 0 0 5px;
  color: white;
  text-transform: capitalize;
  font-weight: 600;
  background-color: ${colorFromStatus};
`;

const TextField = styled.div`
  text-overflow: ellipsis;
  overflow-x: auto;
  padding-bottom: 5px;

  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => theme.primary} ${({ theme }) => theme.backgroundLight};

  &::-webkit-scrollbar {
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.backgroundLight};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.primary};
    border-radius: 20px;
  }
`;

type Status = "new" | "deleted" | "unmodified" | "updated";

type UpdatedProps = {
  status: "updated";
  fieldDiff: Partial<Connection>;
};

type ExtraProps =
  | {
      status: "new" | "deleted" | "unmodified";
    }
  | UpdatedProps;

type ConnectionMarkerProps = ExtraProps & {
  connection: Connection;
};

function isUpdate(props: { status: Status }): props is UpdatedProps {
  return props.status === "updated";
}

export function ConnectionMarker({ connection, ...rest }: ConnectionMarkerProps) {
  const { status } = rest;
  const fieldDiff = isUpdate(rest) ? rest.fieldDiff : null;

  return (
    <ConnectionMarkerContainer status={status}>
      <ConnectionStatus status={status}>{status}</ConnectionStatus>
      {["name", "category", "kind", "source"].map((k: keyof Connection) => (
        <ConnectionFieldGroup key={k} label={k} conn={connection} fieldDiff={fieldDiff} />
      ))}
    </ConnectionMarkerContainer>
  );
}

interface ConnectionFieldGroupProps {
  label: keyof Connection;
  conn: Connection;
  fieldDiff?: Partial<Connection>;
}

function ConnectionFieldGroup({ label, conn, fieldDiff }: ConnectionFieldGroupProps) {
  const diff = fieldDiff?.[label];

  return (
    <>
      <LabelConnection>{label}</LabelConnection>
      <TextField>
        {diff && <ModifiedPConnection>{diff}</ModifiedPConnection>}
        <PConnection>{conn[label]}</PConnection>
      </TextField>
    </>
  );
}

const SmallConnectionMarkerContainer = styled.div`
  border: 1px solid #dddddd;
  border-radius: 5px;
  height: fit-content;

  p {
    margin: 0;
    font-weight: 500;
    word-break: break-all;
  }
`;

const ConnectionInfo = styled.div`
  display: grid;
  grid-template-columns: 70px 1fr;
  padding: 10px;
  border-top: 1px solid #dddddd;

  div {
    margin-bottom: 5px;
  }

  div:last-child {
    margin-bottom: 0;
  }
`;

const Title = styled.div`
  padding: 10px;
  width: 100%;
  font-weight: 500;
`;

interface ConnectionFieldProps {
  label: string;
  value: string;
}

function CopyConnectionField({ label, value }: ConnectionFieldProps) {
  return (
    <>
      <LabelConnection>{label}</LabelConnection>
      <div>
        <PConnection>{value}</PConnection>
        <CopyButton value={value} />
      </div>
    </>
  );
}

interface SmallConnectionMarkerProps {
  connection: Connection;
}

export function SmallConnectionMarker({ connection }: SmallConnectionMarkerProps) {
  return (
    <SmallConnectionMarkerContainer>
      <Title>{connection.name}</Title>
      <ConnectionInfo>
        <ConnectionFieldGroup label="category" conn={connection} />
        <ConnectionFieldGroup label="kind" conn={connection} />
        <CopyConnectionField label="source" value={connection.source} />
      </ConnectionInfo>
    </SmallConnectionMarkerContainer>
  );
}
