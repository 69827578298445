import React, { ChangeEvent } from "react";
import styled from "styled-components";

import { Select, Title } from "@src/Components/BlockSelector/BlockSelectorList";

const Theme = {
  "vs-dark": "visual studio dark",
  light: "visual studio"
};

const RightTitle = styled(Title)`
  margin-left: auto;
`;

interface ThemeSelectProps {
  changeTheme: (e: ChangeEvent<HTMLSelectElement>) => void;
}

export function ThemeSelect({ changeTheme }: ThemeSelectProps) {
  return (
    <>
      <RightTitle>theme</RightTitle>
      <Select name="theme" id="theme" onChange={changeTheme}>
        {Object.entries(Theme).map(([key, value]) => (
          <option value={key} key={key}>
            {value}
          </option>
        ))}
      </Select>
    </>
  );
}
