import { Link } from "react-router-dom";

import { styled } from "../theme";
import { primaryButtonStyles } from "./Primary";

export const PrimaryLinkButton = styled(Link)`
  ${primaryButtonStyles}

  line-height: 45px;
  text-align: center;
  text-decoration: none;
  font-size: 13px;
`;
