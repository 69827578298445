import React from "react";
import styled from "styled-components";

const ToastWrapper = styled.div`
  font-size: 16px;
  font-weight: 500;
`;
const ToastTitle = styled.h4`
  margin: 4px 0;
`;
const ToastMsg = styled.p`
  margin: 4px 0;
`;

type ToastProps = {
  title: string;
  msg?: string;
};

export const ToastNotification = ({ title, msg }: ToastProps) => {
  return (
    <ToastWrapper>
      <ToastTitle>{title}</ToastTitle>
      <ToastMsg>{msg && msg}</ToastMsg>
    </ToastWrapper>
  );
};
