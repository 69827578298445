import { Form, Formik, FormikProps } from "formik";
import { GraphQLError } from "graphql";
import React, { useCallback, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import * as Yup from "yup";

import { isApolloError, useMutation, useQuery } from "@apollo/client";
import { ButtonRowCustom, ButtonRowDefault } from "@src/Components/Buttons/ButtonRow";
import { DescriptionList } from "@src/Components/DescriptionList";
import { ErrorContainer } from "@src/Components/ErrorContainer";
import { FieldLabel, Fieldset, InputField } from "@src/Components/Input/InputGroup";
import { Loading } from "@src/Components/Loading/Loading";
import { H2, H3 } from "@src/Components/Text";
import {
  AddUserMutation,
  AddUserMutationVariables,
  FetchOrganizationsQuery,
  FetchOrganizationsQueryVariables,
  Role,
  Site
} from "@src/generated/graphql";
import { useToggle } from "@src/Hooks/toggle";
import FetchOrganizations from "@src/Settings/FetchOrganizations.graphql";
import { UserManagementContext } from "@src/Settings/userManagementContext";
import AddUser from "@src/User/AddUser.graphql";
import OrgUsersTable from "@src/User/OrgUsersTable.graphql";
import { Shape } from "@src/yupTypes";

import { OrgFormField } from "./OrgFormField";
import { UserRoleSelector } from "./UserRoleSelector";

export interface CreateUserInput {
  name: string;
  email: string;
  org: string;
  role: Role;
}

const schema = Yup.object<Shape<CreateUserInput>>().shape({
  name: Yup.string().required("name is required"),
  email: Yup.string().email("email format is not correct").required("email is required"),
  org: Yup.string().required("organization is required"),
  role: Yup.mixed().oneOf(Object.values(Role), "role is required")
});

export function AddUserForm() {
  const { orgId } = useParams<{ orgId: string }>();
  const isAllOrgs = orgId === "all-organizations";
  const { navigateToOrg } = useOutletContext<UserManagementContext>();
  const navigateBack = useCallback(() => navigateToOrg(orgId), [navigateToOrg, orgId]);

  const { data } = useQuery<FetchOrganizationsQuery, FetchOrganizationsQueryVariables>(
    FetchOrganizations
  );

  const organizations = (data?.orgs || []).map(o => ({ id: o.id, displayName: o.displayName }));

  const initialValues: CreateUserInput = {
    email: "",
    name: "",
    org: isAllOrgs ? "" : orgId,
    role: Role.Admin
  };
  const { state: showReviewScreen, toggle: toggleReviewScreen } = useToggle();
  const [addUser] = useMutation<AddUserMutation, AddUserMutationVariables>(AddUser);
  const [submitErrors, setSubmitErrors] = useState<Readonly<GraphQLError[]>>();

  return (
    <Formik<CreateUserInput>
      initialValues={initialValues}
      validationSchema={schema}
      validateOnMount
      onSubmit={async values => {
        setSubmitErrors(null);
        try {
          await addUser({
            variables: values,
            refetchQueries: [{ query: OrgUsersTable, variables: { org: values.org } }]
          });
          navigateBack();
        } catch (e) {
          if (isApolloError(e)) {
            setSubmitErrors(e.graphQLErrors);
          }
          console.error(e);
        }
      }}
    >
      {formikProps =>
        formikProps.isSubmitting ? (
          <Loading />
        ) : showReviewScreen ? (
          <ReviewForm
            {...formikProps}
            submitErrors={submitErrors}
            organizations={organizations}
            toggleReviewScreen={toggleReviewScreen}
          />
        ) : (
          <FormInner
            {...formikProps}
            organizations={organizations}
            navigateBack={navigateBack}
            toggleReviewScreen={toggleReviewScreen}
          />
        )
      }
    </Formik>
  );
}

interface FormInnerProps extends FormikProps<CreateUserInput> {
  navigateBack: () => void;
  toggleReviewScreen: () => void;
  organizations: Site[];
}

function FormInner({
  navigateBack,
  toggleReviewScreen,
  organizations,
  ...formikProps
}: FormInnerProps) {
  const { errors } = formikProps;

  return (
    <>
      <H2>Add new user</H2>
      <Form>
        <Fieldset>
          <FieldLabel htmlFor="name">name</FieldLabel>
          <InputField name="name" errors={errors} />
          <FieldLabel htmlFor="email">email</FieldLabel>
          <InputField type="email" name="email" errors={errors} />
          <OrgFormField orgs={organizations} errors={errors} />
          <FieldLabel htmlFor="role">role</FieldLabel>
          <UserRoleSelector name="role" errors={errors} />
          <ButtonRowCustom
            onClickBack={navigateBack}
            onClickSubmit={toggleReviewScreen}
            isValid={formikProps.isValid}
            isSubmitting={formikProps.isSubmitting}
          />
        </Fieldset>
      </Form>
    </>
  );
}

interface ReviewFormProps extends FormikProps<CreateUserInput> {
  toggleReviewScreen: () => void;
  organizations: Site[];
  submitErrors: readonly GraphQLError[];
}

function ReviewForm({
  toggleReviewScreen,
  submitErrors,
  organizations,
  ...formikProps
}: ReviewFormProps) {
  const { values, isSubmitting } = formikProps;
  const orgName = organizations?.find(o => o.id === values.org)?.displayName;
  return (
    <>
      <H2>Review user details</H2>
      {submitErrors ? (
        <ErrorContainer>
          <H3>Failed to create user</H3>
          <p>Please review the details and try again:</p>
          <ul>
            {submitErrors.map((err, i) => (
              <li key={i}>{err.message}</li>
            ))}
          </ul>
        </ErrorContainer>
      ) : null}
      <Form>
        <DescriptionList>
          <dt>Name</dt>
          <dd>{values.name}</dd>
          <dt>Email</dt>
          <dd>{values.email}</dd>
          <dt>Organization</dt>
          <dd>{orgName}</dd>
          <dt>Role</dt>
          <dd>{values.role}</dd>
        </DescriptionList>
        <ButtonRowDefault
          onClickBack={toggleReviewScreen}
          isSubmitting={isSubmitting}
          backText="Back"
          submitText="Add"
        />
      </Form>
    </>
  );
}
