import { useSelect, UseSelectStateChange } from "downshift";
import React, { useCallback } from "react";

import ArrowDownDD from "@img/arrow_light_down.svg";
import ArrowUpDD from "@img/arrow_light_up.svg";
import { DropdownMenuItemWrapper, Menu, MenuAnchor } from "@src/Components/DropdownMenu";
import { baseInputStyles } from "@src/Components/Input/Input";
import { styled } from "@src/Components/theme";

export const Select = styled.div`
  ${baseInputStyles}
  padding: 8px;
  height: auto;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SelectorWrapper = styled.div`
  min-width: 150px;
  max-width: 300px;
`;

const OrgSelectorMenuItemWrapper = styled(DropdownMenuItemWrapper)`
  font-size: 12px;
`;
interface Organization {
  id: string;
  displayName?: string;
}

export interface OrgSelectorProps {
  selectedOrg: string;
  organizations: Organization[];
  selectOrg: (org: string) => void;
}

export function OrgSelector({ selectedOrg, organizations, selectOrg }: OrgSelectorProps) {
  const onSelect = useCallback(
    ({ selectedItem: org }: UseSelectStateChange<Organization>) => {
      selectOrg(org.id);
    },
    [selectOrg]
  );
  const initialOrg = organizations.find(org => org.id === selectedOrg);
  const sortedOrgs = organizations
    .filter(org => org?.displayName)
    .sort((orgA, orgB) => orgA.displayName.localeCompare(orgB.displayName));

  const {
    getToggleButtonProps,
    selectedItem,
    getMenuProps,
    isOpen,
    highlightedIndex,
    getItemProps
  } = useSelect({
    items: sortedOrgs,
    initialSelectedItem: initialOrg,
    onSelectedItemChange: onSelect
  });

  return (
    <SelectorWrapper>
      <Select type="button" {...getToggleButtonProps()}>
        {selectedItem?.displayName || `select organization`}
        {isOpen ? <ArrowUpDD height={12} width={12} /> : <ArrowDownDD height={12} width={12} />}
      </Select>
      <MenuAnchor {...getMenuProps()}>
        {isOpen && (
          <Menu>
            {sortedOrgs.map((item, index) => (
              <OrgSelectorMenuItemWrapper
                key={item.id}
                highlighted={highlightedIndex === index}
                {...getItemProps({ item, index })}
              >
                {item.displayName}
              </OrgSelectorMenuItemWrapper>
            ))}
          </Menu>
        )}
      </MenuAnchor>
    </SelectorWrapper>
  );
}
