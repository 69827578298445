import React from "react";
import { Link } from "react-router-dom";

import { H2, H3 } from "@src/Components/Text";
import { styled } from "@src/Components/theme";

const Types = styled.div`
  display: grid;
  grid-auto-columns: 50%;
  grid-auto-flow: row;
  gap: 10px;
`;

const InventoryType = styled(Link)`
  color: initial;
  text-decoration: none;
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 3px;
  padding: 10px;

  :hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px;
  }
`;

const Description = styled.p``;

export function SelectInventoryType() {
  return (
    <>
      <H2>Select Inventory Type</H2>
      <Types>
        <InventoryType to="cloudlet">
          <H3>Kubernetes Cloudlet</H3>
          <Description>Provide Kubeconfig to onboard an existing Cloudlet</Description>
        </InventoryType>
        <InventoryType to="crossplane">
          <H3>Other Cloud Resources</H3>
          <Description>Register existing Cloud resources by specifying provider config</Description>
        </InventoryType>
        <InventoryType to="nztp">
          <H3>nZTP Provisioning</H3>
          <Description>Provision a new device</Description>
        </InventoryType>
        <InventoryType to="other">
          <H3>Other Devices</H3>
          <Description>Add a passive piece of infrastructure</Description>
        </InventoryType>
      </Types>
    </>
  );
}
