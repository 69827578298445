import { AuditLogEntry } from "@src/generated/graphql";

interface FlatAuditLogsEntry {
  Time: string;
  Reason: string;
  "Event Type": string;
  Message: string;
  "Entity Type": string;
  "Entity ID": string;
  "Entity Name": string;
  "User Id": string;
  "User Email": string;
  "Organization ID": string;
  "Organization Name": string;
}

function parseAuditLogs(logs: AuditLogEntry[]) {
  const parsedLogs: FlatAuditLogsEntry[] = [];

  // if one of them has a comma the parsing breaks
  logs.forEach(log => {
    const { entity } = log;
    const parsedLog = {
      Time: `"${log.time}"`,
      Reason: log.reason,
      "Event Type": log.type,
      Message: `"${log.message}"`,
      "Entity Type": log.entityType,
      "Entity ID": entity.id,
      "Entity Name": `"${entity.name}"`,
      "User Id": log.user.id,
      "User Email": log.user.email,
      "Organization ID": log.org.id,
      "Organization Name": `"${log.org.displayName}"`
    };
    parsedLogs.push(parsedLog);
  });
  return parsedLogs;
}

function convertToCSV(data: object[]) {
  const headers = Object.keys(data[0]);
  const csvString = [headers, ...data.map(item => Object.values(item))]
    .map(e => e.join())
    .join("\n");
  return csvString;
}

export function downloadLogsCSV(data: AuditLogEntry[]) {
  const parsedLogs = parseAuditLogs(data);
  const csvString = convertToCSV(parsedLogs);
  const blob = new Blob([csvString], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("download", "auditLogs.csv"); // this label can be more explicit (like saying the parameters and the actual date)
  a.style.display = "none";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
