import { permissions } from "@src/Auth/kratos";
import { useReactQuery } from "@src/reactQueryClient";
import { useSession } from "@src/Session";

export interface PermissionArgs {
  relation: string;
  objectId: string;
  objectType: string;
}

export function usePermissionCheck({ relation, objectId, objectType }: PermissionArgs) {
  const { session } = useSession();
  const userId = session?.identity?.id;

  const queryEnabled = !!userId && !!objectId && !!relation && !!objectType;

  const { data, isLoading: loading } = useReactQuery(
    [objectType, objectId, relation, userId],
    () =>
      permissions.checkPermission({
        namespace: objectType,
        object: objectId,
        relation: relation,
        subjectSetNamespace: "users",
        subjectSetObject: userId,
        subjectSetRelation: ""
      }),
    {
      enabled: queryEnabled
    }
  );

  const allowed = data?.data?.allowed;

  return { allowed, loading };
}
