import React, { useCallback } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { useIsMno } from "@src/Hooks/isMno";

import { FederationsContext } from "./federationsContext";
import { NoMnoPermissions } from "./NoMnoPermissions";

export function Federations() {
  const navigate = useNavigate();
  const navigateBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const { isMno } = useIsMno();

  const context: FederationsContext = {
    navigateBack
  };
  if (isMno == null) return null;

  return isMno ? <Outlet context={context} /> : <NoMnoPermissions />;
}
