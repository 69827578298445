import { Formik } from "formik";
import { GraphQLError } from "graphql";
import React, { useCallback, useReducer, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";

import { ApolloError, useMutation } from "@apollo/client";
import { Loading } from "@src/Components/Loading/Loading";
import {
  ProvisionCrossplaneMutation,
  ProvisionCrossplaneMutationVariables
} from "@src/generated/graphql";
import { useToggle } from "@src/Hooks/toggle";
import { InventoryContext } from "@src/Infrastructure/sites/Inventory";
import { initialMapState, mapReducer } from "@src/Map/mapReducer";
import { useMapSettings } from "@src/Map/mapState";

import { CrossplaneDeviceFormInner, CrossplaneReviewForm } from "./CrossplaneFormComponents";
import { addCrossplaneSchema as schema, ProvisionValues } from "./crossplaneUtils";
import ProvisionCrossplane from "./ProvisionCrossplane.graphql";

export function ProvisionCrossplaneForm() {
  const { returnToInventory: onComplete } = useOutletContext<InventoryContext>();
  const { siteId } = useParams<{ siteId: string }>();
  const [submitErrors, setSubmitErrors] = useState<Readonly<GraphQLError[]>>();
  const [mapState, dispatch] = useReducer(mapReducer, initialMapState);
  const { longitude: lng, latitude: lat } = mapState;
  const mapIsReady = useMapSettings(mapState, dispatch);

  const { state: showReviewScreen, toggle: toggleReviewScreen } = useToggle();

  const initialValues: ProvisionValues = {
    displayName: "",
    site: siteId,
    controllerSite: "",
    position: {
      lat,
      lng
    },
    chart: null,
    config: []
  };

  const [provision] = useMutation<
    ProvisionCrossplaneMutation,
    ProvisionCrossplaneMutationVariables
  >(ProvisionCrossplane);

  const onSubmit = useCallback(
    async (values: ProvisionValues) => {
      try {
        const configValues = values.config.map(({ label, value }) => ({
          label,
          value: String(value)
        }));
        await provision({
          variables: {
            input: {
              displayName: values.displayName.trim(),
              position: values.position,
              deviceSite: values.site,
              controllerSite: values.controllerSite,
              chartName: values.chart.name,
              chartVersion: values.chart.version,
              config: configValues
            }
          }
        });
        onComplete();
      } catch (e) {
        const err: ApolloError = e;
        setSubmitErrors(err.graphQLErrors);
      }
    },
    [onComplete, provision]
  );

  return !mapIsReady ? (
    <Loading />
  ) : (
    <Formik<ProvisionValues>
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {formikProps =>
        formikProps.isSubmitting ? (
          <Loading />
        ) : showReviewScreen ? (
          <CrossplaneReviewForm
            {...formikProps}
            toggleReviewScreen={toggleReviewScreen}
            submitErrors={submitErrors}
          />
        ) : (
          <CrossplaneDeviceFormInner
            {...formikProps}
            mapState={mapState}
            dispatch={dispatch}
            toggleReviewScreen={toggleReviewScreen}
            onComplete={onComplete}
          />
        )
      }
    </Formik>
  );
}
