import React from "react";
import styled from "styled-components";

import { GhostButton } from "@src/Components/Buttons/Ghost";
import { PrimaryButton } from "@src/Components/Buttons/Primary";
import { Title } from "@src/Components/Modal/Modal";
import { ExpandedObject } from "@src/Hooks/siteTree";
import { SiteItem, SiteTree, StyledTree } from "@src/Infrastructure/FileTree/SiteTree";

import { useSiteIdSelector } from "./siteIdSelector";

const TreeWrapper = styled.div`
  margin: 30px 0;
`;

const WrapperAlignRight = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

interface SiteBrowserProps {
  onSelectSite: (siteId: string, expanded?: ExpandedObject) => void;
  modalTitle: string;
  ancestors?: string[];
  selectedSite?: string;
}

export function SiteIdSelector({
  modalTitle,
  onSelectSite,
  ancestors,
  selectedSite
}: SiteBrowserProps) {
  const { treeProps, siteIdSelected, handleClick } = useSiteIdSelector({
    onSelectSite,
    ancestors,
    selectedSite
  });
  return (
    <>
      <Title>{modalTitle}</Title>
      <TreeWrapper>
        <SiteTree readOnly {...treeProps} />
      </TreeWrapper>
      <WrapperAlignRight>
        <PrimaryButton type="button" disabled={!siteIdSelected} onClick={handleClick}>
          OK
        </PrimaryButton>
      </WrapperAlignRight>
    </>
  );
}

interface SiteOnlyIdSelectorProps {
  onSelectSite: (siteId: string, expanded?: ExpandedObject) => void;
  modalTitle: string;
  ancestors?: string[];
  sites: string[];
  selectedSite?: string;
  toggleModal: () => void;
}

export function SiteOnlyIdSelector({
  modalTitle,
  onSelectSite,
  ancestors,
  selectedSite,
  sites,
  toggleModal
}: SiteOnlyIdSelectorProps) {
  const { treeProps, siteIdSelected, handleClick } = useSiteIdSelector({
    onSelectSite,
    ancestors,
    selectedSite
  });
  const validSiteSelected = selectedSite !== null || siteIdSelected !== ancestors[0];

  return (
    <>
      <Title>{modalTitle}</Title>
      <TreeWrapper>
        <StyledTree>
          {(sites || []).length > 0 ? (
            <>
              {sites.map(s => (
                <SiteItem key={s} siteId={s} ancestors={ancestors} readOnly {...treeProps} />
              ))}
            </>
          ) : (
            <>This Organization does not currently have any sites. Please add one and come back.</>
          )}
        </StyledTree>
      </TreeWrapper>
      <WrapperAlignRight>
        {sites.length !== 0 ? (
          <PrimaryButton type="button" disabled={!validSiteSelected} onClick={handleClick}>
            OK
          </PrimaryButton>
        ) : (
          <GhostButton type="button" onClick={toggleModal}>
            Close
          </GhostButton>
        )}
      </WrapperAlignRight>
    </>
  );
}
