import { FormikErrors } from "formik";

import {
  Block,
  BlockInput,
  DeployServiceChainMutationVariables,
  ServiceChain
} from "@src/generated/graphql";

export type FormBlock = Pick<Block, "id" | "displayName" | "selectedVersion" | "values"> & {
  name: string; // name of the blockChart. Used to fetch the chart fields in the designer tab. e.g: OktoAmbientalE2ETestingApplicationOktoSignature
};

export type ServiceForm = {
  name: string;
  blocks: {
    [key: string]: FormBlock;
  };
};

export function validate(form: ServiceForm): FormikErrors<ServiceForm> {
  const errors: { [field: string]: string } = {};
  if (form.name === "") errors.name = "name cannot be empty";
  if (Object.values(form.blocks).length === 0)
    errors.blocks = "at least one block is needed to deploy a service";

  const blockErrors = Object.entries(form.blocks).reduce((errors, [id, block]) => {
    const fieldErrors: Partial<Block> = {};
    if (block.values === "") fieldErrors.values = "block values cannot be empty";
    if (!block.selectedVersion) fieldErrors.selectedVersion = "selected version is needed";
    const hasFieldErrors = Object.values(fieldErrors).some(err => err != null);
    return hasFieldErrors
      ? {
          ...errors,
          [id]: {
            ...(hasFieldErrors ? fieldErrors : null)
          }
        }
      : errors;
  }, {});

  if (Object.keys(blockErrors).length) {
    return {
      ...errors,
      blocks: blockErrors
    };
  }
  return errors;
}

export function deserialiseFromEdit(serviceChain: ServiceChain): ServiceForm {
  const blocks: { [key: string]: FormBlock } = {};
  (serviceChain.blocks || []).forEach(b => {
    blocks[b.displayName] = {
      ...b,
      name: b.chart.name
    };
  });
  return {
    name: serviceChain.name,
    blocks
  };
}

export function serialiseBlocks(blocks: { [key: string]: FormBlock }): BlockInput[] {
  return Object.values(blocks).map(b => ({
    id: b.id,
    values: b.values || "",
    displayName: b.displayName,
    blockChartName: b.name,
    blockChartVersion: b.selectedVersion
  }));
}

export function serialise(data: ServiceForm): DeployServiceChainMutationVariables {
  return {
    name: data.name.trim(),
    blocks: serialiseBlocks(data.blocks)
  };
}
