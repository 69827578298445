import React, { useCallback } from "react";
import toast from "react-hot-toast";

import { useApolloClient, useMutation } from "@apollo/client";
import { ToastNotification } from "@src/Components/ToastNotification";
import { SelectOrgMutation, SelectOrgMutationVariables } from "@src/generated/graphql";
import { useSession } from "@src/Session";
import SelectOrg from "@src/User/SelectOrg.graphql";

export function useSelectOrg() {
  const { setOrgSession } = useSession();
  const [selectOrgMtn] = useMutation<SelectOrgMutation, SelectOrgMutationVariables>(SelectOrg);
  const client = useApolloClient();
  const selectOrg = useCallback(
    (org: string) => {
      selectOrgMtn({ variables: { org } }).then(({ data }) => {
        const orgName = data?.setCurrentOrg?.loggedInOrg?.displayName;
        setOrgSession(data?.setCurrentOrg?.loggedInOrg?.id);
        toast.success(<ToastNotification title={`Switched to ${orgName}`} />);
        return client.resetStore();
      });
    },
    [client, selectOrgMtn, setOrgSession]
  );

  return {
    selectOrg
  };
}
