import { FormikProps } from "formik";
import React, { useCallback, useState } from "react";

import { useLazyQuery } from "@apollo/client";
import { Block } from "@src/Components/BlockSelector/Block";
import { AddButton, AddMoreButton, Instances } from "@src/Components/BlockSelector/BlockStyles";
import { ErrorMessage } from "@src/Components/ErrorMessage";
import {
  BlockChart,
  FetchBlockInformationQuery,
  FetchBlockInformationQueryVariables
} from "@src/generated/graphql";

import FetchBlockInformation from "./FetchBlockInformation.graphql";
import { ServiceForm } from "./serialise";

interface DesignerBlockProps extends FormikProps<ServiceForm> {
  view: number;
  block: BlockChart;
  openBlockInformation: (blockName: string, version: string) => void;
}

export function DesignerBlock({
  view,
  block,
  openBlockInformation,
  ...formikProps
}: DesignerBlockProps) {
  const {
    values: { blocks },
    setFieldValue
  } = formikProps;
  const { displayName, name, version } = block;

  const [error, setError] = useState<string>(null);

  const [query, { loading }] = useLazyQuery<
    FetchBlockInformationQuery,
    FetchBlockInformationQueryVariables
  >(FetchBlockInformation);
  const addBlock = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setError(null);
      query({
        variables: {
          name,
          version
        }
      }).then(({ data, error }) => {
        if (error) {
          setError("error adding chart instance");
        }
        let newDisplayName = displayName;
        if (blocks[displayName]) {
          let i = 2;
          while (blocks[displayName + ` (${i})`]) i++;
          newDisplayName = displayName + ` (${i})`;
        }
        setFieldValue("blocks", {
          ...blocks,
          [newDisplayName]: {
            id: null,
            displayName: newDisplayName,
            selectedVersion: version,
            values: data.blockChart.overridesYaml || "",
            name
          }
        });
      });
    },
    [displayName, blocks, query, name, version, setFieldValue]
  );

  const instances = Object.values(blocks).filter(fB => fB.name === name).length;

  return (
    <Block view={view} openBlockInformation={openBlockInformation} block={block}>
      <Instances>
        {instances === 0 ? (
          <AddButton onClick={addBlock}>+ add to designer</AddButton>
        ) : (
          <>
            <strong>{instances}</strong> instance{instances !== 1 ? "s" : ""}
            <AddMoreButton onClick={addBlock} disabled={loading}>
              add
            </AddMoreButton>
          </>
        )}
      </Instances>
      {error && <ErrorMessage error={error} />}
    </Block>
  );
}
