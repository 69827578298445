import { parseQuickRangeOption, validTimeStrings } from "./rangeOptions";

export function dateToFormattedString(timeStamp: number) {
  const date = new Date(timeStamp);
  return (
    [
      date.getFullYear(),
      addZeroIfOneDigit(date.getMonth() + 1),
      addZeroIfOneDigit(date.getDate())
    ].join("/") +
    " " +
    [
      addZeroIfOneDigit(date.getHours()),
      addZeroIfOneDigit(date.getMinutes()),
      addZeroIfOneDigit(date.getSeconds())
    ].join(":")
  );
}

function addZeroIfOneDigit(n: number) {
  return n >= 10 ? n : `0${n}`;
}

export function validateParams(from: string, to: string, pageSize: string) {
  const isStartValid = !!Number(from) || validTimeStrings.includes(from);
  const isEndValid = !!Number(to) || validTimeStrings.includes(to);
  const isDateConsistent =
    Number(from) < Number(to) || validTimeStrings.indexOf(from) > validTimeStrings.indexOf(to);
  const pageSizeValid = !!Number(pageSize) && Number(pageSize) > 0;

  return isStartValid && isEndValid && isDateConsistent && pageSizeValid;
}

export function calculateCalendarState(from: string, to: string): [Date, Date] {
  const isQuickRange = !Number(from) && !Number(to);
  if (isQuickRange)
    return [new Date(parseQuickRangeOption(from)), new Date(parseQuickRangeOption(to))];

  return [new Date(Number(from)), new Date(Number(to))];
}

export function ISOTimeStringToTimeStamp(time: string) {
  return new Date(time).getTime();
}
