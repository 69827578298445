import "react-resizable/css/styles.css";
import "./resizable.css";

import React, { CSSProperties, useCallback } from "react";
import { ResizableBox, ResizeCallbackData } from "react-resizable";

import { useQuery } from "@apollo/client";
import { Loading } from "@src/Components/Loading/Loading";
import { H3 } from "@src/Components/Text";
import { styled } from "@src/Components/theme";
import { FetchPanelLogsQuery, FetchPanelLogsQueryVariables } from "@src/generated/graphql";

import FetchPanelLogs from "./FetchPanelLogs.graphql";
import { PanelLogsTable } from "./PanelLogsTable";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  box-sizing: border-box;
  overflow: auto;
`;

const FirstLine = styled.div`
  position: sticky;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px 10px 40px;
  box-sizing: border-box;
  top: 0;
  background-color: white;
  border-bottom: 1px solid #ccc;
`;

const Cross = styled.span`
  cursor: pointer;
  font-size: 20px;
`;

const PanelTitle = styled(H3)`
  margin: 0;
`;

const resizableBoxStyles: CSSProperties = {
  backgroundColor: "white",
  gridArea: "panel",
  position: "sticky",
  bottom: 0,
  boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.25)"
};

interface LogsPanelProps {
  close: () => void;
}

export function LogsPanel({ close }: LogsPanelProps) {
  const { data, loading } = useQuery<FetchPanelLogsQuery, FetchPanelLogsQueryVariables>(
    FetchPanelLogs,
    {
      errorPolicy: "all",
      fetchPolicy: "network-only",
      pollInterval: 10000
    }
  );

  const logs = (data?.auditLogs?.edges || []).map(({ node }) => node);

  const handleResize = useCallback(
    (e: never, data: ResizeCallbackData) => {
      const height = data.size.height;
      if (height < 200) close();
    },
    [close]
  );

  return (
    <ResizableBox
      width={Infinity}
      height={500}
      resizeHandles={["n"]}
      axis="y"
      style={resizableBoxStyles}
      onResize={handleResize}
    >
      <Wrapper>
        <FirstLine>
          <PanelTitle>Recent Logs</PanelTitle>
          <Cross title="close" onClick={close}>
            ✕
          </Cross>
        </FirstLine>
        {loading ? <Loading /> : <PanelLogsTable logs={logs} />}
      </Wrapper>
    </ResizableBox>
  );
}
