import { Formik } from "formik";
import React from "react";
import * as Yup from "yup";

import { BlockPublisherForm } from "./BlockPublisherForm";
import { usePublishBlock } from "./publishBlock";
import { BlockPublisherForm as BlockPublisherFormType } from "./serialise";

interface BlockPublisherProps {
  formValues: BlockPublisherFormType;
}

const BlockPublishSchema = Yup.object().shape({
  templateName: Yup.string().required("template name cannot be empty"),
  selectedVersion: Yup.string().required("selected version is needed"),
  values: Yup.string().required("template values cannot be empty"),
  overrides: Yup.string(),
  descriptor: Yup.object()
    .required("block descriptor cannot be empty")
    .shape({
      name: Yup.string().required("block descriptor name cannot be empty"),
      version: Yup.string().required("block descriptor version cannot be empty"),
      displayName: Yup.string().required("block descriptor display name cannot be empty"),
      vendor: Yup.string().required("block descriptor vendor cannot be empty"),
      description: Yup.string().required("block descriptor description cannot be empty"),
      categories: Yup.array()
        .required("block descriptor categories cannot be empty")
        .min(1, "block descriptor should have at least 1 category")
    })
});

export function BlockPublisher({ formValues }: BlockPublisherProps) {
  const { publish, publishState, setPublishState } = usePublishBlock();

  return (
    formValues && (
      <Formik<BlockPublisherFormType>
        initialValues={formValues}
        onSubmit={publish}
        validationSchema={BlockPublishSchema}
        validateOnMount={true}
      >
        {formikProps => (
          <BlockPublisherForm
            publishState={publishState}
            setPublishState={setPublishState}
            {...formikProps}
          />
        )}
      </Formik>
    )
  );
}
