import React from "react";

import { DeviceStatus, Status } from "../generated/graphql";
import { styled } from "./theme";

interface StatusTagProps {
  status: Status;
}

export const Tag = styled.span`
  display: inline-block;
  height: 20px;
  line-height: 20px;
  font-size: 9px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-align: center;
  text-transform: uppercase;
  padding: 0 16px;
  border-radius: 10px;
  background-color: #ffffff;
`;

const Deploying = styled(Tag)`
  border: solid 1px ${({ theme }) => theme.success};
  color: ${({ theme }) => theme.success};
`;

const Undeploying = Deploying;

const Provisioning = styled(Tag)`
  border: solid 1px ${({ theme }) => theme.lightblue};
  color: ${({ theme }) => theme.lightblue};
`;

const Ready = styled(Tag)`
  background-color: ${({ theme }) => theme.success};
  color: white;
`;

const Failure = styled(Tag)`
  background-color: ${({ theme }) => theme.failure};
  color: white;
`;

const Unknown = styled(Tag)`
  border: 1px solid ${({ theme }) => theme.grey};
  color: #ccc;
`;

const Pending = Unknown;

export function StatusTag({ status }: StatusTagProps) {
  switch (status) {
    case Status.Deploying:
      return <Deploying>deploying</Deploying>;
    case Status.Ready:
      return <Ready>ready</Ready>;
    case Status.Undeploying:
      return <Undeploying>undeploying</Undeploying>;
    case Status.Failure:
      return <Failure>failure</Failure>;
    case Status.Pending:
      return <Pending>pending</Pending>;
    case Status.Unknown:
    default:
      return <Unknown>unknown</Unknown>;
  }
}

interface DeviceStatusTagProps {
  status: DeviceStatus;
}

export function DeviceStatusTag({ status }: DeviceStatusTagProps) {
  switch (status) {
    case DeviceStatus.Deploying:
      return <Provisioning>provisioning</Provisioning>;
    case DeviceStatus.Ready:
      return <Ready>ready</Ready>;
    case DeviceStatus.Unknown:
    default:
      return <Unknown>unknown</Unknown>;
  }
}
