import React from "react";
import { css } from "styled-components";

import { UiText } from "@ory/client";
import { styled } from "@src/Components/theme";

interface MessageProps {
  type: string;
  gridColumn?: number;
}

const Message = styled.p<MessageProps>`
  ${({ gridColumn }) =>
    gridColumn
      ? css`
          grid-column: ${gridColumn};
        `
      : null}
  color: ${({ theme, type }) => type === "error" && theme.error};
`;

interface KratosMessagesProps {
  messages: UiText[];
  gridColumn?: number;
}

export const KratosMessages = ({ messages, gridColumn }: KratosMessagesProps) => (
  <>
    {messages?.map(({ text, id, type }) => (
      <Message key={id} type={type} gridColumn={gridColumn}>
        {text}
      </Message>
    ))}
  </>
);
