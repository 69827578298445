import * as Yup from "yup";

import { Connection, Device } from "@src/generated/graphql";
import { Shape } from "@src/yupTypes";

export const connectionSchema = Yup.object<Shape<Connection>>().shape({
  id: Yup.string(),
  name: Yup.string().required("name is required"),
  category: Yup.string().required("category is required"),
  kind: Yup.string().required("kind is required"),
  source: Yup.string().required("source is required")
});

export function deviceConnections(device: Device) {
  return device?.connections?.filter(
    c => c.category !== "url.telemetry" && connectionSchema.isValidSync(c)
  );
}
