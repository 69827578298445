import { useCallback, useRef } from "react";

export enum UploadFieldType {
  Json = "JSON",
  Yaml = "YAML"
}

export interface FileUploadProps {
  type: UploadFieldType;
  setValue: (value: string) => void;
  setError: (error: string) => void;
}

export function useFileUpload({
  type,
  setValue,
  setError
}: FileUploadProps): (e: React.ChangeEvent<HTMLInputElement>) => void {
  const readerRef = useRef(new FileReader());

  const handleContentFile = useCallback(() => {
    if (type === UploadFieldType.Json) {
      try {
        const result = readerRef.current.result.toString();
        const parsed = JSON.parse(result);
        setValue(JSON.stringify(parsed, null, 2));
      } catch {
        setError("invalid JSON value in file");
      }
    } else {
      setValue(readerRef.current.result.toString());
    }
  }, [type, setError, setValue]);

  readerRef.current.onloadend = handleContentFile;

  const handleFileUpload = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0];
    readerRef.current.readAsText(file);
  }, []);

  return handleFileUpload;
}
