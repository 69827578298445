import React, {
  Dispatch,
  forwardRef,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { Map, MapLayerMouseEvent, MapRef, ViewStateChangeEvent } from "react-map-gl";

import { useQuery } from "@apollo/client";
import { SecondaryButton } from "@src/Components/Buttons/Secondary";
import { Loading } from "@src/Components/Loading/Loading";
import { MapOverlayText } from "@src/Components/Text";
import { styled } from "@src/Components/theme";
import { MapSettingsQuery } from "@src/generated/graphql";
import { useMapboxApiToken } from "@src/Hooks/apiToken";
import { useKeyPress } from "@src/Hooks/keyPress";
import { checkMapReady, MapAction, MapState } from "@src/Map/mapReducer";
import MapSettings from "@src/Settings/Settings.graphql";

const MapContainer = styled.div`
  height: 600px;
  grid-column: 1 / -1;
  display: grid;
  position: relative;
`;

export const MapButton = styled(SecondaryButton).attrs({ type: "button" })`
  width: 100px;
  height: 30px;
  margin-left: 5px;
`;

const ResetPosition = styled(MapButton)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const ScrollInfo = styled(MapOverlayText)`
  top: 10px;
  left: 10px;
`;

interface MapboxMapProps {
  mapState: MapState;
  dispatch: Dispatch<MapAction>;
  onClick?: (e: MapLayerMouseEvent) => void;
  children?: ReactNode;
  interactiveLayerIds?: string[];
}

export const MapboxMap = forwardRef(function MapboxMap(
  { mapState, dispatch, onClick, children, interactiveLayerIds = [] }: MapboxMapProps,
  ref: RefObject<MapRef>
) {
  const container = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const { clientHeight: height, clientWidth: width } = container.current;
    if (height !== mapState.height || width != mapState.width) {
      dispatch({ type: "setDimensions", width, height });
    }
  });

  const onViewportChange = useCallback(
    (e: ViewStateChangeEvent) => {
      const { latitude, longitude, zoom } = e.viewState;
      dispatch({
        type: "setViewport",
        center: { lat: latitude, lng: longitude },
        zoom
      });
    },
    [dispatch]
  );

  const apiToken = useMapboxApiToken();
  const mapIsReady = apiToken && checkMapReady(mapState);

  const { data: mapSettingsData } = useQuery<MapSettingsQuery>(MapSettings);

  const [cursor, setCursor] = useState<string>("");
  const onMouseEnter = useCallback(() => setCursor("default"), [setCursor]);
  const onMouseLeave = useCallback(() => setCursor(""), [setCursor]);

  const resetMap = useCallback(() => {
    if (mapState.resetPosition) {
      const { latitude, longitude, zoom } = mapState.resetPosition;
      dispatch({
        type: "setViewport",
        center: { lat: latitude, lng: longitude },
        zoom
      });
    } else {
      const map = mapSettingsData?.settings?.map;
      if (!map) return;
      dispatch({
        type: "setViewport",
        center: { lat: map.center.lat, lng: map.center.lng },
        zoom: map.zoom
      });
    }
  }, [dispatch, mapSettingsData?.settings?.map, mapState.resetPosition]);

  const localRef = useRef<MapRef>();
  const mapRef = ref || localRef;
  useEffect(() => {
    if (mapState.shouldFly) {
      const { longitude, latitude, zoom } = mapState.shouldFly;
      mapRef.current?.flyTo({ center: [longitude, latitude], zoom, duration: 1000 });
      dispatch({
        type: "setViewport",
        center: { lat: latitude, lng: longitude },
        zoom
      });
    }
  }, [mapRef, mapState.shouldFly, dispatch]);

  const ctrlKeyPressed = useKeyPress("Control");

  return (
    <MapContainer ref={container}>
      {mapIsReady ? (
        <Map
          ref={mapRef}
          onClick={onClick}
          mapboxAccessToken={apiToken}
          scrollZoom={ctrlKeyPressed}
          {...mapState}
          onMove={onViewportChange}
          attributionControl
          interactiveLayerIds={interactiveLayerIds}
          cursor={cursor}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {children}
          <ResetPosition onClick={resetMap}>reset view</ResetPosition>
          <ScrollInfo>Zoom: Ctrl+Scroll</ScrollInfo>
        </Map>
      ) : (
        <Loading />
      )}
    </MapContainer>
  );
});
