import React from "react";
import styled from "styled-components";

const CheckboxLabel = styled.label`
  display: flex;
  cursor: pointer;
  align-items: baseline;
`;

const CheckboxInput = styled.input.attrs({ type: "checkbox" })`
  position: absolute;
  opacity: 0;

  & + span {
    position: relative;
    width: 24px;
    height: 12px;
    margin-top: 3px;
    display: block;
    background-color: #77869c;
    border-radius: 6px;
    transition: background 0.2s ease;

    ::before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 12px;
      height: 12px;
      display: block;
      background-color: #2e3789;
      border-radius: 10px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      transition: all 0.2s ease;
    }

    ::after {
      content: "";
      position: absolute;
      display: block;
      width: 32px;
      height: 32px;
      background: rgba(79, 46, 220, 0.5);
      border-radius: 50%;
      transform: scale(0);
      opacity: 1;
      pointer-events: none;
      top: -12px;
    }
  }

  &:checked + span {
    background: rgba(154, 92, 150, 0.6);

    ::before {
      background-color: #662099;
      transform: translateX(12px);
      transition: all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25), background 0.15s ease;
    }

    ::after {
      transform: scale(1);
      opacity: 0;
      transition: all 0.4s ease;
    }
  }
`;
export const CheckBoxWrapper = styled.div`
  display: flex;
  gap: 6px;
  padding-bottom: 10px;
  vertical-align: baseline;
`;
export const CheckLabel = styled.span`
  font-weight: 500;
`;

interface CheckboxSliderProps {
  name: string;
  checked: boolean;
  onChange: () => void;
  labelLeft: string;
  labelRight: string;
}

export function CheckboxSlider({ labelLeft, labelRight, ...props }: CheckboxSliderProps) {
  return (
    <>
      {labelLeft}
      <CheckboxLabel>
        <CheckboxInput {...props} />
        <span />
      </CheckboxLabel>
      {labelRight}
    </>
  );
}
